import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { P2Text } from '../Typography/styled';

import colors2 from '../../utils/theme/theme2/colors2';

const PagingLine = styled(P2Text)`
  position: relative;
  border-top: 2px solid ${(props) => (props.pagingLineColor ? props.pagingLineColor : colors2.gray)};
  width: ${(props) => props.pagingLineWidth}px;
  height: 0;
  margin-left: 10px;
`;

const AnimatedPagingLine = styled(P2Text)`
  position: absolute;
  top: -3.5px;
  border-top: 4px solid
    ${(props) => (props.pagingAnimatedLineColor ? props.pagingAnimatedLineColor : colors2.black)};
  width: ${(props) => props.pagingAnimatedLineWidth}px;
  transition: width 1s;
`;

const SliderPagingLine = ({
  pagingLineWidth,
  pagingAnimatedLineWidth,
  pagingLineColor,
  pagingAnimatedLineColor,
}) => (
  <PagingLine pagingLineWidth={pagingLineWidth} pagingLineColor={pagingLineColor}>
    <AnimatedPagingLine
      as="span"
      pagingAnimatedLineWidth={pagingAnimatedLineWidth}
      pagingAnimatedLineColor={pagingAnimatedLineColor}
    />
  </PagingLine>
);

SliderPagingLine.propTypes = {
  pagingLineWidth: PropTypes.number,
  pagingAnimatedLineWidth: PropTypes.number,
  pagingLineColor: PropTypes.string,
  pagingAnimatedLineColor: PropTypes.string,
};

SliderPagingLine.defaultProps = {
  pagingLineWidth: 130,
  pagingAnimatedLineWidth: 0,
  pagingLineColor: colors2.lightViolet,
  pagingAnimatedLineColor: colors2.black,
};

export default SliderPagingLine;
