import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H2Text, H4Text } from '../Typography/styled';

export const SubmitBtn = styled.button`
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;

  background: ${colors2.black};
  border-radius: 10px;
  color: ${colors2.white};
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  transition: all 0.7s;
  border: 1px solid transparent;

  &:hover {
    color: ${colors2.black};
    background-color: ${colors2.white};
    border: 1px solid ${colors2.black};
  }
`;

export const DropUs = styled.div`
  max-width: 800px;
  width: 100%;

  .dropus_block__bottom {
    display: flex;
    gap: 40px;
    justify-content: space-between;
  }
  .email {
    margin-top: 60px;
    align-items: center;
  }

  .address-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  @media ${breakpoints.md} {
    display: flex;
    flex-direction: column;

    & ${H2Text} {
      font-size: 40px;
      line-height: 130%;
    }
    & ${H4Text} {
      font-weight: 500;
      font-size: 16px;
      line-height: 165%;
    }
  }
  @media ${breakpoints.mds} {
    display: flex;
    flex-direction: column;

    .email {
      margin-top: 30px;
    }

    & ${H2Text} {
      font-size: 20px;
      line-height: 130%;
    }
    & ${H4Text} {
      font-weight: 500;
      font-size: 14px;
      line-height: 165%;
    }
    & .dropus_block__bottom {
      flex-direction: column;
    }
  }
  @media ${breakpoints.sm} {
    .email {
      gap: 10px;
      & ${H2Text} {
        font-size: 16px;
      }
    }
  }
`;

export const Contact = styled.div`
  display: inline-grid;
  grid-template-columns: 66px 1fr;
  gap: 20px;
  align-items: start;

  a {
    text-decoration: none;
  }

  > :first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors2.lightGray};
    width: 66px;
    height: 66px;
    border-radius: 100%;
  }

  .phones {
    display: flex;
    flex-direction: column;
    gap: 20px;
    white-space: nowrap;
  }

  @media ${breakpoints.sm} {
    > :first-child {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .email {
      gap: 10px;
    }
  }
`;

export const Divider = styled.div`
  border-top: 1px solid rgba(38, 38, 38, 0.12);
  margin: 40px 0;
  width: 100%;
  @media ${breakpoints.md} {
    margin: 20px 0;
  }
`;
