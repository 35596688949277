import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Partners } from './styled';

export const partnersQuery = graphql`
  {
    algosec: file(relativePath: { eq: "images/updateDesign/algosec.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    vmware: file(relativePath: { eq: "images/updateDesign/vmware.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    autodesk: file(relativePath: { eq: "images/updateDesign/autodesk.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    itv: file(relativePath: { eq: "images/updateDesign/itv.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const PartnersBlock = () => {
  const { vmware, autodesk, algosec, itv } = useStaticQuery(partnersQuery);

  const techList = [
    { name: 'algosec', icon: algosec },
    { name: 'vmware', icon: vmware },
    { name: 'autodesc-c', icon: autodesk },
    { name: 'itv', icon: itv },
  ];
  return (
    <Partners>
      {techList.map((el, ind) => {
        return (
          <GatsbyImage
            image={el.icon.childImageSharp.gatsbyImageData}
            alt={el.name}
            key={`partners-img_${ind}`}
          />
        );
      })}
    </Partners>
  );
};

export default PartnersBlock;
