import styled from 'styled-components';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import colors2 from '../../utils/theme/theme2/colors2';

export const Tags = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 40px;

  > div {
    padding: 15px 30px;
    border: solid 1px transparent;
    border-radius: 10px;
    cursor: pointer;
    text-transform: capitalize;
    span {
      color: ${colors2.lightText};
    }

    @media ${breakpoints.mds} {
      padding: 15px;
    }
  }

  .selected {
    border: solid 1px rgba(38, 38, 38, 0.12);
    span {
      color: ${colors2.black};
    }
  }

  @media ${breakpoints.mds} {
    margin-bottom: 20px;
  }
`;

export const TopWrapper = styled.div`
  align-self: start;
`;
export const Tools = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .dropdown {
    max-width: 290px;
  }

  @media ${breakpoints.mds} {
  }

  @media ${breakpoints.sm} {
    flex-direction: column;
    align-items: center;

    & > :last-child {
      margin-bottom: 20px;
    }
    .dropdown {
      max-width: none;
    }
  }
`;
