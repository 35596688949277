import React from 'react';

import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import ColorBlock from '../ColorBlock';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import ProcessIcon from '../../assets/icons/updateDesign/aboutUs/processIcon.svg';

export const Wrapper = styled.div`
  .description_wrapper,
  .titleInfo {
    position: relative;
    max-width: 687px;
    width: 100%;
    z-index: 2;
  }
  .color-block_wrapper {
    & .color-block_img {
      z-index: 0;
    }
  }

  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 80px;
  }

  .step {
    display: flex;
    position: relative;
    margin-bottom: 110px;
    padding: 0 8%;
    .divider {
      position: absolute;
      left: 75px;
      top: 150px;
      width: 1px;
      height: 90px;
      background-color: ${colors2.lightGray};
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .numberOfStep {
      font-weight: 400;
      font-size: 18px;
      line-height: 165%;
      color: ${colors2.lightText};
      margin-bottom: 30px;
    }

    .title {
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: ${colors2.black};
      margin-bottom: 20px;
    }

    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 165%;
      color: ${colors2.lightText};
    }

    &:not(:last-child) {
      .divider {
        display: none;
      }
    }
  }

  .number {
    width: 150px;
    height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 60px;
    margin-top: 45px;
  }
  @media ${breakpoints.mds} {
    gap: 60px;
    .step {
      flex-direction: column;
      margin-bottom: 0px;

      padding: 0 30px;
    }
    .number {
      width: 70px;
      height: 70px;
      gap: 30px;
      margin-right: 0px;
      margin-top: 0px;
    }
    .numberOfStep {
      margin-bottom: 0px;
      margin-top: 30px;
    }
  }
`;

const steps = [
  {
    number: 'step 1',
    icon: <ProcessIcon />,
    title: 'Pre-planning stage',
    text: 'We do not do a full-fledged TOR (terms of reference). As obligatory part we develop vocabulary of terms for the project, so we could speak the same language with you. We build technical architecture solution (list of tech decisions).',
  },
  {
    number: 'step 2',
    icon: <ProcessIcon />,

    title: 'Initial planning',
    text: 'We do planning 2 sprints ahead. What will be done on each sprint according to the existing priorities is approved by you. We estimate complexity of the selected features and effort of implementation. If the estimations exceed time borders we revise set of features with you and highlight the features to be done first and fit to the time sprint. On the daily meetings (project status) each member of the team announce detailed plan of actions for the day. For the efficient project planning and bug tracking we use professional project management software like Youtrack and Jira.',
  },
  {
    number: 'step 3',
    icon: <ProcessIcon />,
    title: 'Development process',
    text: 'Development of required components. Process is organized with two-week sprints. At the end of each sprint we do a demo, budget update, visual changelog and planning of the next sprint.',
  },
  {
    number: 'step 4',
    icon: <ProcessIcon />,
    title: 'Quality assurance',
    text: 'Verification of the system performance and results of development to ensure timely bug fixing, support and stabilization of the entire system. Quality control is an ongoing process, undertaking on each step.',
  },
  {
    number: 'step 5',
    icon: <ProcessIcon />,
    title: 'Integration and support',
    text: 'Integration with your existing environment (with your DMS and CRM). We provide system support and maintenance to make sure system works smoothly.',
  },
];

const HowWeWork = ({ image }) => {
  return (
    <Wrapper>
      <ColorBlock
        title="Processes & Methodology"
        description="We are a small company and from the very beginning, we decided not to follow the modern management trends. For example, we do not implement Scrum from a book or the training, but we strive to create conditions for deploying the best practices.To do so we have developed our own values - what is good and what is bad. We also implemented technology of retrospectives, which has given us everything that we need for successful work."
        image={image}
        bgColor={colors2.lightGreen}
      />
      {steps.map((step, idx) => {
        return (
          <div className="step" key={idx}>
            <div className="number">{step.icon}</div>
            <div className="info">
              <p className="numberOfStep">{step.number}</p>
              <p color={colors2.black} className="title">
                {step.title}
              </p>
              <p color={colors2.lightText} className="text">
                {step.text}
              </p>
            </div>
          </div>
        );
      })}
    </Wrapper>
  );
};

export default HowWeWork;
