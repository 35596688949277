/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Layout from './src/components/Layout';
import theme from './src/utils/theme';

require('prismjs/themes/prism-okaidia.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);

export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};

wrapRootElement.propTypes = {
  element: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.shape().isRequired,
};
