import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';
import MVPServices from './MVPdevelopmentServices';
import ProcessSection from './ProcessSection';
import MessageCard from '../Card/MessageCard';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ContactUsForm from '../ContactUsForm';

const MVPDevelopmentPage = () => {
  const { message } = useStaticQuery(query);
  return (
    <PageContainer>
      <PageContent>
        <TopPageSectionWrapper
          title="MVP development company"
          description="We’ll help you verify idea feasibility, collect user feedback, and extend a software
          product accordingly. Get a cost-effective MVP in 3 months."
        />
        <MVPServices />

        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <MessageCard
          image={message}
          title="Have an innovative idea?"
          subtitile="Contact us to put your vision into life!"
          button="Contact us"
          style={{ margin: '0' }}
        />
        <ProcessSection />
        <Technologies />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default MVPDevelopmentPage;
