import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import colors2 from '../../utils/theme/theme2/colors2';
import { H1Text, P1Text } from '../Typography/styled';

const styles = {
  container: {
    maxWidth: 600,
    margin: 'auto',
    padding: 60,
    backgroundColor: colors2.lightViolet,
    borderRadius: 40,
  },
};

const MVPCalculatorResultEstimation = ({ title, value }) => {
  return (
    <Flex flexDirection="column" style={styles.container}>
      <Flex flexDirection="column" style={styles.header}>
        <P1Text color={colors2.lightText} style={{ marginBottom: '10px' }}>
          {title}
        </P1Text>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="space-between"
          alignItems={['flex-start', 'flex-end']}
        >
          <H1Text>{value}</H1Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

MVPCalculatorResultEstimation.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default MVPCalculatorResultEstimation;
