import { navigate } from 'gatsby';
import axios from 'axios';
import { DASHAPPS_CONNECTION_URL_FOR_FORMS } from '../constants';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const handleNetlifyFormSubmit = (event, data) => {
  event.preventDefault();

  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({
      'form-name': 'DashbouquetForm',
      ...data,
    }),
  })
    .then(() => navigate('/thankyou/'))
    .catch((error) => alert(error));
};
const handleNetlifyContactUsFormSubmit = (event, file) => {
  event.preventDefault();

  const formData = new FormData(event.target);
  formData.append('contact-file', file);

  fetch('/', {
    method: 'POST',
    body: formData,
  })
    .then(() => navigate('/thankyou/'))
    .catch((error) => alert(error));
};
const sendFormDataToDashapps = (data) => {
  const dataToSend = {
    Name: `${data.name}[WebForm]`,
    Email: data['email-text'],
    DataByStepsValues: data['footer-message'],
  };
  axios
    .post(DASHAPPS_CONNECTION_URL_FOR_FORMS, dataToSend)
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
};
const sendContactFormDataToDashapps = (data) => {
  const dataToSend = {
    Name: `${data['contact-name']}[WebForm]`,
    Email: data['contact-email'],
    DataByStepsValues: data['contact-message'],
  };
  axios
    .post(DASHAPPS_CONNECTION_URL_FOR_FORMS, dataToSend)
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
};

export {
  handleNetlifyFormSubmit,
  sendFormDataToDashapps,
  sendContactFormDataToDashapps,
  handleNetlifyContactUsFormSubmit,
};
