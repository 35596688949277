import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';
import ProcessSection from './ProcessSection';

import MessageCard from '../Card/MessageCard';
import MobileAppExpertise from './MobileAppExpertise';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import colors2 from '../../utils/theme/theme2/colors2';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ServiceListBlock from '../ServiceList';
import ContactUsForm from '../ContactUsForm';

const MobileAppDevelopmentPage = () => {
  const { message } = useStaticQuery(query);
  const list = [
    {
      icon: <LogoIcon />,
      title: 'iOS mobile app development services',
      text: 'We make iOS apps that support iPhone, iPad, Apple Watch, and Apple TV. Get a UX-optimized solution tailored to your goals and expectations.',
      // link: '/services/',
    },
    {
      icon: <LogoIcon />,
      title: 'Android development services',
      text: 'Our team builds apps for all types of Android devices, involving smartphones, tablets, and wearables. We’ll create a secure product that will satisfy end-user needs.',
      // link: '/services/',
    },
    {
      icon: <LogoIcon />,
      title: 'Cross-platform mobile app development services',
      text: 'We develop cross-platform applications that provide a native-like user experience. Launch a performant solution, reducing costs by 30%–40%.',
      // link: '/services/',
    },
  ];

  return (
    <PageContainer>
      <PageContent>
        <TopPageSectionWrapper
          title="Custom mobile application development services"
          description="We create mobile apps that bring our clients engaged users and revenue. Start your project
          in 7 days and get new features every 1–3 weeks."
        />
        <ServiceListBlock
          title="Web development services we deliver"
          iconColor={colors2.blue}
          list={list}
          bgColor={colors2.lightBlue}
        />
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <MessageCard
          image={message}
          title="Aim to build a top-notch mobile app?"
          subtitile="Let’s put your idea into life!"
          button="Contact us"
          style={{ margin: '0' }}
        />
        <ProcessSection />
        <MobileAppExpertise />
        <Technologies />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default MobileAppDevelopmentPage;
