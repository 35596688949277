import { OTHER_ROLE_NAME } from '../../constants';

export const addOrdinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

export const getDevsAvailableDate = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);
};

export const formatAvailableDate = (date) => {
  return `${new Intl.DateTimeFormat('en-US', {
    month: 'long',
  }).format(date)} ${addOrdinalSuffix(date.getDate())}  ${date.getFullYear()}`;
};

export const getAvailableDate = (roleName) => {
  return roleName === OTHER_ROLE_NAME ? 'TBD' : formatAvailableDate(getDevsAvailableDate());
};

export const displayNumberAsPriceOr = (ifNullStr, number, eurCurrency) => {
  return number != null && !Number.isNaN(number)
    ? eurCurrency
      ? `€${number}`
      : `$${number}`
    : ifNullStr;
};
