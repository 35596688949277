import React, { useState, useEffect, useRef, Fragment } from 'react';
import { graphql } from 'gatsby';
import { Box } from 'rebass';
import PropTypes from 'prop-types';
import MVPCalculatorResult from '../components/MVPCalculatorResult';
import MVPCalculatorStepsData, {
  MVPCalculatorRequiredSteps,
  MVPCalculatorOptionalSteps,
  MVPCalculatorSteps,
  MVP_CALCULATOR_STEPS,
  PARAMETERS_SELECTION_STEP_INDEX,
} from '../configs/mvp-calculator/MVPCalculatorStepsData';
import { getItemsValuesByStepValue, getReportDataBySteps } from '../utils/mvp-calculator/stepsData';
import { getTotalCost, getDefaultEstimationsByTeamMembers } from '../utils/mvp-calculator/estimate';
import { getTimeEstimationBySteps } from '../utils/mvp-calculator/timeEstimation';
import SEO from '../components/SEO';
import MVPCalculatorMain from '../components/MVPCalculatorPage';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageWrapper } from '../components/homePage/styled';
import MVPCalculatorTextAreaStep from '../components/MVPCalculatorStep/MVPCalculatorTextAreaStep';
import MVPCalculatorSelectorStep from '../components/MVPCalculatorStep/MVPCalculatorSelectorStep';

const MVPCalculator = ({
  data: {
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
    mvpCalculatorPageInfo: {
      frontmatter: { seoTitle, description, keywords, ...mainData },
    },
    thanksImg,
  },
}) => {
  const contentContainerRef = useRef(null);
  const [totalCost, setTotalCost] = useState(0);
  const [reportDataBySteps, setReportDataBySteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(-1);
  const [activeSteps, setActiveSteps] = useState(MVPCalculatorRequiredSteps);
  const [selectedItems, setSelectedItems] = useState([]);
  const [projectIdea, setProjectIdea] = useState('');
  const [isResultStep, setResultStepState] = useState(false);
  const [isChangingFromResultStep, setChangingFromResultStepState] = useState(false);

  const startCalculation = () => {
    setCurrentStep(MVPCalculatorSteps[0]);

    if (typeof window !== 'undefined' && !window.location.hostname.includes('localhost'))
      window.gtag('event', 'click', { event_category: 'Button', event_label: 'MVP Start' });
  };

  const scrollToTop = () => {
    contentContainerRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const handleBackButtonClick = () => {
    const prevStepIndex = activeSteps.indexOf(currentStep) - 1;

    if (
      currentStep === MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER &&
      reportDataBySteps
        .filter((item) => item.value === 'customDesign')[0]
        .itemsValues.includes('customDesign:false')
    ) {
      setCurrentStep(MVP_CALCULATOR_STEPS.CUSTOM_DESIGN);
      scrollToTop();

      return;
    }

    setCurrentStep(activeSteps[prevStepIndex]);
    scrollToTop();
  };

  const handleNextButtonClick = () => {
    if (isChangingFromResultStep) {
      setResultStepState(true);
      setChangingFromResultStepState(false);
      scrollToTop();
      return;
    }

    const nextStepIndex = activeSteps.indexOf(currentStep) + 1;

    if (nextStepIndex === activeSteps.length) {
      setResultStepState(true);
      scrollToTop();
      return;
    }

    setCurrentStep(activeSteps[nextStepIndex]);
    scrollToTop();
  };

  const onTextAreaChange = (value) => {
    setProjectIdea(value);
  };

  const onTextAreaSubmit = () => {
    handleNextButtonClick();
  };

  const handleItemClick = (itemValue, isMultiSelectStep) => {
    setSelectedItems((prevItems) => {
      const isItemWasAddedPreviously = prevItems.includes(itemValue);

      if (!isMultiSelectStep && !isItemWasAddedPreviously) {
        const itemsValuesByCurrentStep = getItemsValuesByStepValue(
          MVPCalculatorStepsData,
          currentStep
        );
        const updatedPrevItems = prevItems.filter(
          (item) => !itemsValuesByCurrentStep.includes(item)
        );

        if (
          currentStep === MVP_CALCULATOR_STEPS.CUSTOM_DESIGN &&
          itemValue === 'customDesign:false'
        ) {
          setCurrentStep(MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER);
          return [...updatedPrevItems, itemValue];
        }
        handleNextButtonClick();

        return [...updatedPrevItems, itemValue];
      }

      if (!isMultiSelectStep && isItemWasAddedPreviously) {
        handleNextButtonClick();

        return prevItems;
      }

      const updatedItems = isItemWasAddedPreviously
        ? prevItems.filter((item) => item !== itemValue)
        : [...prevItems, itemValue];
      if (currentStep === MVP_CALCULATOR_STEPS.PARAMETERS_SELECTION) {
        const selectedOptionalSteps = MVPCalculatorOptionalSteps.filter((optionalStep) =>
          updatedItems.includes(optionalStep)
        );

        const newActiveSteps = [...MVPCalculatorRequiredSteps];
        newActiveSteps.splice(PARAMETERS_SELECTION_STEP_INDEX + 1, 0, ...selectedOptionalSteps);

        setActiveSteps(newActiveSteps);
      }

      return updatedItems;
    });
  };

  const handleResultTotalAmount = () => {
    const estimationsByMembers = getDefaultEstimationsByTeamMembers(reportDataBySteps);

    const optionalMembers = estimationsByMembers.filter((el) => el.id === 'QA' || el.id === 'PM');

    const optionalMembersCost = optionalMembers.reduce((sum, position) => sum + position.cost, 0);

    return totalCost + optionalMembersCost;
  };

  const handleUpdateTotalCost = (value, isIncrease = true) => {
    setTotalCost((prevTotalCost) => prevTotalCost + (isIncrease ? value : -value));
  };

  useEffect(() => {
    setReportDataBySteps(() => {
      const timeEstimationBySteps = getTimeEstimationBySteps(MVPCalculatorStepsData, selectedItems);
      const updatedReportData = getReportDataBySteps(
        MVPCalculatorStepsData,
        selectedItems,
        projectIdea,
        timeEstimationBySteps
      );
      return updatedReportData;
    });
  }, [selectedItems, projectIdea]);

  useEffect(() => {
    setTotalCost(getTotalCost(reportDataBySteps));
  }, [reportDataBySteps]);

  const commonStepsProps = {
    activeSteps,
    currentStep,
    handleNextButtonClick,
    handleBackButtonClick,
    reportDataBySteps,
  };
  return (
    <>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={`${siteUrl}/mvp-calculator`}
        description={description}
      />
      <PageWrapper>
        <Header />
        <Box ref={contentContainerRef}>
          {isResultStep ? (
            <MVPCalculatorResult
              totalAmount={handleResultTotalAmount()}
              reportDataBySteps={reportDataBySteps}
              handleUpdateTotalCost={handleUpdateTotalCost}
              thanksImg={thanksImg.childImageSharp.gatsbyImageData}
            />
          ) : currentStep === -1 ? (
            <MVPCalculatorMain {...mainData} onClick={startCalculation} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {currentStep === MVP_CALCULATOR_STEPS.PROJECT_IDEA ? (
                <MVPCalculatorTextAreaStep
                  {...commonStepsProps}
                  initialValue={projectIdea}
                  onChange={onTextAreaChange}
                  onSubmit={onTextAreaSubmit}
                />
              ) : (
                <MVPCalculatorSelectorStep
                  {...commonStepsProps}
                  selectedItems={selectedItems}
                  handleItemClick={handleItemClick}
                />
              )}
            </>
          )}
        </Box>
        <Footer />
      </PageWrapper>
    </>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    mvpCalculatorPageInfo: markdownRemark(frontmatter: { name: { eq: "mvp calculator" } }) {
      frontmatter {
        seoTitle
        description
        keywords
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FIXED)
          }
          id
        }
        title
        longTitle
        additionalDescription
        list
      }
    }
    thanksImg: file(relativePath: { eq: "images/ThanksForPatience.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 200, height: 200, placeholder: NONE, layout: FIXED)
      }
    }
  }
`;

MVPCalculator.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default MVPCalculator;
