/* eslint-disable import/prefer-default-export */

import { groupBy } from 'ramda';

export const getMergedIcons = (icons, hoverIcons, activeIcons) =>
  icons.map((icon) => ({
    label: icon.node.name,
    initial: icon.node.childImageSharp.gatsbyImageData,
    hover:
      hoverIcons &&
      hoverIcons.find((hoverIcon) => hoverIcon.node.name === `${icon.node.name}Hover`).node
        .childImageSharp.gatsbyImageData,
    active:
      activeIcons &&
      activeIcons.find((activeIcon) => activeIcon.node.name === `${icon.node.name}Active`).node
        .childImageSharp.gatsbyImageData,
    width: icon.node.childImageSharp.original.width,
  }));

export const groupByCategory = (data) =>
  groupBy((blog) => {
    const {
      node: {
        frontmatter: { category },
      },
    } = blog;

    return category;
  })(data);
