import React from 'react';

import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import ServiceListBlock from '../ServiceList';

const services = [
  {
    icon: <LogoIcon />,
    title: 'Web interfaces',
    text: 'We’ll create an appealing and intuitive UI of your product. Using cutting-edge technologies, our software engineers will provide an engaging customer experience.',
  },
  {
    icon: <LogoIcon />,
    title: 'Single-page applications',
    text: 'We will develop a fast, responsive, and intuitive SPA. We employ frameworks such as React, Angular, and Vue.js to create dynamic interfaces that keep users engaged.',
  },
  {
    icon: <LogoIcon />,
    title: 'Mobile interfaces ',
    text: 'Our frontend development team will make a mobile-friendly UI optimized for different screen sizes and operating systems. Boost user satisfaction and prevent churn while cutting costs.',
  },
];

const FrontendSolutions = () => {
  return (
    <ServiceListBlock
      title="Frontend development solutions we make"
      iconColor={colors2.blue}
      list={services}
      bgColor={colors2.lightBlue}
    />
  );
};

export default FrontendSolutions;
