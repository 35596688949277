import React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import ReliableTeam from '../components/homePage/ReliableTeam';
import Offers from '../components/homePage/Offers';
import Badges from '../components/homePage/Badges';
import Technologies from '../components/homePage/Technologies';

import StatisticsBanner from '../components/homePage/StatisticsBanner';
import Partners from '../components/homePage/Partners';

import Packages from '../components/homePage/Packages';
import Reviews from '../components/homePage/Reviews';
import Works from '../components/homePage/Works';
import News from '../components/homePage/News';
import { PageContent, PageWrapper } from '../components/homePage/styled';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import MessageCard from '../components/Card/MessageCard';
import CoreExpertise from '../components/Expertise/CoreExpertise';

import HealthcareIcon from '../assets/icons/updateDesign/development/healthcare.svg';
import EcommerceIcon from '../assets/icons/updateDesign/development/ecommerce.svg';
import HRIcon from '../assets/icons/updateDesign/development/hr.svg';
import FintechIcon from '../assets/icons/updateDesign/development/fintech.svg';
import ContactUsForm from '../components/ContactUsForm';

const expertise = [
  {
    icon: <FintechIcon />,
    title: 'Fintech',
    desc: `Our custom finance software development company delivers corporate/personal finance control solutions, mobile banking apps, robo advisors, and investment portfolio management platforms. Get a secure, performant, and UX-optimized fintech product.`,
    link: '/solutions/fintech-software-development-services',
  },
  {
    icon: <HealthcareIcon />,
    title: 'Healthcare',
    desc: `We help medical providers automate manual processes, improve patient care, and cut costs using technologies. Hire our custom healthcare software development company to create a medical solution compliant with regulations like GDPR, HIPAA, and HITECH.`,
    link: '/solutions/healthcare',
  },
  {
    icon: <HRIcon />,
    title: 'HR',
    desc: `Specializing in HR software development, we help businesses efficiently manage their employee data, monitor performance, and track job satisfaction. We also build software that allows our clients to automate candidate search and recruitment.`,
    link: '/solutions/hr-software-development',
  },
  {
    icon: <EcommerceIcon />,
    title: 'eCommerce',
    desc: `With vast experience in e-commerce software development, we make websites and marketplaces that are visually appealing, secure, and easy to use. Boost customer engagement, prevent churn, and generate income with a custom-built marketplace.`,
    link: '/solutions/ecommerce-development',
  },
  // {
  //   title: 'AI/ML',
  //   desc: `We create AI software solutions to streamline manual tasks, streamline decision-making, eliminate human errors, prevent fraud, and provide personalized services. Partner with our AI software development company to address business challenges and reduce costs.`,
  //   link: '/services/ai-and-research',
  // },
  // {
  //   title: 'Startups',
  //   desc: `We are a trusted custom software development company for startups building digital products at reasonable prices. We help our clients validate ideas, collect user feedback, and find a market fit. Get a working solution in 2–3 months and raise funds.`,
  //   link: '/services/software-development-for-startups',
  // },
];

const HomePage = ({
  data: {
    site: {
      siteMetadata: { siteUrl },
    },
    messageImg,
    news: { edges: news },
    homePageInfo: {
      frontmatter: { seoTitle, description, keywords },
    },
  },
}) => {
  return (
    <>
      <SEO title={seoTitle} keywords={keywords} url={siteUrl} description={description} />
      <PageWrapper>
        <Header />
        <PageContent>
          <ReliableTeam />
          <Offers />
          <div className="badges-wrapper">
            <Badges />
            <Reviews />
          </div>
        </PageContent>
        <Works />
        <PageContent>
          <CoreExpertise title="Core expertise" data={expertise} />
          <Packages />
          <MessageCard
            image={messageImg}
            title="Need to talk with an expert?"
            subtitile="Contact us to schedule a free project consultation."
            button="Get a free consultation"
          />

          <Technologies />
          <StatisticsBanner />
          <Partners />
          <News news={news} />
          <ContactUsForm />
        </PageContent>
        <Footer />
      </PageWrapper>
    </>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
    messageImg: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    news: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            category
            postImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            cardBackgroundImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            altPostImageText
            date
            title
            introtext
            author
            color
            colorFont
          }
        }
      }
    }
    homePageInfo: markdownRemark(frontmatter: { name: { eq: "home" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }
  }
`;

export default HomePage;
