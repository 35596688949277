import React from 'react';

import styled from 'styled-components';

import TestingIcon from '../../assets/icons/updateDesign/dedicated/testing.svg';
import TestingScrollIcon from '../../assets/icons/updateDesign/dedicated/testing-hover.svg';
import UxScrollIcon from '../../assets/icons/updateDesign/development/ux-hover.svg';
import UxIcon from '../../assets/icons/updateDesign/development/ux.svg';
import DevelopmentIcon from '../../assets/icons/updateDesign/development/development.svg';
import DevelopmentScrollIcon from '../../assets/icons/updateDesign/development/development-hover.svg';
import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import SupportIcon from '../../assets/icons/updateDesign/dedicated/support.svg';
import SupportScrollIcon from '../../assets/icons/updateDesign/dedicated/support-hover.svg';

import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import ProcessComponent from '../ProcessComponent';

export const Wrapper = styled.div`
  @media ${breakpoints.sm} {
    padding: 0 30px;
    .list-with-big-icons_wrapper {
      gap: 30px;
      .step {
        flex-direction: column;
        margin-bottom: 0px;
        gap: 30px;
      }
      .number {
        margin-top: 0px;
        margin-right: 0px;
      }
    }
  }
`;
const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'Discovery',
    text: 'We analyze fintech project requirements to get a clear vision of your needs and goals. As a deliverable, you receive a technical specification, a documented work scope, and budget estimate.',
  },
  {
    number: 'step 2',
    icon: <UxIcon />,
    onScrollIcon: <UxScrollIcon />,
    title: 'UX/UI design',
    text: 'In 2 weeks, our fintech software development company makes a dynamic prototype to visualize the product interface. After discussing the prototype with you, we create a user-friendly design enjoyed by the audience.',
  },
  {
    number: 'step 3',
    icon: <DevelopmentIcon />,
    onScrollIcon: <DevelopmentScrollIcon />,
    title: 'Fintech app development',
    text: 'Following Agile best practices, we deliver new functionality every 1–3 weeks. By setting up a CI/CD pipeline, we automate the testing, build, and deployment processes, reducing the feature release cycle by 3x–4x.',
  },
  {
    number: 'step 4',
    icon: <TestingIcon />,
    onScrollIcon: <TestingScrollIcon />,
    title: 'Quality assurance',
    text: 'Our QA experts test custom fintech software to make sure it is error-free, performs as intended, as well as meets the necessary guidelines and regulations. We also prevent potential bottlenecks like security and performance issues.',
  },
  {
    number: 'step 5',
    icon: <SupportIcon />,
    onScrollIcon: <SupportScrollIcon />,
    title: 'Launch, support, and maintenance',
    text: 'Finally, we launch your fintech software solution. What’s more, we offer post-release support and maintenance to ensure smooth system operation. Our fintech application developers implement new features and fix arising issues on demand.',
  },
];

const ProcessSection = () => {
  return (
    <Wrapper>
      <ProcessComponent title="Our fintech software development process" data={work} />;
    </Wrapper>
  );
};

export default ProcessSection;
