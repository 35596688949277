import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { DefaultButton } from '../Buttons/styled';
import { H1Text, P2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import CheckIcon from '../../assets/icons/updateDesign/mvpCalculator/Check.svg';
import { MVPCalculatorMainContainer, TitleBlock, ImageBlock } from './styled';

const MVPCalculatorMain = ({ image, longTitle, additionalDescription, title, list, onClick }) => {
  return (
    <MVPCalculatorMainContainer>
      <TitleBlock>
        <H1Text>{title}</H1Text>
        <P2Text color={colors2.lightText}>{longTitle}</P2Text>
        <DefaultButton width="217px" height="56px" onClick={onClick}>
          START
        </DefaultButton>
      </TitleBlock>
      <ImageBlock>
        <P2Text>{additionalDescription}</P2Text>
        {list && (
          <ul>
            {list.map((list, idx) => (
              <li key={idx}>
                <CheckIcon />
                <P2Text> {list}</P2Text>
              </li>
            ))}
          </ul>
        )}
        <DefaultButton width="217px" height="56px" onClick={onClick}>
          START
        </DefaultButton>
        <GatsbyImage
          className="mvp-calculator_image"
          image={image.childImageSharp.gatsbyImageData}
          alt="image"
        />
      </ImageBlock>
    </MVPCalculatorMainContainer>
  );
};

export default MVPCalculatorMain;
