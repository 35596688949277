import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import SEO from '../components/SEO';
import Image from '../components/Image';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { breakpoints } from '../utils/theme/theme2/breakpoints';
import colors2 from '../utils/theme/theme2/colors2';
import { H2Text, P3Text } from '../components/Typography/styled';
import { filters } from '../utils/theme/theme2/colorFilters';
import News from '../components/homePage/News';
import { PageContainer, PageContent, PageWrapper } from '../components/homePage/styled';
import { MarkdownBlock } from './styled';

export const BlogPostContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ol a,
  ol span {
    color: ${colors2.lightText};
  }
  ${H2Text} {
    max-width: 1024px;
    width: 100%;
    margin-bottom: 30px;
  }
  ${P3Text} {
    width: 90%;
  }
`;
export const PostImageWrapper = styled.div`
  max-width: 1120px;
  width: 100%;
  margin-top: 120px;
  margin-bottom: 40px;
  .gatsby-image-wrapper {
    border-radius: 40px;
  }

  @media ${breakpoints.sm} {
    margin-top: 40px;
    margin-bottom: 30px;
  }
`;
export const PostBodyWrapper = styled.div`
  max-width: 926px;
  width: 100%;
`;
export const PostBodyContent = styled.div`
  h3,
  h2,
  colorwrapper {
    color: ${colors2.black};
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    margin-top: 80px;
    margin-bottom: 40px;
  }
  colorwrapper {
    span:first-child {
      color: ${(props) => (props.colorTheme && props.colorTheme.textColor) || 'unset'};
    }
  }
  h4 {
    color: ${colors2.black};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 165%;
  }
  a {
    color: ${colors2.blue};
    text-decoration: none;
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 165%;
    color: ${colors2.lightText};
  }
  ol,
  ul {
    color: ${colors2.lightText};
    list-style-position: inside;
  }
  strong {
    font-weight: 500;
    color: ${colors2.black};
  }
  .gatsby-resp-image-wrapper {
    width: 100%;
    max-width: unset !important;
    border-radius: 40px;
    display: flex !important;
  }
  .gatsby-resp-image-image {
    height: unset !important;
    border-radius: 40px;
    object-fit: contain;
    object-position: center;
  }
  iconwrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    align-items: flex-start;
    .img-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      max-width: 38px;
      min-width: 38px;
      height: 38px;
      background: ${(props) => (props.colorTheme && props.colorTheme.bgColor) || ''};
    }
    img {
      ${(props) => (props.colorTheme && props.colorTheme.filter) || ''}
      margin: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: transparent;
    }
  }

  blockquotewrapper {
    background: ${colors2.lightGray};
    padding: 60px 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 40px;

    & span {
      color: ${colors2.black};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 165% !important;
    }
    .blockquote-author {
      display: flex;
      gap: 15px;
      & span:first-child {
        white-space: nowrap;
      }
    }
  }
  @media ${breakpoints.mds} {
    h2,
    h3,
    colorwrapper {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
    }
    blockquotewrapper {
      padding: 40px;
      & span {
        font-size: 16px;
      }
    }
  }
  @media ${breakpoints.sm} {
    h2,
    h3,
    colorwrapper {
      font-size: 18px;
      line-height: 30px;
      margin-top: 60px;
      margin-bottom: 30px;
    }
    p {
      font-size: 14px;
    }
    blockquotewrapper {
      padding: 30px;
      & span {
        font-size: 14px;
      }
    }
  }
`;

const switchColorTheme = (colorTheme) => {
  switch (colorTheme) {
    case colors2.blue || colors2.lightBlue:
      return {
        textColor: colors2.blue,
        filter: filters.blue,
        bgColor: colors2.lightBlue,
      };
    case colors2.green || colors2.lightGreen:
      return {
        textColor: colors2.green,
        filter: filters.green,
        bgColor: colors2.lightGreen,
      };
    case colors2.violet || colors2.lightViolet:
      return {
        textColor: colors2.violet,
        filter: filters.violet,
        bgColor: colors2.lightViolet,
      };
    case colors2.yellow || colors2.lightYellow:
      return {
        textColor: colors2.yellow,
        filter: filters.yellow,
        bgColor: colors2.lightYellow,
      };
    default:
      return {
        textColor: colors2.blue,
        filter: filters.blue,
        bgColor: colors2.lightBlue,
      };
  }
};

const BlogPostTemplate = ({
  data: {
    blog: {
      html,
      fields: { slug },
      frontmatter: { title, postImage, altPostImageText, keywords, introtext, date, author, color },
    },
    news: { edges: news },
    site: {
      siteMetadata: { siteUrl, titleTemplate },
    },
    mainLogo: {
      childImageSharp: {
        original: { src: mainLogo },
      },
    },
  },
}) => {
  const schemaMurkup = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: title,
    image: [`${siteUrl}${postImage}`],
    author: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Dashbouquet Development LLC',
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}${mainLogo}`,
      },
    },
    mainEntityOfPage: `${siteUrl}${slug}`,
    datePublished: new Date(date).toDateString(),
    dateModified: new Date(date).toDateString(),
  };
  const blogThemeColor = switchColorTheme(color);

  return (
    <PageWrapper>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaMurkup)}</script>
      </Helmet>
      <SEO
        titleTemplate={titleTemplate}
        title={title}
        keywords={keywords}
        url={`${siteUrl}${slug}`}
        description={introtext}
        image={`${siteUrl}${postImage}`}
        pageType="article"
      />
      <Header />
      <PageContainer>
        <PageContent>
          <BlogPostContent>
            <TitleBlock>
              <H2Text>{title}</H2Text>
              <P3Text color={colors2.lightText}>{introtext}</P3Text>
            </TitleBlock>
            <PostImageWrapper>
              <Image
                imgStyle={{ objectPosition: 'center', objectFit: 'contain' }}
                image={postImage}
                fadeIn
                alt={altPostImageText || ''}
              />
            </PostImageWrapper>
            <PostBodyWrapper>
              <P3Text color={colors2.lightText}>
                <span>{new Date(date).toDateString().slice(4)}</span> | by{' '}
                <span style={{ color: colors2.blue }}>{author}</span>
              </P3Text>
              <MarkdownBlock html={html} colorTheme={blogThemeColor} />
            </PostBodyWrapper>
          </BlogPostContent>
          <News news={news} />
        </PageContent>
      </PageContainer>
      <Footer />
    </PageWrapper>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default BlogPostTemplate;

export const query = graphql`
  query blogPost($id: String) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      fileAbsolutePath
      frontmatter {
        category
        postImage {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        altPostImageText
        date
        color
        title
        introtext
        author
        keywords
      }
      fields {
        slug
      }
      html
    }
    allBlogs: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            category
            date
            title
            colorFont
            introtext
            postImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        titleTemplate
      }
    }
    news: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            category
            postImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            cardBackgroundImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            altPostImageText
            date
            title
            introtext
            author
            color
            colorFont
          }
        }
      }
    }
    mainLogo: file(relativePath: { eq: "images/mainLogo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
        original {
          src
        }
      }
    }
  }
`;
