import React from 'react';
import { Flex } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

import { graphql, useStaticQuery } from 'gatsby';
import { StatisticsBanner } from './styled';

import { P2Text, H1Text, H2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';

import ArrowBtn from '../Buttons/ArrowBtn';
import Link from '../Link';

export const statisticsBannerQuery = graphql`
  {
    statictics: file(relativePath: { eq: "images/updateDesign/statictics-banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const StatisticsBannerBlock = ({ title, subtitle }) => {
  const { statictics } = useStaticQuery(statisticsBannerQuery);
  return (
    <StatisticsBanner>
      <Flex className="content">
        <div className="content__btn__mobile">
          <Link to="/about-us">
            <ArrowBtn text="Learn more about us" />
          </Link>
        </div>
        {title ? (
          <div className="titles">
            <H2Text style={{ display: 'block', marginBottom: '30px' }}>{title}</H2Text>
            <P2Text color={colors2.lightText} style={{ display: 'block' }}>
              {subtitle}
            </P2Text>
          </div>
        ) : (
          <Flex className="content__text" flexDirection="column">
            <Flex flexDirection="column">
              <H1Text className="content__title">100+</H1Text>
              <P2Text className="content__desc" color={colors2.lightText}>
                successful products delivered
              </P2Text>
            </Flex>
            <Flex flexDirection="column">
              <H1Text className="content__title">8+</H1Text>
              <P2Text className="content__desc" color={colors2.lightText}>
                years of experience in software development
              </P2Text>
            </Flex>
            <Flex flexDirection="column">
              <H1Text className="content__title">$125+</H1Text>
              <P2Text className="content__desc" color={colors2.lightText}>
                million raised by startup products we built
              </P2Text>
            </Flex>
            <Flex flexDirection="column">
              <H1Text className="content__title">60+</H1Text>
              <P2Text className="content__desc" color={colors2.lightText}>
                skilled developers, designers, testers, business analysts, and project managers
              </P2Text>
            </Flex>
          </Flex>
        )}
        <Flex className="content__img" flexDirection="column" alignItems="flex-end">
          <div className="img_container">
            <GatsbyImage
              image={statictics.childImageSharp.gatsbyImageData}
              alt="talking-banner"
              className="technology__img"
            />
          </div>
          <div className="content__btn">
            <Link to="/about-us">
              <ArrowBtn text="Learn more about us" />
            </Link>
          </div>
        </Flex>
      </Flex>
    </StatisticsBanner>
  );
};

export default StatisticsBannerBlock;
