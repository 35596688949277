import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import WebIcon from '../../assets/icons/updateDesign/development/web.svg';
import MobileIcon from '../../assets/icons/updateDesign/development/mobile.svg';
import ModernizationIcon from '../../assets/icons/updateDesign/development/modernization.svg';
import ServiceListBlock from '../ServiceList';
import colors2 from '../../utils/theme/theme2/colors2';

const services = [
  {
    icon: <WebIcon />,
    title: 'Web development',
    text: 'We build performant web apps and websites that have beautiful intuitive designs. Hire our custom software development company to make a web product that will meet your business-specific needs and budget.',
    link: '/services/web-development',
  },
  {
    icon: <MobileIcon />,
    title: 'Mobile app development',
    text: 'Our team creates both native and cross-platform apps that deliver an engaging user experience. Employing advanced technologies, we ensure security, scalability, fault tolerance, and performance.',
    link: '/services/mobile-app-development',
  },
  {
    icon: <ModernizationIcon />,
    title: 'Software Modernization',
    text: 'Modernize a web system to fix performance issues, achieve scalability, facilitate maintenance, and cut infrastructure costs. We’ll help you migrate to new technologies and recreate a software architecture.',
    link: '/',
  },
];

const SecondSection = () => {
  const { image } = useStaticQuery(query);
  return (
    <ServiceListBlock
      image={image}
      list={services}
      title="Custom offshore software development services"
      iconColor={colors2.blue}
    />
  );
};

export const query = graphql`
  {
    image: file(relativePath: { eq: "images/updateDesign/tv.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default SecondSection;
