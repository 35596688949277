import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import { PageWrapper } from '../components/homePage/styled';
import Web3Page from '../components/Web3Page';
import { web3Colors } from '../components/Web3Page/styled';

export const Web3PageWrapper = styled(PageWrapper)`
  background: ${web3Colors.background};
  padding: 0;
`;

const Web3 = () => {
  return (
    <Web3PageWrapper>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <style>{`
          body:after {
            content: '';
            position: fixed;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            z-index: -1;
            background: ${web3Colors.background};
          }
        `}</style>
      </Helmet>
      <Web3Page />
      <Footer style={{ zIndex: 1 }} web3Mode={true} />
    </Web3PageWrapper>
  );
};

export default Web3;
