import React from 'react';
import { Flex } from 'rebass';

import { LetsBannerContent } from '../homePage/styled';
import { H1Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { DefaultButton } from '../Buttons/styled';

import Link from '../Link';

const LetsBanner = () => {
  return (
    <LetsBannerContent>
      <Flex className="content" flexDirection="column">
        <H1Text>
          Let's build your <br />
          digital product
        </H1Text>
        <Flex className="content__btns">
          <Link to="/mvp-calculator">
            <DefaultButton className="content__btn"> MVP-Calculator</DefaultButton>
          </Link>
          <a href="https://calendly.com/kobiashov">
            <DefaultButton bgColor={colors2.lightGray} color={colors2.black}>
              Schedule a call
            </DefaultButton>
          </a>
        </Flex>
      </Flex>
    </LetsBannerContent>
  );
};

export default LetsBanner;
