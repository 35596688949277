import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { P2Text, H2Text } from '../Typography/styled';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  width: 540px;
  padding: 0px 0px 60px 60px;
  border-radius: 40px;
  gap: 60px;
  background-color: ${(p) => p.bgColor ?? colors2.lightGray};
  margin-right: 40px !important;
  @media ${breakpoints.sm} {
    width: 315px;
    padding: 0px 0px 30px 30px;
    border-radius: 30px;
    gap: 30px;
    margin-right: 20px !important;
  }
`;
export const CardImageWrapper = styled.div`
  display: flex;
  width: 480px;
  height: 447px;
  img {
    border-radius: 40px;
  }
  @media ${breakpoints.sm} {
    width: 285px;
    height: 266px;
    img {
      border-radius: 30px;
    }
  }
`;
export const CardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 440px;
  ${P2Text} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    color: ${colors2.lightText};
  }
  ${H2Text}, ${P2Text}, button span, button svg {
    color: ${(p) => (p.bgColor === colors2.blue ? colors2.white : '')};
  }
  @media ${breakpoints.sm} {
    max-width: 255px;
  }
`;
export const TagsContainer = styled.div`
  display: flex;
  gap: 10px;
  .card-tag {
    display-flex;
    border-radius: 18px;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    background-color: ${(p) => (p.bgColor === colors2.blue ? '#ffffff1a' : colors2.white)};

`;
