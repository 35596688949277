import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Image from '../Image';
import { H3Text, P2Text, P3Text } from '../Typography/styled';
import ArrowBtn from '../Buttons/ArrowBtn';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import slugify from '../../utils/slugify';

export const Wrapper = styled.div`
  border-radius: 40px;
  display: grid;
  gap: 20px;
`;

export const Category = styled.div`
  text-transform: capitalize;
  background-color: ${({ bg }) =>
    bg && bg === 'white' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.8)'};
  border-radius: 18px;
  padding: 5px 15px;
  width: min-content;
  white-space: nowrap;
`;

export const StyledLink = styled(Link)`
  margin-top: 40px;
  width: fit-content;
  flex: 1;
  display: flex;
  align-items: end;
  text-decoration: none;
`;

export const Container = styled.div`
  padding: 60px;

  display: flex;
  flex-direction: column;

  @media ${breakpoints.md} {
    padding: 30px;
  }

  @media ${breakpoints.mds} {
    padding: 30px 0 30px 30px;
  }

  @media ${breakpoints.sm} {
    padding: 30px;
    margin-top: 20px;
  }

  .text {
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    lineclamp: 3;
    -webkit-box-orient: vertical;
  }
`;

const PortfolioCard = ({
  data: {
    node: {
      id,
      frontmatter: { title, subtitle, industry, platform, cardBackgroundImage, color, colorFont },
    },
  },
  ...props
}) => (
  <Wrapper
    {...props}
    width={1}
    flexDirection="column"
    key={id}
    css={{ textAlign: 'left', background: color ?? '#F6F6F6' }}
  >
    <Container>
      <Box mb={3} display="flex" style={{ gap: '10px' }}>
        <Category bg={colorFont}>
          <P3Text
            color={
              colorFont && colorFont === 'white' ? 'rgba(255, 255, 255, 0.85)' : colors2.lightText
            }
          >
            {platform}
          </P3Text>
        </Category>
        <Category bg={colorFont}>
          <P3Text
            color={
              colorFont && colorFont === 'white' ? 'rgba(255, 255, 255, 0.85)' : colors2.lightText
            }
          >
            {industry}
          </P3Text>
        </Category>
      </Box>
      <Box mb={2}>
        <H3Text color={colorFont && colorFont === 'white' ? colors2.white : colors2.black}>
          {title}
        </H3Text>
      </Box>
      {subtitle && (
        <P2Text
          color={
            colorFont && colorFont === 'white' ? 'rgba(255, 255, 255, 0.85)' : colors2.lightText
          }
          className="text"
        >
          {subtitle}
        </P2Text>
      )}
      <StyledLink to={`/our-work/${slugify(title)}`}>
        <ArrowBtn color={colorFont} text="Learn more" />
      </StyledLink>
    </Container>
    <Image
      imgStyle={{ objectPosition: 'center', objectFit: 'contain' }}
      image={cardBackgroundImage}
      fadeIn
      alt=""
    />
  </Wrapper>
);

PortfolioCard.propTypes = {
  data: PropTypes.shape({
    node: PropTypes.shape({
      id: PropTypes.string,
      fields: PropTypes.shape(),
      googleAnalyticsViews: PropTypes.number,
      frontmatter: PropTypes.shape({
        postImage: PropTypes.shape(),
        category: PropTypes.string,
        title: PropTypes.string,
        introtext: PropTypes.string,
        author: PropTypes.string,
        date: PropTypes.string,
        altPostImageText: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default PortfolioCard;
