import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';
import Solutions from './Solutions';

import LogoIcon from '../../assets/icons/updateDesign/logo.svg';

import colors2 from '../../utils/theme/theme2/colors2';

import AssembleIcon from '../../assets/icons/updateDesign/dedicated/assemble.svg';
import AssembleScrollIcon from '../../assets/icons/updateDesign/dedicated/assemble-hover.svg';
import TestingIcon from '../../assets/icons/updateDesign/dedicated/testing.svg';
import TestingScrollIcon from '../../assets/icons/updateDesign/dedicated/testing-hover.svg';
import UxScrollIcon from '../../assets/icons/updateDesign/development/ux-hover.svg';
import UxIcon from '../../assets/icons/updateDesign/development/ux.svg';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import SupportIcon from '../../assets/icons/updateDesign/dedicated/support.svg';
import SupportScrollIcon from '../../assets/icons/updateDesign/dedicated/support-hover.svg';
import ProcessComponent from '../ProcessComponent';
import ServiceListBlock from '../ServiceList';
import ContactUsForm from '../ContactUsForm';

const list = [
  {
    icon: <LogoIcon />,
    title: 'Electronic health record systems',
    text: 'Our medical software development company creates EHR systems to reduce paperwork, increase doctor productivity,  and provide better patient care. We also deliver features such as automated reporting, billing, video/audio calls, and online appointment booking.',
  },
  {
    icon: <LogoIcon />,
    title: 'Patient portals ',
    text: 'We create healthcare portals that allow patients to easily access medical information from any device, interact with doctors online, and find answers to the required questions. Our engineers will ensure seamless portal integration with your existing platforms.',
  },
  {
    icon: <LogoIcon />,
    title: 'e-Prescribing (eRx) software',
    text: 'Our medical software development team builds e-prescribing systems that enable healthcare companies to manage patient medication histories, digitize paper-based processes, enhance medication adherence, and minimize human errors.',
  },
  {
    icon: <LogoIcon />,
    title: 'Medical practice management systems',
    text: 'Medical practice management systems we create allow healthcare organizations to manage patient data, schedule doctor appointments, process claims, track payments, and automate reporting. Furthermore, we’ll integrate the platform into your current workflows.',
  },
  {
    icon: <LogoIcon />,
    title: 'Clinical decision support systems',
    text: 'We build clinical decision support solutions that provide healthcare companies with real-time, evidence-based guidance. Using cutting-edge technologies like AI and ML, our medical software development team helps doctors make more informed decisions much faster.',
  },
  {
    icon: <LogoIcon />,
    title: 'Telemedicine solutions ',
    text: 'Our engineers create telemedicine solutions to provide efficient remote care, increase doctor productivity, boost patient satisfaction, and cut operating costs. With vast experience in medical software development, we use IoT technology to provide remote health monitoring.',
  },
];

const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'Discovery',
    text: 'We analyze medical software project requirements to get a clear vision of your needs and goals. As a deliverable, you receive a technical specification, a documented feature scope, and budget estimate.',
  },
  {
    number: 'step 2',
    icon: <UxIcon />,
    onScrollIcon: <UxScrollIcon />,
    title: 'UI/UX design',
    text: 'In 2 weeks, our medical software development team makes a dynamic prototype to visualize the product interface. After discussing the prototype with you, we create a user-friendly design enjoyed by the audience.',
  },
  {
    number: 'step 3',
    icon: <AssembleIcon />,
    onScrollIcon: <AssembleScrollIcon />,
    title: 'Medical solution development',
    text: 'Following Agile best practices, we deliver new functionality every 1–3 weeks. By enabling a CI/CD pipeline, we automate the testing, build, and deployment processes, reducing the feature release cycle by 3x–4x.',
  },
  {
    number: 'step 4',
    icon: <TestingIcon />,
    onScrollIcon: <TestingScrollIcon />,
    title: 'Quality assurance',
    text: 'Our QA experts test custom medical software to make sure it is error-free, performs as intended, as well as meets all the requirements and regulations. We also prevent potential bottlenecks such as security issues.',
  },
  {
    number: 'step 5',
    icon: <SupportIcon />,
    onScrollIcon: <SupportScrollIcon />,
    title: 'Launch, support, and maintenance',
    text: 'Finally, we launch your medical solution. On top of that, we offer post-release support and maintenance to ensure smooth system operation. Our medical app developers implement new features and fix arising issues on the fly.',
  },
];

const HealthcarePage = () => {
  const { health } = useStaticQuery(query);

  return (
    <PageContainer>
      <PageContent>
        <div className="service-wrapper">
          <TopPageSectionWrapper
            title="Medical software development company"
            description="We build custom medical solutions that help healthcare providers improve patient treatment,
        automate manual workflows, and eliminate human errors."
          />
          <ServiceListBlock
            title="Medical software solutions we deliver"
            iconColor={colors2.blue}
            image={health}
            list={list}
            bgColor={colors2.lightBlue}
          />
        </div>
        <ProcessComponent
          withoutIcons
          title="Our medical software development process"
          data={work}
        />
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <Technologies />
        <Solutions />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    health: file(relativePath: { eq: "images/updateDesign/syringe.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default HealthcarePage;
