import { Helmet } from 'react-helmet';
import React from 'react';
import Inter400 from '../../assets/fonts/inter/Inter-Regular.ttf';
import GolosText400 from '../../assets/fonts/golos-text/GolosText-Regular.ttf';
import GolosText500 from '../../assets/fonts/golos-text/GolosText-Medium.ttf';
import Poppins400 from '../../assets/fonts/poppins/Poppins-Regular.ttf';
import Poppins500 from '../../assets/fonts/poppins/Poppins-Medium.ttf';
import EuclidCircularA400 from '../../assets/fonts/euclid-circular-a/Euclid Circular A Regular.ttf';
import EuclidCircularA500 from '../../assets/fonts/euclid-circular-a/Euclid Circular A Medium.ttf';
import EuclidCircularA600 from '../../assets/fonts/euclid-circular-a/Euclid Circular A SemiBold.ttf';
import EuclidCircularA700 from '../../assets/fonts/euclid-circular-a/Euclid Circular A Bold.ttf';

export default {
  inter: `'Inter', sans-serif`,
  golos: `'Golos Text', sans-serif`,
  poppins: `'Poppins', sans-serif`,
  euclidCircularA: `'Euclid Circular A', sans-serif`,
};

export const FontFace = () => (
  <Helmet>
    <style>
      {`
      @font-face {
        font-family: Inter;
        src: url('${Inter400}') format('ttf');
        font-weight: 400;
        font-display: swap;
      }
      @font-face {
        font-family: 'Golos Text';
        src: url('${GolosText400}') format('truetype');
        font-weight: 400;
        font-display: swap;
      }
      @font-face {
        font-family: 'Golos Text';
        src: url('${GolosText500}') format('truetype');
        font-weight: 500;
        font-display: swap;
      }
      @font-face {
        font-family: 'Poppins';
        src: url('${Poppins400}') format('truetype');
        font-weight: 400;
        font-display: swap;
      }
      @font-face {
        font-family: 'Poppins';
        src: url('${Poppins500}') format('truetype');
        font-weight: 500;
        font-display: swap;
      }
      @font-face {
        font-family: 'Euclid Circular A';
        src: url('${EuclidCircularA400}') format('truetype');
        font-weight: 400;
        font-display: swap;
      }
      @font-face {
        font-family: 'Euclid Circular A';
        src: url('${EuclidCircularA500}') format('truetype');
        font-weight: 400;
        font-display: swap;
      }
      @font-face {
        font-family: 'Euclid Circular A';
        src: url('${EuclidCircularA600}') format('truetype');
        font-weight: 600;
        font-display: swap;
      }
      @font-face {
        font-family: 'Euclid Circular A';
        src: url('${EuclidCircularA700}') format('truetype');
        font-weight: 700;
        font-display: swap;
      }
    `}
    </style>
  </Helmet>
);
