import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  padding: 80px;
  background-color: ${({ color }) => color ?? colors2.lightBlue};
  border-radius: 40px;
  position: relative;
  .color-block-mobile-img {
    display: none;
  }
  .titleInfo {
    max-width: ${({ image }) => (image ? '55%' : 'auto')};
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media ${breakpoints.mds} {
      max-width: none;
      width: 100%;
    }
    @media ${breakpoints.sm} {
      margin-bottom: 0px;
    }
  }

  & svg {
    ${({ iconColor }) => iconColor && `fill: ${iconColor}`};
  }

  @media ${breakpoints.mds} {
    padding: ${({ image }) => (image ? ' 0 80px 80px' : '80px')};
    .color-block-mobile-img {
      display: block;
      position: relative;
      top: -80px;
      right: -30px;
    }
  }

  @media ${breakpoints.sm} {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: ${({ image }) => (image ? ' 0 30px 30px' : '30px')};
    .color-block-mobile-img {
      top: 30px;
      right: auto;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 80px 0;
  border-bottom: 1px dashed rgba(38, 38, 38, 0.12);

  @media ${breakpoints.sm} {
    margin: 30px 0;
  }
`;
export const DescriptionWrapper = styled.div`
  max-width: 530px;
  width: 50%;

  @media ${breakpoints.mds} {
    max-width: auto;
    width: 100%;
  }
`;
