import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';
import UIUXServices from './UIUXServices';
import ProcessSection from './ProcessSection';
import MessageCard from '../Card/MessageCard';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ContactUsForm from '../ContactUsForm';

const UIUXDesignPage = () => {
  const { message } = useStaticQuery(query);
  return (
    <PageContainer>
      <PageContent>
        <TopPageSectionWrapper
          title="A trusted provider of UI/UX design services"
          description="We create appealing, user-friendly designs that help our clients delight the audience,
          increase brand awareness, minimize churn, and drive conversions."
        />
        <UIUXServices />
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <MessageCard
          image={message}
          title="Want to turn your ideas into an appealing design?"
          subtitile="Let's create it together!"
          button="Contact us"
          style={{ margin: '0' }}
        />
        <ProcessSection />
        <Technologies />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default UIUXDesignPage;
