import React from 'react';

export default () => (
  <form
    name="DashbouquetContactUsForm"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="text" name="contact-name" />
    <input type="text" name="contact-budget" value="contact-budget" />
    <textarea name="contact-message" />
    <input type="email" name="contact-email" />
    <input type="phone" name="contact-phone" />
    <input type="file" name="contact-file" id="contact-file" />
    <input name="bot-field" />
    <input type="hidden" name="form-name" value="DashbouquetContactUsForm" />
    <input type="hidden" name="subject" value="Dashbouquet contact form" />
    <button type="submit">Send</button>
  </form>
);
