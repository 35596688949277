import React from 'react';
import styled from 'styled-components';
import { AvailableBlock, CategoryBlock, CrossedPriceBlock } from './components';
import fonts from '../../../../../utils/theme/fonts';
import { ROLES } from '../../constants';

const Container = styled.div``;
const Block = styled.div`
  margin-bottom: 20px;
  & span {
    font-size: 16px;
  }
  &:last-child {
    margin-bottom: 24px;
  }
`;
const Title = styled.p`
  font-family: ${fonts.euclidCircularA};
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  margin-bottom: 12px;
`;

const Item = styled.div`
  border-bottom: 1px solid #1b172d;
  margin-bottom: 24px;

  //&:after {
  //  content: '';
  //  width: 100%;
  //  border-bottom: solid 1px #ff0000;
  //  position: absolute;
  //  left: 0;
  //  top: 50%;
  //  z-index: 1;
  //}

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const MobileTable = ({ selected, onCounterChange, eurCurrency }) => {
  return (
    <Container>
      {selected.map((item) => {
        const role = ROLES.find((role) => role.name === item.name);
        return (
          <Item key={item.name}>
            <Block>
              <Title>Category</Title>
              <CategoryBlock item={item} onCounterChange={onCounterChange(item.name)} />
            </Block>
            <Block>
              <Title>Available</Title>
              <AvailableBlock roleName={item.name} />
            </Block>
            <Block>
              <Title>Price</Title>
              <CrossedPriceBlock
                crossedPrice={
                  eurCurrency
                    ? role.crossedPrice * item.count * 0.9
                    : role.crossedPrice * item.count
                }
                actualPrice={
                  eurCurrency ? role.actualPrice * item.count * 0.9 : role.actualPrice * item.count
                }
                eurCurrency={eurCurrency}
              />
            </Block>
          </Item>
        );
      })}
    </Container>
  );
};

export default MobileTable;
