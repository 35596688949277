import React, { useState } from 'react';
import styled from 'styled-components';
import fonts from '../../../utils/theme/fonts';
import SelectionBlock from './SelectionBlock';
import { breakpoints } from '../../../utils/theme/theme2/breakpoints';
import { MOBILE_VIEW_BREAKPOINT } from '../constants';
import PriceBlock from './PriceBlock';
import OrderNowBlock from './OrderNowBlock';

const Container = styled.div`
  width: 90%;
  margin-top: 160px;
  @media ${breakpoints.md} {
    width: 100%;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    margin-top: 0;
  }
`;

const FirstTitle = styled.h3`
  position: relative;
  z-index: 1;
  font-family: ${fonts.euclidCircularA};
  font-weight: 700;
  line-height: 120%;
  font-size: 50px;
  color: white;
  margin-bottom: 60px;
  text-align: center;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 24px;
    margin-bottom: 40px;
  }
`;

const Card = styled.div`
  background: #0c0918;
  border-radius: 30px;
  padding: 60px 140px;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    padding: 20px;
  }
`;

const AnchorForScroll = styled.div`
  visibility: hidden;
  position: relative;
  top: -106px;

  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    top: -82px;
  }
`;

const DevPicker = () => {
  const [selected, setSelected] = useState([]);
  const [eurCurrency, setEurCurrency] = useState(false);

  const handleCurrency = () => {
    setEurCurrency(!eurCurrency);
  };

  return (
    <Container>
      <AnchorForScroll id="see-pricing-plan-scroll-target" />
      <FirstTitle>Pick your next Web3 wizard</FirstTitle>
      <Card>
        <SelectionBlock selected={selected} setSelected={setSelected} />
        <PriceBlock
          selected={selected}
          setSelected={setSelected}
          eurCurrency={eurCurrency}
          setEurCurrency={handleCurrency}
        />
      </Card>
      <OrderNowBlock selected={selected} eurCurrency={eurCurrency} />
    </Container>
  );
};

export default DevPicker;
