import styled from 'styled-components';
import { web3Colors } from '../Web3Page/styled';
import { MOBILE_VIEW_BREAKPOINT } from '../Web3Page/constants';

export const CheckboxContainer = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
`;

export const Icon = styled.svg`
  display: flex;
  justify-content: center;
  align-items: center;
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${(props) => (props.checked ? web3Colors.blue : 'transparent')};
  border-radius: 3px;
  border: 1.5px solid ${web3Colors.blue};
  text-align: center;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }

  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    width: 15px;
    height: 15px;
    margin-bottom: 3px;
  }
`;
