import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ColoredServiceBlock, Divider } from './styled';
import { H2Text, P2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import ListWithSmallIcons from '../ListWithSmallIcons';

const ServiceListBlock = ({ list, image, title, description, bgColor, iconColor }) => {
  return (
    <ColoredServiceBlock bgColor={bgColor} isImage={!!image} className="colored-service-block">
      <div className="title-info">
        {image && (
          <GatsbyImage
            className="custom-offshore-img"
            image={image.childImageSharp.gatsbyImageData}
            alt="header-team"
          />
        )}

        <H2Text>{title}</H2Text>
        {description && <P2Text color={colors2.lightText}>{description}</P2Text>}
      </div>
      {list && (
        <>
          <Divider />
          <ListWithSmallIcons
            data={list}
            columns={1}
            bgIcon={colors2.white}
            iconColor={iconColor}
          />
        </>
      )}
    </ColoredServiceBlock>
  );
};
export default ServiceListBlock;
