import React from 'react';
import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import ButtonWithIcon from '../ButtonWithIcon';
import LeftIcon from '../../assets/icons/MVPCalculator/left.svg';
import SliderPagingLine from '../Slider/SliderPagingLineMVP';
import { MVPCalculatorSteps } from '../../configs/mvp-calculator/MVPCalculatorStepsData';
import { DefaultButton } from '../Buttons/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { H2Text, P3Text } from '../Typography/styled';
import { MVPCalculatorStepWrapper, ButtonsContainer } from './styled';

const Button = ({ children, ...props }) => (
  <DefaultButton width="217px" className="submit" {...props}>
    {children}
  </DefaultButton>
);
const SubmitButton = (props) => <Button {...props}>Submit</Button>;
const SkipButton = (props) => (
  <Button bgColor={colors2.gray} color={colors2.black} {...props}>
    Skip
  </Button>
);

const styles = {
  totalAmount: {
    color: '#FF053B',
    fontSize: 34,
    lineHeight: 1.2,
    fontWeight: 'bold',
  },
  titleWrapper: {
    textAlign: 'center',
    marginBottom: 40,
    maxWidth: 710,
  },

  pagingLineContainer: {
    marginRight: 10,
  },
  pagingLine: {
    width: 290,
  },
};

const MVPCalculatorStep = ({
  activeSteps,
  currentStepData,
  onSubmit,
  handleNextButtonClick,
  handleBackButtonClick,
  isDisplayDisabledSubmitButton,
  isReadyForSubmit,
  reportDataBySteps,
  children,
}) => {
  const getCurrentStepNumber = () => activeSteps.indexOf(currentStepData.value) + 1;
  const getCountOfSteps = () => {
    return activeSteps.length;
  };
  const getPagingAnimatedLineWidth = () => {
    const stepWidth = styles.pagingLine.width / getCountOfSteps();

    return stepWidth * getCurrentStepNumber();
  };

  const getNextButton = () => {
    const prevStep = reportDataBySteps.length + 1 > getCurrentStepNumber();
    if (currentStepData.canBeSkipped && !isReadyForSubmit) {
      return <SkipButton onClick={handleNextButtonClick} />;
    }
    if (isDisplayDisabledSubmitButton || prevStep) {
      return <SubmitButton onClick={onSubmit} disabled={!isReadyForSubmit} />;
    }
    return null;
  };

  return (
    <MVPCalculatorStepWrapper>
      <Flex flexDirection="column" alignItems="center" mb={4}>
        <Box mb={1}>
          <P3Text color={colors2.lightText}>
            {`${getCurrentStepNumber()} of ${getCountOfSteps()} questions`}
          </P3Text>
        </Box>
        <Box style={styles.pagingLineContainer}>
          <SliderPagingLine
            pagingLineWidth={styles.pagingLine.width}
            pagingLineColor="#323347"
            pagingAnimatedLineWidth={getPagingAnimatedLineWidth()}
          />
        </Box>
      </Flex>
      <Flex flexDirection="column" alignItems="center" style={styles.titleWrapper}>
        <H2Text>{currentStepData.title}</H2Text>
        {currentStepData.description && (
          <Box mt={2}>
            {currentStepData.description.map((description) => (
              <P3Text color={colors2.lightText} key={description}>
                {description}
              </P3Text>
            ))}
          </Box>
        )}
      </Flex>
      <Box width="100%">
        {children}

        {MVPCalculatorSteps[0] !== currentStepData.value && (
          <ButtonsContainer>
            <ButtonWithIcon icon={LeftIcon} onClick={handleBackButtonClick}>
              Previous Question
            </ButtonWithIcon>
            {getNextButton()}
          </ButtonsContainer>
        )}
      </Box>
    </MVPCalculatorStepWrapper>
  );
};

MVPCalculatorStep.propTypes = {
  activeSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentStepData: PropTypes.object.isRequired, // Item of the MVPCalculatorStepsData
  onSubmit: PropTypes.func.isRequired,
  handleNextButtonClick: PropTypes.func,
  handleBackButtonClick: PropTypes.func,
  isDisplayDisabledSubmitButton: PropTypes.bool,
  isReadyForSubmit: PropTypes.bool,
};

MVPCalculatorStep.defaultProps = {
  handleNextButtonClick: () => {},
  handleBackButtonClick: () => {},
};

export default MVPCalculatorStep;
