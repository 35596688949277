import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H5Text } from '../Typography/styled';

export const SidebarWrapper = styled.div`
  display: none;

  ${H5Text} {
    font-weight: 600;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    width: 26px;
    height: 14px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 20px !important;
    width: 20px !important;
    top: 36px !important;
    right: 30px !important;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${colors2.white};
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 20px 40px;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .logo {
    margin-top: 20px;
  }
  @media (max-width: 1215px) {
    display: flex;
  }

  @media ${breakpoints.sm} {
    .bm-item-list {
      padding: 16px;
    }
  }
`;
export const MenuContainer = styled.div`
  &.bm-item {
    margin-top: 70px;
    margin-bottom: 80px;
    display: flex !important;
    flex-direction: column;
    gap: 40px;
  }
  @media ${breakpoints.mds} {
    &.bm-item {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
`;
export const MenuListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:focus {
    outline: none !important;
  }
`;
