import React, { useState, useRef, useEffect } from 'react';
import { DropdownWrapper, DropdownMenuListItem } from './styled';
import DownArrowIcon from '../../assets/icons/updateDesign/down.svg';
import { P3Text } from '../Typography/styled';

const DropdownPortfolio = ({ label, menuData, isAccordion, onClick }) => {
  const [isOpen, setOpen] = useState(false);

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const toggleDropdown = (e) => {
    e.target.id === e.currentTarget.id ? setOpen(!isOpen) : setOpen(isOpen);
  };

  return (
    <DropdownWrapper className="dropdown" ref={wrapperRef} isOpen={isOpen}>
      <div className="dropdown-header" onClick={(e) => toggleDropdown(e)}>
        <DropdownMenuListItem isOpen={isOpen}>
          <P3Text>{label}</P3Text>
          <DownArrowIcon className="arrow" />
        </DropdownMenuListItem>
      </div>
      {isOpen && (
        <div className={isAccordion ? `accordion-body` : `dropdown-body`}>
          {menuData.map((item, i) => {
            return (
              <div
                className="dropdown-item"
                id={item.name}
                key={i}
                onClick={() => {
                  onClick(item.label);
                  setOpen(false);
                }}
              >
                <P3Text>{item.label}</P3Text>
              </div>
            );
          })}
        </div>
      )}
    </DropdownWrapper>
  );
};
export default DropdownPortfolio;
