import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HeaderContainer } from '../Header/styled';
import Logo from '../../assets/images/updateDesign/icons/logo.svg';
import { web3Colors } from './styled';
import { CALENDLY_KOBIASHOV, CONTENT_SIDE_PADDING, MOBILE_VIEW_BREAKPOINT } from './constants';
import { PurpleButton } from './components';

const HeaderContainerStyled = styled(HeaderContainer)`
  background: ${web3Colors.background};
  padding: ${CONTENT_SIDE_PADDING};
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`;

const WhiteLogo = styled(Logo)`
  color: white;
  & > path {
    fill: currentColor;
  }
`;

const LetsTalkButton = styled(PurpleButton)`
  width: 169px;
  height: 58px;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    padding: 0;
    width: fit-content;
    height: fit-content;
    background: transparent;
    box-shadow: none;
    border: none;
    &:hover {
      background: transparent;
      border: none;
    }
  }
`;

const Light = styled.div`
  width: 700px;
  height: 700px;
  position: absolute;
  z-index: 1;
  left: -400px;
  top: ${(props) => -250 - props.offset}px;
  background-image: radial-gradient(
    circle,
    rgba(116, 98, 244, 0.43) -20%,
    rgba(255, 255, 0, 0) 70%
  );

  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    width: 400px;
    height: 400px;
    left: -200px;
    top: ${(props) => -200 - props.offset}px;
    background-image: radial-gradient(
      circle,
      rgba(116, 98, 244, 0.43) -20%,
      rgba(255, 255, 0, 0) 100%
    );
  }
`;

const Header = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <HeaderContainerStyled>
      <WhiteLogo style={{ height: '34px', zIndex: '10' }} />
      <a href={CALENDLY_KOBIASHOV} rel="noopener noreferrer">
        <LetsTalkButton text="Let's Talk" withIcon />
      </a>
      <Light offset={offset} />
    </HeaderContainerStyled>
  );
};

export default Header;
