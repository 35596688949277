import React from 'react';
import { graphql } from 'gatsby';
import AboutUsPage from '../components/AboutUsPage';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageWrapper } from '../components/homePage/styled';
import SEO from '../components/SEO';

const AboutUs = ({
  data: {
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
    pageInfo: {
      frontmatter: { seoTitle, description, keywords },
    },
  },
}) => {
  return (
    <PageWrapper>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={siteUrl}
        description={description}
      />
      <Header />
      <AboutUsPage />
      <Footer />
    </PageWrapper>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    pageInfo: markdownRemark(frontmatter: { name: { eq: "about-us" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }
  }
`;

export default AboutUs;
