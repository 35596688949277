/* eslint-disable prefer-template */
import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import FAQPage from '../components/FAQPage';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageWrapper } from '../components/homePage/styled';

const FAQ = ({
  data: {
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
    faqPageInfo: {
      frontmatter: { seoTitle, description, keywords },
    },
  },
}) => (
  <>
    <SEO
      titleTemplate={titleTemplate}
      title={seoTitle}
      keywords={keywords}
      url={`${siteUrl}/faq`}
      description={description}
    />
    <PageWrapper>
      <Header />
      <FAQPage />
      <Footer />
    </PageWrapper>
  </>
);
export const query = graphql`
  {
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    faqPageInfo: markdownRemark(frontmatter: { name: { eq: "faq" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }
  }
`;

export default FAQ;
