import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate, Script } from 'gatsby';
import { Input } from './styled';
import { PurpleButton } from '../components';
import fonts from '../../../utils/theme/fonts';
import { web3Colors } from '../styled';
import { CALENDLY_KOBIASHOV, MOBILE_VIEW_BREAKPOINT } from '../constants';
import { CONTACT_EMAIL, INFO_EMAIL, OUTREACH_EMAIL, SMTP_SECURE_TOKEN } from '../../../constants';
import { calculateTotal } from './helpers';
import { ROLES } from './constants';
import { displayNumberAsPriceOr, getAvailableDate } from './PriceBlock/Table/helpers';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 74px;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    padding-top: 40px;
  }
`;

const ElementsBlock = styled.div`
  width: 450px;
`;

const EmailInput = styled(Input)`
  background: #100b27;
  font-weight: 500;
  padding: 20px;
  margin-bottom: 30px;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

const OrderNowButton = styled(PurpleButton)`
  width: 100%;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 16px;
  padding: 20px;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 16px;
    margin-bottom: 14px;
  }
`;

const InfoText = styled.span`
  display: block;
  font-family: ${fonts.poppins};
  font-size: 16px;
  line-height: 160%;
  color: ${web3Colors.grey};
  text-align: center;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 12px;
  }
`;

const emailContent = ({
  email,
  selected,
  total,
  calendly,
  regardsName,
  regardsStoreName,
  eurCurrency,
}) => {
  return `<div
        style=" padding: 40px;
    font-size: 14px; max-width: 500px; width: 100%"
>
    <style type="text/css" scoped>
      .pick-time:hover { cursor: pointer; }
    </style>
    <p style="margin: 20px 0px; color: black;">Dear ${email},</p>
    <p style="margin: 20px 0px; color: black;">
        Thank you for choosing Dash as a partner that will grow your team. Below is a summary of your selections:
    </p>
    <div style="padding: 15px; background: #f1f1f1; border-radius: 10px;">
        <ol type="1" style="padding: 0 20px 10px 20px; margin: 0">
            ${selected
              .map(
                (item) =>
                  `<li style="margin-bottom: 5px">x${item.count} ${
                    item.text || item.name
                  } - Availability: ${item.available}, Price: ${displayNumberAsPriceOr(
                    'TBD',
                    item.actualPrice * item.count,
                    eurCurrency
                  )}</li>`
              )
              .join('')}
        </ol>
        <p style="padding-top: 10px; margin: 0; color: black; border-top: 1px dashed black;">
            Total: ${displayNumberAsPriceOr('TBD', total, eurCurrency)}
        </p>
    </div>
    <p style="margin: 20px 0px; color: black;">
        Let's pick a time for us to connect and talk about the roles you've selected!
        <br/>
        We'd like to gain a deeper understanding of the specific skills, experience, and qualities you are seeking in candidates.
        <br/>
        By clarifying your expectations and preferences, we can ensure that we present you with candidates who closely match your needs and who will be valuable additions to your team.
    </p>
    <a href="${calendly}">
        <button class="pick-time" style="background: #7D6CFF; color: white; padding: 10px 20px; border: none; border-radius: 5px; font-size: 14px">
            Pick time
        </button>
    </a>
    <p style="margin: 20px 0px; color: black;">
        We're here to ensure you have the right expertise at your disposal.
    </p>
    <p style="margin: 40px 0px 20px; color: black;">
        Best regards,
        <br />
        ${regardsName}
        <br />
        ${regardsStoreName} Customer Support Team
    </p>
</div>`;
};

const OrderNowBlock = ({ selected, eurCurrency }) => {
  const [email, setEmail] = useState('');

  const sendEmail = () => {
    if (window.Email && email && selected.length > 0) {
      window.Email.send({
        SecureToken: SMTP_SECURE_TOKEN,
        To: email,
        From: INFO_EMAIL,
        Cc: ['ak@dashbouquet.com'],
        Bcc: [CONTACT_EMAIL, OUTREACH_EMAIL],
        Subject: 'Your Dashbouquet Cart Summary',
        Body: emailContent({
          email,
          selected: selected.map((item) => {
            const role = ROLES.find((role) => role.name === item.name);
            return {
              ...item,
              actualPrice: eurCurrency ? role.actualPrice * 0.9 : role.actualPrice,
              available: getAvailableDate(item.name),
            };
          }),
          total: calculateTotal(selected, eurCurrency).totalActualPrice,
          calendly: CALENDLY_KOBIASHOV,
          regardsName: 'Emily Hansley',
          regardsStoreName: 'Dashbouquet',
          eurCurrency,
        }),
      }).then(() => {
        navigate('/web3-thankyou');
      });
    }
  };

  return (
    <Container>
      <ElementsBlock>
        <EmailInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email Address"
        />
        <OrderNowButton text="Order Now" onClick={sendEmail} withIcon />
        <InfoText>You won't be charged yet</InfoText>
      </ElementsBlock>
      <Script src="https://smtpjs.com/v3/smtp.js" />
    </Container>
  );
};

export default OrderNowBlock;
