import MVPCalculatorStepsData, {
  COST_RATE_BY_TEAM_MEMBERS,
  requiredPositions,
  MVP_CALCULATOR_STEPS,
  PARAMETERS_SELECTION_STEP_INDEX,
} from '../../configs/mvp-calculator/MVPCalculatorStepsData';
/**
 * Returns array of items by step value
 *
 * @param {object} stepsData
 * @param {string} stepValue
 * @returns {object[]}
 */
export function getItemsByStepValue(stepsData, stepValue) {
  return stepsData.find((step) => step.value === stepValue).items;
}

/**
 * Returns array of items values by step value
 *
 * @param {object} stepsData
 * @param {string} stepValue
 * @returns {string[]}
 */
export function getItemsValuesByStepValue(stepsData, stepValue) {
  const itemsByStep = getItemsByStepValue(stepsData, stepValue);

  return itemsByStep.map((item) => item.value);
}

/**
 * Returns step by belonging item value
 *
 * @param {object} stepsData
 * @param {string} itemValue
 * @returns {object}
 */
export function getStepByBelongingItemValue(stepsData, itemValue) {
  return stepsData.find(({ value }) =>
    getItemsValuesByStepValue(stepsData, value).includes(itemValue)
  );
}

/**
 * Returns array of objects with items grouped by step
 * [{
 *  value: string;
 *  itemsValues: string[];
 * }]
 *
 * @param {object} stepsData
 * @param {string[]} selectedItems
 * @returns {string[]}
 */
export function getGroupedByStepsSelectedItems(stepsData, selectedItems) {
  return stepsData.reduce((result, { value }) => {
    const itemsValues = getItemsValuesByStepValue(stepsData, value).filter((itemsValue) =>
      selectedItems.includes(itemsValue)
    );

    if (!itemsValues.length) {
      return result;
    }

    return [...result, { value, itemsValues }];
  }, []);
}

/**
 * Returns array of selected items values by step
 *
 * @param {object} stepsData
 * @param {string[]} selectedItems
 * @param {string} stepValue
 * @returns {string[]}
 */
export function getSelectedItemsValuesByStepValue(stepsData, selectedItems, stepValue) {
  const groupedByStepsSelectedItems = getGroupedByStepsSelectedItems(stepsData, selectedItems);

  if (!groupedByStepsSelectedItems.length) {
    return [];
  }

  const groupedByStepsSelectedItem = groupedByStepsSelectedItems.find(
    ({ value }) => value === stepValue
  );

  if (!groupedByStepsSelectedItem) {
    return [];
  }

  return groupedByStepsSelectedItem.itemsValues;
}

/**
 * Returns array of items by step value
 *
 * @param {object} stepsData
 * @param {string[]} selectedItems
 * @param {string} stepValue
 * @returns {object[]}
 */
export function getSelectedItemsByStepValue(stepsData, selectedItems, stepValue) {
  const itemsValues = getSelectedItemsValuesByStepValue(stepsData, selectedItems, stepValue);

  return stepsData.reduce(
    (result, step) => [...result, ...step.items.filter(({ value }) => itemsValues.includes(value))],
    []
  );
}

export const getCostByTeamMember = (rateOfTeam, timeEstimation) => {
  return requiredPositions.reduce((positionResult, position) => {
    const positionEstimation = rateOfTeam[position] * timeEstimation;
    const positionCost = Math.ceil(positionEstimation * COST_RATE_BY_TEAM_MEMBERS[position]);
    return [
      ...positionResult,
      {
        position,
        estimation: positionEstimation,
        cost: positionCost,
        rate: rateOfTeam[position],
      },
    ];
  }, []);
};

export function getReportDataBySteps(stepsData, selectedItems, projectIdea, timeEstimetionBySteps) {
  const reportData = timeEstimetionBySteps.map(({ value, timeEstimation }) => {
    const { title, rateOfTeam } = stepsData.find((step) => step.value === value);
    const selectedItemsData = getSelectedItemsByStepValue(stepsData, selectedItems, value);

    const itemsTitles =
      value === MVP_CALCULATOR_STEPS.PARAMETERS_SELECTION
        ? selectedItemsData.map((item) => `${item.title} ${item.description}`)
        : selectedItemsData.map((item) => item.title);
    const itemsValues = selectedItemsData.map((item) => item.value);
    const technicalSolution = selectedItemsData.map((item) =>
      item.technicalSolution ? item.technicalSolution : null
    );
    const costByTeamMember = getCostByTeamMember(rateOfTeam, timeEstimation);

    let stepCost = costByTeamMember.reduce((sum, membercost) => sum + membercost.cost, 0);
    stepCost = value === MVP_CALCULATOR_STEPS.AI_TECHNOLOGIES ? stepCost / 2.5 : stepCost; // 2.5 - random value to reduce sum for AI step

    return {
      value,
      title,
      stepCost,
      costByTeamMember,
      itemsTitles,
      itemsValues,
      rateOfTeam,
      timeEstimation,
      technicalSolution,
    };
  });

  if (projectIdea) {
    const projectIdeaStepData = getReportDataForProjectIdea(projectIdea);
    reportData.splice(PARAMETERS_SELECTION_STEP_INDEX + 1, 0, projectIdeaStepData);
  }

  return reportData;
}

export const getReportDataForProjectIdea = (projectIdea) => {
  const projectIdeaStepData = MVPCalculatorStepsData.find(
    (stepData) => stepData.value === MVP_CALCULATOR_STEPS.PROJECT_IDEA
  );
  return {
    value: projectIdeaStepData.value,
    title: projectIdeaStepData.title,
    stepCost: 0,
    costByTeamMember: getCostByTeamMember(projectIdeaStepData.rateOfTeam, 0),
    itemsTitles: [projectIdea],
    itemsValues: [`${projectIdeaStepData.value}:${projectIdea}`],
    rateOfTeam: projectIdeaStepData.rateOfTeam,
    timeEstimation: 0,
    technicalSolution: [],
  };
};
