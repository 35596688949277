import React from 'react';

import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import ServiceListBlock from '../ServiceList';

const services = [
  {
    icon: <LogoIcon />,
    title: 'Web system backend ',
    text: 'Rely on our backend development company to make a robust web system that can handle heavy loads. Following best software engineering practices, we’ll achieve scalability, performance, and ease of infrastructure maintenance.',
  },
  {
    icon: <LogoIcon />,
    title: 'Mobile app backend',
    text: 'Get a powerful responsive mobile application delivering an engaging user experience. Our team will build a complex business logic, enable fast performance, and protect against cybersecurity threats.',
  },
  {
    icon: <LogoIcon />,
    title: 'API integration',
    text: 'Reduce costs and time to market by utilizing third-party services. We’ll integrate all the required APIs, including payment/shipping gateways, data analytics tools, chats, social networks, and inventory management systems.',
  },
  {
    icon: <LogoIcon />,
    title: 'Cloud deployment',
    text: 'Ensure on-demand scalability of computing resources, improve performance, and provide disaster recovery. With vast expertise in cloud backend development, we’ll automate the deployment process and enforce security.',
  },
];

const BackendSolutions = () => {
  return (
    <ServiceListBlock
      title="Backend development solutions we create"
      iconColor={colors2.blue}
      list={services}
      bgColor={colors2.lightBlue}
    />
  );
};

export default BackendSolutions;
