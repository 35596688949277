import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import * as JsSearch from 'js-search';
import Helmet from 'react-helmet';
import BlogLayout from '../components/BlogLayout';
import BlogCard from '../components/BlogCard';
import SortByDate from '../utils/sortByDate';

import SEO from '../components/SEO';
import { Main } from '../templates/Blog';

const BlogSearchPage = ({
  location,
  data: {
    blogSearchPageInfo: {
      frontmatter: { title, seoTitle, description, keywords },
    },
    allBlogs: { edges: allBlogs },
    site: {
      siteMetadata: { titleTemplate, siteUrl, contacts },
    },
  },
}) => {
  const { search } = location;
  const urlParams =
    search &&
    JSON.parse(
      `{"${decodeURI(search.slice(1, search.length))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  const dataToSearch = new JsSearch.Search(['node', 'frontmatter', 'title']);
  dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
  dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
  dataToSearch.addIndex(['node', 'frontmatter', 'introtext']);
  dataToSearch.addIndex(['node', 'html']);
  dataToSearch.addIndex(['node', 'frontmatter', 'title']);
  dataToSearch.addDocuments(allBlogs);
  const searchResult = dataToSearch.search(urlParams && urlParams.search ? urlParams.search : '');
  return (
    <>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={`${siteUrl}/blog-search`}
        description={description}
      />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <BlogLayout
        title={title}
        allBlogs={allBlogs}
        contacts={contacts}
        posts={allBlogs}
        slug={`${siteUrl}/blog-search`}
        location={location}
        siteUrl={siteUrl}
        description={description}
      >
        {!searchResult.length &&
          `There were no search results for the search "${urlParams.search}". Please try using more general terms to get more results.`}
        {searchResult.length && (
          <Main>
            {SortByDate(searchResult).map((data) => (
              <BlogCard key={data.node.id} mb={8} data={data} />
            ))}
          </Main>
        )}
      </BlogLayout>
    </>
  );
};

export const query = graphql`
  {
    blogSearchPageInfo: markdownRemark(frontmatter: { name: { eq: "blog search" } }) {
      frontmatter {
        title
        seoTitle
        description
        keywords
      }
    }
    allBlogs: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          html
          frontmatter {
            category
            postImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            altPostImageText
            date
            title
            introtext
            author
            cardBackgroundImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            color
            colorFont
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        titleTemplate
        contacts {
          socialLinks {
            icon
            url
          }
          email {
            url
            label
          }
          phones {
            url
            label
          }
        }
      }
    }
  }
`;

BlogSearchPage.propTypes = {
  data: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default BlogSearchPage;
