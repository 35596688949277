import React from 'react';

import styled from 'styled-components';

import TestingIcon from '../../assets/icons/updateDesign/dedicated/testing.svg';
import TestingScrollIcon from '../../assets/icons/updateDesign/dedicated/testing-hover.svg';
import UxScrollIcon from '../../assets/icons/updateDesign/development/ux-hover.svg';
import UxIcon from '../../assets/icons/updateDesign/development/ux.svg';
import DevelopmentIcon from '../../assets/icons/updateDesign/development/development.svg';
import DevelopmentScrollIcon from '../../assets/icons/updateDesign/development/development-hover.svg';
import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import SupportIcon from '../../assets/icons/updateDesign/dedicated/support.svg';
import SupportScrollIcon from '../../assets/icons/updateDesign/dedicated/support-hover.svg';

import { H2Text } from '../Typography/styled';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import ProcessComponent from '../ProcessComponent';

export const Wrapper = styled.div`
  @media ${breakpoints.sm} {
    ${H2Text} {
      margin-bottom: 0px;
    }
    padding: 0 30px;
    .list-with-big-icons_wrapper {
      gap: 30px;
      .step {
        flex-direction: column;
        margin-bottom: 0px;
        gap: 30px;
      }
      .number {
        margin-top: 0px;
        margin-right: 0px;
      }
    }
  }
`;
const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'Discovery',
    text: 'We collect project requirements to get a clear vision of your business goals. We also help identify the target audience, conduct competitor analysis, and work out a branding style.',
  },
  {
    number: 'step 2',
    icon: <UxIcon />,
    onScrollIcon: <UxScrollIcon />,
    title: 'Prototyping',
    text: 'Our frontend development company draws wireframes and mockups to provide a visual representation of the design layout. In 2 weeks, you’ll get a dynamic clickable prototype.',
  },
  {
    number: 'step 3',
    icon: <DevelopmentIcon />,
    onScrollIcon: <DevelopmentScrollIcon />,
    title: 'Development',
    text: 'After discussing the prototype with you, we’ll create a modern intuitive design that your users will love. We’ll send you demos every 1–2 weeks.',
  },
  {
    number: 'step 4',
    icon: <TestingIcon />,
    onScrollIcon: <TestingScrollIcon />,
    title: 'Testing',
    text: 'We use various types of testing (usability, manual, automated, etc.) to achieve smooth, bug-free product operation. We prevent potential bottlenecks, including security and performance issues.',
  },
  {
    number: 'step 5',
    icon: <SupportIcon />,
    onScrollIcon: <SupportScrollIcon />,
    title: 'Deployment',
    text: 'When the app/website is ready for production, our frontend development company releases it on the market. On top of that, our team provides post-launch support and maintenance.',
  },
];

const ProcessSection = () => {
  return (
    <Wrapper>
      <ProcessComponent title="Our frontend development process " data={work} />
    </Wrapper>
  );
};

export default ProcessSection;
