/* eslint-disable jsx-a11y/html-has-lang */
import * as React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

const SEO = ({
  title,
  titleTemplate,
  // keywords,
  description,
  image,
  url,
  pageType,
  data: {
    mainLogo: {
      childImageSharp: {
        original: { src: mainLogo },
      },
    },
    site: {
      siteMetadata: { siteUrl },
    },
  },
}) => {
  const schemaMurkup = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Dashbouquet Development LLC',
    image: siteUrl + mainLogo,
    url: siteUrl,
    telephone: '+375297551821',
    priceRange: 'from 10000$ to 50000$',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '',
      addressLocality: '',
      postalCode: '',
      addressCountry: '',
    },
  };
  return (
    <Helmet
      title={title}
      titleTemplate={titleTemplate}
      htmlAttributes={{
        prefix:
          'og:http://ogp.me/ns# website:http://ogp.me/ns/website# article:http://ogp.me/ns/article# profile:http://ogp.me/ns/profile# fb:http://ogp.me/ns/fb#',
      }}
      headAttributes={{
        itemtype: 'http://schema.org/WPHeader',
      }}
    >
      {description && <meta name="description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      {description && <meta name="twitter:image:alt" content={description} />}

      {/* {description && <meta name="keywords" content={keywords} />} */}

      <meta name="image" content={image || siteUrl + mainLogo} />
      <meta name="twitter:image:src" content={image || siteUrl + mainLogo} />
      <meta name="twitter:image" content={image || siteUrl + mainLogo} />

      <meta name="twitter:card" content={image ? 'summary' : 'summary_large_image'} />
      <meta name="twitter:title" content={title} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={pageType} />
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:image" content={image || siteUrl + mainLogo} />
      {description && <meta property="og:image:alt" content={description} />}
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Dashbouquet" />
      <script key="setCookie" type="text/javascript" src={withPrefix('setCookie.js')} defer />
      <link rel="canonical" href={url} />
      <script type="application/ld+json">{JSON.stringify(schemaMurkup)}</script>
      <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js" />
    </Helmet>
  );
};
export default SEO;

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  titleTemplate: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string.isRequired,
  // keywords: PropTypes.string.isRequired,
  pageType: PropTypes.string,
  data: PropTypes.shape().isRequired,
};

SEO.defaultProps = {
  description: null,
  image: null,
  titleTemplate: null,
  pageType: 'website',
};
