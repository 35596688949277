import React from 'react';
import styled from 'styled-components';
import fonts from '../../../utils/theme/fonts';
import { web3Colors } from '../styled';
import { MOBILE_VIEW_BREAKPOINT } from '../constants';
import { OTHER_ROLE_NAME, ROLES } from './constants';
import { Input } from './styled';
import Web3Checkbox from '../../Checkbox/Web3Checkbox';

const ItemContainer = styled.label`
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    margin-bottom: 16px;
  }
`;

const ItemTitle = styled.div`
  font-family: ${fonts.poppins};
  font-size: 20px;
  line-height: 160%;
  color: ${web3Colors.grey};
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 16px;
  }
`;

const Item = ({ title, isSelected, setSelected }) => {
  const onChange = (e) => {
    const { checked } = e.target;
    setSelected((prev) => {
      if (!checked) {
        return prev.filter((s) => s.name !== title);
      }
      return [...prev, { name: title, count: 1 }];
    });
  };

  return (
    <ItemContainer for={title}>
      <Web3Checkbox id={title} checked={isSelected} onChange={onChange} />
      <ItemTitle>{title}</ItemTitle>
    </ItemContainer>
  );
};

const Card = styled.div`
  background: #120f21;
  border-radius: 30px;
  border: 1px solid #1a1729;
  padding: 40px 70px;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    padding: 30px 40px;
  }
`;

const Title = styled.h5`
  font-family: ${fonts.euclidCircularA};
  font-weight: 600;
  font-size: 28px;
  line-height: 135%;
  color: white;
  padding-bottom: 16px;
  border-bottom: 1px dashed #2c2a3d;
  margin-bottom: 30px;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

const Section = styled.div`
  &:first-child {
    margin-right: 20px;
    @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
      margin-right: 0;
    }
  }
`;

const Selectors = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    flex-direction: column;
  }
`;

const OtherInput = styled(Input)`
  border-radius: 8px;
`;

const SelectionBlock = ({ selected, setSelected }) => {
  const renderItems = (roles) => {
    return roles.map((role) => (
      <Item
        key={role.name}
        title={role.name}
        isSelected={Boolean(selected.find((s) => s.name === role.name))}
        setSelected={setSelected}
      />
    ));
  };

  const onInputChange = (e) => {
    const { value } = e.target;
    setSelected((prev) => {
      return prev.map((s) => {
        if (s.name === OTHER_ROLE_NAME) {
          return { ...s, text: value };
        }
        return s;
      });
    });
  };

  const other = selected.find((s) => s.name === OTHER_ROLE_NAME);
  return (
    <Card>
      <Title>Role</Title>
      <Selectors>
        <Section>{renderItems(ROLES.slice(0, 4))}</Section>
        <Section>{renderItems(ROLES.slice(4, 9))}</Section>
      </Selectors>
      {other && (
        <OtherInput
          value={other.text}
          onChange={onInputChange}
          placeholder="What role are you searching for ?"
        />
      )}
    </Card>
  );
};

export default SelectionBlock;
