import * as React from 'react';
import { Image } from 'rebass';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import PropTypes, { oneOfType, string, number } from 'prop-types';

const MyImage = ({ src, width, image, alt, ...props }) =>
  src ? (
    <Image
      as={StaticImage}
      src={src}
      {...props}
      width={width}
      alt={alt}
      css="outline: none;  height: auto;"
    />
  ) : (
    <Image
      as={GatsbyImage}
      image={getImage(image)}
      {...props}
      width={width}
      alt={alt}
      css="outline: none; height: auto;"
    />
  );

MyImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.arrayOf(oneOfType([number, string])),
};

MyImage.defaultProps = {
  src: '',
  width: [1, 1, 1], // /Why?
};

export default MyImage;
