export const mvpPdfEmailContent = (name) => {
  const bodyContent = `<div style = " padding: 40px;
    font-size: 14px; max-width: 500px; width: 100%">
  <p style="margin: 20px 0px; color: black;">
  Hi ${name},
  </p>
  <p style="margin: 20px 0px; color: black;">
  This is Mary from Dashbouquet Development.<br>
  Thanks for your MVP calculation request on our website!
  </p>
  <p style="margin: 20px 0px; color: black;">
   You can find the estimation for your project in the attachment below.<br>
   Please, share your thoughts.
   </p>
   <p style="margin: 20px 0px; color: black;">
   If you would like a more accurate estimate tailored to your exact requirements, 
   let’s have a call. What time would work for you? 
  </p>
  <p style="margin: 40px 0px 20px; color: black;">
  Kind regards,<br>
  Mary S.<br>
  Sales Manager | <a href="https://www.linkedin.com/in/mary-s-a9877221a/">LinkedIn</a> 
  <p/>
  <p style="margin: 20px 0px; color: black;">
  +1  646  934  8662<br>
  <a href="https://dashbouquet.com/">Dashbouquet Development</a> 
  </p>
  </div>`;
  return bodyContent;
};
export const groupBy = (data, key) => {
  return data.reduce((rv, x) => {
    // ! mutating incoming data does not seem like good practice
    // eslint-disable-next-line no-param-reassign
    rv[x[key]] = rv[x[key]] || [];
    rv[x[key]].push(x);
    return rv;
  }, {});
};
