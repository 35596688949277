import styled from 'styled-components';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import colors2 from '../../utils/theme/theme2/colors2';
import { H2Text, P2Text, H3Text } from '../Typography/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${H2Text} {
    margin-bottom: 30px;
  }
  ${P2Text} {
    margin-bottom: 80px;
    color: ${colors2.lightText};
  }
  @media ${breakpoints.mds} {
    ${H2Text} {
      margin-bottom: 20px;
    }
    ${P2Text} {
      margin-bottom: 30px;
      color: ${colors2.lightText};
    }
  }
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
  text-align: center;
  > span {
    border: 1px solid rgba(38, 38, 38, 0.12);
    border-radius: 60px;
    padding: 20px 40px;
    margin-right: 20px;
  }

  > svg {
    margin-right: 20px;
  }

  > last-child {
    margin-right: 0;
  }
  @media ${breakpoints.md} {
    flex-wrap: wrap;
    gap: 20px;
    > span {
      padding: 10px 25px;
      margin-right: 0px;
    }
    > svg {
      margin-right: 10px;
      height: 62px;
      width: 62px;
    }
  }
  @media ${breakpoints.sm} {
    flex-wrap: wrap;
    gap: 10px;
    > span {
      padding: 2px 18px;
    }
    > svg {
      margin-right: 10px;
      height: 44px;
      width: 44px;
    }
    ${H3Text} {
      font-size: 14px;
      line-height: 23px;
    }
  }
`;
