import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H2Text } from '../Typography/styled';

export const ColoredServiceBlock = styled.div`
  width: 100%;
  padding: 80px;

  background-color: ${({ bgColor }) => bgColor ?? colors2.lightBlue};
  border-radius: 40px;
  position: relative;

  .title-info {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: ${(p) => (p.isImage ? '520px' : 'unset')};
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .custom-offshore-img {
    width: 440px;
    height: 440px;
    position: absolute !important;
    top: -100px;
    right: 0;
  }

  @media ${breakpoints.md} {
    .title-info {
      max-width: unset;
    }
    padding: 0 50px 50px;
    ${H2Text} {
      margin-top: ${(p) => (p.isImage ? '340px' : '50px')};
    }
    .custom-offshore-img {
      right: 0px;
    }
    .list-with-small-icons_wrapper {
      gap: 50px;
    }
  }
  @media ${breakpoints.sm} {
    padding: 0 30px 30px;

    ${H2Text} {
      margin-top: ${(p) => (p.isImage ? '243px' : '30px')};
    }
    .custom-offshore-img {
      top: -80px;
      width: 343px;
      height: 343px;
    }
    .list-with-small-icons_wrapper {
      gap: 40px;
      .text {
        margin-bottom: 30px;
      }
      .step {
        margin-top: 0;
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  max-width: 960px;
  height: 1px;
  margin: 80px 0;
  background: rgba(38, 38, 38, 0.12);
  @media ${breakpoints.mds} {
    margin: 50px 0;
  }
  @media ${breakpoints.sm} {
    margin: 40px 0;
  }
`;
