import React from 'react';

import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import ServiceListBlock from '../ServiceList';

const services = [
  {
    icon: <LogoIcon />,
    title: 'Prototyping',
    text: 'After discovering your business aims, we create wireframes to visualize product structure. Then, we make a clickable prototype enjoyed by users and clear for frontend developers.',
  },
  {
    icon: <LogoIcon />,
    title: 'Usability testing',
    text: 'Our team tests solution usability to make sure it is intuitive, engaging and provides a seamless user experience. We also verify design compliance with project requirements.',
  },
  {
    icon: <LogoIcon />,
    title: 'UI/UX design consulting',
    text: 'We offer UI/UX design consulting services to help you improve existing products, deliver an amazing user experience, and boost customer engagement.',
  },
];

const UIUXServices = () => {
  return (
    <ServiceListBlock
      title="UI/UX design services we provide"
      iconColor={colors2.blue}
      list={services}
      bgColor={colors2.lightBlue}
    />
  );
};

export default UIUXServices;
