import React, { useState } from 'react';
import { StyledTextarea } from '../ContactUsForm/styled';
import { P3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import MVPCalculatorStep from './index';
import withCurrentStepData from './withCurrentStepData';

const MVPCalculatorTextAreaStep = ({ initialValue, currentStepData, ...props }) => {
  const [value, setValue] = useState(initialValue);
  const [textAreaCount, setTextAreaCount] = useState(initialValue.length);

  const onValueChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    setTextAreaCount(newValue.length);
    props.onChange(newValue);
  };

  return (
    <MVPCalculatorStep
      {...props}
      currentStepData={currentStepData}
      isReadyForSubmit={textAreaCount > 0}
      isDisplayDisabledSubmitButton
    >
      <span className="textarea">
        <StyledTextarea
          name="contact-message"
          value={value}
          onChange={onValueChange}
          placeholder="Tell us more about your idea or project"
          maxLength="5000"
        />
        <span className="textarea-counter-wrapper">
          <P3Text color={colors2.lightText}>{textAreaCount}</P3Text>
          <P3Text color={colors2.lightText}>/ 5000</P3Text>
        </span>
      </span>
    </MVPCalculatorStep>
  );
};

export default withCurrentStepData(MVPCalculatorTextAreaStep);
