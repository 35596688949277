import React from 'react';

import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import ServiceListBlock from '../ServiceList';

const services = [
  {
    icon: <LogoIcon />,
    title: 'Project consulting ',
    text: 'Obtain a clear understanding of your existing problems and areas it is possible to improve. We’ll analyze your goals, formulate a list of features, and make an approximate cost estimate.',
  },
  {
    icon: <LogoIcon />,
    title: 'UI/UX design',
    text: 'After collecting project requirements, we develop a clickable prototype. Then, our team will introduce the necessary changes according to your feedback. Finally, we’ll create an intuitive design your user will love.',
  },
  {
    icon: <LogoIcon />,
    title: 'Fintech software development',
    text: 'Address your pain points with a fintech app designed according to domain specifics, timeline, and budget. Our team will ensure compliance with the required standards like GDPR and PCI DSS.',
  },
  {
    icon: <LogoIcon />,
    title: 'Quality assurance',
    text: 'Our QA experts use various types of testing to deliver robust solutions. Furthermore, we’ll prevent potential bottlenecks such as performance and security problems while ensuring compliance with project requirements.',
  },
  {
    icon: <LogoIcon />,
    title: 'Legacy software modernization',
    text: 'Achieve fast system performance, high availability, scalability, and performance by migrating to a new technology stack. Our finance software developers will implement new updates and features if necessary.',
  },
];

const FinanceServices = () => {
  return (
    <ServiceListBlock
      title="Custom fintech software development services "
      iconColor={colors2.blue}
      list={services}
      bgColor={colors2.lightBlue}
    />
  );
};

export default FinanceServices;
