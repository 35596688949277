import React from 'react';
import { Link } from 'gatsby';
import { Box } from 'rebass';
import { Wrapper, IconWrapper } from './styled';
import { H2Text } from '../Typography/styled';
import ArrowBtn from '../Buttons/ArrowBtn';

const ListWithSmallIcons = ({ data, title, columns, iconColor, bgIcon, ...props }) => {
  const colsToShow = columns ?? 2;

  return (
    <Wrapper cols={colsToShow} {...props} className="list-with-small-icons_wrapper">
      {title && (
        <Box
          css={{
            '@media screen and (max-width: 744px)': {
              marginBottom: '80px',
            },
            '@media screen and (max-width: 520px)': {
              marginBottom: '0',
            },
          }}
        >
          <H2Text>{title}</H2Text>
        </Box>
      )}
      {data.map((item) => {
        return (
          <div className="step" key={item.title}>
            <IconWrapper color={iconColor} bg={bgIcon}>
              {item.icon}
            </IconWrapper>
            <div className="info">
              <p className="title">{item.title}</p>
              {item.text && <p className="text">{item.text}</p>}
              {item.link && (
                <Link to={item.link} style={{ textDecoration: 'none' }}>
                  <ArrowBtn text="Learn more" />
                </Link>
              )}
            </div>
          </div>
        );
      })}
    </Wrapper>
  );
};

export default ListWithSmallIcons;
