import styled from 'styled-components';
import { Flex } from 'rebass';
import React from 'react';
import Counter from './Counter';
import { web3Colors } from '../../../styled';
import fonts from '../../../../../utils/theme/fonts';
import CurrencySwitch from '../../../../CurrencySwitch';
import { displayNumberAsPriceOr, getAvailableDate } from './helpers';
import { OTHER_ROLE_NAME } from '../../constants';

const WordBreakSpan = styled.span`
  word-break: break-all;
`;

const TotalContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CategoryBlock = styled(({ item, onCounterChange, className }) => (
  <Flex className={className}>
    <Counter count={item.count} onChange={onCounterChange} />
    <WordBreakSpan>{item.name === OTHER_ROLE_NAME ? item.text : item.name}</WordBreakSpan>
  </Flex>
))`
  & > span {
    margin: auto 0 auto 12px;
  }
`;

export const AvailableBlock = styled(({ roleName, className }) => (
  <span className={className}>{getAvailableDate(roleName)}</span>
))`
  color: ${web3Colors.grey};
`;

export const CrossedPriceBlock = styled(
  ({ crossedPrice, actualPrice, className, isTotalPrice, eurCurrency, setEurCurrency }) =>
    isTotalPrice ? (
      <TotalContainerWrapper>
        <Flex className={className}>
          <span className="price--crossed-out">
            {displayNumberAsPriceOr('', crossedPrice, eurCurrency)}
          </span>
          &nbsp;
          <span>{displayNumberAsPriceOr('TBD', actualPrice, eurCurrency)} /week</span>
        </Flex>
        <CurrencySwitch eurCurrency={eurCurrency} setEurCurrency={setEurCurrency} />
      </TotalContainerWrapper>
    ) : (
      <Flex className={className}>
        <span className="price--crossed-out">
          {displayNumberAsPriceOr('', crossedPrice, eurCurrency)}
        </span>
        &nbsp;
        <span>{displayNumberAsPriceOr('TBD', actualPrice, eurCurrency)} /week</span>
      </Flex>
    )
)`
  font-family: ${fonts.poppins};
  font-weight: 500;
  line-height: 160%;
  font-size: 17px;
  & > span.price--crossed-out {
    color: ${web3Colors.grey};
    text-decoration: line-through;
  }
`;
