import React from 'react';
import { graphql } from 'gatsby';
import UIUXDesignPage from '../../components/UIUXDesignPage';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { PageWrapper } from '../../components/homePage/styled';
import SEO from '../../components/SEO';

const UIUXDesign = ({
  data: {
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
    pageInfo: {
      frontmatter: { seoTitle, description, keywords },
    },
  },
}) => {
  return (
    <PageWrapper>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={siteUrl}
        description={description}
      />
      <Header />
      <UIUXDesignPage />
      <Footer />
    </PageWrapper>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    pageInfo: markdownRemark(frontmatter: { name: { eq: "services/ui-ux-design" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }
  }
`;

export default UIUXDesign;
