import React from 'react';
import { Flex } from 'rebass';

import { PackagesContent } from './styled';
import { P2Text, H3Text, H2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';

import { DefaultButton } from '../Buttons/styled';
import Link from '../Link';
import CheckIcon from '../../assets/icons/updateDesign/check.svg';

const Packages = () => {
  const data = [
    {
      title: 'Services for startups',
      desc: `Build an MVP in 3 month to validate your idea and raise a pre-seed round.`,
      class: 'content__card-purple',
      items: [
        'MVP development',
        'UI/UX design',
        'Web or mobile app development',
        'Ongoing technical support',
      ],
      count: 10,
    },
    {
      title: 'Services for SMEs',
      desc: `Hire our team to solve business challenges and increase revenue.`,
      class: 'content__card-blue',
      items: [
        'Custom software development',
        'API integration',
        'Cloud migration and hosting',
        'Project management',
      ],
      count: 12,
    },
    {
      title: 'Custom software development',
      desc: `See what stages we pass to deliver your software project.`,
      class: 'content__card-green',
      items: [
        'Requirements analysis and consulting',
        'Agile software development',
        'Quality assurance',
        'Support and maintenance',
      ],
      count: 8,
    },
  ];
  return (
    <PackagesContent>
      <H2Text>Price packages</H2Text>

      <Flex className="content">
        {data.map((el, indx) => {
          return (
            <Flex flexDirection="column" className="content__wrapper" key={`packages_${indx}`}>
              <Flex className={[el.class, 'content__card']} flexDirection="column">
                <H3Text className="content__title">{el.title}</H3Text>
                <Flex className="block_wrapper">
                  <div className="text">
                    <P2Text className="content__desc" color={colors2.lightText}>
                      {el.desc}
                    </P2Text>
                    <Link to="/contact-us">
                      <DefaultButton width="100%" marginBottom="30px">
                        Start a project
                      </DefaultButton>
                    </Link>
                  </div>
                  <Flex className="offers_block_tablet" flexDirection="column">
                    {el.items.map((item, ind) => {
                      return (
                        <Flex className="content__offers" key={`packages-offers_${ind}`}>
                          <Flex>
                            <CheckIcon />
                            <P2Text className="content__icon">{item}</P2Text>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
              <Flex className="offers_block" flexDirection="column">
                {el.items.map((item, ind) => {
                  return (
                    <Flex className="content__offers" key={`packages-offers_${ind}`}>
                      <Flex>
                        <CheckIcon />
                        <P2Text className="content__icon">{item}</P2Text>
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </PackagesContent>
  );
};

export default Packages;
