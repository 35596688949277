import { getPlatformTimeEstimation } from './platformsStep';
import { getOptionalStepTimeEstimation } from './optionalSteps';
import {
  getGroupedByStepsSelectedItems,
  getItemsByStepValue,
  getSelectedItemsValuesByStepValue,
} from './stepsData';
import {
  MVPCalculatorOptionalSteps,
  MVP_CALCULATOR_STEPS,
} from '../../configs/mvp-calculator/MVPCalculatorStepsData';
import { getSizeTimeEstimation } from './sizeStep';

export function getTimeEstimationBySteps(stepsData, selectedItems) {
  const groupedByStepsItems = getGroupedByStepsSelectedItems(stepsData, selectedItems);
  const choosedPlatforms = getSelectedItemsValuesByStepValue(
    stepsData,
    selectedItems,
    MVP_CALCULATOR_STEPS.PLATFORM
  );
  const mobilePlatformFilter = () => {
    if (
      choosedPlatforms.includes('platform:ios') &&
      choosedPlatforms.includes('platform:android')
    ) {
      const filteredChoosePlatforms = choosedPlatforms.includes('platform:hybrydMobile')
        ? [
            ...choosedPlatforms.filter((el) => {
              return el !== 'platform:ios' && el !== 'platform:android';
            }),
          ]
        : [
            ...choosedPlatforms.filter((el) => {
              return el !== 'platform:ios' && el !== 'platform:android';
            }),
            'platform:hybrydMobile',
          ];
      return filteredChoosePlatforms;
    }
    return choosedPlatforms;
  };

  const [sizeSelectedItemValue] = getSelectedItemsValuesByStepValue(
    stepsData,
    selectedItems,
    MVP_CALCULATOR_STEPS.SIZE
  );

  return groupedByStepsItems.reduce((result, { value }) => {
    if (value === MVP_CALCULATOR_STEPS.PARAMETERS_SELECTION) {
      return result;
    }

    const allItemsByStep = getItemsByStepValue(stepsData, value);
    const isOptionalStep = MVPCalculatorOptionalSteps.includes(value);
    let timeEstimation = 0;

    if (isOptionalStep) {
      const optionalItemsValues = getSelectedItemsValuesByStepValue(
        stepsData,
        selectedItems,
        value
      );
      const needMobileFilterSteps = [
        'mediaAndSearch',
        'socialFeaturesAndMessaging',
        'notifications',
        'databaseAndAPI',
        'monetization',
        'adminPanel',
        'security',
        'aiTechnologies',
        'blockchain',
      ];
      const isNeedMobileFilter = needMobileFilterSteps.includes(value);
      const platform = mobilePlatformFilter();

      timeEstimation = getOptionalStepTimeEstimation(
        isNeedMobileFilter ? platform : choosedPlatforms,
        stepsData,
        optionalItemsValues
      );
    }

    if (value === MVP_CALCULATOR_STEPS.PLATFORM) {
      timeEstimation = getPlatformTimeEstimation(choosedPlatforms, allItemsByStep);
    }

    if (value === MVP_CALCULATOR_STEPS.SIZE) {
      timeEstimation = getSizeTimeEstimation(
        choosedPlatforms,
        allItemsByStep,
        sizeSelectedItemValue
      );
    }

    if (value === MVP_CALCULATOR_STEPS.DESIGN_COMPLEXITY) {
      const [designComplexityItemValue] = getSelectedItemsValuesByStepValue(
        stepsData,
        selectedItems,
        MVP_CALCULATOR_STEPS.DESIGN_COMPLEXITY
      );
      const platforms = mobilePlatformFilter();
      timeEstimation = getSizeTimeEstimation(platforms, allItemsByStep, designComplexityItemValue);
    }
    if (value === MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER) {
      const [cloudItemValue] = getSelectedItemsValuesByStepValue(
        stepsData,
        selectedItems,
        MVP_CALCULATOR_STEPS.CLOUD_INFRASTRUCTURE_PROVIDER
      );
      const platforms = mobilePlatformFilter();
      timeEstimation = getSizeTimeEstimation(platforms, allItemsByStep, cloudItemValue);
    }

    return [
      ...result,
      {
        value,
        timeEstimation: Math.ceil(timeEstimation),
      },
    ];
  }, []);
}
export function getTotalTime(timeEstimationByStepsData) {
  return timeEstimationByStepsData.reduce((result, step) => result + step.timeEstimation, 0);
}
