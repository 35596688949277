import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import { Script } from 'gatsby';
import Image from '../Image';
import {
  optionalPositions,
  getProjectEstimationInWeeks,
  getDefaultEstimationsByTeamMembers,
  updateEstimationsByTeamMembers,
} from '../../utils/mvp-calculator/estimate';
import MVPCalculatorResultSendPDFForm from './MVPCalculatorResultSendPDFForm';
import { H1Text } from '../Typography/styled';
import MVPCalculatorResultEstimation from './MVPCalculatorResultEstimation';
import colors2 from '../../utils/theme/theme2/colors2';

const styles = {
  container: {
    maxWidth: 810,
    width: '100%',
    marginTop: '20px',
    paddingTop: 60,
  },
  controls: {
    marginBottom: 100,
  },
  text: {
    color: colors2.black,
    textAlign: 'center',
  },
  estimationDetails: {
    marginTop: '60px',
    width: '100%',
  },
};
const MVPCalculatorResult = ({
  totalAmount,
  reportDataBySteps,
  handleUpdateTotalCost,
  thanksImg,
}) => {
  const [isPDFSent, setPDFsentState] = useState(false);
  const [projectEstimation, setProjectEstimation] = useState(0);
  const [estimationsByMembers, setEstimationsByMembers] = useState(() =>
    getDefaultEstimationsByTeamMembers(reportDataBySteps)
  );

  /**
   * @param {string} id
   * @param {string} position
   * @param {number} estimation
   * @param {string} action 'remove' | 'add'
   */

  const handleMemberActionClick = (id, position, estimation, cost, action) => {
    if (optionalPositions.includes(position)) {
      handleUpdateTotalCost(cost, action === 'add');
    }

    const updatedEstimationsByMembers = estimationsByMembers.map((estimationData) => {
      if (estimationData.id !== id) {
        return estimationData;
      }

      return {
        ...estimationData,
        canBeAdded: action === 'remove',
        canBeRemoved: action === 'add',
      };
    });

    setEstimationsByMembers(() => {
      const updatedRecalculatedEstimationsByMembers = updateEstimationsByTeamMembers(
        updatedEstimationsByMembers,
        reportDataBySteps
      );

      setProjectEstimation(getProjectEstimationInWeeks(updatedRecalculatedEstimationsByMembers));

      return updatedRecalculatedEstimationsByMembers;
    });
  };

  useEffect(() => {
    const defaultEstimationsByMembers = getDefaultEstimationsByTeamMembers(reportDataBySteps);
    setProjectEstimation(getProjectEstimationInWeeks(defaultEstimationsByMembers));
    setEstimationsByMembers(defaultEstimationsByMembers);
  }, [reportDataBySteps]);

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <Flex flexDirection="column" style={styles.container}>
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            {isPDFSent ? (
              <Flex
                style={{
                  flexDirection: 'column',
                  maxWidth: '530px',
                  width: '100%',
                  height: '60vh',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                id="thank-you-container"
              >
                <Image image={thanksImg} alt="image" />
                <Box
                  style={{
                    fontWeight: '700',
                    fontSize: '34px',
                    color: '#323347',
                    marginBottom: '20px',
                    marginTop: '50px',
                  }}
                >
                  Thanks for contacting us!
                </Box>
                <Box style={styles.text}>
                  Check your inbox to get your project estimate, which can be easily presented to
                  your board. If not received within 15 seconds, check your spam folder.
                </Box>
              </Flex>
            ) : (
              <>
                <H1Text style={{ textAlign: 'center' }}>Get your project estimate</H1Text>
                <MVPCalculatorResultSendPDFForm
                  reportDataBySteps={reportDataBySteps}
                  totalAmount={totalAmount}
                  projectEstimation={projectEstimation}
                  estimationByMembers={estimationsByMembers.filter(({ canBeAdded }) => !canBeAdded)}
                  handleFormDataWillSent={() => {
                    setPDFsentState(true);
                  }}
                />

                <Box id="project_duration" style={styles.estimationDetails}>
                  <MVPCalculatorResultEstimation
                    title="Project duration"
                    value={`${projectEstimation} weeks`}
                  />
                </Box>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Script src="https://smtpjs.com/v3/smtp.js" />
    </>
  );
};

MVPCalculatorResult.propTypes = {
  totalAmount: PropTypes.number.isRequired,
  // ! do something about propType object
  // eslint-disable-next-line react/forbid-prop-types
  reportDataBySteps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MVPCalculatorResult.defaultProps = {};

export default MVPCalculatorResult;
