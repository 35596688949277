import React from 'react';

export default () => (
  <form name="LeaveUsForm" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
    <input type="text" name="name" />
    <input type="email" name="email" />
    <textarea name="message" />
    <label htmlFor="file-input">
      <input type="file" name="file" id="file-input" />
    </label>
    <input name="bot-field" />
    <input type="hidden" name="form-name" value="LeaveUsForm" />
    <input type="hidden" name="subject" value="Dashbouquet form submission" />
    <button type="submit">Send</button>
  </form>
);
