import styled from 'styled-components';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import colors2 from '../../utils/theme/theme2/colors2';

export const ArrowBtn = styled.div`
  display: flex;
  align-items: center;
  background: none;
  color: inherit;
  border: 0px;
  padding: 0px;
  cursor: pointer;
  outline: inherit;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media ${breakpoints.sm} {
    span {
      display: none;
    }

    justify-content: center;
    align-items: center;
    background: ${colors2.gray};
    border-radius: 50%;
    width: 46px;
    height: 46px;
  }
`;
