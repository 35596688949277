import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';
import BackendSolutions from './BackendSolutions';
import ProcessSection from './ProcessSection';
import MessageCard from '../Card/MessageCard';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ContactUsForm from '../ContactUsForm';

const BackendDevelopmentPage = () => {
  const { message } = useStaticQuery(query);
  return (
    <PageContainer>
      <PageContent>
        <TopPageSectionWrapper
          title="Backend development company you can trust"
          description="Assemble a backend development team in less than 1 week. Create a robust system, achieve
          scalability, enforce security, and cut infrastructure costs."
        />

        <BackendSolutions />

        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <MessageCard
          image={message}
          title="Aim to build a robust backend for your app? "
          subtitile="Let’s discuss your project."
          button="Contact us"
          style={{ margin: '0' }}
        />
        <ProcessSection />

        <Technologies />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default BackendDevelopmentPage;
