import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import Image from '../Image';
import { H3Text, P2Text, P3Text } from '../Typography/styled';
import ArrowBtn from '../Buttons/ArrowBtn';
import colors2 from '../../utils/theme/theme2/colors2';
import { Wrapper, Category, StyledLink, Container } from './styled';

const BlogCard = ({
  data: {
    node: {
      id,
      fields: { slug },
      frontmatter: {
        category,
        title,
        introtext,
        altPostImageText,
        color,
        colorFont,
        cardBackgroundImage,
        postImage,
      },
    },
  },
  ...props
}) => {
  return (
    <Wrapper
      {...props}
      width={1}
      flexDirection="column"
      key={id}
      css={{ textAlign: 'left', background: color ?? '#F6F6F6' }}
    >
      <Container>
        <Box mb={3}>
          <Category bg={colorFont}>
            <P3Text
              color={
                colorFont && colorFont === 'white' ? 'rgba(255, 255, 255, 0.85)' : colors2.lightText
              }
            >
              {category}
            </P3Text>
          </Category>
        </Box>
        <Box mb={2}>
          <H3Text color={colorFont && colorFont === 'white' ? colors2.white : colors2.black}>
            {title}
          </H3Text>
        </Box>
        {introtext && (
          <P2Text
            color={
              colorFont && colorFont === 'white' ? 'rgba(255, 255, 255, 0.85)' : colors2.lightText
            }
            className="text"
          >
            {introtext}
          </P2Text>
        )}
        <StyledLink to={slug}>
          <ArrowBtn color={colorFont} text="Read article" />
        </StyledLink>
      </Container>
      <Image
        className={`${cardBackgroundImage ? '' : 'blog-card-image'}`}
        image={cardBackgroundImage ?? postImage}
        fadeIn
        alt={altPostImageText || ''}
      />
    </Wrapper>
  );
};

BlogCard.propTypes = {
  data: PropTypes.shape({
    node: PropTypes.shape({
      id: PropTypes.string,
      fields: PropTypes.shape(),
      googleAnalyticsViews: PropTypes.number,
      frontmatter: PropTypes.shape({
        postImage: PropTypes.shape(),
        category: PropTypes.string,
        title: PropTypes.string,
        introtext: PropTypes.string,
        author: PropTypes.string,
        date: PropTypes.string,
        altPostImageText: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default BlogCard;
