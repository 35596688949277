// ! what is this "finished" propety
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { DnD } from './styled';
import CloudIcon from '../../assets/icons/updateDesign/cloud.svg';

export const DropzoneComponent = ({ handleChange }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    handleChange?.(selectedFiles);
  }, [handleChange, selectedFiles]);

  return (
    <Dropzone
      onDrop={(files) => {
        if (files.length > 0) {
          setSelectedFiles(files);
        }
      }}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <DnD>
          <div {...getRootProps()} finished={!!selectedFiles[0]} className="dnd_input">
            <input {...getInputProps()} />

            {selectedFiles && selectedFiles[0]?.name ? (
              <div className="selected-file">{selectedFiles && selectedFiles[0]?.name}</div>
            ) : (
              <div className="placeholder">
                <CloudIcon />
                <p>
                  <span style={{ color: '#2665F6' }}>Upload</span> or drag any files that could be
                  useful, e.g., project specification, prototypes, etc.
                </p>
              </div>
            )}
          </div>
        </DnD>
      )}
    </Dropzone>
  );
};
