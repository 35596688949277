import styled from 'styled-components';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const ExpertiseDescriptionBlock = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 30px;
  li {
    list-style-type: none;
  }
  @media ${breakpoints.sm} {
    gap: 20px;
  }
`;
