import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass';
import { Divider, Wrapper, DescriptionWrapper } from './styled';
import { H2Text, P2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import ListWithSmallIcons from '../ListWithSmallIcons';

const ColorBlock = ({ image, list, title, bgColor, iconColor, description }) => {
  return (
    <Wrapper image={!!image} color={bgColor} iconColor={iconColor} className="color-block_wrapper">
      <Box className="color-block-mobile-img">
        <GatsbyImage
          className="color-block_img"
          image={image?.childImageSharp.gatsbyImageData}
          alt="header-team"
        />
      </Box>
      <div className="titleInfo">
        <H2Text>{title}</H2Text>
      </div>
      <Box
        css={{
          position: 'absolute',
          top: '-100px',
          right: 0,

          '@media screen and (max-width: 940px)': {
            display: 'none',
          },
        }}
      >
        <GatsbyImage
          className="color-block_img"
          image={image?.childImageSharp.gatsbyImageData}
          alt="header-team"
        />
      </Box>

      {list ? (
        <>
          <Divider />
          <ListWithSmallIcons
            data={list}
            columns={1}
            bgIcon={colors2.white}
            iconColor={colors2.blue}
          />
        </>
      ) : (
        description && (
          <DescriptionWrapper className="description_wrapper">
            <P2Text color={colors2.lightText}>{description}</P2Text>
          </DescriptionWrapper>
        )
      )}
    </Wrapper>
  );
};

export default ColorBlock;
