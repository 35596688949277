import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'gatsby';
import { SidebarWrapper, MenuContainer, MenuListItem } from './styled';
import { CloseMenuIcon, MenuIcon } from '../../assets/icons/updateDesign/header';
import { DefaultButton } from '../Buttons/styled';
import { H5Text, P3Text } from '../Typography/styled';
import Dropdown from '../Dropdown/Dropdown';
import Logo from '../../assets/images/updateDesign/icons/logo.svg';

const Sidebar = ({ menuList }) => {
  return (
    <SidebarWrapper>
      <Menu right width="100%" customBurgerIcon={<MenuIcon />} customCrossIcon={<CloseMenuIcon />}>
        <Link to="/">
          <Logo style={{ height: '34px' }} />
        </Link>

        <MenuContainer>
          {menuList.map((item, i) => {
            const menuSublinks = item.subLinks
              ? item.subLinks.map((el, i) => {
                  return {
                    label: (
                      <Link to={el.link}>
                        <P3Text>{el.name}</P3Text>
                      </Link>
                    ),
                    key: `header-sublink_${i}`,
                  };
                })
              : null;

            return item.subLinks ? (
              <Dropdown label={item.name} menuData={menuSublinks} key={i} isAccordion />
            ) : (
              <MenuListItem key={i}>
                <Link to={item.link} style={{ textDecoration: 'none' }}>
                  <H5Text>{item.name}</H5Text>
                </Link>
              </MenuListItem>
            );
          })}
        </MenuContainer>
        <Link to="/contact-us" style={{ textDecoration: 'none' }}>
          <DefaultButton width="144px" className="contactBtn">
            Contact us
          </DefaultButton>
        </Link>
      </Menu>
    </SidebarWrapper>
  );
};

export default Sidebar;
