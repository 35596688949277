import React from 'react';
import styled from 'styled-components';
import { Link as RebassLink } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';

import colors2 from '../../utils/theme/theme2/colors2';

const Link = styled(({ color, colorOnHover, borderRadius, ...props }) => (
  <RebassLink as={props.to ? GatsbyLink : 'a'} {...props} href={props.to || props.href} />
))`
  display: flex;
  cursor: pointer;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  color: ${(props) => props.color};
  text-decoration: none;
  text-align: center;
  user-select: text;

  &:hover {
    color: ${(props) => props.colorOnHover};
  }
`;

Link.defaultProps = {
  color: colors2.white,
  colorOnHover: colors2.blue,
};

export default Link;
