import React from 'react';
import { Flex, Box } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { H2Text, P1Text } from '../Typography/styled';

import { CheckMediumIcon } from '../../assets/icons/updateDesign/ecommerceMobileDev';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  gap: 80px;

  @media ${breakpoints.mds} {
    .desktop {
      display: none;
    }
  }

  @media ${breakpoints.sm} {
    flex-direction: column-reverse;
    gap: 60px;

    .desktop {
      display: block;
    }

    .possibility_img {
      margin: auto;
    }
  }
`;

const possibilitiesList = [
  'Smart Search',
  'Multiple Payment Options',
  'Quick Checkout',
  'Social Media Integration',
  'Instant customer support',
  'Favorites',
  'Reviews and Ratings',
  'Push notifications and personal recommendations/ offers',
  'Profile settings',
  'Any other custom feature you need',
];

const EndlessPossibilities = () => {
  const { posibilitiesImg } = useStaticQuery(statisticsBannerQuery);
  return (
    <div>
      <Wrapper>
        <div className="possibility_img">
          <GatsbyImage image={posibilitiesImg.childImageSharp.gatsbyImageData} alt="" />
        </div>

        <Flex flexDirection="column" style={{ maxWidth: '660px', width: '100%' }}>
          <H2Text>Endless possibilities of mobile E-commerce apps</H2Text>
          <Box
            className="desktop"
            flexDirection="column"
            css={{
              marginTop: '60px',
              gap: '20px',
              '@media screen and (max-width: 744px)': {
                display: 'none',
              },
              '@media screen and (max-width: 520px)': {
                marginTop: '30px',
                display: 'flex',
              },
            }}
          >
            {possibilitiesList.map((el, index) => {
              return (
                <Flex
                  key={index}
                  css={{
                    gap: '28px',
                    '@media screen and (max-width: 375px)': {
                      gap: '10px',
                    },
                  }}
                >
                  <CheckMediumIcon style={{ minWidth: '36px' }} /> <P1Text>{el}</P1Text>
                </Flex>
              );
            })}
          </Box>
        </Flex>
      </Wrapper>
      <Box
        css={{
          display: 'none',
          '@media screen and (max-width: 744px)': {
            marginTop: '40px',
            display: 'block',
          },
          '@media screen and (max-width: 520px)': {
            display: 'none',
          },
        }}
      >
        {possibilitiesList.map((el, index) => {
          return (
            <Flex key={index} style={{ gap: '28px' }}>
              <CheckMediumIcon style={{ minWidth: '36px' }} /> <P1Text>{el}</P1Text>
            </Flex>
          );
        })}
      </Box>
    </div>
  );
};
export const statisticsBannerQuery = graphql`
  {
    posibilitiesImg: file(relativePath: { eq: "images/updateDesign/endlessPossibilities.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
export default EndlessPossibilities;
