import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  margin: auto;
  gap: 80px;

  .step {
    display: flex;
    position: relative;
    align-items: center;
    gap: 100px;

    @media ${breakpoints.sm} {
      align-items: start;
      flex-direction: column;
      gap: 20px;
    }

    .divider {
      position: absolute;
      left: 75px;
      top: 150px;
      width: 1px;
      height: 90px;
      background-color: ${colors2.lightGray};
    }

    .info {
      display: flex;
      flex-direction: column;
    }

    .numberOfStep {
      color: ${colors2.lightText};
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: 500;
      line-height: 165%;

      @media ${breakpoints.mds} {
        font-size: 16px;
      }
      @media ${breakpoints.sm} {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }

    .title {
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: ${colors2.black};
      margin-bottom: 20px;

      @media ${breakpoints.mds} {
        font-size: 24px;
        line-height: 36px;
      }

      @media ${breakpoints.sm} {
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 10px;
      }
    }

    .text {
      color: ${colors2.lightText};

      font-size: 18px;
      font-weight: 400;
      line-height: 165%;

      @media ${breakpoints.mds} {
        font-size: 16px;
        font-weight: 400;
        line-height: 165%; /* 26.4px */
      }

      @media ${breakpoints.sm} {
        font-size: 14px;
        font-weight: 400;
        line-height: 160%;
      }
    }
    &:not(:last-child) {
      .divider {
        display: none;
      }
    }
  }

  .number {
    width: 150px;
    height: 150px;
    padding: ${({ withoutIcons }) => (withoutIcons ? 'auto' : '52px')};
    background-color: ${({ withoutIcons }) => (withoutIcons ? 'transparent' : colors2.lightGray)};
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${breakpoints.mds} {
      width: auto;
      height: auto;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media ${breakpoints.sm} {
    gap: 40px;
  }
`;

export const IconWrapper = styled.div`
  width: 150px;
  height: 150px;
  padding: 20px;
  background-color: ${({ bg }) => bg ?? colors2.lightGray};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    ${({ color }) => color && `fill: ${color}`};
  }
`;

export const Titles = styled.div`
  display: grid;
  gap: 30px;
  text-align: center;
  @media ${breakpoints.sm} {
    gap: 20px;
    text-align: left;
  }
`;
