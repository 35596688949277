import React from 'react';

import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import ServiceListBlock from '../ServiceList';

const solutions = [
  {
    icon: <LogoIcon />,
    title: 'Payment apps',
    text: 'Our fintech software development company makes fintact products that automate payments, increase financial service accessibility, and boost user engagement. We integrate a number of payment methods, from credit cards to digital wallets and cryptocurrencies.',
  },
  {
    icon: <LogoIcon />,
    title: 'Trading solutions',
    text: 'We build trading apps that allow users to trade various assets (shares, bonds, ETFs, cryptocurrencies, etc.) and provide real-time data insights. Our engineers will implement interactive charts, personalized recommendations, and incorporate engaging educational content for newbies.',
  },
  {
    icon: <LogoIcon />,
    title: 'Banking software ',
    text: 'Our fintech application development company creates secure banking solutions that help companies digitize their processes and improve customer service. We deliver features like instant payments, money transfers, transaction history, loan management, invoicing, loyalty programs, etc.  ',
  },
  {
    icon: <LogoIcon />,
    title: 'Investment management apps',
    text: 'We make fintech applications for investment portfolio management that provide interactive charts, dynamic rebalancing, real-time analytics, push notifications, and other functionality. Our fintech software experts employ AI technology to automate investments and personalize offerings.',
  },
  {
    icon: <LogoIcon />,
    title: 'Insurance apps',
    text: 'Our fintech software development company builds insurance software solutions that enable users to easily manage claims and policies, find the required information, as well as get personalized advice. Using AI and big data, we make fintech products that identify, assess, and prevent potential risks.  ',
  },
  {
    icon: <LogoIcon />,
    title: 'Audit & Accounting software',
    text: 'We develop audit/accounting software that helps companies streamline routine tasks, increase accuracy, and minimize errors. We build functionality such as audit checklist templates, data visualization dashboards, report generation, real-time data analytics, and compliance management.',
  },
];

const FinanceSolutions = () => {
  return (
    <ServiceListBlock
      title="Custom fintech solutions we build"
      iconColor={colors2.violet}
      list={solutions}
      bgColor={colors2.lightViolet}
    />
  );
};

export default FinanceSolutions;
