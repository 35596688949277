import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const Wrapper = styled.div`
  border-radius: 40px;
  display: grid;
  gap: 40px 60px;
  padding: 60px;
  align-items: center;
  img {
    object-position: center;
    object-fit: contain !important;
  }
  .blog-card-image {
    height: fit-content;
    img {
      border-radius: 40px;
    }
  }

  @media ${breakpoints.mds} {
    border-radius: 30px;
    padding: 60px 0 60px 60px;
    gap: 20px;
    img {
      border-radius: 30px;
    }
  }

  @media ${breakpoints.sm} {
    border-radius: 24px;
    padding: 30px;
    img {
      border-radius: 24px;
    }
  }
`;

export const Category = styled.div`
  background-color: ${({ bg }) =>
    bg && bg === 'white' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.8)'};
  border-radius: 18px;
  padding: 5px 15px;
  width: min-content;
  white-space: nowrap;
`;

export const StyledLink = styled(Link)`
  margin-top: 40px;
  width: fit-content;
  flex: 1;
  display: flex;
  align-items: end;
  text-decoration: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .text {
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    lineclamp: 3;
    -webkit-box-orient: vertical;
  }
`;
