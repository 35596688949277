import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H2Text, H3Text } from '../Typography/styled';

export const IconWrapper = styled.div`
  width: 66px;
  height: 66px;
  padding: 20px;
  background-color: ${colors2.lightGray};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    fill: ${colors2.blue};
  }
`;

export const SolutionsSection = styled.div`
  margin-top: 200px;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 80px;
`;

export const SolutionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 66px 1fr;
    gap: 30px;
    margin-bottom: 80px;
  }

  .info {
    padding-top: 13px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export const IntegrationsCard = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 80px;
  padding: 80px;
  background-color: ${({ color }) => color || colors2.green};
  border-radius: 40px;
  ${H2Text} {
    max-width: 440px;
    min-width: 440px;
  }
  @media ${breakpoints.mds} {
    padding: 50px;
    flex-direction: column;
    gap: 50px;
    ${H2Text} {
      max-width: unset;
      min-width: unset;
    }
  }
  @media ${breakpoints.sm} {
    padding: 30px;
    gap: 30px;
  }
`;
export const IntegrationsCardItems = styled.div`
  min-width: 440px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    display: flex;
    padding: 20px 80px 20px 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 60px;
    background-color: ${colors2.white};
  }

  ${H3Text} {
    width: 100%;
    text-align: center;
  }

  @media ${breakpoints.mds} {
    min-width: unset;
  }
  @media ${breakpoints.sm} {
    > div {
      padding: 10px 40px 10px 10px;
      gap: 10px;
    }
  }
`;
