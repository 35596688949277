import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import Header from '../Header';
import { H5Text } from '../Typography/styled';
import Footer from '../Footer';
import DropdownPortfolio from '../DropdownPortfolio';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import { PageContainer, PageContent, PageWrapper } from '../homePage/styled';
import { TopWrapper, Tools, Tags } from './styled';

const PortfolioLayout = ({
  setPlatforma,
  platforms,
  children,
  title,
  longTitle,
  platforma,
  industries,
  setIndustry,
  industry,
}) => {
  const dropdown = industries.map((item) => ({
    label: item.node.frontmatter.title,
    key: item.node.id,
  }));

  return (
    <PageWrapper>
      <Header />
      <PageContainer>
        <PageContent>
          <TopWrapper>
            <TopPageSectionWrapper title={title} description={longTitle} />
          </TopWrapper>
          <div>
            <Tools>
              <Tags>
                <Box
                  className={platforma === 'all' ? 'selected' : null}
                  onClick={() => {
                    setPlatforma('all');
                  }}
                >
                  <H5Text>All</H5Text>
                </Box>
                {platforms.map((item) => (
                  <Box
                    className={platforma === item.node.frontmatter.title ? 'selected' : null}
                    onClick={() => setPlatforma(item.node.frontmatter.title)}
                  >
                    <H5Text>{item.node.frontmatter.title}</H5Text>
                  </Box>
                ))}
              </Tags>

              <DropdownPortfolio
                onClick={setIndustry}
                label={industry}
                menuData={[
                  {
                    label: 'All industries',
                    key: 'All industries',
                  },
                  ...dropdown,
                ]}
              />
            </Tools>
            <Box>{children}</Box>
          </div>
        </PageContent>
      </PageContainer>
      <Footer />
    </PageWrapper>
  );
};

PortfolioLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default PortfolioLayout;
