import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import InnerLayout from './Layout';

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            keywords
            contacts {
              email {
                url
                label
              }
              addresses
              phones {
                url
                label
              }
              socialLinks {
                icon
                url
              }
            }
          }
        }
        footerbg: file(relativePath: { eq: "images/newFooterBgImg.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        badgeImage: file(relativePath: { eq: "icons/badges/top-mobile-app-dev-2021.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 308, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <InnerLayout data={data} pathname={location.pathname}>
        {children}
      </InnerLayout>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape().isRequired,
};

export default Layout;
