import React from 'react';

import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import ServiceListBlock from '../ServiceList';

const services = [
  {
    icon: <LogoIcon />,
    title: 'Website ',
    text: 'Attract customers and drive sales with a website or landing page. We create performant SEO-optimized websites, ensuring responsiveness and providing a speed index of more than 90%. ',
  },
  {
    icon: <LogoIcon />,
    title: 'Web portal',
    text: 'Increase operational efficiency and improve customer experience with a web portal. We’ll make a UX-optimized, scalable, and secure web system that will align with your business-specific needs.',
  },
  {
    icon: <LogoIcon />,
    title: 'Progressive web app',
    text: 'Boost user satisfaction and cut down expenses with mobile and web experiences combined together. Our team can also upgrade your website to a PWA to attract smartphone users and increase revenue.',
  },
  {
    icon: <LogoIcon />,
    title: 'Single-page application',
    text: 'Our engineers make dynamic and interactive single-page applications that provide a seamless user experience. SPAs we deliver are SEO-optimized and performant.',
  },
];

const WeAreReadyToBuild = () => {
  return (
    <ServiceListBlock
      title="We are ready to build your project"
      iconColor={colors2.black}
      list={services}
      bgColor={colors2.lightGreen}
    />
  );
};

export default WeAreReadyToBuild;
