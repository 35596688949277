import styled from 'styled-components';
import { Link } from 'gatsby';
import colors2 from '../../utils/theme/theme2/colors2';
import { web3Colors } from '../Web3Page/styled';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H1Text, P1Text, P2Text, P3Text, H2Text, H3Text, H4Text } from '../Typography/styled';
import { MOBILE_VIEW_BREAKPOINT } from '../Web3Page/constants';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  font-family: 'Golos Text', sans-serif;
  width: 100%;
  overflow-x: hidden;
  & .footer {
    max-width: 1280px;
    margin-top: 200px;
    overflow: hidden;
  }
  @media ${breakpoints.mds} {
    padding: 0px 32px 30px;
    & .footer {
      width: 100%;
      margin-top: 138px;
    }
  }
  @media ${breakpoints.sm} {
    padding: 0px 28px 30px;
    & .footer {
      margin-top: 120px;
    }
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 180px;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors2.black};
  .list-with-big-icons_wrapper {
    padding: 0;
  }
  @media ${breakpoints.mds} {
    margin-top: 180px;
  }
  @media ${breakpoints.sm} {
    margin-top: 125px;
  }
`;

export const PageImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors2.black};

  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    margin-top: 20px;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  padding: 0px 80px 0px;
  overflow: hidden;
  width: 100%;
  .top-page-section-wrapper {
    width: 100%;
  }
  .service-wrapper {
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: 100%;
    .colored-service-block {
      margin-top: 50px;
    }
    @media ${breakpoints.sm} {
      gap: 60px;
      .colored-service-block {
        margin-top: 80px;
      }
    }
  }
  .badges-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
  gap: 100px;
  & p,
  span {
    font-family: 'Golos Text', sans-serif;
  }

  @media ${breakpoints.mds} {
    gap: 120px;
    padding: 0;
  }
  @media ${breakpoints.sm} {
    gap: 80px;
  }
`;

export const ReliableTeamContent = styled.section`
  max-width: 854px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 270px;
  .gatsby-image-wrapper,
  .gatsby-image-wrapper-constrained {
    vertical-align: middle !important;
  }
  .content_description {
    padding: 30px 0px 60px 0px;
  }
  img {
    margin-right: 20px;
    margin-left: 20px;
    vertical-align: middle;
    height: 60px !important;
    width: 140px !important;
  }

  @media ${breakpoints.md} {
    margin-top: 270px;

    ${P1Text} {
      font-weight: 400;
      font-size: 18px;
      line-height: 165%;
    }
    .content_description {
      padding: 0px;
      margin: 20px 0 40px;
    }
  }
  @media ${breakpoints.mds} {
    margin-top: 230px;

    ${P1Text} {
      font-size: 16px;
      line-height: 165%;
    }
    .content_description {
      margin: 20px 0 30px;
    }
  }
  @media ${breakpoints.sm} {
    margin-top: 150px;
    img {
      height: 34px !important;
      width: 79px !important;
    }
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  @media ${breakpoints.md} {
    & button {
      width: 220px;
    }
  }
  @media ${breakpoints.mds} {
    flex-direction: column;
    gap: 16px;
    width: 100%;
    & button,
    a {
      width: 100%;
    }
  }
`;

export const OffersContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  .text_container {
    display: flex;
    flex-direction: column;
    padding: 60px 60px 30px;
  }
  .offerContents-card__text {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 60px 0px 60px 60px;
  }
  .offerContents-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
  }
  .offerContents-card__desc {
    padding: 20px 0px 50px 0px;
  }
  .offerContents-card {
    display: flex;
    gap: 40px;
    div {
      height: fit-content;
      border-radius: 40px;
    }
    &__web {
      background-color: ${colors2.green};
    }
    &__mobile {
      background-color: ${colors2.lightBlue};
    }
    &__mvp {
      display: flex;
      background-color: #f6f6f6;
      .cloud_img__tablet {
        display: none;
      }
    }
  }
  .offerContents-card__img {
    border-radius: 40px;
    bottom: 0;
  }
  @media ${breakpoints.md} {
    .offerContents-card__text {
      width: 100%;
    }
    .offerContents-card {
      flex-direction: column;
      gap: 40px;
    }
    .offerContents-cards {
      gap: 40px;
    }
  }
  @media ${breakpoints.mds} {
    .offerContents-card__text {
      padding: 30px;
      margin: 0px;
    }
    .offerContents-card__desc {
      padding: 20px 0px 50px 0px;
    }
    .offerContents-card {
      &__mvp {
        flex-direction: column;
        .img_container {
          overflow: hidden;
          svg {
            width: 100%;
            height: min-content;
          }
        }
        .cloud_img__tablet {
          display: block;
        }
        .cloud_img {
          display: none !important;
        }
      }
    }

    .text_container {
      padding: 0;
      margin: 30px;
    }
  }
  @media ${breakpoints.sm} {
    .offerContents-card {
      gap: 20px;
    }
    .offerContents-cards {
      gap: 20px;
    }
  }
`;

export const TechnologiesContent = styled.div`
  .technology {
    :nth-child(1),
    :nth-child(7) {
      border-left: none;
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      position: relative;
      width: 100%;
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      padding: 45px 20px;

      ${P3Text} {
        max-width: 146px;
        align-items: center;
        text-align: center;
      }

      &::after,
      &::before {
        content: '';
        display: block;
        height: calc(100% + 50px);
        position: absolute;
        width: 100%;
      }

      &::after {
        box-shadow: inset 1px 1px 0px 0px rgba(38, 38, 38, 0.12);
        left: -1px;
        top: 0px;
      }

      &::before {
        bottom: -1px;
        box-shadow: inset -1px -1px 0px 0px rgba(38, 38, 38, 0.12);
        left: 0;
      }

      &:nth-child(6)::before,
      &:nth-child(12)::before {
        box-shadow: inset 0 -1px 0px 0px rgba(38, 38, 38, 0.12);
      }

      &:first-child::after,
      &:nth-child(7)::after {
        box-shadow: inset 0px 1px 0px 0px rgba(38, 38, 38, 0.12);
      }
    }
  }
  .technology__img {
    width: 50px;
    height: 50px;
  }
  @media ${breakpoints.mds} {
    .technology__list {
      grid-template-columns: repeat(4, 1fr);
    }
    .technology__item {
      padding: 30px 20px;
    }
  }
  @media ${breakpoints.sm} {
    .technology__list {
      grid-template-columns: repeat(2, 1fr);
    }
    .technology__item {
      padding: 40px 20px;
    }
  }
`;

export const StatisticsBanner = styled.div`
  display: flex;
  .titles {
    max-width: 420px;
  }

  .content {
    padding: 80px;
    justify-content: space-between;
    background-color: ${colors2.lightGray};
    border-radius: 40px;
    max-width: 1120px;
    width: 100%;
    gap: 120px;
    &__text {
      max-width: 520px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 60px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__btn {
      height: 100%;
      display: flex;
      align-items: end;
    }
  }
  .content__btn__mobile {
    display: none;
  }
  @media ${breakpoints.md} {
    .content {
      padding: 50px;
      gap: 30px;
      &__text {
        gap: 40px;
      }
      .content__img {
        justify-content: space-between;
      }
      .img_container {
        min-width: 240px;
      }
      &__btn {
        height: unset;
        display: flex;
        align-items: end;
      }
    }
    ${H1Text} {
      font-weight: 500;
      font-size: 50px;
      line-height: 40px;
      white-space: nowrap;
    }
    ${P2Text} {
      font-weight: 400;
      font-size: 16px;
      line-height: 165%;
    }
  }

  @media ${breakpoints.mds} {
    .content {
      gap: 50px;
      padding: 50px;
      flex-direction: column-reverse;
      &__text {
        gap: 50px;
      }

      .content__btn {
        display: none;
      }
      .img_container {
        width: 100%;
      }
      .content__img {
        align-self: center;
      }
    }
    .content__btn__mobile {
      display: flex;
      justify-content: flex-end;
    }
    ${H1Text} {
      font-size: 34px;
      line-height: 30px;
    }
    ${P2Text} {
      font-size: 14px;
      line-height: 165%;
    }
  }
  @media ${breakpoints.sm} {
    .content {
      gap: 30px;
      padding: 30px;
      &__text {
        gap: 30px;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const Partners = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  gap: 80px;
  @media ${breakpoints.md} {
    width: 100%;
    gap: 60px;
  }
  @media ${breakpoints.mds} {
    flex-direction: column;
    gap: 40px;
    & > div {
      height: 45px;
    }
    img {
      object-fit: contain !important;
    }
  }
`;

export const ExpertiseContent = styled.div`
  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 80px;

    &__card {
      max-width: 520px;
      width: 50%;
      margin-bottom: 90px;
    }

    &__img {
      width: 66px;
    }

    &__title {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    &__desc {
      margin-bottom: 40px;
    }
  }
  ${H2Text} {
    font-weight: 500;
    font-size: 40px;
    line-height: 165%;
    color: ${colors2.black};
    margin-bottom: 40px;
    display: inline-block;
  }
  @media ${breakpoints.md} {
    .content {
      display: none;
    }
    ${H2Text} {
      font-weight: 400;
      font-size: 14px;
      line-height: 165%;
      color: ${colors2.lightText};
      margin-bottom: 40px;
      display: inline-block;
    }
  }
  @media ${breakpoints.md} {
    ${H2Text} {
      margin-bottom: 30px;
    }
  }
  @media ${breakpoints.mds} {
    ${H2Text} {
      margin-left: 16px;
    }
  }
`;

export const PackagesContent = styled.div`
  width: 100%;

  .content {
    margin-top: 80px;
    justify-content: space-between;

    &__wrapper {
      &:nth-child(1),
      &:nth-child(2) {
        margin-right: 30px;
        max-width: 350px;
      }
    }
    .offers_block_tablet {
      display: none;
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      button {
        margin: 0;
      }
      span {
        margin: 0;
      }
    }
    .block_wrapper {
      height: 100%;
    }
    &__card {
      border-radius: 30px;
      padding: 30px;
      margin-bottom: 30px;
      height: 316px;

      &-blue {
        background: ${colors2.lightBlue};
      }
      &-purple {
        background: ${colors2.lightViolet};
      }
      &-green {
        background: ${colors2.lightGreen};
      }
    }

    &__img {
      width: 66px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__desc {
      margin-bottom: 40px;
    }
    &__offers {
      padding: 10px 50px 5px 30px;
    }
    &__icon {
      margin-right: 10px;
    }
    &__count {
      padding-left: 30px;
      margin-top: 30px;
    }
  }
  @media ${breakpoints.md} {
    .content {
      margin-top: 40px;
      flex-direction: column;
      width: 100%;
      gap: 30px;

      &__wrapper {
        &:nth-child(1),
        &:nth-child(2) {
          margin-right: 0px;
          max-width: unset;
        }
      }
      &__card {
        padding: 50px;
        height: unset;
        &-blue {
          background: ${colors2.lightGreen};
        }
        &-purple {
          background: ${colors2.lightViolet};
        }
        &-green {
          background: ${colors2.lightBlue};
        }
      }
      .content__offers {
        padding: 0;
        white-space: nowrap;
        & > div {
          gap: 14px;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        button {
          width: 100%;
          margin: 0;
        }
        span {
          margin: 0;
        }
      }
    }
    .block_wrapper {
      gap: 34px;
      height: unset;
    }
    .offers_block_tablet {
      display: flex !important;
      gap: 16px;
    }
    .offers_block {
      display: none;
    }
    ${H2Text} {
      font-weight: 500;
      font-size: 40px;
      line-height: 130%;
    }
    ${H3Text} {
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
    }
    ${P2Text} {
      font-weight: 400;
      font-size: 16px;
      line-height: 165%;
    }
  }
  @media ${breakpoints.mds} {
    .content {
      margin-top: 30px;
    }
    .offers_block_tablet {
      display: none !important;
    }
    .offers_block {
      display: flex;
    }
    .text {
      gap: 34px;
    }
    ${H2Text} {
      font-size: 28px;
      line-height: 130%;
    }
    ${H3Text} {
      font-size: 24px;
      line-height: 130%;
    }
    ${P2Text} {
      font-size: 14px;
      line-height: 165%;
    }
  }
  @media ${breakpoints.sm} {
    .content {
      margin-top: 20px;
      gap: 20px;
    }
    .content__wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .content__card {
      margin-bottom: 0;
    }
  }
`;

export const LetsBannerContent = styled.div`
  width: 100%;

  .content {
    margin-left: 80px;
    @media ${breakpoints.mds} {
      margin-left: 0;
      ${H1Text} {
        text-align: center;
      }
    }
    &__btns {
      margin-top: 60px;
      @media ${breakpoints.mds} {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }

      @media ${breakpoints.sm} {
        flex-direction: column;
        a:first-child {
          margin-bottom: 13px;
        }

        button {
          max-width: 300px;
          width: 100%;
          margin: auto;
        }
      }
    }

    &__btn {
      margin-right: 20px;

      @media ${breakpoints.sm} {
        margin-right: 0;
      }
    }
  }
`;

export const ReviewsContent = styled.div`
  width: 100%;
  .slick-list {
    width: calc(100% - 180px);
    margin: 0 auto;
  }
  @media ${breakpoints.md} {
    .slick-slider {
      padding-bottom: 100px;
    }
    .slick-list {
      width: 100%;
    }
  }
  @media ${breakpoints.mds} {
    .slick-slider {
      padding-bottom: 80px;
    }
    ${H3Text} {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
    }
    ${H4Text} {
      font-weight: 500;
      font-size: 16px;
      line-height: 165%;
    }
  }
  @media ${breakpoints.sm} {
    .slick-slider {
      padding-bottom: 70px;
    }
    ${H3Text} {
      font-size: 18px;
      line-height: 30px;
    }
    ${H4Text} {
      font-size: 14px;
    }
  }
`;
export const ReviewCard = styled.div`
  display: flex !important;
  flex-direction: column;
  gap: 40px;

  @media ${breakpoints.sm} {
    gap: 20px;
  }
`;
export const NewsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .card {
    &__vertical {
      min-height: 708px;
      width: 540px;
      padding: 60px;
      background: ${colors2.lightBlue};
      border-radius: 40px;

      &:first-child {
        margin-right: 40px;
      }

      &-green {
        background-color: ${colors2.green};
      }
    }
    &__horizontal {
      margin-top: 40px;
      height: 544px;
      max-width: 1120px;
      padding: 60px;
      background: ${colors2.lightViolet};
      border-radius: 40px;

      &-green {
        background-color: ${colors2.green};
      }
      .text_content {
        width: 50%;
      }
    }
    &__img {
      width: 420px;
      height: 236px;
    }
    &__image {
      width: 520px;
      height: 292px;
    }

    &__tag {
      background: ${colors2.white};
      border-radius: 18px;
      display: inline-block;
      height: 26px;
      line-height: 26px;
      padding: 0 20px 0 23px;
      position: relative;
      margin: 60px 10px 30px 0;
      text-decoration: none;
      -webkit-transition: color 0.2s;

      &-green {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
    &__tille {
      margin-bottom: 20px;
    }
  }
  .news_container {
    margin-top: 60px;
  }
  @media ${breakpoints.md} {
    max-width: 744px;
    width: 100%;
    .late_news_content {
      flex-direction: column;
      gap: 30px;
      align-items: center;
    }
    .card__vertical {
      margin-right: 0px !important;
      width: 100%;
    }
    .card__horizontal {
      height: unset;
      flex-direction: column-reverse;
    }
    .card__horizontal .text_content {
      width: 100%;
    }
    .news_container {
      margin-top: 40px;
    }
    ${H2Text} {
      font-weight: 500;
      font-size: 40px;
      line-height: 130%;
    }

    ${H3Text} {
      font-weight: 500;
      font-size: 24px;
      line-height: 130%;
    }
    ${P3Text} {
      font-weight: 400;
      font-size: 14px;
      line-height: 165%;
    }
    ${P2Text} {
      font-weight: 400;
      font-size: 16px;
      line-height: 165%;
    }
    .card__image {
      height: 100%;
      width: 100%;
    }
  }
  @media ${breakpoints.mds} {
    width: 100%;
    .late_news_content {
      flex-direction: column;
      gap: 30px;
      align-items: center;
    }
    .card__vertical {
      margin-right: 0px !important;
      width: 100%;
    }
    .card__horizontal {
      margin-top: 0px;
      height: unset;
      flex-direction: column-reverse;
    }
    .card__horizontal .text_content {
      width: 100%;
    }

    .card__image {
      height: 100%;
      width: 100%;
    }
    .card__img {
      height: 100%;
      width: 100%;
    }
    .news_container {
      margin-top: 24px;
    }
    ${H2Text} {
      font-size: 28px;
      line-height: 130%;
    }
    ${H3Text} {
      font-size: 20px;
      line-height: 130%;
    }
    ${P3Text} {
      font-size: 12px;
      line-height: 165%;
    }
    ${P2Text} {
      font-size: 14px;
      line-height: 165%;
    }
  }
`;

export const WorksContent = styled.div`
  max-width: 1740px;
  width: 100%;
  overflow: hidden;
  margin: 200px 0;
  .slick-slider {
    max-width: 1740px;
    width: 100%;
    border-radius: 40px;
    padding-bottom: 110px;
  }

  @media (max-width: 1280px) {
    max-width: 1280px;
  }
  @media ${breakpoints.md} {
    overflow: hidden;
    .slick-slider {
      max-width: 1180px;
      width: 100%;
      border-radius: 40px;
    }
  }
  @media ${breakpoints.mds} {
    width: 100%;
    margin: 120px 0;
    padding: 0px 30px;
  }
  @media (max-width: 600px) {
    .slick-slider {
      padding-bottom: 150px;
    }
  }
  @media ${breakpoints.sm} {
    margin: 80px 0;
    padding: 0px 20px;
  }
`;

export const WorksLink = styled(Link)`
  align-self: end;
  position: relative;
  top: -60px;
  right: 90px;

  @media (max-width: 900px) {
    right: 30px;
  }
  @media ${breakpoints.mds} {
    right: -20px;
  }
  @media (max-width: 600px) {
    top: -115px;
  }
`;

export const BadgesContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  .badges_container {
    width: 100%;
    padding-bottom: 80px;
    border-bottom: 1px solid rgba(38, 38, 38, 0.12);
    .slick-slide {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .slick-list {
      width: calc(100% - 180px);
      margin: 0 auto;
    }
  }

  .card {
    &__img {
      width: 148px;
    }
  }
  @media ${breakpoints.md} {
    .badges_container {
      .slick-list {
        width: 100%;
        margin: 0 auto;
      }
      padding-bottom: 166px;
    }
    .card {
      &__img {
        width: 106px;
      }
    }
  }
  @media ${breakpoints.sm} {
    .badges_container {
      padding-bottom: 136px;
    }
    .card {
      &__img {
        width: 101px;
      }
    }
  }
`;
