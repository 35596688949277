import React from 'react';
import { navigate, graphql } from 'gatsby';
import styled from 'styled-components';
import Header from '../components/Header';
import { H1Text, P1Text } from '../components/Typography/styled';
import colors2 from '../utils/theme/theme2/colors2';
import { breakpoints } from '../utils/theme/theme2/breakpoints';
import { PageWrapper } from '../components/homePage/styled';
import SEO from '../components/SEO';
import { DefaultButton } from '../components/Buttons/styled';

export const ThankYouPageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  ${H1Text} {
    font-size: 100px;
    line-height: 100px;
    text-align: center;
  }
  ${P1Text} {
    color: ${colors2.lightText};
    text-align: center;
  }
  @media ${breakpoints.sm} {
    gap: 20px;
    ${H1Text} {
      font-size: 50px;
      line-height: 50px;
    }
  }
`;

const ThankYouPage = ({
  data: {
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
    pageInfo: {
      frontmatter: { seoTitle, description, keywords },
    },
  },
}) => {
  return (
    <PageWrapper style={{ height: '100vh' }}>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={siteUrl}
        description={description}
      />
      <Header />
      <ThankYouPageContent>
        <H1Text>Thank you!</H1Text>
        <P1Text>Your form submission has been received</P1Text>
        <DefaultButton
          onClick={() => {
            navigate('/');
          }}
        >
          Back home
        </DefaultButton>
      </ThankYouPageContent>
    </PageWrapper>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    pageInfo: markdownRemark(frontmatter: { name: { eq: "thank-you" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }
  }
`;

export default ThankYouPage;
