import React from 'react';

import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import ServiceListBlock from '../ServiceList';

const services = [
  {
    icon: <LogoIcon />,
    title: 'Prototyping & User testing',
    text: 'Our IT experts develop a dynamic prototype in 2–3 weeks and discuss it with you. On top of that, we conduct user testing to collect feedback from potential customers and refine the prototype accordingly.',
  },
  {
    icon: <LogoIcon />,
    title: 'UI/UX design ',
    text: 'Keeping pace with UI/UX trends, we create a modern, visually appealing design loved by end users. By applying a user-centric approach, we make sure the design is intuitive and meets the audience’s preferences.',
  },
  {
    icon: <LogoIcon />,
    title: 'MVP development',
    text: 'Following Agile best practices, our MVP app development company delivers new functionality every 1–3 weeks. By setting up a CI/CD pipeline, we automate building, testing, and deployment processes.',
  },
  {
    icon: <LogoIcon />,
    title: 'Testing',
    text: 'We employ various types of testing (functional, manual, usability, automated, etc.) to make robust solutions. Our QA engineers prevent potential bottlenecks like performance issues and security breaches.',
  },
  {
    icon: <LogoIcon />,
    title: 'Support & Maintenance ',
    text: 'Our engineers introduce new features and upgrades according to end-user feedback. Furthermore, we instantly fix arising issues to provide the smooth, error-free operation of your minimum viable product. ',
  },
];

const MVPServices = () => {
  return (
    <ServiceListBlock
      title="Our MVP development services"
      iconColor={colors2.blue}
      list={services}
      bgColor={colors2.lightBlue}
    />
  );
};

export default MVPServices;
