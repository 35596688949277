import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import BlogCard from '../components/BlogCard';
import BlogLayout from '../components/BlogLayout';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import { breakpoints } from '../utils/theme/theme2/breakpoints';

export const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  .box:nth-child(3n + 1) {
    grid-column: auto / span 2;
    grid-template-columns: 1fr 1fr;

    @media ${breakpoints.md} {
      grid-column: auto;
      grid-template-columns: 1fr 1fr;
    }

    @media ${breakpoints.sm} {
      grid-template-columns: 1fr;
      > div {
        grid-template-columns: 1fr;
      }
    }
  }
  .box:nth-child(3n + 2),
  .box:nth-child(3n + 3) {
    grid-column: auto / span 1;
    grid-template-rows: auto 1fr;
    > div {
      grid-template-columns: 1fr;
    }

    .gatsby-image-wrapper {
      grid-row: 1;
    }

    @media ${breakpoints.md} {
      grid-column: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;

      > div {
        grid-template-columns: 1fr 1fr;
      }

      .gatsby-image-wrapper {
        grid-row: auto;
      }
    }

    @media ${breakpoints.sm} {
      grid-template-columns: 1fr;

      > div {
        grid-template-columns: 1fr;
      }

      .gatsby-image-wrapper {
        grid-row: 1;
      }
    }
  }

  @media ${breakpoints.md} {
    grid-template-columns: 1fr;
  }

  @media ${breakpoints.sm} {
    grid-column: auto / span 1;
    grid-template-rows: auto 1fr;
    > div {
      grid-template-columns: 1fr;
    }

    .gatsby-image-wrapper {
      grid-row: 1;
    }
  }
`;

const BlogPageTemplate = ({
  location,
  pageContext: { limit },
  data: {
    blogPageInfo: {
      frontmatter: { seoTitle, description, keywords },
    },
    allBlogs: { edges: allBlogs },
    site: {
      siteMetadata: { siteUrl, titleTemplate, contacts },
    },
  },
}) => {
  const { search } = location;
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('page')) {
      setCurrentPage(+searchParams.get('page'));
    } else {
      setCurrentPage(1);
    }
  }, [search, setCurrentPage]);

  const pages = useMemo(() => Math.ceil(allBlogs.length / limit), [allBlogs.length, limit]);

  return (
    <>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={`${siteUrl}/blog`}
        description={description}
      />
      <BlogLayout
        title={seoTitle}
        socialTitle={seoTitle}
        allBlogs={allBlogs}
        contacts={contacts}
        posts={allBlogs}
        slug={`${siteUrl}/blog/`}
        location={location}
        siteUrl={siteUrl}
        description={description}
      >
        <Main>
          {allBlogs.slice((currentPage - 1) * limit, limit * currentPage).map((data) => (
            <BlogCard className="box" key={data.node.id} data={data} />
          ))}
        </Main>
        <Pagination
          page={currentPage}
          nextButtonClick={() => {
            navigate(currentPage === 2 ? `/blog` : `/blog?page=${currentPage - 1}`);
          }}
          previousButtonClick={() => {
            navigate(`/blog?page=${+currentPage + 1}`);
          }}
          onPageChange={(page) => {
            navigate(`/blog?page=${page}`);
          }}
          countPage={pages}
        />
      </BlogLayout>
    </>
  );
};

export const query = graphql`
  {
    blogPageInfo: markdownRemark(frontmatter: { name: { eq: "blog" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }
    allBlogs: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            category
            postImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            cardBackgroundImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            altPostImageText
            date
            title
            introtext
            author
            color
            colorFont
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        titleTemplate
        contacts {
          socialLinks {
            icon
            url
          }
          email {
            url
            label
          }
          phones {
            url
            label
          }
        }
      }
    }
  }
`;

BlogPageTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default BlogPageTemplate;
