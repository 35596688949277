import React from 'react';
import { Flex, Box } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { P2Text, H2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';

import ArrowBtn from '../Buttons/ArrowBtn';
import Link from '../Link';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const OurExpertsBanner = styled.div`
  display: flex;

  width: 100%;

  .content {
    padding: 80px;
    display: flex;
    gap: 112px;
    width: 100%;
    justify-content: space-between;
    background-color: ${colors2.lightGray};
    border-radius: 40px;
    .technology__img {
      max-width: 360px;
    }
    &__btn {
      justify-content: space-between;
      width: 100%;
      min-width: 213px;
    }
    .image-block--desktop {
      flex-direction: column;
      justify-content: space-between;
      gap: 80px;
      align-items: end;
    }
    @media ${breakpoints.mds} {
      padding: 50px;
      flex-direction: column;
      gap: 50px;
    }
    @media ${breakpoints.sm} {
      padding: 30px;
      gap: 30px;
    }
  }
`;

const OurExperts = () => {
  const { statictics } = useStaticQuery(statisticsBannerQuery);
  return (
    <OurExpertsBanner>
      <Flex className="content">
        <Box
          css={{
            display: 'none',
            '@media screen and (max-width: 744px)': {
              display: 'flex',

              justifyContent: 'end',
              width: '100%',
            },
          }}
        >
          <GatsbyImage
            image={statictics.childImageSharp.gatsbyImageData}
            alt="talking-banner"
            className="technology__img"
          />
        </Box>
        <Flex
          flexDirection="column"
          css={{
            width: '100%',
            gap: '30px',
            maxWidth: '468px',
            '@media screen and (max-width: 744px)': {
              maxWidth: 'unset',
            },
          }}
        >
          <H2Text>Our experts</H2Text>
          <P2Text color={colors2.lightText}>
            Our team of 60+ developers has been working with CRM platform for over 7 years and
            throughout these years, we’ve always been engaged in Salesforce-related projects.
            Security and scalability are our top priorities and something we’d never compromise on.
          </P2Text>
        </Flex>

        <Flex className="image-block--desktop">
          <Box
            css={{
              '@media screen and (max-width: 744px)': {
                display: 'none',
              },
            }}
          >
            <GatsbyImage
              image={statictics.childImageSharp.gatsbyImageData}
              alt="talking-banner"
              className="technology__img"
            />
          </Box>
          <Link to="/about-us">
            <div className="content__btn">
              <ArrowBtn text="Learn more about us" />
            </div>
          </Link>
        </Flex>
      </Flex>
    </OurExpertsBanner>
  );
};
export const statisticsBannerQuery = graphql`
  {
    statictics: file(relativePath: { eq: "images/updateDesign/statictics-banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
export default OurExperts;
