import styled from 'styled-components';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  gap: 30px;
  @media ${breakpoints.sm} {
    gap: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  white-space: nowrap;
  a {
    text-decoration: none;
  }
  button {
    width: 200px;
    padding: 15px 30px;
  }
  @media ${breakpoints.sm} {
    flex-direction: column;
    gap: 10px;
    button {
      width: 100%;
      padding: 15px 30px;
    }
    a {
      width: 100%;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media ${breakpoints.sm} {
    gap: 30px;
  }
`;
