import React from 'react';
import * as PropTypes from 'prop-types';
import { Box } from 'rebass';
import Helmet from 'react-helmet';
import GlobalStyles from '../../utils/theme/globalStyles';
// ? why we don't use the footer?
// eslint-disable-next-line no-unused-vars

import { FontFace } from '../../utils/theme/fonts';

const InnerLayout = ({
  children,
  data: {
    site: {
      siteMetadata: { title, description },
    },
  },
}) => {
  return (
    <>
      <Helmet
        title={title}
        meta={[
          {
            name: 'description',
            content: description,
          },
        ]}
        //  link={[{ rel: 'shortcut icon', type: 'image/x-icon', href: `${favicon}` }]}
      />
      <FontFace />
      <GlobalStyles />
      <Box as="main">{children}</Box>
    </>
  );
};

InnerLayout.propTypes = {
  data: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
};

export default InnerLayout;
