import React from 'react';
import styled from 'styled-components';
import ArrowUpRightIcon from '../../assets/icons/web3/arrowUpRight.svg';
import { DefaultButton } from '../Buttons/styled';
import fonts from '../../utils/theme/fonts';
import { MOBILE_VIEW_BREAKPOINT } from './constants';

const BUTTON_BACKGROUND = '#1300FF';
export const PurpleButton = styled(({ text, withIcon, ...props }) => {
  return (
    <DefaultButton width="144px" height="48px" {...props}>
      {text}
      {withIcon && <ArrowUpRightIcon />}
    </DefaultButton>
  );
})`
  background: ${BUTTON_BACKGROUND};
  font-family: ${fonts.poppins};
  font-weight: 500;
  font-size: 18px;
  line-height: 21.6px;
  width: fit-content;
  height: fit-content;
  padding: 18px 30px;

  & > svg {
    margin-left: 8px;
  }

  &:hover {
    background: ${BUTTON_BACKGROUND};
    color: white;
  }

  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 16px;
  }
`;
