import { Button as RebassButton } from 'rebass';
import styled, { css } from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

const defaultButtonColorStyle = (props) => css`
  color: ${props.color ? props.color : colors2.white};
  background-color: ${props.bgColor ? props.bgColor : colors2.black};
`;
export const DefaultButton = styled(RebassButton)`
  font-family: 'Golos Text', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 165%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid ${colors2.black};
  text-wrap: nowrap;
  border-radius: 10px;
  height: ${(props) => (props.height ? props.height : '56px')};
  width: ${(props) => (props.width ? props.width : '220px')};
  ${defaultButtonColorStyle};
  cursor: pointer;
  transition: all 0.7s;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media ${breakpoints.md} {
    width: auto;
    font-weight: 400;
    padding: 15px 30px;
  }

  &.light {
    &:hover {
      background-color: ${colors2.white};
    }
  }

  &:hover {
    color: ${colors2.black};
    background-color: ${colors2.white};
    border: 1px solid ${colors2.black};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
    &:hover {
      ${defaultButtonColorStyle};
    }
  }
`;

export const ArrowBtnContainer = styled.div`
  .btn {
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    color: ${({ color }) => (color && color === 'white' ? colors2.white : colors2.black)};
    border: 0px;
    padding: 0px;
    cursor: pointer;
    outline: inherit;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export const PaginationArrowBtn = styled.div`
  .btn {
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    color: inherit;
    border: 0px;
    padding: 0px;
    cursor: pointer;
    outline: inherit;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;
