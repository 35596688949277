import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Header from '../components/Header';
import { H1Text, H3Text, P1Text } from '../components/Typography/styled';
import colors2 from '../utils/theme/theme2/colors2';
import { breakpoints } from '../utils/theme/theme2/breakpoints';
import { PageWrapper } from '../components/homePage/styled';

import { DefaultButton } from '../components/Buttons/styled';

export const NotFoundPageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  ${H1Text} {
    font-size: 150px;
    line-height: 120px;
  }
  ${P1Text} {
    color: ${colors2.lightText};
    text-align: center;
  }
  @media ${breakpoints.sm} {
    gap: 20px;
    ${H1Text} {
      font-size: 100px;
      line-height: 80px;
    }
  }
`;

const NotFoundPage = () => {
  return (
    <PageWrapper style={{ height: '100vh' }}>
      <Header />
      <NotFoundPageContent>
        <H1Text>404</H1Text>
        <H3Text> OOOPS!</H3Text>
        <P1Text>Something went wrong on our side we’re sorry about that</P1Text>
        <DefaultButton
          onClick={() => {
            navigate('/');
          }}
        >
          Back home
        </DefaultButton>
      </NotFoundPageContent>
    </PageWrapper>
  );
};

export default NotFoundPage;
