import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { PageContainer, PageContent, PageImageContainer } from '../homePage/styled';
import Header from './Header';
import Onboard from './Onboard';
import { CONTENT_SIDE_PADDING, MOBILE_VIEW_BREAKPOINT } from './constants';
import ClientsSlider from './ClientsSlider';
import DevPicker from './DevPicker';
import useWindowSize from './hooks/useWindowSize';

const PageContainerStyled = styled(PageContainer)`
  z-index: 1;
`;

const PageContentStyled = styled(PageContent)`
  padding: 0 ${CONTENT_SIDE_PADDING};
  margin: auto;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Light = styled.div`
  ${(props) => !props.rightPosition && 'display: none;'}
  position: absolute;
  top: 75px;
  right: ${(props) => props.rightPosition}px;
  width: 800px;
  height: 800px;
  background-image: radial-gradient(circle, rgb(116 98 244 / 28%) -20%, rgba(255, 255, 0, 0) 70%);

  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    width: 500px;
    height: 500px;
    right: -175px;
    top: 0;
  }
`;

const BottomLightContainer = styled.div`
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  @media (min-width: ${MOBILE_VIEW_BREAKPOINT}) {
    width: 500px;
    height: 500px;
  }
`;

const BottomLight = styled.div`
  z-index: 0;
  position: absolute;
  top: -40%;
  left: -20%;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle, rgb(116 98 244 / 67%) -20%, rgba(255, 255, 0, 0) 70%);
`;

const Web3Page = () => {
  const [windowWidth] = useWindowSize();
  const [rightPosition, setRightPosition] = useState();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const element = document.getElementById('page-content-id');
      if (element) {
        setRightPosition((windowWidth - element.offsetWidth) / 2 - 300);
      }
    }
  }, [windowWidth]);

  return (
    <>
      <Header />
      <PageContainerStyled>
        <Onboard />
        <Container>
          <ClientsSlider />
          <PageContentStyled id="page-content-id">
            <DevPicker />
          </PageContentStyled>
          <Light rightPosition={rightPosition} />
        </Container>
      </PageContainerStyled>
      <PageImageContainer>
        <StaticImage
          src="../../assets/images/web3/web3-background.png"
          alt="background"
          quality={100}
          style={{ zIndex: '1' }}
        />
        <BottomLightContainer>
          <BottomLight />
        </BottomLightContainer>
      </PageImageContainer>
    </>
  );
};

export default Web3Page;
