import React, { useState, useRef, useEffect } from 'react';
import { DropdownWrapper, DropdownMenuListItem } from './styled';
import DownArrowIcon from '../../assets/icons/updateDesign/down.svg';
import { H5Text } from '../Typography/styled';

const Dropdown = ({ label, menuData, isAccordion }) => {
  const [isOpen, setOpen] = useState(false);

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [wrapperRef]);

  const toggleDropdown = (e) => {
    e.target.id === e.currentTarget.id ? setOpen(!isOpen) : setOpen(isOpen);
  };

  return (
    <DropdownWrapper className="dropdown" ref={wrapperRef} isOpen={isOpen}>
      <div className="dropdown-header" onClick={(e) => toggleDropdown(e)}>
        <DropdownMenuListItem isOpen={isOpen}>
          <H5Text>{label}</H5Text>
          <DownArrowIcon className="arrow" />
        </DropdownMenuListItem>
      </div>

      <div
        className={
          isAccordion
            ? `${isOpen ? 'active' : ''} accordion-body`
            : `${isOpen ? 'active' : ''} dropdown-body`
        }
      >
        {menuData.map((item, i) => {
          return (
            <div className="dropdown-item" id={item.name} key={i}>
              {item.label}
            </div>
          );
        })}
      </div>
    </DropdownWrapper>
  );
};
export default Dropdown;
