import React from 'react';
import SelectField from 'react-select';

const Select = ({ onChange, ...props }) => (
  <SelectField
    onChange={({ value }) => onChange && onChange(value)}
    components={{
      IndicatorSeparator: () => null,
    }}
    styles={{
      container: (styles) => ({
        ...styles,
        width: '100%',
        height: '100%',
      }),
      control: (styles) => ({
        ...styles,
        backgroundColor: '#f6f6f6',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0px',
        cursor: 'pointer',
        height: '100%',
      }),
      valueContainer: (styles) => ({
        ...styles,
        padding: '5px 15px',
      }),
      menu: (styles) => ({
        ...styles,
      }),
      option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isSelected ? '#E1EAED' : isFocused ? '#F3F7F9' : null,
        cursor: isFocused ? 'pointer' : 'default',
        // eslint-disable-next-line no-nested-ternary
        color: isSelected ? '#323347' : isFocused ? '#323347' : null,
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? '#F3F7F9' : '#F3F7F9',
        },
      }),
      indicatorsContainer: (styles, { selectProps: { menuIsOpen } }) => ({
        ...styles,
        transition: 'all .2s ease',
        transform: menuIsOpen ? 'rotate(180deg)' : null,
      }),
    }}
    {...props}
  />
);

export default Select;
