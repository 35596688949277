import React from 'react';
import { IconWrapper, SolutionsSection, SolutionsWrapper } from './styled';
import { H2Text, H3Text, P2Text, P3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';

const solutions = [
  {
    icon: <LogoIcon />,
    title: 'Custom electronic health record',
    text: 'Electronic Health Record (EHR) and Electronic Medical Record (EMR) systems are designed for quick and safe medical information exchange between patients, doctors and care providers. These feature-rich types of medical software maintain personal medical records, facilitate efficient clinical workflow and allow better care coordination between caregivers and patients.',
  },
  {
    icon: <LogoIcon />,
    title: 'Patient identification',
    text: 'Electronic Health Record (EHR) and Electronic Medical Record (EMR) systems are designed for quick and safe medical information exchange between patients, doctors and care providers. These feature-rich types of medical software maintain personal medical records, facilitate efficient clinical workflow and allow better care coordination between caregivers and patients.',
  },
  {
    icon: <LogoIcon />,
    title: 'Medical appointment',
    text: 'Electronic Health Record (EHR) and Electronic Medical Record (EMR) systems are designed for quick and safe medical information exchange between patients, doctors and care providers. These feature-rich types of medical software maintain personal medical records, facilitate efficient clinical workflow and allow better care coordination between caregivers and patients.',
  },
];

const Solutions = () => {
  return (
    <SolutionsSection>
      <div className="info">
        <H2Text>Innovative solutions</H2Text>
        <P2Text color={colors2.lightText}>
          Healthcare is exactly that field where the slightest technological advantage has a
          significant impact on people’s lives. When a hospital works close to its capacity,
          technical superiority means saved lives.
        </P2Text>
      </div>

      <SolutionsWrapper>
        {solutions.map((solution) => {
          return (
            <div className="wrapper">
              <IconWrapper>{solution.icon}</IconWrapper>
              <div className="info">
                <H3Text style={{ display: 'block', marginBottom: '20px' }}>{solution.title}</H3Text>
                <P3Text style={{ display: 'block' }} color={colors2.lightText}>
                  {solution.text}
                </P3Text>
              </div>
            </div>
          );
        })}
      </SolutionsWrapper>
    </SolutionsSection>
  );
};

export default Solutions;
