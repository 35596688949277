import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import CustomSlider from './Slider';
import colors2 from '../../utils/theme/theme2/colors2';

const SliderContainer = ({
  children,
  slidesCount,
  slidesToShowDesktop,
  slidesToScrollDesktop,
  slidesToShowTablet,
  slidesToScrollTablet,
  slidesToShowMobile,
  slidesToScrollMobile,
  paging,
  pagingPosition,
  pagingLineWidth,
  prevArrowStyles,
  nextArrowStyles,
  infinite,
  pageLabelColor,
  pagingLineColor,
  autoplay,
  centerMode,
  centerPadding,
  variableWidth,
  adaptiveHeightMobile,
  className,
}) => (
  <Box className={`slider-wrapper ${className || ''}`} width="100%">
    <CustomSlider
      adaptiveHeightMobile={adaptiveHeightMobile}
      slidesCount={slidesCount}
      slidesToShow={slidesToShowDesktop}
      slidesToScroll={slidesToScrollDesktop}
      slidesToShowTablet={slidesToShowTablet}
      slidesToScrollTablet={slidesToScrollTablet}
      slidesToShowMobile={slidesToShowMobile}
      slidesToScrollMobile={slidesToScrollMobile}
      paging={paging}
      infinite={infinite}
      pagingPosition={pagingPosition}
      pagingLineWidth={pagingLineWidth}
      pageLabelColor={pageLabelColor}
      pagingLineColor={pagingLineColor}
      prevArrowStyles={prevArrowStyles}
      nextArrowStyles={nextArrowStyles}
      autoplay={autoplay}
      centerMode={centerMode}
      centerPadding={centerPadding}
      variableWidth={variableWidth}
    >
      {children}
    </CustomSlider>
  </Box>
);

SliderContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  slidesCount: PropTypes.number,
  paging: PropTypes.bool,
  pagingPosition: PropTypes.string,
  pagingLineWidth: PropTypes.number,
  slidesToShowDesktop: PropTypes.number,
  slidesToScrollDesktop: PropTypes.number,
  slidesToShowTablet: PropTypes.number,
  slidesToScrollTablet: PropTypes.number,
  slidesToShowMobile: PropTypes.number,
  slidesToScrollMobile: PropTypes.number,
  prevArrowStyles: PropTypes.string,
  nextArrowStyles: PropTypes.string,
  infinite: PropTypes.bool,
  pageLabelColor: PropTypes.string,
  pagingLineColor: PropTypes.string,
  autoplay: PropTypes.bool,
  centerMode: PropTypes.bool,
  centerPadding: PropTypes.string,
  variableWidth: PropTypes.bool,
  className: PropTypes.string,
};

SliderContainer.defaultProps = {
  slidesCount: null,
  slidesToShowDesktop: 3,
  slidesToScrollDesktop: 3,
  slidesToShowTablet: 2,
  slidesToScrollTablet: 2,
  slidesToShowMobile: 1,
  slidesToScrollMobile: 1,
  paging: false,
  pagingPosition: 'left',
  pagingLineWidth: 130,
  prevArrowStyles: '',
  nextArrowStyles: '',
  infinite: true,
  pageLabelColor: colors2.lightText,
  pagingLineColor: colors2.lightText,
  autoplay: false,
  centerMode: false,
  variableWidth: false,
  centerPadding: '5px',
};

export default SliderContainer;
