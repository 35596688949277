import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import StatisticsBanner from '../homePage/StatisticsBanner';
import { PageContent, PageContainer } from '../homePage/styled';

import MessageCard from '../Card/MessageCard';
import ListWithSmallIcons from '../ListWithSmallIcons';
import Integrations from './Integrations';

import colors2 from '../../utils/theme/theme2/colors2';

import ConsultingIcon from '../../assets/icons/updateDesign/salesforce/consulting.svg';
import ConsultingScrollIcon from '../../assets/icons/updateDesign/salesforce/consulting-hover.svg';
import CustomizationIcon from '../../assets/icons/updateDesign/salesforce/customization.svg';
import CustomizationScrollIcon from '../../assets/icons/updateDesign/salesforce/customization-hover.svg';
import IntegrationIcon from '../../assets/icons/updateDesign/salesforce/integration.svg';
import IntegrationScrollIcon from '../../assets/icons/updateDesign/salesforce/integration-hover.svg';
import DataTransferIcon from '../../assets/icons/updateDesign/salesforce/data-transfer.svg';
import DataTransferScrollIcon from '../../assets/icons/updateDesign/salesforce/data-transfer-hover.svg';
import TrainingIcon from '../../assets/icons/updateDesign/salesforce/training.svg';
import TrainingScrollIcon from '../../assets/icons/updateDesign/salesforce/training-hover.svg';
import LaunchIcon from '../../assets/icons/updateDesign/salesforce/launch.svg';
import LaunchScrollIcon from '../../assets/icons/updateDesign/salesforce/launch-hover.svg';
import SupportIcon from '../../assets/icons/updateDesign/dedicated/support.svg';
import SupportScrollIcon from '../../assets/icons/updateDesign/dedicated/support-hover.svg';

import InnovativeIcon from '../../assets/icons/updateDesign/salesforce/innovative.svg';
import AgileIcon from '../../assets/icons/updateDesign/salesforce/agile.svg';
import PaperworkIcon from '../../assets/icons/updateDesign/salesforce/paperwork.svg';
import CostEffectivenessIcon from '../../assets/icons/updateDesign/salesforce/cost-effectiveness.svg';

import AutomationIcon from '../../assets/icons/updateDesign/salesforce/automation.svg';
import FineTuningIcon from '../../assets/icons/updateDesign/salesforce/fine-tuning.svg';
import StructuredIcon from '../../assets/icons/updateDesign/salesforce/structured.svg';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ProcessComponent from '../ProcessComponent';
import ChooseTechnologies from '../ChooseTechnologies';

import CloudIcon from '../../assets/icons/updateDesign/salesforce/salesforce-cloud.svg';
import IntegrationSalesIcon from '../../assets/icons/updateDesign/salesforce/salesforce-integration.svg';
import ServiceListBlock from '../ServiceList';
import ContactUsForm from '../ContactUsForm';

const list = [
  {
    icon: <AutomationIcon />,
    title: 'Automation of needed processes',
    text: 'Whatever you need to automate, we can handle. From customer service to discount calculation, Salesforce has it all and we know how to work with it. We’ve mastered Salesforce automation so you can enjoy seamless operations and improved productivity and performance.',
  },
  {
    icon: <FineTuningIcon />,
    title: 'Salesforce fine-tuning so your customers love it',
    text: 'Any business decision that you make is about your customers so we can deliver superior user experience with the help of the Salesforce Cloud of choice. But we won’t stop here: we will also make Salesforce easy for all your employees who will be using it.',
  },
  {
    icon: <StructuredIcon />,
    title: 'Personalized and structured approach towards Salesforce delivery',
    text: 'Every Salesforce implementation is unique, yet, it still requires a thought-out structure. We use a structured approach toward Salesforce delivery and it serves as a base for every new implementation. In this way, we can launch core functionality much faster and add any necessary features and customization later on.',
  },
];

const expertise = [
  {
    icon: <InnovativeIcon />,
    title: 'Innovative',
    text: 'We are known for innovative approach that is backed up by our rich industry and cloud expertise coupled with best-in-class ISV app partners. As a result, you get best-in-class solutions.',
  },
  {
    icon: <AgileIcon />,
    title: 'Agile methodology',
    text: 'We prefer flexible Agile methodology to effectively deliver our software solutions and implement requested changes on the go. In this way, you don’t have to worry about the quality and performance of the final product.',
  },
  {
    icon: <PaperworkIcon />,
    title: 'No-brainer paperwork',
    text: 'We don’t want to overload you with excessive documentation when you can focus on more critical things instead. Hence, all paperwork is extremely transparent, concise and clear.',
  },
  {
    icon: <CostEffectivenessIcon />,
    title: 'Cost-effective',
    text: 'We don’t do any rip-offs: instead, we provide engagement and quality that is right for you and is delivered in a short period of time.',
  },
];

const work = [
  {
    number: 'step 1',
    icon: <ConsultingIcon />,
    onScrollIcon: <ConsultingScrollIcon />,
    title: 'Consulting',
    text: 'Salesforce offers many solutions and each of them can be customized and tailored. Hence, step number one in our Salesforce implementation strategy is to analyze your current business goals and understand what exactly you wish to achieve. In this way, we will be able to select the most suitable Salesforce solution so its features are 100% aligned with your goals.',
  },
  {
    number: 'step 2',
    icon: <CustomizationIcon />,
    onScrollIcon: <CustomizationScrollIcon />,
    title: 'Customization',
    text: 'Remember we said that every Salesforce solution is unique? This is where customization comes into play. Depending on your needs, we can either modify default Salesforce functionality or dig deeper into the Apex code for larger changes.',
  },
  {
    number: 'step 3',
    icon: <IntegrationIcon />,
    onScrollIcon: <IntegrationScrollIcon />,
    title: 'Integration',
    text: 'If you are using any third-party systems, we can seamlessly integrate them with the selected Salesforce solution. HubSpot, ERP systems (i.e. NetSuite or Microsoft Dynamics), CRM systems (i.e. Zoho, Infusionsoft, Capterra) - you name it!',
  },
  {
    number: 'step 4',
    icon: <DataTransferIcon />,
    onScrollIcon: <DataTransferScrollIcon />,
    title: 'Data Transfer',
    text: 'Data is the lifeblood of your company so we’ll make sure to take care of it as neatly as possible. We will migrate your data from the old CRM to a new Salesforce solution without any data losses and without system downtimes. Note that the transferred data will be as well-structured as before and you will be ready to use it as soon as it’s transferred.',
  },
  {
    number: 'step 5',
    icon: <TrainingIcon />,
    onScrollIcon: <TrainingScrollIcon />,
    title: 'Training',
    text: 'We offer comprehensive Salesforce training for your employees so they easily adapt to the new functionality and can start working on it as soon as your Salesforce solution is launched. We are also here to help in case there will be any questions after the training is over. In this way, you can rest assured of employee productivity and delivered results.',
  },
  {
    number: 'step 6',
    icon: <LaunchIcon />,
    onScrollIcon: <LaunchScrollIcon />,
    title: 'Launch',
    text: 'When everything is set up and configured, we perform rigorous testing to ensure the solution works as intended. We also hold a User Acceptance Testing stage to double-check if everything is just right. After the testing is done and no bugs/flaws are detected, we launch your Salesforce solution into the production and it’s ready to be used!',
  },
  {
    number: 'step 7',
    icon: <SupportIcon />,
    onScrollIcon: <SupportScrollIcon />,
    title: 'After-Launch Support',
    text: 'You didn’t that would be all, did you? Once your Salesforce solution is launched, we can provide on-demand support and maintenance just in case you need a bit of help.',
  },
];

const techData = [
  ['Sales Cloud', 'Service Cloud', <IntegrationSalesIcon />],
  ['Community Cloud', 'Marketing Cloud', 'Non profit Cloud'],
  ['Financial services Cloud', <CloudIcon />, 'Salesforce customization'],
  ['Salesforce chatter', 'Salesforce cpq'],
];

const SalesforcePage = () => {
  const { cloud, message } = useStaticQuery(query);

  return (
    <PageContainer>
      <PageContent>
        <div className="service-wrapper">
          <TopPageSectionWrapper
            title="Salesforce development services"
            description="Unlock all benefits that Salesforce offers with our Salesforce Consulting and Implementation
        services."
          />
          <ServiceListBlock
            title="Our team can help you with"
            iconColor={colors2.violet}
            image={cloud}
            list={list}
            bgColor={colors2.lightViolet}
          />
        </div>

        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <ChooseTechnologies title="Salesforce Clouds that we implement" techData={techData} />
        <Integrations />
        <ProcessComponent title="How DashBouquet implements Salesforce: the process" data={work} />
        <MessageCard
          image={message}
          title="Ready to start your custom app development?"
          subtitile="Contact us today to schedule a consultation and get a project estimate."
          button="Contact us"
        />
        <ListWithSmallIcons title="Why choose DashBouquet?" data={expertise} />
        <StatisticsBanner
          title="Our experts"
          subtitle={
            'Our team of 60+ developers has been working with CRM platform for over 7 years and throughout these years, we’ve always been engaged in Salesforce-related projects. \n\nSecurity and scalability are our top priorities and something we’d never compromise on.'
          }
        />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    cloud: file(relativePath: { eq: "images/updateDesign/cloud.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default SalesforcePage;
