import styled from 'styled-components';
import { Tab, TabList, TabPanel } from 'react-tabs';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H3Text, H2Text, H4Text, P2Text } from '../Typography/styled';
import 'react-tabs/style/react-tabs.css';

export const ExpertiseContent = styled.div`
  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 80px;

    &__card {
      max-width: 520px;
      width: 50%;
      margin-bottom: 90px;
    }

    &__img {
      width: 66px;
    }

    &__title {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    &__desc {
      margin-bottom: 40px;
    }
  }
  @media ${breakpoints.md} {
    .content {
      display: none;
    }
    ${H2Text} {
      font-weight: 400;
      font-size: 14px;
      line-height: 165%;
      color: ${colors2.lightText};
      margin-bottom: 40px;
      display: inline-block;
    }
  }
  @media ${breakpoints.mds} {
    ${H2Text} {
      margin-bottom: 30px;
    }
  }
  @media ${breakpoints.mds} {
    ${H2Text} {
      margin-bottom: 20px;
      margin-left: 16px;
    }
  }
`;
export const TabsWrapper = styled.div`
  display: none;
  @media ${breakpoints.md} {
    display: block;
    width: min-content;
    ${H3Text} {
      font-weight: 500;
      font-size: 40px;
      line-height: 130%;
    }
    ${H4Text} {
      font-weight: 500;
      font-size: 16px;
      line-height: 165%;
      &.content__desc {
        font-weight: 400;
      }
    }
  }
  @media ${breakpoints.mds} {
    padding: 0 16px;
    max-width: 100vw;
    overflow-x: hidden;
    ${H3Text} {
      font-size: 28px;
    }
    ${H4Text} {
      font-size: 14px;
    }
  }
`;
export const ExpertTabList = styled(TabList)`
  & li {
    padding-bottom: 20px;
    white-space: nowrap;
  }
  & .react-tabs__tab--selected {
    ${H4Text} {
      color: ${colors2.black};
    }
    border-bottom: 2px solid #262626;
  }
  @media ${breakpoints.md} {
    list-style: none;
    display: flex;
    gap: 40px;
    border-bottom: 1px solid rgba(38, 38, 38, 0.1);
    ${H4Text} {
      color: ${colors2.lightText};
    }
  }
  @media ${breakpoints.mds} {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const ExpertTab = styled(Tab)`
  @media ${breakpoints.md} {
    padding: 0;
  }
`;
export const ExpertTabPanelWrapper = styled.div`
  @media ${breakpoints.md} {
    padding-top: 40px;
  }
  @media ${breakpoints.sm} {
    padding-top: 20px;
  }
`;
export const ExpertTabPanel = styled(TabPanel)`
  @media ${breakpoints.md} {
    .content__btn {
      margin-top: 40px;
    }
  }
  @media ${breakpoints.sm} {
    .content__btn {
      margin-top: 20px;
    }
  }
`;
export const ExpertTitleContent = styled.div`
  @media ${breakpoints.md} {
    display: flex;
    gap: 20px;
    align-items: center;
  }
`;
// //--------CoreExpertise---------///////
export const CoreExpertiseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  @media ${breakpoints.mds} {
    gap: 50px;
  }
  @media ${breakpoints.sm} {
    gap: 40px;
  }
`;
export const CoreExpertiseCards = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 80px;
  @media ${breakpoints.mds} {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  @media ${breakpoints.sm} {
    gap: 40px;
  }
`;
export const CoreExpertiseCard = styled.div`
  display: flex;
  gap: 30px;
  @media ${breakpoints.sm} {
    flex-direction: column;
    gap: 20px;
  }
`;
export const IconBox = styled.div`
  border-radius: 50%;
  background: ${colors2.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: fit-content;
  height: fit-content;
`;
export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  ${P2Text} {
    margin: 20px 0 40px;
  }
  @media ${breakpoints.sm} {
    ${P2Text} {
      margin: 10px 0 30px;
    }
  }
`;
