import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { P2Text } from '../Typography/styled';

export const StyledScheduleCard = styled.div`
  display: inline-flex;
  padding: 40px 80px 40px 40px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  background-color: ${({ color }) => color || colors2.green};
  border-radius: 40px;
  .message-card-img {
    min-width: 520px;
    width: 100%;
  }

  @media ${breakpoints.md} {
    .message-card-img {
      min-width: 120px;
    }
  }
  @media ${breakpoints.mds} {
    padding: 50px;
    align-items: flex-start;
  }
  @media ${breakpoints.sm} {
    padding: 30px;
    flex-direction: column;
    gap: 30px;
  }
`;

export const ScheduleText = styled.div`
  display: flex;
  flex-direction: column;
  ${P2Text} {
    margin: 20px 0 50px;
  }
  button {
    padding: 15px 30px;
    width: auto;
  }

  @media ${breakpoints.sm} {
    min-width: 283px;
    ${P2Text} {
      margin: 20px 0 30px;
    }
  }
`;
