import React from 'react';
import PropTypes from 'prop-types';

import { ArrowBtnContainer } from './styled';
import { H4Text } from '../Typography/styled';

import Arrow from '../../assets/images/updateDesign/icons/arrow-right.svg';

const ArrowBtn = ({ text, color }) => {
  return (
    <ArrowBtnContainer color={color}>
      <button className="btn" type="button">
        <H4Text color={color && color} style={{ marginRight: '15px' }}>
          {text}
        </H4Text>
        <Arrow />
      </button>
    </ArrowBtnContainer>
  );
};

ArrowBtn.propTypes = {
  text: PropTypes.string,
};

ArrowBtn.defaultProps = {
  text: 'Learn more',
};

export default ArrowBtn;
