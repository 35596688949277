import styled from 'styled-components';
import { web3Colors } from '../Web3Page/styled';

export const SwitchWrapper = styled.div`
  overflow: hidden;
  border: 1px solid white;
  border-radius: 10px;
  width: 140px;
  height: 30px;
  margin-top: 20px;
`;

export const SwitchForm = styled.form`
  overflow: hidden;
`;

export const SwitchLabel = styled.label`
  display: inline-block;
  width: 50%;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  background-color: transperent;
  text-align: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;

export const SwitchInput = styled.input`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);

  &:checked + ${SwitchLabel} {
    background-color: ${web3Colors.purple};
    color: #fff;
    box-shadow: none;
  }
`;
