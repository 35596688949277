import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { H4Text } from '../Typography/styled';

export const FaqContentWrapper = styled(Flex)`
  flex-direction: column;
  gap: 80px;
`;
export const HtmlWrapper = styled(Box)`
  padding-top: 12px;
  font-family: 'Golos Text', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 165%;
  color: rgba(38, 38, 38, 0.7);

  ol {
    padding-left: 15px;
    margin-left: 30px;
  }
  li {
    padding-left: 7px;
    font-weight: bold;
  }
  li > ol {
    list-style-type: lower-alpha;
  }
  li > p {
    margin-top: -20px;
  }
  li:before {
    font-weight: bold;
  }
  li em {
    font-weight: 400;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const AnswerWrapper = styled(Box)`
  overflow: hidden;

  transition: max-height 0.3s, padding-bottom 0.3s;
  transition-delay: 0s;

  max-height: ${(props) => (props.open ? `${props.answerRef + 40}px` : '0')};
  padding-bottom: ${(props) => (props.open ? '40px' : '0')};
  @media (max-width: 768px) {
    max-height: ${(props) => (props.open ? `${props.answerRef + 20}px` : '0')};
    padding-bottom: ${(props) => (props.open ? '20px' : '0')};
  }
`;
export const QuestionContainer = styled.div`
  border: 1.5px solid rgba(38, 38, 38, 0.2);
  border-radius: 24px;
  padding: 24px 30px;
  cursor: pointer;

  ${H4Text} {
    font-family: 'Golos Text', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 165%;
  }
`;
