import React from 'react';

import TestingIcon from '../../assets/icons/updateDesign/dedicated/testing.svg';
import TestingScrollIcon from '../../assets/icons/updateDesign/dedicated/testing-hover.svg';
import UxScrollIcon from '../../assets/icons/updateDesign/development/ux-hover.svg';
import UxIcon from '../../assets/icons/updateDesign/development/ux.svg';
import DevelopmentIcon from '../../assets/icons/updateDesign/development/development.svg';
import DevelopmentScrollIcon from '../../assets/icons/updateDesign/development/development-hover.svg';
import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import SupportIcon from '../../assets/icons/updateDesign/dedicated/support.svg';
import SupportScrollIcon from '../../assets/icons/updateDesign/dedicated/support-hover.svg';
import ProcessComponent from '../ProcessComponent';

const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'Discovery',
    text: 'We start by analyzing project requirements to obtain a clear vision of your goals. As a result, you’ll receive a technical specification, a documented work scope, and cost estimates.',
  },
  {
    number: 'step 2',
    icon: <UxIcon />,
    onScrollIcon: <UxScrollIcon />,
    title: 'UI/UX design',
    text: 'In 2 weeks, our team develops a dynamic prototype to visualize the mobile app interface. After discussing the prototype with you, we create an intuitive design enjoyed by the audience.',
  },
  {
    number: 'step 3',
    icon: <DevelopmentIcon />,
    onScrollIcon: <DevelopmentScrollIcon />,
    title: 'Mobile app development',
    text: 'Following Agile best practices, we implement new functionality every 1–3 weeks. By setting up a CI/CD pipeline, we automate development processes, reducing the feature release cycle by 3x–4x.',
  },
  {
    number: 'step 4',
    icon: <TestingIcon />,
    onScrollIcon: <TestingScrollIcon />,
    title: 'Quality assurance',
    text: 'Our QA experts test a mobile app to make sure it is error-free, works as intended, and meets all the requirements. We also prevent potential bottlenecks such as security and performance issues.',
  },
  {
    number: 'step 5',
    icon: <SupportIcon />,
    onScrollIcon: <SupportScrollIcon />,
    title: 'Launch, support, and maintenance',
    text: 'We launch your mobile app on the market. On top of that, we offer post-release support and maintenance to ensure smooth system operation. Our engineers introduce new features and fix arising issues on the fly.',
  },
];

const ProcessSection = () => {
  return <ProcessComponent title="How we work" data={work} />;
};

export default ProcessSection;
