import React from 'react';

import { WhoWeAreWrapper, WhoWeAreCard, WhoWeAreContainer, IconContainer } from './styled';
// import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import LogoIcon from '../../assets/icons/updateDesign/logo1.svg';

import { H2Text, H3Text, P2Text } from '../Typography/styled';

const services = [
  {
    icon: <LogoIcon />,
    title: 'Specialization',
    text: 'Web & mobile apps for AI, Healthcare, eCommerce, HR, and Fintech industries utilizing cutting-edge technologies. Are you next?',
  },
  {
    icon: <LogoIcon />,
    title: 'Founded',
    text: 'September 2014',
  },
  {
    icon: <LogoIcon />,
    title: 'Focus',
    text: 'Custom robust products & long-term partnership',
  },
  {
    icon: <LogoIcon />,
    title: 'Full cycle of development',
    text: 'From prototype and designs to final testing and market launch',
  },
  {
    icon: <LogoIcon />,
    title: 'Over 60',
    text: 'Successful projects',
  },
];

const WhoWeAre = () => {
  return (
    <WhoWeAreWrapper>
      <H2Text>Who We Are?</H2Text>
      <WhoWeAreContainer>
        {services.map((el, index) => {
          return (
            <WhoWeAreCard key={index} index={index}>
              <IconContainer index={index}>
                <LogoIcon />
              </IconContainer>

              <div className="text-content">
                <H3Text>{el.title}</H3Text>
                <P2Text>{el.text}</P2Text>
              </div>
            </WhoWeAreCard>
          );
        })}
      </WhoWeAreContainer>
    </WhoWeAreWrapper>
  );
};

export default WhoWeAre;
