import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import InnerSEO from './SEO';

const SEO = ({ ...props }) => (
  <StaticQuery
    query={graphql`
      {
        mainLogo: file(relativePath: { eq: "images/LogoForLink.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
            original {
              src
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <InnerSEO data={data} {...props} />}
  />
);

export default SEO;
