import * as React from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = styled.button`
  width: auto;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5em;
  color: #323347;
  background-color: transparent;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  outline: none;

  :disabled {
    opacity: 0.3;
  }

  :hover {
    opacity: 1;

    :disabled {
      opacity: 0.3;
    }
  }
`;

const ButtonWithIcon = ({ children, icon: Icon, iconPosition, isDisabled, onClick }) => (
  <Button type="button" disabled={isDisabled} onClick={(e) => onClick(e)}>
    <Flex alignItems="center" flexDirection={iconPosition === 'left' ? 'row' : 'row-reverse'}>
      <Icon />
      <Box
        css={{
          paddingLeft: iconPosition === 'left' ? 10 : 0,
          paddingRight: iconPosition === 'right' ? 10 : 0,
          paddingBottom: 1,
        }}
      >
        {children}
      </Box>
    </Flex>
  </Button>
);

ButtonWithIcon.propTypes = {
  icon: PropTypes.elementType.isRequired,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

ButtonWithIcon.defaultProps = {
  iconPosition: 'left',
  isDisabled: false,
  children: null,
  onClick: () => {},
};

export default ButtonWithIcon;
