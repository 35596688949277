import React from 'react';
import { Flex } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ReviewsContent, ReviewCard } from './styled';
import { H4Text, P3Text, H3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import Slider from '../Slider';
import Star from '../../assets/images/updateDesign/icons/star.svg';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

const Reviews = () => {
  const reviewsList = [
    {
      origin: 'Clutch',
      score: 5,
      review: `The client has grown its DAU by 30% and increased revenue by 2x since partnering with Dashbouquet Development. Their communication, development skills, and problem-solving skills are impressive. Absolutely loved working with Dashbouquet and looking forward to continuing working with them.`,
      avatar: null,
      name: 'Ryan Perry',
      position: 'CEO',
      company: 'Pyroscope',
      country: 'US, California',
    },
    {
      origin: 'Clutch',
      score: 5,
      review: `The engineers we work with are as skilled as the top developers I've worked with in Silicon Valley.`,
      avatar: null,
      name: 'Paul Benigeri',
      position: 'Head of Ecommerce',
      company: 'HVMN',
      country: 'US, California',
    },
    {
      origin: 'Clutch',
      score: 5,
      review: `Internal stakeholders are pleased with Dashbouquet’s deliverables, noting their accuracy and efficiency in development. The team managed the project exceptionally well, meeting deadlines and communicating well.`,
      avatar: null,
      name: 'Robert Fair',
      position: 'CEO',
      company: 'Marble App',
      country: 'US, California',
    },
    {
      origin: 'Clutch',
      score: 5,
      review: `Delivering all work on time, Dashbouqet Development has built a positive reputation with the client. Using Slack and Google-based communication tools, they participate in daily standups. Future customers can expect a dependable technical team.`,
      avatar: null,
      name: 'Gavin Anderson',
      position: 'Head of User & Brand Experience',
      company: 'Just Carbon',
      country: 'UK',
    },
    {
      origin: 'Clutch',
      score: 5,
      review: `We were so satisfied with the work of Dashbouquet that I recommended them to a lot of people in my network.`,
      avatar: null,
      name: 'Anna-Lena Gerber',
      position: 'CEO',
      company: 'SitEinander',
      country: 'Germany',
    },
    {
      origin: 'Clutch',
      score: 5,
      review: `Dashbouquet Development managed the engagement well. They were communicative and willing to go the extra mile for the projects' success.`,
      avatar: null,
      name: 'Paolo Memoli',
      position: 'CFO & CTO',
      company: '100Shapes',
      country: 'UK',
    },
    {
      origin: 'Clutch',
      score: 5,
      review: `Dashbouquet Development's transparent approach to project management has made for a seamless partnership. Everyone, from their management team to their developers, is willing and ready to answer our questions.`,
      avatar: null,
      name: 'Mord Maman',
      position: 'CTO',
      company: 'Urbix',
      country: 'UK',
    },
  ];
  return (
    <ReviewsContent>
      <Flex flexDirection="column" justifyContent="center">
        <P3Text color="transparent">.</P3Text>
        <Slider
          slidesCount={reviewsList.length}
          slidesToShowDesktop={1}
          slidesToScrollDesktop={1}
          slidesToShowTablet={1}
          adaptiveHeightMobile={true}
          prevArrowStyles={`
          top: 50% !important;
          left: 0px !important;
            :hover {
              opacity: 0.7;x
            }
            @media ${breakpoints.md} {
              top: unset !important;
              bottom: -23px;
              right: 56px !important;
              left: unset !important;
            }
        `}
          nextArrowStyles={`
            top: 50% !important;
            right: 0px !important;
              :hover {
                opacity: 0.7;x
              }
              @media ${breakpoints.md} {
                top: unset !important;
                bottom: -23px;
                right: 0px !important;
              }
    `}
        >
          {reviewsList.map((el, indx) => (
            <ReviewCard key={`review_${indx}`}>
              <Flex className="clutch-star-block">
                <H4Text style={{ marginRight: '10px' }}>{el.origin}</H4Text>
                {[...Array(el.score)].map((el, i) => (
                  <Star key={`${i}-star`} />
                ))}
                <H4Text style={{ marginLeft: '10px' }}>{el.score}</H4Text>
              </Flex>
              <H3Text>{el.review}</H3Text>
              <Flex className="author-info-block">
                {el.avatar && (
                  <GatsbyImage
                    image={el.avatar.childImageSharp.gatsbyImageData}
                    alt="talking-banner"
                    style={{ width: '50px', marginRight: '20px' }}
                  />
                )}

                <H4Text color={colors2.lightText}>
                  {el.name}, {el.position}, {el.company}, {el.country}
                </H4Text>
              </Flex>
            </ReviewCard>
          ))}
        </Slider>
      </Flex>
    </ReviewsContent>
  );
};
export default Reviews;
