import { createGlobalStyle } from 'styled-components';
import fonts from './fonts';

import colors2 from './theme2/colors2';

export const ModalGlobalStyles = createGlobalStyle`
  ${({ modalIsOpen, fixScrollPosition }) =>
    fixScrollPosition
      ? `
      body {
       ${modalIsOpen ? 'overflow-y: hidden;' : null}
      }
    `
      : null}
  #___gatsby {
    ${({ modalIsOpen, fixScrollPosition }) =>
      !fixScrollPosition
        ? `
        position: ${modalIsOpen ? 'fixed' : 'unset'}
      `
        : null}
    width: ${({ modalIsOpen }) => (modalIsOpen ? '100%' : 'unset')};
    ${({ modalIsOpen, fixScrollPosition }) =>
      modalIsOpen
        ? `
      @media (max-width: 1023px) and (orientation: landscape) {
      position: unset; 
      max-height: 700px;
      ${!fixScrollPosition ? 'overflow: hidden;' : null}
    }
      `
        : null};   
  }
`;

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }

  body {
    color: ${colors2.white};
    background-color: ${colors2.white};
    font-family: ${fonts.golos};
    font-size: 16px;
    line-height: 1.3;
  }


button, a {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  &:focus {
    outline: none !important;
  }
}

  #___gatsby {
    white-space: pre-wrap;
  }

  .clutch-widget iframe {
    max-width: 915px;
    margin: 0 auto;
  }
`;
