/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';
import Slider from 'react-slick';
import NavigateBeforeIcon from '../../assets/images/updateDesign/icons/arrow-before.svg';
import NavigateNextIcon from '../../assets/images/updateDesign/icons/arrow-next.svg';
import SliderPaging from './SliderPaging';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NavigateNext = styled(NavigateNextIcon)`
  ${(props) => props.css}
`;

const NavigateBefore = styled(NavigateBeforeIcon)`
  ${(props) => props.css}
`;

const touchStart = (e) => {
  e.target.firstClientX = e.touches[0].clientX;
  e.target.firstClientY = e.touches[0].clientY;
};

const preventTouch = (e) => {
  const minValue = 15; // threshold
  e.target.clientX = e.touches[0].clientX - e.target.firstClientX;
  e.target.clientY = e.touches[0].clientY - e.target.firstClientY;

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(e.target.clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;
    return false;
  }
  return true;
};

const CustomSlider = ({
  adaptiveHeightMobile,
  children,
  slidesCount,
  slidesToShow,
  slidesToScroll,
  slidesToShowTablet,
  slidesToScrollTablet,
  slidesToShowMobile,
  slidesToScrollMobile,
  paging,
  pagingPosition,
  pagingLineWidth,
  prevArrowStyles,
  nextArrowStyles,
  infinite,
  pageLabelColor,
  pagingLineColor,
  pagingAnimatedLineColor,
  autoplay,
  centerMode,
  centerPadding,
  variableWidth,
}) => {
  const [activeSliderPage, setActiveSliderPage] = useState(1);
  const [pagingAnimatedLineWidth, setPagingAnimatedLineWidth] = useState(
    pagingLineWidth / Math.ceil(slidesCount / slidesToShow)
  );
  const [slideViewChanged, setSlideViewChanged] = useState(new Map());
  const [screen, SetScreen] = useState(null);
  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, { passive: false });
    };
  }, []);
  const sliderSettings = {
    dots: paging,
    autoplay,
    slidesToShow,
    slidesToScroll,
    touchThreshold: 30,
    useTransform: false,
    lazyLoad: true,
    centerMode,
    centerPadding,
    variableWidth,
    arrows: true,
    prevArrow: (
      <NavigateBefore
        css={`
          width: 46px !important;
          height: 46px !important;
          ${prevArrowStyles}
        `}
      />
    ),
    nextArrow: (
      <NavigateNext
        css={`
          width: 46px !important;
          height: 46px !important;
          ${nextArrowStyles}
        `}
      />
    ),
    afterChange: (index) => {
      if (slideViewChanged.size) {
        setSlideViewChanged(new Map());
      }
      if (paging) {
        const sliderCurrentPage = Math.ceil(
          (index + 1) / (infinite ? slidesToShow : slidesToScroll)
        );
        setPagingAnimatedLineWidth(
          (pagingLineWidth / Math.ceil((slidesCount - slidesToShow) / slidesToScroll + 1)) *
            sliderCurrentPage
        );
        setActiveSliderPage(sliderCurrentPage);
      }
    },
    appendDots: () => (
      <SliderPaging
        pageLabelColor={pageLabelColor}
        pagingLineColor={pagingLineColor}
        pagingAnimatedLineColor={pagingAnimatedLineColor}
        pagingPosition={pagingPosition}
        pagingLineWidth={pagingLineWidth}
        pagingAnimatedLineWidth={pagingAnimatedLineWidth}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        slidesCount={slidesCount}
        activeSliderPage={activeSliderPage}
      />
    ),
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: slidesToShowTablet,
          slidesToScroll: slidesToScrollTablet,
          arrows: true,
          onInit: () => {
            SetScreen('Tablet');
          },
          afterChange: (index) => {
            if (slideViewChanged.size) {
              setSlideViewChanged(new Map());
            }
            if (paging) {
              const sliderCurrentPage = Math.ceil(
                (index + 1) / (infinite ? slidesToShowTablet : slidesToScrollTablet)
              );
              setPagingAnimatedLineWidth(
                (pagingLineWidth /
                  Math.ceil((slidesCount - slidesToShowTablet) / slidesToScrollTablet + 1)) *
                  sliderCurrentPage
              );
              setActiveSliderPage(sliderCurrentPage);
            }
          },
          appendDots: () => (
            <SliderPaging
              pageLabelColor={pageLabelColor}
              pagingLineColor={pagingLineColor}
              pagingPosition={pagingPosition}
              pagingLineWidth={pagingLineWidth}
              pagingAnimatedLineWidth={pagingAnimatedLineWidth}
              slidesToShow={slidesToShowTablet}
              slidesToScroll={slidesToScrollTablet}
              slidesCount={slidesCount}
              activeSliderPage={activeSliderPage}
            />
          ),
        },
      },
      {
        breakpoint: 767,
        settings: {
          adaptiveHeight: adaptiveHeightMobile,
          slidesToShow: slidesToShowMobile,
          slidesToScroll: slidesToScrollMobile,
          arrows: true,
          onInit: () => {
            SetScreen('mobile');
          },
          afterChange: (index) => {
            if (slideViewChanged.size) {
              setSlideViewChanged(new Map());
            }
            if (paging) {
              const sliderCurrentPage = Math.ceil(
                (index + 1) / (infinite ? slidesToShowMobile : slidesToScrollMobile)
              );
              setPagingAnimatedLineWidth(
                (pagingLineWidth /
                  Math.ceil((slidesCount - slidesToShowMobile) / slidesToScrollMobile + 1)) *
                  sliderCurrentPage
              );
              setActiveSliderPage(sliderCurrentPage);
            }
          },
          appendDots: () => (
            <SliderPaging
              pageLabelColor={pageLabelColor}
              pagingLineColor={pagingLineColor}
              pagingPosition={pagingPosition}
              pagingLineWidth={pagingLineWidth}
              pagingAnimatedLineWidth={pagingAnimatedLineWidth}
              slidesToShow={slidesToShowMobile}
              slidesToScroll={slidesToScrollMobile}
              slidesCount={slidesCount}
              activeSliderPage={activeSliderPage}
            />
          ),
        },
      },
    ],
    onInit: () => {
      SetScreen('desktop');
    },
  };

  return (
    <Flex
      flexDirection="column"
      css={`
        .slick-disabled {
          opacity: 0.3;
        }
      `}
    >
      <Slider {...sliderSettings} infinite={infinite} css="max-width: 100%;">
        {typeof children === 'function'
          ? children({ slideViewChanged, setSlideViewChanged, screen })
          : children}
      </Slider>
    </Flex>
  );
};

CustomSlider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  slidesCount: PropTypes.number,
  slidesToShow: PropTypes.number.isRequired,
  slidesToScroll: PropTypes.number.isRequired,
  slidesToShowTablet: PropTypes.number.isRequired,
  slidesToScrollTablet: PropTypes.number.isRequired,
  slidesToShowMobile: PropTypes.number.isRequired,
  slidesToScrollMobile: PropTypes.number.isRequired,
  paging: PropTypes.bool,
  pagingPosition: PropTypes.string.isRequired,
  pagingLineWidth: PropTypes.number.isRequired,
  prevArrowStyles: PropTypes.string,
  nextArrowStyles: PropTypes.string,
  infinite: PropTypes.bool,
  pageLabelColor: PropTypes.string,
  pagingLineColor: PropTypes.string,
  autoplay: PropTypes.bool,
  centerMode: PropTypes.bool,
  centerPadding: PropTypes.string,
};

CustomSlider.defaultProps = {
  slidesCount: null,
  paging: false,
  prevArrowStyles: '',
  nextArrowStyles: '',
  centerPadding: '5px',
  infinite: true,
  pageLabelColor: null,
  pagingLineColor: null,
  autoplay: false,
};

export default CustomSlider;
