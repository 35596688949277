import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import { H5Text, P3Text } from '../Typography/styled';
import { HeaderContainer, HeaderMenuList, HeaderMenuListItem } from './styled';

import Logo from '../../assets/images/updateDesign/icons/logo.svg';
import Dropdown from '../Dropdown/Dropdown';
import Sidebar from '../Sidebar/Sidebar';
import { DefaultButton } from '../Buttons/styled';

const Header = () => {
  const { downArrowIcon, mainLogo, ...data } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menuLinks {
            name
            link
            event
            subLinks {
              name
              link
              event
            }
          }
        }
      }
      mainLogo: file(relativePath: { eq: "images/updateDesign/logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);
  const menuList = data.site.siteMetadata.menuLinks;

  return (
    <HeaderContainer className="header-container">
      <Link to="/">
        <Logo style={{ height: '34px' }} />
      </Link>

      <HeaderMenuList>
        {menuList.map((item) => {
          const menuSublinks = item.subLinks
            ? item.subLinks.map((el) => {
                return {
                  label: (
                    <Link to={el.link}>
                      <P3Text>{el.name}</P3Text>
                    </Link>
                  ),
                  key: el.name,
                };
              })
            : null;

          return item.subLinks ? (
            <Dropdown label={item.name} menuData={menuSublinks} key={item.name} />
          ) : (
            <HeaderMenuListItem key={item.name}>
              <Link to={item.link} style={{ textDecoration: 'none' }}>
                <H5Text>{item.name}</H5Text>
              </Link>
            </HeaderMenuListItem>
          );
        })}

        <li>
          <Link to="/contact-us" style={{ textDecoration: 'none' }}>
            <DefaultButton width="144px" height="48px">
              Contact us
            </DefaultButton>
          </Link>
        </li>
      </HeaderMenuList>
      <Sidebar
        menuList={menuList}
        logo={
          <Link to="/">
            <Logo style={{ height: '34px' }} />
          </Link>
        }
      />
    </HeaderContainer>
  );
};

export default Header;
