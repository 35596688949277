import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import fonts from '../../utils/theme/fonts';
import { web3Colors } from './styled';
import { CONTENT_MAX_WIDTH_PX, MOBILE_VIEW_BREAKPOINT } from './constants';
import { PurpleButton } from './components';

const OnboardContent = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr ${CONTENT_MAX_WIDTH_PX / 2}px ${CONTENT_MAX_WIDTH_PX / 2}px 1fr;

  @media (max-width: ${CONTENT_MAX_WIDTH_PX}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const TextBlock = styled.div`
  grid-column: 2 / 3;
  padding: 0 55px;
  margin-bottom: 125px;
  background: transparent;

  @media (max-width: ${CONTENT_MAX_WIDTH_PX}px) {
    grid-column: 1 / 2;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
`;

const SphereImageContainer = styled.div`
  grid-column: 3 / 5;
  display: flex;
  justify-content: end;
  align-items: end;

  @media (max-width: ${CONTENT_MAX_WIDTH_PX}px) {
    grid-column: 2 / 3;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    grid-column: 1 / 2;
  }
`;

const TABLET_VIEW_BREAKPOINT = '960px';
const FirstTitle = styled.h3`
  font-family: ${fonts.euclidCircularA};
  font-weight: 400;
  font-size: 58px;
  line-height: 120%;
  color: ${web3Colors.grey};
  @media (max-width: ${TABLET_VIEW_BREAKPOINT}) {
    font-size: 40px;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 26px;
    margin-bottom: 10px;
  }
`;

const SecondTitle = styled.h3`
  font-family: ${fonts.euclidCircularA};
  font-weight: 700;
  font-size: 73px;
  line-height: 120%;
  color: white;
  margin-bottom: 30px;
  @media (max-width: ${TABLET_VIEW_BREAKPOINT}) {
    font-size: 55px;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 33px;
    margin-bottom: 20px;
  }
`;

const Desctription = styled.h5`
  font-family: ${fonts.poppins};
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: ${web3Colors.grey};
  margin-bottom: 40px;
  @media (max-width: ${TABLET_VIEW_BREAKPOINT}) {
    font-size: 18px;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

const SeePricingButton = styled(PurpleButton)`
  font-size: 20px;
  line-height: 120%;
  padding: 20px 50px;

  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 16px;
    margin-bottom: 14px;
  }
`;

const Onboard = () => {
  const scrollToDevPicker = () => {
    document.getElementById('see-pricing-plan-scroll-target').scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <OnboardContent>
      <TextBlock>
        <FirstTitle>Onboard Top-tier</FirstTitle>
        <SecondTitle>Devs to your Web3 team today</SecondTitle>
        <Desctription>
          Start small, grow big. Basic contract as short as 2 weeks. Cancel anytime.
        </Desctription>
        <SeePricingButton text="See Pricing Plan" onClick={scrollToDevPicker} />
      </TextBlock>
      <SphereImageContainer>
        <StaticImage src="../../assets/images/web3/sphere.png" alt="sphere" quality={100} />
      </SphereImageContainer>
    </OnboardContent>
  );
};

export default Onboard;
