import React from 'react';

import HealthcareIcon from '../../assets/icons/updateDesign/development/healthcare.svg';
import EcommerceIcon from '../../assets/icons/updateDesign/development/ecommerce.svg';
import HRIcon from '../../assets/icons/updateDesign/development/hr.svg';
import FintechIcon from '../../assets/icons/updateDesign/development/fintech.svg';
import CoreExpertise from '../Expertise/CoreExpertise';

const services = [
  {
    icon: <HealthcareIcon />,
    title: 'Healthcare',
    desc: 'Our team delivers mobile apps for remote health monitoring, online doctor search, appointment booking, EHR/lab test management, and medical image analysis. We ensure compliance with all the required regulations that include GDPR, HIPAA, and HITECH.',
    link: '/solutions/healthcare',
  },
  {
    icon: <EcommerceIcon />,
    title: 'E-commerce',
    desc: 'We make e-commerce apps with features like search, catalog, wish list, reviews, ratings, AI bot, personalized offers, and third-party integrations (payment gateways, order/inventory management systems, data analytics, etc.). Open a new sales channel with a user-friendly e-commerce solution.',
    link: '/solutions/ecommerce-development',
  },
  {
    icon: <HRIcon />,
    title: 'HRM',
    desc: 'Our engineers build mobile products for vacancy/interview/career management, candidate scoring/onboarding, employee performance tracking, and job satisfaction monitoring. Get an HRM mobile app that will address your business-specific needs.',
    link: '/solutions/hr-software-development',
  },
  {
    icon: <FintechIcon />,
    title: 'Fintech',
    desc: 'We create fintech apps for personal/corporate finance management, mobile banking, financial risk analysis, crypto asset exchange, and investment portfolio management. Our team will ensure compliance with industry regulations like GDPR and PCI DSS.',
    link: '/solutions/fintech-software-development-services',
  },
  // {
  //   title: 'Marketing and social media',
  //   desc: 'Our mobile app developers make marketing and social media apps that allow businesses to increase brand awareness and drive conversions. Using cutting-edge technologies such as AI and big data, we help our clients engage the audience and increase revenues.',
  //   link: '/solutions/',
  // },
];

const MobileAppExpertise = () => {
  return <CoreExpertise data={services} title="Our expertise in custom mobile app development" />;
};

export default MobileAppExpertise;
