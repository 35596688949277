import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';

import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const H1Text = styled.span`
  font-weight: 500;
  font-size: 66px;
  line-height: 80px;
  color: ${(props) => (props.color ? props.color : colors2.black)};

  @media ${breakpoints.md} {
    font-size: 50px;
    line-height: 64px;
  }

  @media ${breakpoints.sm} {
    font-size: 30px;
    line-height: 40px;
  }
`;
export const H2Text = styled.span`
  font-weight: 500;
  font-size: 50px;
  line-height: 130%;
  color: ${(props) => (props.color ? props.color : colors2.black)};

  @media ${breakpoints.mds} {
    font-size: 40px;
    line-height: 52px;
  }

  @media ${breakpoints.sm} {
    font-size: 26px;
    line-height: 36px;
  }
`;
export const H3Text = styled.span`
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: ${(props) => (props.color ? props.color : colors2.black)};

  @media ${breakpoints.mds} {
    font-size: 24px;
    line-height: 36px;
  }

  @media ${breakpoints.sm} {
    font-size: 18px;
    line-height: 30px;
  }
`;
export const H4Text = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 165%;
  color: ${(props) => (props.color ? props.color : colors2.black)};

  @media ${breakpoints.mds} {
    font-size: 16px;
    line-height: 26px;
  }

  @media ${breakpoints.sm} {
    font-size: 14px;
    line-height: 23px;
  }
`;
export const H5Text = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 165%;
  color: ${(props) => (props.color ? props.color : colors2.black)};

  @media ${breakpoints.sm} {
    font-size: 14px;
    line-height: 23px;
  }
`;
export const P1Text = styled.span`
  font-weight: 400;
  font-size: 22px;
  line-height: 165%;
  color: ${(props) => (props.color ? props.color : colors2.black)};

  @media ${breakpoints.md} {
    font-size: 18px;
    line-height: 30px;
  }
`;
export const P2Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 165%;
  color: ${(props) => (props.color ? props.color : colors2.black)};

  @media ${breakpoints.mds} {
    font-size: 16px;
    line-height: 26px;
  }

  @media ${breakpoints.sm} {
    font-size: 14px;
    line-height: 23px;
  }
`;
export const P3Text = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 165%;
  color: ${(props) => (props.color ? props.color : colors2.black)};

  @media ${breakpoints.mds} {
    font-size: 14px;
    line-height: 23px;
  }
`;
