import React from 'react';
import { Link } from 'gatsby';
import { ButtonsContainer, Wrapper, TextContainer } from './styled';
import { H1Text, P2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { DefaultButton } from '../Buttons/styled';

const TopPageSectionWrapper = ({ title, description }) => {
  return (
    <Wrapper className="top-page-section-wrapper">
      <TextContainer>
        <H1Text>{title}</H1Text>
        <P2Text color={colors2.lightText}>{description}</P2Text>
      </TextContainer>

      <ButtonsContainer>
        <Link to="/contact-us">
          <DefaultButton>Contact us</DefaultButton>
        </Link>
        <Link to="/mvp-calculator">
          <DefaultButton className="light" bgColor={colors2.lightGray} color={colors2.black}>
            MVP-Calculator
          </DefaultButton>
        </Link>
      </ButtonsContainer>
    </Wrapper>
  );
};

export default TopPageSectionWrapper;
