import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  background-color: white;
  position: relative;
  .dropdown-header {
    color: black;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .dropdown-body {
    display: none;
    opacity: 0;
    max-height: 0;
    margin-top: 30px;
    border-radius: 20px;
    padding: 10px 30px;
    position: absolute;
    z-index: 23;
    white-space: nowrap;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  }
  .active.dropdown-body {
    display: block;
    max-height: 100vh;
    opacity: 1;
    -webkit-transition: max-height 0.35s;
    -o-transition: max-height 0.35s;
    transition: max-height 0.35s;
    -webkit-transition: opacity 0.35s;
    -o-transition: opacity 0.35s;
    transition: opacity 0.35s;
  }

  .accordion-body {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;

    & > :last-child {
      margin-bottom: 0;
    }
  }
  .active.accordion-body {
    max-height: 100vh;
    -webkit-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;
  }
  .dropdown-item {
    margin: 20px 0;

    span {
      font-weight: 400;
    }

    a {
      text-decoration: none;
      font-weight: 400;
    }
  }

  .dropdown-item:hover {
    cursor: pointer;
  }
`;
export const DropdownMenuListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:focus {
    outline: none !important;
  }
  .arrow {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'rotate(0)')};
    transition: transform 0.4s;
  }
`;
