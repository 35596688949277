import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Wrapper } from './styled';
import { H2Text, P2Text } from '../Typography/styled';

import colors2 from '../../utils/theme/theme2/colors2';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;
const OurTeam = ({ image }) => {
  return (
    <Wrapper>
      <TextContainer>
        <H2Text>Our Team</H2Text>
        <P2Text color={colors2.lightText}>
          A team of 60+ passionate tech & non-tech professionals
        </P2Text>
      </TextContainer>
      <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" />
    </Wrapper>
  );
};

export default OurTeam;
