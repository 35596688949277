import React from 'react';

const DevelopmentOptimization = () => {
  return (
    <div style={{ height: '100vh' }}>
      <iframe
        title="Development Optimization PDF"
        src="https://drive.google.com/file/d/1ICBq0dHZYAlozRepzNPwoiO6NO67slT2/preview"
        width="100%"
        height="100%"
        allow="autoplay"
      />
    </div>
  );
};

export default DevelopmentOptimization;
