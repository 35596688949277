exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-search-js": () => import("./../../../src/pages/blog-search.js" /* webpackChunkName: "component---src-pages-blog-search-js" */),
  "component---src-pages-clutch-global-leader-js": () => import("./../../../src/pages/clutch-global-leader.js" /* webpackChunkName: "component---src-pages-clutch-global-leader-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-development-optimization-js": () => import("./../../../src/pages/development-optimization.js" /* webpackChunkName: "component---src-pages-development-optimization-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-form-contact-us-js": () => import("./../../../src/pages/form-contact-us.js" /* webpackChunkName: "component---src-pages-form-contact-us-js" */),
  "component---src-pages-form-leave-us-js": () => import("./../../../src/pages/form-leave-us.js" /* webpackChunkName: "component---src-pages-form-leave-us-js" */),
  "component---src-pages-form-registration-js": () => import("./../../../src/pages/form-registration.js" /* webpackChunkName: "component---src-pages-form-registration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mvp-calculator-js": () => import("./../../../src/pages/mvp-calculator.js" /* webpackChunkName: "component---src-pages-mvp-calculator-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-services-backend-development-js": () => import("./../../../src/pages/services/backend-development.js" /* webpackChunkName: "component---src-pages-services-backend-development-js" */),
  "component---src-pages-services-custom-software-development-js": () => import("./../../../src/pages/services/custom-software-development.js" /* webpackChunkName: "component---src-pages-services-custom-software-development-js" */),
  "component---src-pages-services-dedicated-team-js": () => import("./../../../src/pages/services/dedicated-team.js" /* webpackChunkName: "component---src-pages-services-dedicated-team-js" */),
  "component---src-pages-services-frontend-development-js": () => import("./../../../src/pages/services/frontend-development.js" /* webpackChunkName: "component---src-pages-services-frontend-development-js" */),
  "component---src-pages-services-mobile-app-development-js": () => import("./../../../src/pages/services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-js" */),
  "component---src-pages-services-mvp-development-company-js": () => import("./../../../src/pages/services/mvp-development-company.js" /* webpackChunkName: "component---src-pages-services-mvp-development-company-js" */),
  "component---src-pages-services-ui-ux-design-js": () => import("./../../../src/pages/services/ui-ux-design.js" /* webpackChunkName: "component---src-pages-services-ui-ux-design-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-pages-solutions-ai-software-development-js": () => import("./../../../src/pages/solutions/ai-software-development.js" /* webpackChunkName: "component---src-pages-solutions-ai-software-development-js" */),
  "component---src-pages-solutions-ecommerce-development-js": () => import("./../../../src/pages/solutions/ecommerce-development.js" /* webpackChunkName: "component---src-pages-solutions-ecommerce-development-js" */),
  "component---src-pages-solutions-fintech-software-development-services-js": () => import("./../../../src/pages/solutions/fintech-software-development-services.js" /* webpackChunkName: "component---src-pages-solutions-fintech-software-development-services-js" */),
  "component---src-pages-solutions-healthcare-js": () => import("./../../../src/pages/solutions/healthcare.js" /* webpackChunkName: "component---src-pages-solutions-healthcare-js" */),
  "component---src-pages-solutions-hr-software-development-js": () => import("./../../../src/pages/solutions/hr-software-development.js" /* webpackChunkName: "component---src-pages-solutions-hr-software-development-js" */),
  "component---src-pages-solutions-salesforce-js": () => import("./../../../src/pages/solutions/salesforce.js" /* webpackChunkName: "component---src-pages-solutions-salesforce-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-web-3-js": () => import("./../../../src/pages/web3.js" /* webpackChunkName: "component---src-pages-web-3-js" */),
  "component---src-pages-web-3-thankyou-js": () => import("./../../../src/pages/web3-thankyou.js" /* webpackChunkName: "component---src-pages-web-3-thankyou-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/BlogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-our-portfolio-js": () => import("./../../../src/templates/OurPortfolio.js" /* webpackChunkName: "component---src-templates-our-portfolio-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/PolicyPage.js" /* webpackChunkName: "component---src-templates-policy-page-js" */)
}

