import React from 'react';
import MobileTable from './MobileTable';
import useWindowSize from '../../../hooks/useWindowSize';
import { MOBILE_VIEW_BREAKPOINT } from '../../../constants';
import DesktopTable from './DesktopTable';
import { OTHER_ROLE_NAME } from '../../constants';

const Table = ({ selected, setSelected, eurCurrency }) => {
  const [width] = useWindowSize();

  const onCounterChange = (roleName) => (newCount) => {
    if (newCount < 1) {
      setSelected((prev) => prev.filter((p) => p.name !== roleName));
    }
    setSelected((prev) => {
      return prev.map((p) => {
        if (p.name === roleName) {
          return { ...p, count: newCount };
        }
        return p;
      });
    });
  };

  const tablesProps = {
    selected: selected.filter((s) => !(s.name === OTHER_ROLE_NAME && !s.text)),
    onCounterChange,
    eurCurrency,
  };

  return width <= MOBILE_VIEW_BREAKPOINT.slice(0, -2) ? (
    <MobileTable {...tablesProps} />
  ) : (
    <DesktopTable {...tablesProps} />
  );
};

export default Table;
