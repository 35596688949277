import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Footer from '../components/Footer';
import ClutchGlobalLeaderPage from '../components/ClutchGlobalLeaderPage';
import { PageWrapper } from '../components/homePage/styled';
import { HeaderContainer } from '../components/Header/styled';
import Logo from '../assets/images/updateDesign/icons/logo.svg';
import { DefaultButton } from '../components/Buttons/styled';
import { breakpoints } from '../utils/theme/theme2/breakpoints';
import SEO from '../components/SEO';

const ContactUsBtn = styled(DefaultButton)`
  @media ${breakpoints.sm} {
    padding: 8px 16px !important;
  }
`;

const ClutchGlobalLeader = ({
  data: {
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
    clutchPageInfo: {
      frontmatter: { seoTitle, description, keywords },
    },
  },
}) => {
  return (
    <PageWrapper>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={siteUrl}
        description={description}
      />
      <HeaderContainer>
        <Link to="/">
          <Logo style={{ height: '34px' }} />
        </Link>
        <a href="mailto:contact@dashbouquet.com" style={{ textDecoration: 'none' }}>
          <ContactUsBtn width="144px" height="48px">
            Contact us
          </ContactUsBtn>
        </a>
      </HeaderContainer>

      <ClutchGlobalLeaderPage />

      <Footer />
    </PageWrapper>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    clutchPageInfo: markdownRemark(frontmatter: { name: { eq: "clutch" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }
  }
`;

export default ClutchGlobalLeader;
