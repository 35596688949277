import styled from 'styled-components';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const DnD = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px 80px;
  color: rgba(38, 38, 38, 0.7);
  cursor: pointer;
  border: 1px dashed rgba(38, 38, 38, 0.2);
  border-radius: 10px;
  min-height: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 165%;
  grid-column: 1 / 3;
  background: #f6f6f6;

  .placeholder {
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 20px;
    align-items: center;
  }
  @media ${breakpoints.mds} {
    padding: 15px;
    & .placeholder {
      font-size: 14px;
      line-height: 165%;
    }
  }
`;
