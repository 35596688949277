import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { navigate } from 'gatsby';
import { keys } from 'ramda';
import { groupByCategory } from '../../selectors/selectors';
import slugify from '../../utils/slugify';
import { CategoryWrapper, SearchInput, Tags } from './styled';
import Header from '../Header';
import { H5Text, P3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import SearchIcon from '../../assets/icons/updateDesign/search.svg';
import Footer from '../Footer';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import { PageContainer, PageContent, PageWrapper } from '../homePage/styled';

export const getPosts = (posts) =>
  posts
    ?.map(
      ({
        node: {
          id,
          fields: { slug },
          frontmatter: { date, title },
        },
      }) => ({
        id,
        date,
        title,
        src: slug,
      })
    )
    .slice(0, 5);

const BlogLayout = ({ children, title, allBlogs }) => {
  const handleSearch = (e) => {
    if (e.key === 'Enter' && e.currentTarget.value) {
      navigate(`/blog-search/?search=${e.currentTarget.value}`);
    }
  };
  const blogs = groupByCategory(allBlogs);
  const categoryName = keys(blogs).sort();
  return (
    <PageWrapper>
      <Header />
      <PageContainer>
        <PageContent>
          <TopPageSectionWrapper title={title} />
          <div>
            <Tags>
              <Box
                css={{
                  position: 'relative',
                  '@media screen and (max-width: 520px)': {
                    width: '100%',
                  },
                }}
              >
                <SearchIcon style={{ position: 'absolute', left: '30px', top: '20px' }} />
                <SearchInput type="text" placeholder="Search" onKeyUp={handleSearch} />
              </Box>

              {categoryName.map((category) => (
                <CategoryWrapper
                  key={category}
                  style={{ textDecoration: 'none' }}
                  to={`/blog/${slugify(category)}`}
                >
                  <H5Text style={{ display: 'flex' }}>
                    {category}
                    <Box>
                      <P3Text
                        style={{ fontSize: '12px', position: 'relative', left: '4px', top: '-4px' }}
                        color={colors2.lightText}
                      >{`${blogs[category].length}`}</P3Text>
                    </Box>
                  </H5Text>
                </CategoryWrapper>
              ))}
            </Tags>

            <Box>{children}</Box>
          </div>
        </PageContent>
      </PageContainer>
      <Footer />
    </PageWrapper>
  );
};

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default BlogLayout;
