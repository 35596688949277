import styled from 'styled-components';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import colors2 from '../../utils/theme/theme2/colors2';

export const HeaderContainer = styled.div`
  position: fixed;
  z-index: 9999;
  background-color: ${colors2.white};
  width: 100%;
  padding: 20px 80px;
  display: flex;
  max-width: 1280px;
  gap: 2.5rem;
  align-items: center;
  justify-content: space-between;
  @media ${breakpoints.mds} {
    padding: 20px 40px;
  }
  @media ${breakpoints.sm} {
    padding: 16px 28px;
  }
`;

export const HeaderMenuList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1215px) {
    display: none;
  }
`;

export const HeaderMenuListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
