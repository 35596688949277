export const OTHER_ROLE_NAME = 'Other';
export const ROLES = [
  {
    name: 'Smart Contract Developer',
    crossedPrice: 2800,
    actualPrice: 2000,
  },
  {
    name: 'Smart Contract Auditor',
    crossedPrice: 2800,
    actualPrice: 2000,
  },
  {
    name: 'Blockchain Engineer',
    crossedPrice: 2800,
    actualPrice: 2000,
  },
  {
    name: 'DApp Developer',
    crossedPrice: 2000,
    actualPrice: 1600,
  },
  {
    name: 'Blockchain DevOps Engineer',
    crossedPrice: 2800,
    actualPrice: 2000,
  },
  {
    name: 'Solidity Developer',
    crossedPrice: 2800,
    actualPrice: 2000,
  },
  {
    name: 'TypeScript Developer',
    crossedPrice: 2000,
    actualPrice: 1600,
  },
  {
    name: OTHER_ROLE_NAME,
  },
];
