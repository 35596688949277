import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { P3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import useWindowSize from '../Web3Page/hooks/useWindowSize';

export const technologiesQuery = graphql`
  {
    site {
      siteMetadata {
        menuLinks {
          name
          link
          event
          subLinks {
            name
            link
            event
          }
        }
      }
    }
    defaultIcon: file(relativePath: { eq: "images/technologies/Default.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    js: file(relativePath: { eq: "images/technologies/JavaScript.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    node: file(relativePath: { eq: "images/technologies/NodeJs.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    objective: file(relativePath: { eq: "images/technologies/ObjectiveC.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    react: file(relativePath: { eq: "images/technologies/ReactNative.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    swift: file(relativePath: { eq: "images/technologies/Swift.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    angular: file(relativePath: { eq: "images/technologies/Angular.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ts: file(relativePath: { eq: "images/technologies/TypeScript.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    vue: file(relativePath: { eq: "images/technologies/Vue.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    python: file(relativePath: { eq: "images/technologies/Python.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    net: file(relativePath: { eq: "images/technologies/NET.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    graphql: file(relativePath: { eq: "images/technologies/Graphql.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    gatsby: file(relativePath: { eq: "images/technologies/Gatsby.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    redux: file(relativePath: { eq: "images/technologies/Redux.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    css: file(relativePath: { eq: "images/technologies/Css.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    golang: file(relativePath: { eq: "images/technologies/Golang.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    html: file(relativePath: { eq: "images/technologies/Html.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    cypress: file(relativePath: { eq: "images/technologies/Cypress.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    jest: file(relativePath: { eq: "images/technologies/Jest.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    storybook: file(relativePath: { eq: "images/technologies/Storybook.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    docusaurus: file(relativePath: { eq: "images/technologies/Docusaurus.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aws: file(relativePath: { eq: "images/technologies/AWS.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    s3: file(relativePath: { eq: "images/technologies/S3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    pytorch: file(relativePath: { eq: "images/technologies/Pytorch.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    grafana: file(relativePath: { eq: "images/technologies/Grafana.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    prometheus: file(relativePath: { eq: "images/technologies/Prometheus.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    sass: file(relativePath: { eq: "images/technologies/Sass.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpack: file(relativePath: { eq: "images/technologies/Webpack.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    firebase: file(relativePath: { eq: "images/technologies/Firebase.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    googleMaps: file(relativePath: { eq: "images/technologies/GoogleMaps.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    amazonWebServices: file(relativePath: { eq: "images/technologies/AmazonWebServices.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    googleCloud: file(relativePath: { eq: "images/technologies/GoogleCloud.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    hubspot: file(relativePath: { eq: "images/technologies/HubSpot.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    rubyOnRails: file(relativePath: { eq: "images/technologies/RubyOnRails.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ramda: file(relativePath: { eq: "images/technologies/Ramda.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    postgresql: file(relativePath: { eq: "images/technologies/Postgresql.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    docker: file(relativePath: { eq: "images/technologies/Docker.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    heroku: file(relativePath: { eq: "images/technologies/Heroku.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    cloudinary: file(relativePath: { eq: "images/technologies/Cloudinary.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    apollo: file(relativePath: { eq: "images/technologies/Apollo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    java: file(relativePath: { eq: "images/technologies/Java.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export const TECHNOLOGY = {
  JAVA_SCRIPT: 'JavaScript',
  NODE_JS: 'Node.Js',
  OBJECTIVE_C: 'Objective-C',
  REACT: 'React',
  REACT_NATIVE: 'React Native',
  REACT_AND_REACT_NATIVE: 'React / React Native',
  SWIFT: 'Swift',
  ANGULAR: 'Angular',
  TYPESCRIPT: 'TypeScript',
  VUE: 'Vue.js',
  PYTHON: 'Python',
  DOT_NET: '.NET',
  GRAPHQL: 'Graphql',
  GATSBY: 'Gatsby',
  REDUX: 'Redux',
  REDUX_THUNK: 'Redux Thunk',
  CSS: 'CSS',
  CSS_MODULES: 'CSS Modules',
  GOLANG: 'Golang',
  HTML: 'HTML',
  CANVAS: 'Canvas',
  STORYBOOK: 'Storybook',
  CYPRRESS: 'Cypress',
  JEST: 'Jest',
  DOCUSAURUS: 'Docusaurus',
  AWS: 'AWS',
  S3: 'S3',
  PYTORCH: 'Pytorch',
  GRAFANA: 'Grafana',
  PROMETHEUS: 'Prometheus',
  SASS: 'Sass',
  WEBPACK: 'Webpack',
  FIREBASE: 'Firebase',
  GOOGLE_MAPS: 'Google Maps',
  AMAZON_WEB_SERVICES: 'Amazon Web Services',
  GOOGLE_CLOUD: 'Google Cloud',
  HUBSPOT: 'HubSpot',
  RUBY_ON_RAILS: 'Ruby on Rails',
  RAMDA: 'Ramda',
  POSTGRESQL: 'PostgreSQL',
  DOCKER: 'Docker',
  HEROKU: 'Heroku',
  CLOUDINARY: 'Cloudinary',
  APOLLO: 'Apollo',
  JAVA: 'Java',
};

export const formatTechnologyName = (technologyName) => {
  // eslint-disable-next-line
  return technologyName.toLowerCase().replace(/[\s.\-\/]*/g, '');
};
export const getTechnologiesByTechStack = (techStack) => {
  return techStack.map((techName) => {
    const formattedName = formatTechnologyName(techName);
    return (
      TECHNOLOGIES_FORMATTED_NAMES.find((tech) => {
        return tech.formattedNames.some((n) => n === formattedName);
      })?.name || techName
    );
  });
};
const ADDITIONAL_TECHNOLOGY_FORMATTED_NAMES = {
  [TECHNOLOGY.VUE]: ['vue'],
};
export const TECHNOLOGIES_FORMATTED_NAMES = Object.keys(TECHNOLOGY).map((key) => {
  const techName = TECHNOLOGY[key];
  return {
    name: techName,
    formattedNames: [
      formatTechnologyName(techName),
      ...(ADDITIONAL_TECHNOLOGY_FORMATTED_NAMES[techName] || []),
    ],
  };
});

const TechnologiesContainer = styled.div`
  width: 100%;

  ${P3Text} {
    max-width: 146px;
    align-items: center;
    text-align: center;
  }
`;

const ITEM_WIDTH = 180;
const ITEM_WIDTH_ON_400_PX_SCREEN = 130;
const TechnologyItem = styled.div`
  width: ${ITEM_WIDTH}px;
  min-height: 190px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(38, 38, 38, 0.12);

  @media (max-width: 400px) {
    width: ${ITEM_WIDTH_ON_400_PX_SCREEN}px;
    min-height: 140px;
  }
`;

const LINE_EXTENSION_SIZE = 50;
const LINE_COLOR = 'rgba(38, 38, 38, 0.12)';
const APPLY_STYLES = '&';
const DONT_APPLY_STYLES = '& > .some-kind-magic';
const TechnologiesRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${(props) => (props.isFirstRow ? APPLY_STYLES : DONT_APPLY_STYLES)} {
    ${TechnologyItem} {
      border-top: 1px solid ${LINE_COLOR};

      &::after {
        content: '';
        display: block;
        height: calc(100% + ${LINE_EXTENSION_SIZE}px);
        position: absolute;
        width: 100%;
        box-shadow: inset -1px 0px 0px 0px ${LINE_COLOR};
        left: 0;
        bottom: -1px;
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }

  ${(props) =>
    !props.isFirstRow && !props.isLastRow && !props.isLastRowWithOneItemAndAboveRowWithSeveralItems
      ? APPLY_STYLES
      : DONT_APPLY_STYLES} {
    ${TechnologyItem} {
      &::before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        box-shadow: inset -1px 0px 0px 0px ${LINE_COLOR};
        left: 0;
        top: 0;
      }

      &:last-child {
        &::before {
          content: none;
        }
      }
    }
  }

  ${(props) => (props.isLastRow && props.itemsCount > 1 ? APPLY_STYLES : DONT_APPLY_STYLES)} {
    ${TechnologyItem} {
      &::before {
        content: '';
        display: block;
        height: calc(100% + ${LINE_EXTENSION_SIZE}px);
        position: absolute;
        width: 100%;
        box-shadow: inset -1px 0px 0px 0px ${LINE_COLOR};
        left: 0;
        top: 0;
      }

      &:last-child {
        &::before {
          content: none;
        }
      }
    }
  }

  ${(props) =>
    !props.isFirstRow && props.isLastRow && props.itemsCount === 1
      ? APPLY_STYLES
      : DONT_APPLY_STYLES} {
    ${TechnologyItem} {
      &::after {
        content: '';
        display: block;
        height: calc(100% + ${LINE_EXTENSION_SIZE}px);
        position: absolute;
        width: 100%;
        box-shadow: inset -1px 0px 0px 0px ${LINE_COLOR};
        left: 0;
        top: 1px;
      }

      &::before {
        content: '';
        display: block;
        height: calc(100% + ${LINE_EXTENSION_SIZE}px);
        position: absolute;
        width: 100%;
        box-shadow: inset 1px 0px 0px 0px ${LINE_COLOR};
        left: -1px;
        top: 0;
      }
      @media (max-width: 300px) {
        &::before, &::after {
          content: none;
        }
      }
    }
   }
  }

  ${(props) =>
    props.isFirstRow && props.isLastRow && props.itemsCount === 1
      ? APPLY_STYLES
      : DONT_APPLY_STYLES} {
      ${TechnologyItem} {
        width: calc(${ITEM_WIDTH}px + ${LINE_EXTENSION_SIZE * 2}px);
        border-top: 1px solid ${LINE_COLOR};
        border-bottom: 1px solid ${LINE_COLOR};
  
        &::after {
          content: none;
        }
        &::before {
          content: '';
          display: block;
          height: calc(100% + ${LINE_EXTENSION_SIZE * 2}px);
          position: absolute;
          width: calc(100% - ${LINE_EXTENSION_SIZE * 2}px);
          box-shadow: inset 1px 0px 0px 0px ${LINE_COLOR}, inset -1px 0px 0px 0px ${LINE_COLOR};
          top: ${LINE_EXTENSION_SIZE / 2};
        }
        &:last-child {
          &::before {
            content: '';
          }
        }
        @media (max-width: 400px) { {
          width: calc(${ITEM_WIDTH_ON_400_PX_SCREEN}px + ${LINE_EXTENSION_SIZE * 2}px);
        }
      }
    }

  ${(props) =>
    props.isLastRow && props.itemsCount === 1 && props.prevRowItemsCount > 1
      ? APPLY_STYLES
      : DONT_APPLY_STYLES} {
    ${TechnologyItem} {
      width: calc(${ITEM_WIDTH}px + ${LINE_EXTENSION_SIZE * 2}px);
      &::before {
        content: "";
        display: block;
        height: calc(100% + ${LINE_EXTENSION_SIZE}px);
        position: absolute;
        width: calc(100% - ${LINE_EXTENSION_SIZE}px);
        box-shadow: ${LINE_COLOR} 1px 0px 0px 0px inset;
        left: 49px;
        top: 0;
      }
      &::after {
        content: '';
        display: block;
        height: calc(100% + ${LINE_EXTENSION_SIZE}px);
        position: absolute;
        width: calc(100% - ${LINE_EXTENSION_SIZE}px);
        box-shadow: inset -1px 0px 0px 0px ${LINE_COLOR};
        right: 0;
        top: 0;
      }
      @media (max-width: 400px) { {
        width: calc(${ITEM_WIDTH_ON_400_PX_SCREEN}px + ${LINE_EXTENSION_SIZE * 2}px);
      }
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
`;

const GatsbyImageStyled = styled(GatsbyImage)`
  width: fit-content;
  height: fit-content;
`;

const DEFAULT_TECHS = [
  TECHNOLOGY.JAVA_SCRIPT,
  TECHNOLOGY.NODE_JS,
  TECHNOLOGY.OBJECTIVE_C,
  TECHNOLOGY.REACT_AND_REACT_NATIVE,
  TECHNOLOGY.SWIFT,
  TECHNOLOGY.ANGULAR,
  TECHNOLOGY.TYPESCRIPT,
  TECHNOLOGY.VUE,
  TECHNOLOGY.PYTHON,
  TECHNOLOGY.DOT_NET,
  TECHNOLOGY.GRAPHQL,
  TECHNOLOGY.GATSBY,
];

const Technologies = ({ techs = DEFAULT_TECHS }) => {
  const icons = useStaticQuery(technologiesQuery);
  const [techData] = useState(() => {
    const TECH_ICON = {
      [TECHNOLOGY.JAVA_SCRIPT]: icons.js,
      [TECHNOLOGY.NODE_JS]: icons.node,
      [TECHNOLOGY.OBJECTIVE_C]: icons.objective,
      [TECHNOLOGY.REACT]: icons.react,
      [TECHNOLOGY.REACT_NATIVE]: icons.react,
      [TECHNOLOGY.REACT_AND_REACT_NATIVE]: icons.react,
      [TECHNOLOGY.SWIFT]: icons.swift,
      [TECHNOLOGY.ANGULAR]: icons.angular,
      [TECHNOLOGY.TYPESCRIPT]: icons.ts,
      [TECHNOLOGY.VUE]: icons.vue,
      [TECHNOLOGY.PYTHON]: icons.python,
      [TECHNOLOGY.DOT_NET]: icons.net,
      [TECHNOLOGY.GRAPHQL]: icons.graphql,
      [TECHNOLOGY.GATSBY]: icons.gatsby,
      [TECHNOLOGY.REDUX]: icons.redux,
      [TECHNOLOGY.REDUX_THUNK]: icons.redux,
      [TECHNOLOGY.CSS]: icons.css,
      [TECHNOLOGY.CSS_MODULES]: icons.css,
      [TECHNOLOGY.GOLANG]: icons.golang,
      [TECHNOLOGY.HTML]: icons.html,
      [TECHNOLOGY.CANVAS]: icons.html,
      [TECHNOLOGY.STORYBOOK]: icons.storybook,
      [TECHNOLOGY.CYPRRESS]: icons.cypress,
      [TECHNOLOGY.JEST]: icons.jest,
      [TECHNOLOGY.DOCUSAURUS]: icons.docusaurus,
      [TECHNOLOGY.AWS]: icons.aws,
      [TECHNOLOGY.S3]: icons.s3,
      [TECHNOLOGY.PYTORCH]: icons.pytorch,
      [TECHNOLOGY.GRAFANA]: icons.grafana,
      [TECHNOLOGY.PROMETHEUS]: icons.prometheus,
      [TECHNOLOGY.SASS]: icons.sass,
      [TECHNOLOGY.WEBPACK]: icons.webpack,
      [TECHNOLOGY.FIREBASE]: icons.firebase,
      [TECHNOLOGY.GOOGLE_MAPS]: icons.googleMaps,
      [TECHNOLOGY.AMAZON_WEB_SERVICES]: icons.amazonWebServices,
      [TECHNOLOGY.GOOGLE_CLOUD]: icons.googleCloud,
      [TECHNOLOGY.HUBSPOT]: icons.hubspot,
      [TECHNOLOGY.RUBY_ON_RAILS]: icons.rubyOnRails,
      [TECHNOLOGY.RAMDA]: icons.ramda,
      [TECHNOLOGY.POSTGRESQL]: icons.postgresql,
      [TECHNOLOGY.DOCKER]: icons.docker,
      [TECHNOLOGY.HEROKU]: icons.heroku,
      [TECHNOLOGY.CLOUDINARY]: icons.cloudinary,
      [TECHNOLOGY.APOLLO]: icons.apollo,
      [TECHNOLOGY.JAVA]: icons.java,
    };
    return techs.map((tech) => ({ name: tech, icon: TECH_ICON[tech] || icons.defaultIcon }));
  });
  const [rows, setRows] = useState([]);
  const size = useWindowSize();
  const container = useRef();

  useEffect(() => {
    if (container.current) {
      const width = container.current.offsetWidth;
      const itemsCount = techData.length;
      const itemWidth = container.current.childNodes[0]?.childNodes[0]?.offsetWidth;
      const itemsCountRowCanFit = Math.floor(width / itemWidth);
      const newRows = [];

      // More than 2 rows
      if (itemsCount / itemsCountRowCanFit > 2) {
        let leftItemsCount = itemsCount;
        while (leftItemsCount > 0) {
          newRows.push({
            fromItemIndex: itemsCount - leftItemsCount,
            toItemIndex:
              leftItemsCount > itemsCountRowCanFit
                ? itemsCount - leftItemsCount + itemsCountRowCanFit
                : itemsCount,
          });
          leftItemsCount -= itemsCountRowCanFit;
        }
        // 2 rows
      } else if (itemsCount > itemsCountRowCanFit) {
        const rowItemsCount = Math.ceil(itemsCount / 2);
        newRows.push({ fromItemIndex: 0, toItemIndex: rowItemsCount });
        newRows.push({ fromItemIndex: rowItemsCount, toItemIndex: itemsCount });
      } else {
        newRows.push({ fromItemIndex: 0, toItemIndex: itemsCount });
      }

      setRows(newRows);
    }
  }, [size, techData]);

  return (
    <TechnologiesContainer ref={container}>
      {rows.map((row, index, arr) => {
        const items = techData.slice(row.fromItemIndex, row.toItemIndex);
        return (
          <TechnologiesRow
            isFirstRow={index === 0}
            isLastRow={index === arr.length - 1}
            prevRowItemsCount={
              arr[index - 1] && arr[index - 1].toItemIndex - arr[index - 1].fromItemIndex
            }
            itemsCount={items.length}
            key={`${row.fromItemIndex}-${row.toItemIndex}`}
          >
            {items.map((el) => {
              return (
                <TechnologyItem key={`technology-item_${el.name}`}>
                  {el.icon && (
                    <ImageContainer>
                      <GatsbyImageStyled
                        image={el.icon.childImageSharp.gatsbyImageData}
                        alt={el.name}
                      />
                    </ImageContainer>
                  )}
                  <P3Text color={colors2.lightText}>{el.name}</P3Text>
                </TechnologyItem>
              );
            })}
          </TechnologiesRow>
        );
      })}
    </TechnologiesContainer>
  );
};

export default Technologies;
