/* eslint-disable default-case */
import { getStepByBelongingItemValue } from './stepsData';

/**
 * Returns rate for multiplatform optional step
 *
 * @param {string[]} choosedPlatforms
 * @param {string} optionalStepValue
 * @param {string[]} MVPCalculatorSteps
 * @returns {number}
 */
// eslint-disable-next-line consistent-return
// function getMultiPlatformOptionalStepRate(choosedPlatforms, optionalStepValue, MVPCalculatorSteps) {
//   if (choosedPlatforms.length === 3) {
//     switch (optionalStepValue) {
//       case MVPCalculatorSteps[11]:
//       case MVPCalculatorSteps[13]:
//       case MVPCalculatorSteps[14]:
//         return 0.4;
//       case MVPCalculatorSteps[5]:
//       case MVPCalculatorSteps[12]:
//         return 0.6;
//       case MVPCalculatorSteps[6]:
//       case MVPCalculatorSteps[8]:
//       case MVPCalculatorSteps[9]:
//       case MVPCalculatorSteps[10]:
//         return 0.7;
//       case MVPCalculatorSteps[7]:
//         return 0.9;
//     }
//   }

//   const isIOSIncluded = choosedPlatforms.includes(`${MVPCalculatorSteps[0]}:ios`);
//   const isWebIncluded = choosedPlatforms.includes(`${MVPCalculatorSteps[0]}:web`);
//   const isMobileIncluded = choosedPlatforms.includes(`${MVPCalculatorSteps[0]}:mobile`);

//   if (isIOSIncluded && isWebIncluded) {
//     switch (optionalStepValue) {
//       case MVPCalculatorSteps[13]:
//       case MVPCalculatorSteps[14]:
//         return 0.5;
//       case MVPCalculatorSteps[7]:
//       case MVPCalculatorSteps[11]:
//       case MVPCalculatorSteps[12]:
//         return 0.6;
//       case MVPCalculatorSteps[5]:
//         return 0.7;
//       case MVPCalculatorSteps[8]:
//       case MVPCalculatorSteps[9]:
//       case MVPCalculatorSteps[10]:
//         return 0.8;
//       case MVPCalculatorSteps[6]:
//         return 0.9;
//     }
//   }

//   if (isWebIncluded && isMobileIncluded) {
//     switch (optionalStepValue) {
//       case MVPCalculatorSteps[13]:
//       case MVPCalculatorSteps[14]:
//         return 0.5;
//       case MVPCalculatorSteps[11]:
//       case MVPCalculatorSteps[12]:
//         return 0.6;
//       case MVPCalculatorSteps[7]:
//         return 0.7;
//       case MVPCalculatorSteps[5]:
//       case MVPCalculatorSteps[8]:
//       case MVPCalculatorSteps[9]:
//       case MVPCalculatorSteps[10]:
//         return 0.8;
//       case MVPCalculatorSteps[6]:
//         return 0.9;
//     }
//   }

//   if (isIOSIncluded && isMobileIncluded) {
//     switch (optionalStepValue) {
//       case MVPCalculatorSteps[13]:
//       case MVPCalculatorSteps[14]:
//         return 0.5;
//       case MVPCalculatorSteps[9]:
//       case MVPCalculatorSteps[10]:
//       case MVPCalculatorSteps[11]:
//       case MVPCalculatorSteps[12]:
//         return 0.6;
//       case MVPCalculatorSteps[5]:
//         return 0.7;
//       case MVPCalculatorSteps[7]:
//       case MVPCalculatorSteps[8]:
//         return 0.8;
//       case MVPCalculatorSteps[6]:
//         return 0.9;
//     }
//   }
// }

/**
 * Returns cost of any optional step
 *
 * @param {string[]} choosedPlatforms
 * @param {object} stepsData
 * @param {string[]} itemValues
 * @returns {number}
 */
export function getOptionalStepCost(choosedPlatforms, stepsData, itemValues) {
  const optionalStepItems = getStepByBelongingItemValue(stepsData, itemValues[0]).items;
  const selectedStepItems = optionalStepItems.filter((item) => itemValues.includes(item.value));

  return choosedPlatforms.reduce((result, platform) => {
    const costByPlatform = selectedStepItems.reduce((platformResult, { cost }) => {
      const rate = 1;
      // choosedPlatforms.length === 1
      //   ? 1
      //   : getMultiPlatformOptionalStepRate(choosedPlatforms, stepValue, MVPCalculatorSteps);

      return platformResult + cost[platform] * rate;
    }, 0);

    return result + costByPlatform;
  }, 0);
}

export function getOptionalStepTimeEstimation(choosedPlatforms, stepsData, itemValues) {
  const something = getStepByBelongingItemValue(stepsData, itemValues[0]);

  const optionalStepItems = something.items;
  const selectedStepItems = optionalStepItems.filter((item) => itemValues.includes(item.value));

  return choosedPlatforms.reduce((result, platform) => {
    const timeEstimationByPlatform = selectedStepItems.reduce((platformResult, { estimation }) => {
      return platformResult + estimation[platform];
    }, 0);

    return result + timeEstimationByPlatform;
  }, 0);
}
