import React from 'react';
import MVPCalculatorStep from './index';
import MVPCalculatorStepItem from './MVPCalculatorStepItem';
import withCurrentStepData from './withCurrentStepData';
import { StyledGrid } from './styled';

const MVPCalculatorSelectorStep = ({
  currentStepData,
  selectedItems,
  handleItemClick,
  ...props
}) => {
  const areCurrentStepItemsSelected = currentStepData.items.some((item) =>
    selectedItems.includes(item.value)
  );
  const isNextButtonShown =
    (areCurrentStepItemsSelected && currentStepData.isMultiSelect) || currentStepData.canBeSkipped;

  return (
    <MVPCalculatorStep
      {...props}
      currentStepData={currentStepData}
      isDisplayDisabledSubmitButton={currentStepData.isMultiSelect}
      isReadyForSubmit={areCurrentStepItemsSelected}
      onSubmit={props.handleNextButtonClick}
    >
      <StyledGrid columns={currentStepData.columns}>
        {currentStepData.items.map(({ value, icon, additionalInfo, description, title }) => (
          <MVPCalculatorStepItem
            key={value}
            value={value}
            icon={icon}
            additionalInfo={additionalInfo}
            description={description}
            isSelected={selectedItems.includes(value)}
            title={title}
            onClick={(itemValue) => handleItemClick(itemValue, currentStepData.isMultiSelect)}
          />
        ))}
      </StyledGrid>
    </MVPCalculatorStep>
  );
};

export default withCurrentStepData(MVPCalculatorSelectorStep);
