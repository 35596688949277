import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const DropdownWrapper = styled.div`
  background-color: white;
  position: relative;
  width: 100%;

  .dropdown-header {
    padding: 15px;
    background: ${colors2.lightGray};
    border-radius: 10px;
    width: 100%;
    color: black;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span: {
      overflow: hidden;
    }
  }

  .dropdown-body {
    text-transform: capitalize;
    max-height: 300px;
    overflow: scroll;
    width: 100%;
    margin-top: 10px;
    right: 0;
    border-radius: 10px;
    padding: 10px 30px;
    position: absolute;
    z-index: 23;
    white-space: nowrap;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);

    @media ${breakpoints.mds} {
      padding: 10px 15px;
    }
  }

  .accordion-body {
    & > :last-child {
      margin-bottom: 0;
    }
  }

  .dropdown-item {
    margin: 20px 0;

    span {
      font-weight: 400;
    }

    a {
      text-decoration: none;
      font-weight: 400;
    }
  }

  .dropdown-item:hover {
    cursor: pointer;
  }
`;
export const DropdownMenuListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;

  .arrow {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'rotate(0)')};
    transition: transform 0.4s;
  }
`;
