import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';
import HrSoftwareChallenges from './HrSoftwareChallenges';
import HrSoftwareProcessSection from './HrSoftwareProcessSection';

import MessageCard from '../Card/MessageCard';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ContactUsForm from '../ContactUsForm';

const HrSoftwarePage = () => {
  const { message } = useStaticQuery(query);
  return (
    <PageContainer>
      <PageContent>
        <TopPageSectionWrapper
          title="HR software development company"
          description="HRM software we build helps streamline candidate search, recruitment, and onboarding,
          saving you time and costs. Manage staff performance and boost job satisfaction with ease."
        />
        <HrSoftwareChallenges />
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <MessageCard
          image={message}
          title="Strive to automate HR processes and cut costs?"
          subtitile="Hire our HR software developers."
          button="Contact us"
          style={{ margin: '0' }}
        />
        <HrSoftwareProcessSection />
        <Technologies />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default HrSoftwarePage;
