import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Flex } from 'rebass';
import Link from '../Link';
import ArrowBtn from '../Buttons/ArrowBtn';
import { CardImageWrapper, CardTextWrapper, TagsContainer, CardWrapper } from './styled';
import { P2Text, H2Text, P3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';

const WorksSliderCard = ({ el }) => {
  return (
    <CardWrapper bgColor={el?.bgColor}>
      <CardImageWrapper>
        <GatsbyImage
          className="slider-card-img"
          image={el.image.childImageSharp.gatsbyImageData}
          alt="card_img"
        />
      </CardImageWrapper>
      <CardTextWrapper bgColor={el?.bgColor}>
        <TagsContainer bgColor={el?.bgColor}>
          {el.tags.map((tag, ind) => (
            <div key={`works-tag_${ind}`} className="card-tag">
              <P3Text color={el?.bgColor === colors2.blue ? '#ffffffd9' : colors2.lightText}>
                {tag}
              </P3Text>
            </div>
          ))}
        </TagsContainer>
        <Flex flexDirection="column">
          <H2Text style={{ marginBottom: '20px' }}>{el.title}</H2Text>
          <P2Text>{el.desc}</P2Text>
        </Flex>
        <Link to={el.link}>
          <ArrowBtn text="Learn more" />
        </Link>
      </CardTextWrapper>
    </CardWrapper>
  );
};

export default WorksSliderCard;
