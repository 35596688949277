import React from 'react';
import { SwitchWrapper, SwitchForm, SwitchInput, SwitchLabel } from './styled';

const CurrencySwitch = ({ eurCurrency, setEurCurrency }) => (
  <SwitchWrapper>
    <SwitchForm>
      <SwitchInput
        type="radio"
        id="usd"
        name="currencySwitch"
        checked={!eurCurrency}
        onChange={setEurCurrency}
      />
      <SwitchLabel for="usd">USD</SwitchLabel>
      <SwitchInput
        type="radio"
        id="eur"
        name="currencySwitch"
        checked={eurCurrency}
        onChange={setEurCurrency}
      />
      <SwitchLabel for="eur">EUR</SwitchLabel>
    </SwitchForm>
  </SwitchWrapper>
);

export default CurrencySwitch;
