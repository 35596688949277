import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { H1Text, P1Text } from '../components/Typography/styled';
import { breakpoints } from '../utils/theme/theme2/breakpoints';
import Header from '../components/Web3Page/Header';
import { Web3PageWrapper } from './web3';
import { web3Colors } from '../components/Web3Page/styled';
import fonts from '../utils/theme/fonts';
import { PurpleButton } from '../components/Web3Page/components';

export const ThankYouPageContent = styled.div`
  font-family: ${fonts.euclidCircularA};
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  & button {
    padding: 18px 50px;
  }
  ${H1Text} {
    font-weight: 700;
    font-size: 73px;
    line-height: 120%;
    color: white;
    text-align: center;
  }
  ${P1Text} {
    color: ${web3Colors.grey};
    text-align: center;
  }
  @media ${breakpoints.sm} {
    gap: 20px;
    ${H1Text} {
      font-size: 50px;
      line-height: 50px;
    }
  }
`;

const Web3ThankYouPage = () => {
  return (
    <Web3PageWrapper style={{ height: '100vh' }}>
      <Header />
      <ThankYouPageContent>
        <H1Text>Thank you!</H1Text>
        <P1Text>
          Your order has been successfully placed. An email confirmation has been sent to your
          inbox.
        </P1Text>
        <P1Text>Please check your email shortly for further details.</P1Text>
        <PurpleButton
          text="Back"
          onClick={() => {
            navigate('/web3');
          }}
        />
      </ThankYouPageContent>
    </Web3PageWrapper>
  );
};

export default Web3ThankYouPage;
