import CommissionsIcon from './commissions.svg';
import PaymentProcessingIcon from './paymentProcessing.svg';
import PayoutsWithCommisionsIcon from './payoutsWithCommisions.svg';
import PurchasesIcon from './purchases.svg';
import SubscriptionPlansIcon from './subscriptionPlans.svg';

export {
  CommissionsIcon,
  PaymentProcessingIcon,
  PayoutsWithCommisionsIcon,
  PurchasesIcon,
  SubscriptionPlansIcon,
};
