import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';

import SecondSection from './SecondSection';
import MessageCard from '../Card/MessageCard';
import HealthcareIcon from '../../assets/icons/updateDesign/development/healthcare.svg';
import EcommerceIcon from '../../assets/icons/updateDesign/development/ecommerce.svg';
import HRIcon from '../../assets/icons/updateDesign/development/hr.svg';
import FintechIcon from '../../assets/icons/updateDesign/development/fintech.svg';

import TestingIcon from '../../assets/icons/updateDesign/dedicated/testing.svg';
import TestingScrollIcon from '../../assets/icons/updateDesign/dedicated/testing-hover.svg';
import UxScrollIcon from '../../assets/icons/updateDesign/development/ux-hover.svg';
import UxIcon from '../../assets/icons/updateDesign/development/ux.svg';
import DevelopmentIcon from '../../assets/icons/updateDesign/development/development.svg';
import DevelopmentScrollIcon from '../../assets/icons/updateDesign/development/development-hover.svg';
import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import SupportIcon from '../../assets/icons/updateDesign/dedicated/support.svg';
import SupportScrollIcon from '../../assets/icons/updateDesign/dedicated/support-hover.svg';

import ProcessComponent from '../ProcessComponent';

import CoreExpertise from '../Expertise/CoreExpertise';
import ContactUsForm from '../ContactUsForm';

const expertise = [
  {
    icon: <HealthcareIcon />,
    title: 'Healthcare',
    desc: 'Hire our custom hospital software development company to improve patient treatment, digitize manual processes, and minimize human errors using technologies. Our team will ensure compliance with regulations like GDPR and HIPAA.',
    link: '/solutions/healthcare',
  },
  {
    icon: <EcommerceIcon />,
    title: 'E-commerce',
    desc: 'With solid experience in custom e-commerce software development, we create UX-optimized apps, websites, and marketplaces. Oue engineers provide high availability, scalability, fault tolerance, and security.',
    link: '/solutions/ecommerce-development',
  },
  {
    icon: <HRIcon />,
    title: 'HRM',
    desc: 'HR software development solutions we build help our customers streamline candidate search/recruitment/onboarding processes, boost employee engagement, increase staff productivity, and cut expenditures.',
    link: '/solutions/hr-software-development',
  },
  {
    icon: <FintechIcon />,
    title: 'Fintech',
    desc: 'Our fintech software development company has vast expertise in making mobile banking, expense management, online payment, and robo-advisory solutions. Get a secure product compliant with industry regulations.',
    link: '/solutions/fintech-software-development-services',
  },
  // {
  //   icon: <MarketingIcon />,
  //   title: 'Marketing & Social media',
  //   desc: 'Launch a marketing platform/social network app to increase brand awareness, personalize user experiences, and drive conversions. Powered by AI and big data, digital products we build help our clients gain a competitive advantage.',
  //   link: '/solutions/social-media-app-development-services',
  // },
];

const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'Discovery',
    text: 'We start by analyzing project requirements to obtain a clear vision of your business needs. At the end of this stage, you’ll receive a technical specification, a documented work scope, and budget estimate.',
  },
  {
    number: 'step 2',
    icon: <UxIcon />,
    onScrollIcon: <UxScrollIcon />,
    title: 'UX/UI design',
    text: 'In 2 weeks, our team develops a dynamic prototype to visualize the product interface. After discussing the prototype with you and introducing the necessary changes, we create a user-friendly design enjoyed by the audience.',
  },
  {
    number: 'step 3',
    icon: <DevelopmentIcon />,
    onScrollIcon: <DevelopmentScrollIcon />,
    title: 'Solution development',
    text: 'Following Agile best practices, we deliver new functionality every 1–3 weeks. By enabling a CI/CD pipeline, we automate the testing, build, and deployment processes, reducing the feature release cycle by 3x–4x.',
  },
  {
    number: 'step 4',
    icon: <TestingIcon />,
    onScrollIcon: <TestingScrollIcon />,
    title: 'Quality assurance',
    text: 'Our QA experts test the product to make sure it is error-free, performs as intended, and meets all the requirements. We also prevent potential bottlenecks such as security and scalability issues.',
  },
  {
    number: 'step 5',
    icon: <SupportIcon />,
    onScrollIcon: <SupportScrollIcon />,
    title: 'Support & Maintenance',
    text: 'Finally, we launch your product on the market. On top of that, we offer post-release support and maintenance to ensure smooth system operation. Our engineers implement new features and fix arising issues on the fly.',
  },
];

const CustomSoftwareDevelopmentPage = () => {
  const { message } = useStaticQuery(query);

  return (
    <PageContainer>
      <PageContent>
        <div className="service-wrapper">
          <TopPageSectionWrapper
            title="Custom software development"
            description="Hire a custom software development company to automate business processes, increase the
        conversion rates, and cut operating costs using technologies."
          />
          <SecondSection />
        </div>
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <ProcessComponent title="How we work" data={work} />
        <MessageCard
          image={message}
          title="Ready to start your custom app development?"
          subtitile="Contact us today to schedule a consultation and get a project estimate."
          button="Contact us"
        />

        <CoreExpertise title="Core expertise" data={expertise} />
        <Technologies />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default CustomSoftwareDevelopmentPage;
