import styled from 'styled-components';
import React from 'react';
import { MOBILE_VIEW_BREAKPOINT } from '../../../constants';
import MinusIcon from '../../../../../assets/icons/web3/minus.svg';
import PlusIcon from '../../../../../assets/icons/web3/plus.svg';

const BUTTON_BACKGROUND = '#18142a';
const BORDER_COLOR = '#393455';

const ButtonContainer = styled.div`
  height: 34px;
  display: flex;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 5px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 100%;
  background: ${BUTTON_BACKGROUND};
  border-radius: ${(props) => (props.isLeft ? '5px 0 0 5px' : '0 5px 5px 0')};
  color: white;
  &:after {
    content: '';
    width: 1px;
    height: 80%;
    position: relative;
    ${(props) =>
      props.isLeft
        ? `
          border-right: 1px solid ${BORDER_COLOR};
          left: 12px;
      `
        : `
          border-left: 1px solid ${BORDER_COLOR};
          right: 22px;
     `}
  }
  &:hover {
    cursor: pointer;
  }
`;

const Count = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  background: ${BUTTON_BACKGROUND};
  min-width: 45px;
  text-align: center;
  &:hover {
    cursor: default;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 12px;
  }
`;

const Counter = ({ count, onChange }) => {
  const onMinusClick = () => {
    onChange(count - 1);
  };

  const onPlusClick = () => {
    onChange(count + 1);
  };

  return (
    <ButtonContainer>
      <Button type="button" onClick={onMinusClick} isLeft>
        <MinusIcon />
      </Button>
      <Count>{`x${count}`}</Count>
      <Button type="button" onClick={onPlusClick}>
        <PlusIcon />
      </Button>
    </ButtonContainer>
  );
};

export default Counter;
