import React from 'react';
import { Flex } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { BadgesContent } from './styled';
import { P2Text } from '../Typography/styled';

import Slider from '../Slider';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const awardsQuery = graphql`
  {
    awards: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "awards" }, for: { regex: "/awards/" } } }
      sort: { fields: frontmatter___range, order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                original {
                  width
                }
                gatsbyImageData(quality: 100, width: 151, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

const Badges = () => {
  const { awards } = useStaticQuery(awardsQuery);

  const awardsList = awards.edges.map((item) => {
    return {
      image: item.node.frontmatter.image.childImageSharp.gatsbyImageData,
      title: item.node.frontmatter.title,
    };
  });

  return (
    <BadgesContent>
      <Flex className="badges_container" flexDirection="column" alignItems="center">
        <P2Text color="transparent">.</P2Text>
        <Slider
          slidesCount={awardsList.length}
          slidesToShowDesktop={5}
          slidesToShowTablet={5}
          slidesToShowMobile={3}
          slidesToScrollDesktop={1}
          prevArrowStyles={`
              top: 50% !important;
              left: 0px !important;
                :hover {
                  opacity: 0.7;x
                }
                @media ${breakpoints.md} {
                  top: 160px !important;
                  right: 56px !important;
                  left: unset !important;
                }
            `}
          nextArrowStyles={`
                top: 50% !important;
                right: 0px !important;
                  :hover {
                    opacity: 0.7;x
                  }
                  @media ${breakpoints.md} {
                    top: 160px !important;
                    right: 0px !important;
                  }
        `}
        >
          {awardsList.map((el, indx) => (
            <Flex className="card" key={`badge_${indx}`}>
              <Flex className="card__img">
                <GatsbyImage className="card__img" image={el.image} alt={el.title} />
              </Flex>
            </Flex>
          ))}
        </Slider>
      </Flex>
    </BadgesContent>
  );
};

export default Badges;
