import React from 'react';
import styled from 'styled-components';
import { H2Text } from '../Typography/styled';
import Technologies from '../homePage/Technologies';

export const TechnologiesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
`;

const OurTechnologies = () => {
  return (
    <TechnologiesContent>
      <H2Text>Technologies</H2Text>
      <Technologies />
    </TechnologiesContent>
  );
};

export default OurTechnologies;
