import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import { P2Text } from '../Typography/styled';
import SliderPagingLine from './SliderPagingLine';
import colors2 from '../../utils/theme/theme2/colors2';

const SliderPaging = ({
  pagingPosition,
  pagingLineWidth,
  pagingAnimatedLineWidth,
  pagingAnimatedLineColor,
  pageLabelColor,
  pagingLineColor,
  slidesToShow,
  slidesToScroll,
  slidesCount,
  activeSliderPage,
}) => {
  const sliderPagesCount = Math.ceil((slidesCount - slidesToShow) / slidesToScroll + 1);
  const currentSliderPageLabel = `${activeSliderPage <= 9 ? '0' : ''}${activeSliderPage}`;
  const sliderPagesCountLabel = `${sliderPagesCount <= 9 ? '0' : ''}${sliderPagesCount}`;
  return (
    <Flex
      flexDirection="column"
      pl={pagingPosition === 'left' && 1}
      pr={pagingPosition === 'right' && 1}
    >
      <Flex
        alignItems="center"
        justifyContent={pagingPosition === 'left' ? 'flex-start' : 'flex-end'}
        mt={[2, 3, 3]}
      >
        <P2Text color={pageLabelColor}>
          <span css="font-weight: 900;">{currentSliderPageLabel}</span>
          {` / ${sliderPagesCountLabel}`}
        </P2Text>
        <SliderPagingLine
          pagingLineWidth={pagingLineWidth}
          pagingLineColor={pagingLineColor}
          pagingAnimatedLineColor={pagingAnimatedLineColor}
          pagingAnimatedLineWidth={pagingAnimatedLineWidth}
        />
      </Flex>
    </Flex>
  );
};

SliderPaging.propTypes = {
  pagingPosition: PropTypes.string,
  pagingLineWidth: PropTypes.number,
  pagingAnimatedLineWidth: PropTypes.number,
  pageLabelColor: PropTypes.string,
  pagingLineColor: PropTypes.string,
  slidesToShow: PropTypes.number.isRequired,
  slidesToScroll: PropTypes.number.isRequired,
  slidesCount: PropTypes.number,
  activeSliderPage: PropTypes.number.isRequired,
};

SliderPaging.defaultProps = {
  pagingPosition: 'left',
  pagingLineWidth: 130,
  pagingAnimatedLineWidth: 0,
  pageLabelColor: colors2.lightText,
  pagingLineColor: colors2.lightText,
  slidesCount: null,
};

export default SliderPaging;
