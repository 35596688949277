import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H2Text, P2Text } from '../Typography/styled';

export const FirsSectionWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 77px;
  justify-content: space-between;
  color: ${colors2.black};
  & > :first-child {
    width: 100%;

    display: flex;
    flex-direction: column;

    & > :first-child {
      margin-bottom: 30px;
    }
  }
  & form {
    width: 100%;
    max-width: 600px;
  }
  @media ${breakpoints.md} {
    flex-direction: column;
    gap: 30px;
    & form {
      max-width: 680px;
    }
  }
  @media ${breakpoints.mds} {
    & ${H2Text} {
      font-size: 34px;
      line-height: 40px;
    }
    & ${P2Text} {
      font-size: 16px;
      line-height: 165%;
    }
  }
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  > button {
    grid-column: 1 / 3;
  }

  .subtitle {
    grid-column: 1 / 3;
    color: ${colors2.lightText};
    text-align: center;
  }

  .expected-budget__value-container {
    padding: 15px;
  }

  .textarea {
    grid-column: 1 / 3;
    .textarea-counter-wrapper {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
    }
  }
  @media ${breakpoints.mds} {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 165%;
    }
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  background: ${colors2.lightGray};
  border-radius: 10px;
  color: ${colors2.black};
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 165%;
  outline: none;
  @media ${breakpoints.mds} {
    font-weight: 400;
    font-size: 14px;
    line-height: 165%;
  }
`;
export const StyledPhoneInput = styled.div`
  .PhoneInput {
    width: 100%;
    background: ${colors2.lightGray};
    border-radius: 10px;
    color: ${colors2.black};
    padding: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 165%;
    outline: none;
    @media ${breakpoints.mds} {
      font-weight: 400;
      font-size: 14px;
      line-height: 165%;
    }
    & .PhoneInputInput {
      background: transparent;
      outline: none;
    }
  }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  border-radius: 10px;
  color: ${colors2.black};
  padding: 15px;
  resize: none;
  grid-column: 1 / 3;
  outline: none;
  min-height: 150px;
  background: ${colors2.lightGray};
  @media ${breakpoints.mds} {
    font-weight: 400;
    font-size: 14px;
    line-height: 165%;
  }
`;

export const SubmitBtn = styled.button`
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;

  background: ${colors2.black};
  border-radius: 10px;
  color: ${colors2.white};
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  transition: all 0.7s;
  border: 1px solid transparent;

  &:hover {
    color: ${colors2.black};
    background-color: ${colors2.white};
    border: 1px solid ${colors2.black};
  }
`;
