import React from 'react';
import { H2Text, H4Text, P3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { DropUs, Contact, Divider } from './styled';
import EmailIcon from '../../assets/icons/updateDesign/email.svg';
import LocationIcon from '../../assets/icons/updateDesign/location.svg';
import PhoneIcon from '../../assets/icons/updateDesign/phone.svg';

const DropUsSection = () => {
  return (
    <DropUs>
      <P3Text color={colors2.lightText}>Drop us a line</P3Text>
      <Contact className="email">
        <div>
          <EmailIcon />
        </div>
        <a href="mailto:contact@dashbouquet.com">
          <H2Text>contact@dashbouquet.com</H2Text>
        </a>
      </Contact>
      <Divider />
      <div className="dropus_block__bottom">
        <Contact>
          <div>
            <PhoneIcon />
          </div>
          <div className="phones">
            <a href="tel:+16469348662">
              <H4Text>+1 646 934 8662 (US)</H4Text>
            </a>
            <a href="tel:+442045770721">
              <H4Text>+44 2045 770721 (GB)</H4Text>
            </a>
          </div>
        </Contact>
        <Contact className="location">
          <div>
            <LocationIcon />
          </div>
          <div className="address-block">
            <a
              href="http://maps.google.com/?q=Estonia, Tallinn city, Harju county, 10117, Narva mnt 7-353"
              target="_blank"
              rel="noreferrer"
            >
              <H4Text>
                Estonia, Tallinn city,
                <br />
                Harju county, 10117, Narva mnt 7-353
              </H4Text>
            </a>
            <a
              href="https://www.google.com/maps/place/Domaniewska+17%2F19%2F133,+02-672+Warszawa,+%D0%9F%D0%BE%D0%BB%D1%8C%D1%88%D0%B0/@52.179425,21.0704822,11z/data=!4m6!3m5!1s0x471932d0c2f00001:0xf3b91d9e38fadea5!8m2!3d52.183079!4d21.0160796!16s%2Fg%2F11vbb0b7wv?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              <H4Text>
                Poland, Warszawa, <br />
                ul. Domaniewska, nr 17/19, lok. 133 02-672.
              </H4Text>
            </a>
          </div>
        </Contact>
      </div>
    </DropUs>
  );
};

export default DropUsSection;
