import styled from 'styled-components';
import { Flex } from 'rebass';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { P2Text, H2Text, H4Text } from '../Typography/styled';

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: auto;
  margin-top: 120px;
  color: ${colors2.black};
  .top-page-section {
    width: 100%;
  }
  .list-with-big-icons_wrapper {
    padding: 0;
  }
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  gap: 40px;
  align-items: center;
`;

export const BlueSection = styled.div`
  width: 100%;
  padding: 80px;
  background-color: ${colors2.lightBlue};
  border-radius: 40px;
  position: relative;

  .titleInfo {
    max-width: 520px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
export const Divider = styled.div`
  width: 100%;
  height: 3px;
  margin: 80px 0;

  background-image: linear-gradient(to right, ${colors2.blue} 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 25px 1px;
  background-repeat: repeat-x;
`;
export const WhoWeAreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  ${H2Text} {
    text-align: center;
  }
`;
export const WhoWeAreContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 40px;
  @media ${breakpoints.mds} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${breakpoints.sm} {
    display: flex;
    flex-direction: column;
  }
`;
export const WhoWeAreCard = styled.div`
  border-radius: 40px;
  display: flex;
  width: 100%;
  grid-column: ${(props) => (props.index === 0 ? '1 / 3' : 'unset')};
  padding: ${(props) => (props.index === 0 ? ' 75px 30px' : '30px')};
  flex-direction: ${(props) => (props.index === 0 ? 'row' : 'column')};
  gap: ${(props) => (props.index === 0 ? '30px' : '20px')};
  background-color: ${(props) =>
    props.index === 0 ? colors2.lightGray : props.index % 2 === 0 ? colors2.yellow : colors2.green};

  & .text-content {
    width: 100%;
    max-width: 465px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  @media ${breakpoints.mds} {
    padding: 30px;
    gap: 15px;
    & .text-content {
      width: 70%;
    }
  }
  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;
export const IconContainer = styled.div`
  background-color: ${colors2.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${(props) => (props.index === 0 ? '120px' : '80px')};
  height: ${(props) => (props.index === 0 ? '120px' : '80px')};
  svg {
    width: 50px;
    height: 50px;
  }
  @media ${breakpoints.md} {
    width: ${(props) => (props.index === 0 ? '120px' : '80px')};
    height: ${(props) => (props.index === 0 ? '120px' : '80px')};
  }
  @media ${breakpoints.mds} {
    width: 45px;
    height: 45px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;
export const ReviewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 80px;
`;
export const ReportingWrapper = styled.div`
  display: flex;
  gap: 80px;
  @media ${breakpoints.mds} {
    flex-direction: column;
  }
`;
export const ReportingCard = styled.div`
  display: flex;
  gap: 20px;
  width: 50%;
  @media ${breakpoints.mds} {
    width: 100%;
  }
  & svg {
    max-width: 66px;
    max-height: 66px;
    width: 100%;
    height: 100%;
  }
`;
export const ReportingCardText = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
  padding-top: 20px;
  ${P2Text} {
    color: ${colors2.lightText};
  }
  & ul {
    padding-left: 15px;
    color: ${colors2.lightText};
  }
`;

export const TransparencyWrapper = styled.div`
  width: 100%;
  height: 140px;
  max-width: 810px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DropUs = styled.div`
  max-width: 720px;
  width: 100%;
  margin: auto;

  .email {
    margin-top: 60px;
  }

  & .dropus_block__bottom {
    display: grid;
    grid-template-columns: max-content auto;
    gap: 40px;
  }

  @media ${breakpoints.mds} {
    display: flex;
    flex-direction: column;
    margin-bottom: 140px;
    padding: 0;
    & ${H2Text} {
      font-size: 40px;
      line-height: 130%;
    }
    & ${H4Text} {
      font-weight: 500;
      font-size: 16px;
      line-height: 165%;
    }
  }
  @media ${breakpoints.sm} {
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;
    padding: 0;

    .email {
      margin-top: 30px;
    }
    & ${H2Text} {
      font-size: 20px;
      line-height: 130%;
    }
    & ${H4Text} {
      font-weight: 500;
      font-size: 14px;
      line-height: 165%;
    }
    & .dropus_block__bottom {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;
export const Contact = styled.div`
  display: inline-grid;
  grid-template-columns: 66px 1fr;
  gap: 20px;
  align-items: center;

  a {
    text-decoration: none;
  }

  > :first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors2.lightGray};
    width: 66px;
    height: 66px;
    border-radius: 100%;
  }

  .phones {
    display: flex;
    flex-direction: column;
  }
`;
