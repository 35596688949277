import { groupBy } from './mvpCalcHelper';

export const COST_RATE = 35;
const requiredPositions = ['frontend', 'backend', 'designer'];
export const optionalPositions = ['QA', 'PM'];
const positionsTitlesMap = {
  [requiredPositions[0]]: 'Frontend Engineer',
  [requiredPositions[1]]: 'Backend Engineer',
  [requiredPositions[2]]: 'Designer',
  [optionalPositions[0]]: 'Quality Control Engineer',
  [optionalPositions[1]]: 'Project Manager',
};

export const COST_RATE_BY_TEAM_MEMBERS = {
  frontend: 40,
  backend: 40,
  designer: 35,
  PM: 30,
  QA: 25,
};
/**
 * Returns sum of estimates grouped by positions
 *
 * {
 *  frontend: number;
 *  backend: number;
 *  designer: number;
 * }
 *
 * @param {object[]} reportDataBySteps
 * @returns {object}
 */
function getEstimationsByRequiredPositions(reportDataBySteps) {
  return reportDataBySteps.reduce(
    (result, { rateOfTeam, timeEstimation }) => {
      const adjustedRateOfTeam = rateOfTeam;

      return requiredPositions.reduce((positionResult, position) => {
        const positionEstimation = adjustedRateOfTeam[position] * timeEstimation;
        return {
          ...positionResult,
          [position]: positionResult[position] + positionEstimation,
        };
      }, result);
    },
    {
      [requiredPositions[0]]: 0,
      [requiredPositions[1]]: 0,
      [requiredPositions[2]]: 0,
    }
  );
}

/**
 * Returns project estimation in hours
 *
 * @param {object[]} estimationsByRequiredTeamMembers
 * @returns {number}
 */
function getProjectEstimationInHours(estimationsByRequiredTeamMembers) {
  const estimationsInHoursByRequredMembers = estimationsByRequiredTeamMembers.map(
    ({ estimation }) => estimation || 0
  );

  return Math.ceil(Math.max(...estimationsInHoursByRequredMembers));
}

/**
 * Returns project estimation in weeks
 *
 * @param {object[]} estimationsByMembers
 * @returns {number}
 */
export function getProjectEstimationInWeeks(estimationsByMembers) {
  const workingHoursPerWeek = 40;

  const maxHoursMemberEstimation = Math.max(
    ...estimationsByMembers.map((member) => {
      return member.estimation ? member.estimation : 0;
    })
  );

  return Math.ceil(maxHoursMemberEstimation / workingHoursPerWeek);
}

/**
 * Returns default estimations objects by team members
 *
 * [{
 *  id: string;
 *  position: string;
 *  title: string;
 *  estimation: number | undefined;
 *  canBeRemoved: boolean;
 *  canBeAdded: boolean;
 * }]
 *
 * @param {object[]} reportDataBySteps
 * @returns {object[]}
 */
export function getDesiredDevelopmentTime(reportDataBySteps) {
  const desiredTime = reportDataBySteps[2].itemsValues[0];
  switch (desiredTime) {
    case 'specifyDate:month':
      return 160;

    case 'specifyDate:2-3':
      return 480;

    case 'specifyDate:4-6':
      return 960;
    default:
      return 1600;
  }
}
export function getDefaultEstimationsByTeamMembers(reportDataBySteps) {
  const estimationsByRequiredPositions = getEstimationsByRequiredPositions(reportDataBySteps);

  const desiredDevelopmentTime = getDesiredDevelopmentTime(reportDataBySteps);

  const estimationsByRequiredTeamMembers = requiredPositions.reduce((result, position) => {
    const positionEstimation = Math.ceil(estimationsByRequiredPositions[position]);
    const positionCost = Math.ceil(
      estimationsByRequiredPositions[position] * COST_RATE_BY_TEAM_MEMBERS[position]
    );

    if (positionEstimation <= desiredDevelopmentTime) {
      return [
        ...result,
        {
          id: position,
          position,
          title: positionsTitlesMap[position],
          estimation: positionEstimation,
          cost: positionCost,
          canBeRemoved: false,
          canBeAdded: false,
        },
      ];
    }

    const amountOfMembersByPosition = Math.ceil(positionEstimation / desiredDevelopmentTime);
    const memberEstimation = Math.ceil(positionEstimation / amountOfMembersByPosition);
    const estimationsByPositionMembers = Array(amountOfMembersByPosition)
      .fill()
      .map((item, index) => ({
        id: `${position}_${index + 1}`,
        position,
        title: `${positionsTitlesMap[position]} ${index + 1}`,
        estimation: memberEstimation,
        canBeRemoved: !!index,
        canBeAdded: false,
      }));

    return [...result, ...estimationsByPositionMembers];
  }, []);

  const projectEstimationInHours = getProjectEstimationInHours(estimationsByRequiredTeamMembers);

  return [
    ...estimationsByRequiredTeamMembers,
    {
      id: optionalPositions[0],
      position: optionalPositions[0],
      title: positionsTitlesMap[optionalPositions[0]],
      estimation: Math.ceil(projectEstimationInHours * 0.2),
      cost:
        Math.ceil(projectEstimationInHours * 0.2) * COST_RATE_BY_TEAM_MEMBERS[optionalPositions[0]],
      canBeRemoved: true,
      canBeAdded: false,
    },
    {
      id: optionalPositions[1],
      position: optionalPositions[1],
      title: positionsTitlesMap[optionalPositions[1]],
      estimation: Math.ceil(projectEstimationInHours * 0.2),
      cost:
        Math.ceil(projectEstimationInHours * 0.2) * COST_RATE_BY_TEAM_MEMBERS[optionalPositions[1]],
      canBeRemoved: true,
      canBeAdded: false,
    },
  ];
}

export function getTechSolutionBasedOnCloudProvider(chosenCloudProvider, el) {
  switch (chosenCloudProvider) {
    case 'cloudInfrastructureProvider:aws':
      return (
        el.component !== 'Azure' &&
        el.component !== 'GCP' &&
        el.component !== 'Email service' &&
        el.component !== 'Storage'
      );
    case 'cloudInfrastructureProvider:msAzure':
      return (
        el.component !== 'AWS' &&
        el.component !== 'GCP' &&
        el.component !== 'Email service' &&
        el.component !== 'Storage'
      );
    case 'cloudInfrastructureProvider:googleCloudPlatform':
      return (
        el.component !== 'AWS' &&
        el.component !== 'Azure' &&
        el.component !== 'Email service' &&
        el.component !== 'Storage'
      );
    default:
      return el.component !== 'AWS' && el.component !== 'Azure' && el.component !== 'GCP';
  }
}
export function getFilteredTechSolutionData(reportDataBySteps) {
  const filteredTechnicalSolution = reportDataBySteps.filter((el) => {
    return !(el.technicalSolution.length === 1 && el.technicalSolution[0] === null);
  });

  const usersAndAccountsIndex = reportDataBySteps.findIndex(
    (el) => el.value === 'usersAndAccounts'
  );
  const isSocialLogin = reportDataBySteps[usersAndAccountsIndex]?.itemsValues.includes(
    'usersAndAccounts:socialLogin'
  );
  const cloudProviderStepIndex = reportDataBySteps.findIndex(
    (el) => el.value === 'cloudInfrastructureProvider'
  );

  const chosenCloudProvider = reportDataBySteps[cloudProviderStepIndex].itemsValues[0];

  const technicalSolutions = filteredTechnicalSolution
    .reduce((acc, el) => [...acc, el.technicalSolution.flat()], [])
    .flat()
    .filter((el) => {
      if (
        !isSocialLogin &&
        [
          'cloudInfrastructureProvider:aws',
          'cloudInfrastructureProvider:msAzure',
          'cloudInfrastructureProvider:googleCloudPlatform',
        ].some((el) => el === chosenCloudProvider)
      ) {
        return (
          el !== null &&
          getTechSolutionBasedOnCloudProvider(chosenCloudProvider, el) &&
          el.component !== 'Identity Provider Service'
        );
      }
      return el !== null && getTechSolutionBasedOnCloudProvider(chosenCloudProvider, el);
    });

  return groupBy(technicalSolutions, 'component');
}

/**
 * Update estimations by team members for recalculate estimations
 *
 * @param {object[]} estimationsByTeamMembers
 * @param {object[]} reportDataBySteps
 * @returns {object[]}
 */
export function updateEstimationsByTeamMembers(estimationsByTeamMembers, reportDataBySteps) {
  const estimationsByRequiredPositions = getEstimationsByRequiredPositions(reportDataBySteps);
  return estimationsByTeamMembers.map((estimationData) => {
    const isRequiredPosition = requiredPositions.includes(estimationData.position);

    if (!isRequiredPosition) {
      return estimationData;
    }

    if (estimationData.canBeAdded) {
      return {
        ...estimationData,
        estimation: undefined,
      };
    }

    const amountOfIncludedMembersByPosition = estimationsByTeamMembers.filter(
      (item) => item.position === estimationData.position && !item.canBeAdded
    ).length;
    const positionEstimation = estimationsByRequiredPositions[estimationData.position];
    const memberEstimation = Math.ceil(positionEstimation / amountOfIncludedMembersByPosition);

    return {
      ...estimationData,
      estimation: memberEstimation,
    };
  });
}
export function getTotalCost(costsByStepsData) {
  return costsByStepsData.reduce((result, step) => {
    return result + step.stepCost;
  }, 0);
}
