import AnalyticsIcon from './analytics.svg';
import BrandRecognitionIcon from './brandRecognition.svg';
import FeedbackIcon from './feedback.svg';
import IncreaseIcon from './increase.svg';
import NotificationIcon from './notification.svg';
import CheckMediumIcon from './checkMedium.svg';

export {
  AnalyticsIcon,
  BrandRecognitionIcon,
  FeedbackIcon,
  IncreaseIcon,
  NotificationIcon,
  CheckMediumIcon,
};
