import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import fonts from '../../../../utils/theme/fonts';
import { MOBILE_VIEW_BREAKPOINT } from '../../constants';
import { web3Colors } from '../../styled';
import Table from './Table';
import { CrossedPriceBlock } from './Table/components';
import { calculateTotal } from '../helpers';

const Container = styled.div`
  margin-top: 30px;
  color: white;
`;

const Title = styled.h5`
  font-family: ${fonts.euclidCircularA};
  font-weight: 700;
  font-size: 35px;
  line-height: 130%;
  color: white;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 18px;
    margin-bottom: 12px;
    text-align: start;
  }
`;

const Description = styled.h5`
  font-family: ${fonts.poppins};
  font-size: 18px;
  line-height: 160%;
  color: ${web3Colors.grey};
  text-align: center;
  margin-bottom: 40px;
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: start;
  }
`;

const Total = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  font-family: ${fonts.euclidCircularA};
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  padding: 20px 15px 0 15px;
  margin-top: 180px;
  border-top: 1px dashed #2c2741;
  & > div span {
    font-size: 18px;
  }
  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    padding: 20px 0 0 0;
    flex-direction: column;
    font-size: 13px;
    margin-bottom: 12px;
    margin-top: 70px;
    & > div span {
      font-size: 16px;
    }
  }
`;

const PriceBlock = ({ selected, setSelected, eurCurrency, setEurCurrency }) => {
  const total = calculateTotal(selected, eurCurrency);

  return (
    <Container>
      <Title>Availability, Number of devs & Pricing</Title>
      <Description>Ramp up the pace or reduce the burn rate</Description>
      <Table selected={selected} setSelected={setSelected} eurCurrency={eurCurrency} />
      <Total>
        <span>Total</span>
        <CrossedPriceBlock
          crossedPrice={total.totalCrossedPrice}
          actualPrice={total.totalActualPrice}
          eurCurrency={eurCurrency}
          setEurCurrency={setEurCurrency}
          isTotalPrice={true}
        />
      </Total>
    </Container>
  );
};

export default PriceBlock;
