import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import BlogLayout from '../components/BlogLayout';
import BlogCard from '../components/BlogCard';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import { Main } from './Blog';

const BlogCategoryTemplate = ({
  location,
  pageContext: { limit },
  data: {
    categoryPageInfo: {
      fields: { slug },
      frontmatter: { seoTitle, description, keywords, title },
    },
    blogs: { edges: blogs },
    allBlogs: { edges: allBlogs },
    site: {
      siteMetadata: { siteUrl, titleTemplateBlog, contacts },
    },
  },
}) => {
  const { search } = location;
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('page')) {
      setCurrentPage(+searchParams.get('page'));
    } else {
      setCurrentPage(1);
    }
  }, [search, setCurrentPage]);

  const pages = useMemo(() => Math.ceil(blogs.length / limit), [blogs.length, limit]);

  return (
    <>
      <SEO
        titleTemplate={titleTemplateBlog}
        title={seoTitle}
        keywords={keywords}
        url={`${siteUrl}${slug}`}
        description={description}
      />
      <BlogLayout
        title={title}
        socialTitle={title}
        allBlogs={allBlogs}
        contacts={contacts}
        posts={blogs}
        slug={`${siteUrl}${slug}`}
        location={location}
        siteUrl={siteUrl}
        description={description}
      >
        <Main>
          {blogs.slice((currentPage - 1) * limit, limit * currentPage).map((data) => (
            <BlogCard className="box" key={data.node.id} data={data} />
          ))}
        </Main>
        <Pagination
          page={currentPage}
          nextButtonClick={() => {
            navigate(currentPage === 2 ? `/blog` : `/blog?page=${currentPage - 1}`);
          }}
          previousButtonClick={() => {
            navigate(`/blog?page=${+currentPage + 1}`);
          }}
          onPageChange={(page) => {
            navigate(`/blog?page=${page}`);
          }}
          countPage={pages}
        />
      </BlogLayout>
    </>
  );
};

BlogCategoryTemplate.propTypes = {
  pageContext: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape().isRequired,
};

export default BlogCategoryTemplate;

export const query = graphql`
  query blogCategory($categoryTitle: String) {
    blogs: allMarkdownRemark(
      filter: {
        frontmatter: { collection_name: { eq: "blog-posts" }, category: { eq: $categoryTitle } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            category
            postImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            altPostImageText
            date
            title
            introtext
            author
            cardBackgroundImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            color
            colorFont
          }
        }
      }
    }
    allBlogs: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            category
          }
        }
      }
    }
    categoryPageInfo: markdownRemark(
      frontmatter: { collection_name: { eq: "blog-categories" }, title: { eq: $categoryTitle } }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
        seoTitle
        description
        keywords
      }
    }
    site {
      siteMetadata {
        siteUrl
        contacts {
          socialLinks {
            icon
            url
          }
          email {
            url
            label
          }
          phones {
            url
            label
          }
        }
      }
    }
  }
`;
