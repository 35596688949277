/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import { breakpoints } from '../utils/theme/theme2/breakpoints';
import colors2 from '../utils/theme/theme2/colors2';
import SEO from '../components/SEO';

import Header from '../components/Header';

import { PageWrapper, PageContainer } from '../components/homePage/styled';
import { DefaultButton } from '../components/Buttons/styled';

const PolicyPageContent = styled(PageContainer)`
  padding: 0 80px 0;
  max-width: 1280px;
  width: 100%;
  gap: 60px;
  .top-page-section-wrapper {
    width: 100%;
  }
  @media ${breakpoints.mds} {
    padding: 0;
  }
`;

const WrapperContent = styled.div`
  display: flex;
  margin: 0px 0px 80px;
  width: 80%;
  color: ${colors2.black};
  flex-direction: column;
  h1 {
    font-weight: 500;
    font-size: 66px;
    line-height: 80px;
    margin-bottom: 80px;
  }
  h2 {
    font-weight: 500;
    font-size: 50px;
    line-height: 130%;
    margin: 20px 0 10px;
  }
  h3 {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    margin: 20px 0 10px;
  }
  p,
  li {
    color: ${colors2.lightText};
    font-weight: 400;
    font-size: 22px;
    line-height: 165%;
    margin: 15px 0;
  }
  ol {
    list-style: none;
    counter-reset: point-counter;
  }
  ol li {
    counter-increment: point-counter 1;
    display: flex;
    align-items: baseline;
  }
  ol li::before {
    content: counter(point-counter) '. ';
    font-weight: bold;
    margin-right: 10px;
  }
  strong {
    font-weight: 500;
    color: ${colors2.black};
  }
  ul {
    margin-left: 15px;
  }
  li,
  li p {
    margin: 0;
  }
  a {
    color: ${colors2.blue};
    text-decoration: none;
  }
  @media ${breakpoints.mds} {
    margin: 100px 0px;
    width: 100%;
    h1 {
      margin-bottom: 60px;
      font-size: 50px;
      line-height: 64px;
    }
    h2 {
      font-size: 40px;
      line-height: 52px;
    }
    h3 {
      font-size: 24px;
      line-height: 36px;
    }
    p,
    li {
      font-size: 18px;
      line-height: 30px;
    }
  }
  @media ${breakpoints.sm} {
    margin: 60px 0px 40px;
    width: 100%;
    h1 {
      margin-bottom: 40px;
      font-size: 30px;
      line-height: 40px;
    }
    h2 {
      font-size: 26px;
      line-height: 36px;
    }

    p,
    li {
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

const PolicyPage = ({
  data: {
    pageInfo: { html },
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
    policyPageInfo: {
      frontmatter: { seoTitle, description, keywords },
    },
  },
}) => (
  <PageWrapper>
    <SEO
      titleTemplate={titleTemplate}
      title={seoTitle}
      keywords={keywords}
      url={`${siteUrl}/privacy-policy`}
      description={description}
    />

    <Header />
    <PolicyPageContent>
      <WrapperContent dangerouslySetInnerHTML={{ __html: html }} />
      <DefaultButton style={{ maxWidth: '520px' }} onClick={() => navigate('/')}>
        Back home
      </DefaultButton>
    </PolicyPageContent>
  </PageWrapper>
);

export const query = graphql`
  query page($id: String) {
    pageInfo: markdownRemark(id: { eq: $id }) {
      id
      html
    }
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    policyPageInfo: markdownRemark(frontmatter: { name: { eq: "privacy policy" } }) {
      frontmatter {
        seoTitle
        description
        keywords
      }
    }
  }
`;

PolicyPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default PolicyPage;
