import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  Container,
  ReviewsWrapper,
  ReportingWrapper,
  ReportingCard,
  ReportingCardText,
  TransparencyWrapper,
} from './styled';
import Reviews from '../homePage/Reviews';
import OurTechnologies from './OurTechnologies';
import { PageContent } from '../homePage/styled';

import WhoWeAre from './WhoWeAre';
import OurTeam from './OurTeam';
import OurTeamVision from './OurTeamVision';
import OurServices from './OurServices';
import SalesforceCloudIcon from '../../assets/icons/updateDesign/aboutUs/salesforceCloud.svg';
import IntegrationIcon from '../../assets/icons/updateDesign/aboutUs/integration.svg';
import { H2Text, H3Text, P2Text } from '../Typography/styled';
import HowWeWork from './HowWeWork';
import ReportingIcon from '../../assets/icons/updateDesign/aboutUs/reportingIcon.svg';
import CommunicationIcon from '../../assets/icons/updateDesign/aboutUs/communicationIcon.svg';

import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ContactUsForm from '../ContactUsForm';

const ourServicesData = [
  ['Web development', 'UX/UI design', <IntegrationIcon />],
  ['Mobile app development', 'MVP building for fixed price'],
  ['QA & testing', <SalesforceCloudIcon />, 'Project rescue'],
  ['Mobile app support', 'Staff augmentation'],
];
const customDomainsData = [
  [<IntegrationIcon />, 'Machine Learning', 'Computer Vision'],
  ['Collaboration and Management', 'Knowledge Management System'],
  ['eLearning & Online Training', 'Healthcare', 'Finance and Banking'],
  ['Uber-like Apps', 'Entertainment', <SalesforceCloudIcon />, 'Social Networking'],
];

const AboutUsPage = () => {
  const { pencil, ourTeamImg, ourVisionImg, ourMissionImg, transparencyBg } = useStaticQuery(query);

  return (
    <Container>
      <PageContent>
        <div className="top-page-section">
          <TopPageSectionWrapper title="About us" />
        </div>

        <WhoWeAre />
        <OurTeam image={ourTeamImg} />
        <OurTeamVision images={{ ourVisionImg, ourMissionImg }} />
        <OurServices key="0" data={ourServicesData} title="Our Services" />

        <OurTechnologies />
        <OurServices key="1" data={customDomainsData} title="Custom Domains" />
        <ReviewsWrapper>
          <H2Text>Testimonials</H2Text>
          <Reviews />
        </ReviewsWrapper>
        <HowWeWork image={pencil} />
        <ReportingWrapper>
          <ReportingCard>
            <ReportingIcon />
            <ReportingCardText>
              <H3Text>Reporting</H3Text>
              <div>
                <ul>
                  <li>
                    <P2Text>We do daily/weekly project status (summary of progress)</P2Text>
                  </li>
                  <li>
                    <P2Text>We do milestone achievement report</P2Text>
                  </li>
                </ul>
                <P2Text>You can determine reporting scheme you need.</P2Text>
              </div>
            </ReportingCardText>
          </ReportingCard>
          <ReportingCard>
            <CommunicationIcon />
            <ReportingCardText>
              <H3Text>Communication</H3Text>
              <P2Text>
                Basic principle: respond to clients immediately. If you don't know what to say -
                just let them know when you can get back.Regular communications include daily and
                weekly project status conference calls and reports.
              </P2Text>
            </ReportingCardText>
          </ReportingCard>
        </ReportingWrapper>
        <TransparencyWrapper>
          <GatsbyImage
            style={{ position: 'absolute', zIndex: '0' }}
            image={transparencyBg.childImageSharp.gatsbyImageData}
            alt=""
          />
          <H2Text style={{ zIndex: '2', textAlign: 'center' }}>
            Transparency and collaboration with our clients is the key
          </H2Text>
        </TransparencyWrapper>

        <ContactUsForm />
      </PageContent>
    </Container>
  );
};

export const query = graphql`
  {
    ourTeamImg: file(relativePath: { eq: "images/updateDesign/aboutUs/ourTeam.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ourVisionImg: file(relativePath: { eq: "images/updateDesign/aboutUs/ourVision.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ourMissionImg: file(relativePath: { eq: "images/updateDesign/aboutUs/ourMission.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    pencil: file(relativePath: { eq: "images/updateDesign/aboutUs/pencil.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    transparencyBg: file(relativePath: { eq: "images/updateDesign/aboutUs/transparencyBg.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default AboutUsPage;
