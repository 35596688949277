import styled from 'styled-components';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  .submit {
    max-width: 217px;
    width: 100%;
  }

  @media ${breakpoints.sm} {
    flex-direction: column;
    button {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: auto;
      }
    }
  }
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 190px)`};
  width: fit-content;
  gap: 20px;
  justify-content: center;

  @media (max-width: ${({ columns }) => (columns === 5 ? '1190px' : '980px')}) {
    gap: 15px;
    grid-template-columns: repeat(3, 190px);
  }

  @media (max-width: 664px) {
    grid-template-columns: repeat(2, 190px);
  }

  @media (max-width: 442px) {
    grid-template-columns: repeat(2, 140px);
  }
`;
export const MVPCalculatorStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 210px;
  @media ${breakpoints.mds} {
    margin-top: 180px;
  }
  @media ${breakpoints.sm} {
    margin-top: 125px;
  }
`;
