import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import FormSection from './FormSection';
import { PageContainer, PageContent } from '../homePage/styled';
import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import MessageCard from '../Card/MessageCard';
import LetsBanner from './LetsBanner';
import Technologies from '../homePage/Technologies';
import Partners from '../homePage/Partners';

const ClutchGlobalLeaderPage = () => {
  const { message } = useStaticQuery(query);
  return (
    <PageContainer>
      <PageContent>
        <FormSection />
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <MessageCard
          image={message}
          title="Ready to start your custom app development?"
          subtitile="Contact us today to schedule a consultation and get a project estimate."
          button="Contact us"
          style={{ margin: '0' }}
        />
        <Partners />
        <Technologies />
        <LetsBanner />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default ClutchGlobalLeaderPage;
