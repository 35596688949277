import React from 'react';
import styled from 'styled-components';
import { H2Text, H3Text } from '../Typography/styled';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${H2Text} {
    margin-bottom: 80px;
  }
  @media ${breakpoints.md} {
    ${H2Text} {
      margin-bottom: 30px;
    }
  }
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
  > span {
    border: 1px solid rgba(38, 38, 38, 0.12);
    border-radius: 60px;
    padding: 20px 40px;
    margin-right: 20px;
  }
  > svg {
    margin-right: 20px;
  }
  > last-child {
    margin-right: 0;
  }

  @media ${breakpoints.md} {
    flex-wrap: wrap;
    gap: 20px;
    > span {
      padding: 10px 25px;
      margin-right: 0px;
    }
  }
`;

const OurServices = ({ data, title }) => {
  return (
    <Wrapper>
      <H2Text>{title}</H2Text>
      {data.map((el, index) => {
        return (
          <Tag key={index}>
            {el.map((item, idx) =>
              typeof item === 'string' ? <H3Text key={idx}>{item}</H3Text> : item
            )}
          </Tag>
        );
      })}
    </Wrapper>
  );
};

export default OurServices;
