/**
 * Returns cost of size step
 *
 * @param {string[]} choosedPlatforms
 * @param {object[]} sizeItems
 * @param {string} itemValue
 * @returns {number}
 */
export function getSizeCost(choosedPlatforms, sizeItems, itemValue) {
  const sizeItem = sizeItems.find((item) => item.value === itemValue);
  return choosedPlatforms.reduce((result, platform) => result + sizeItem.cost[platform], 0);
}
export function getSizeTimeEstimation(choosedPlatforms, sizeItems, itemValue) {
  const sizeItem = sizeItems.find((item) => item.value === itemValue);

  return choosedPlatforms.reduce((result, platform) => {
    return result + sizeItem.estimation[platform];
  }, 0);
}
