import React from 'react';

import styled from 'styled-components';
import TestingIcon from '../../assets/icons/updateDesign/dedicated/testing.svg';
import TestingScrollIcon from '../../assets/icons/updateDesign/dedicated/testing-hover.svg';
import UxScrollIcon from '../../assets/icons/updateDesign/development/ux-hover.svg';
import UxIcon from '../../assets/icons/updateDesign/development/ux.svg';
import DevelopmentIcon from '../../assets/icons/updateDesign/development/development.svg';
import DevelopmentScrollIcon from '../../assets/icons/updateDesign/development/development-hover.svg';
import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import ProcessComponent from '../ProcessComponent';

export const Wrapper = styled.div`
  @media ${breakpoints.sm} {
    padding: 0 30px;
    .list-with-big-icons_wrapper {
      gap: 30px;
      .step {
        flex-direction: column;
        margin-bottom: 0px;
        gap: 30px;
      }
      .number {
        margin-top: 0px;
        margin-right: 0px;
      }
    }
  }
`;
const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'Analysis',
    text: 'We delve into your business goals, product vision, and ideas. We’ll identify the target audience and analyze competitors to create a unique branding style and beautiful user interface.',
  },
  {
    number: 'step 2',
    icon: <DevelopmentIcon />,
    onScrollIcon: <DevelopmentScrollIcon />,
    title: 'Prototyping',
    text: 'In 1–3 weeks, we make a dynamic prototype and discuss it with you. Then, we will introduce the required changes according to your comments. As a result, you’ll faster get the app that meets expectations.',
  },
  {
    number: 'step 3',
    icon: <UxIcon />,
    onScrollIcon: <UxScrollIcon />,
    title: 'UI/UX design',
    text: 'We develop a modern appealing design, providing intuitive navigation, usability, and a seamless user experience. Following the latest UI/UX trends and best practices, we build solutions that stand out from the crowd.',
  },
  {
    number: 'step 4',
    icon: <TestingIcon />,
    onScrollIcon: <TestingScrollIcon />,
    title: 'Testing',
    text: 'Our team performs user testing to collect the audience’s feedback, measure reaction, and find out how to improve product design. Finally, you receive a software solution customers do love and want.',
  },
];

const ProcessSection = () => {
  return (
    <Wrapper>
      <ProcessComponent title="Our UI/UX design process " data={work} />
    </Wrapper>
  );
};

export default ProcessSection;
