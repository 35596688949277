import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO';
import slugify from '../utils/slugify';
import Image from '../components/Image';
import Slider from '../components/Slider';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageContainer, PageWrapper } from '../components/homePage/styled';
import { H2Text, P2Text, P3Text } from '../components/Typography/styled';
import StarIcon from '../assets/icons/updateDesign/star.svg';
import colors2 from '../utils/theme/theme2/colors2';
import { breakpoints } from '../utils/theme/theme2/breakpoints';
import { TitleBlock } from './BlogPost';
import { MarkdownBlock } from './styled';
import Technologies, { getTechnologiesByTechStack } from '../components/homePage/Technologies';

const OurPortfolioPageContent = styled(PageContainer)`
  padding: 0 80px 0;
  max-width: 1280px;
  width: 100%;
  gap: 60px;
  .top-page-section-wrapper {
    width: 100%;
  }
  @media ${breakpoints.mds} {
    padding: 0;
  }
`;
const PortfolioItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;
const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: ${colors2.lightGray};
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PortfolioItemBlockWithMarkdown = ({ title, content, ...props }) => (
  <PortfolioItemBlock {...props}>
    <PortfolioItemBlockTitle text={title} />
    <MarkdownBlock content={content} />
  </PortfolioItemBlock>
);
const PortfolioItemBlock = styled.div`
  background-color: ${(props) => (props.background ? props.background : '')};
  display: flex;
  flex-direction: column;
  gap: 30px;
  ${P2Text} {
    color: ${colors2.lightText};
  }
  &.solution-block {
    border-radius: 40px;
    padding: 30px;
    span {
      color: ${(props) => (props.color && props.color === 'white' ? props.color : '')};
    }
  }
  & > ${MarkdownBlock} {
    & > p,
    & > ul {
      color: ${(props) =>
        props.color && props.color === 'white' ? 'rgba(255, 255, 255, 0.85)' : colors2.lightText};
    }
  }
`;

const PortfolioItemBlockTitle = styled(({ text, className }) => (
  <div className={className}>
    <IconWrapper>
      <StarIcon />
    </IconWrapper>
    <H2Text>{text}</H2Text>
  </div>
))`
  display: flex;
  gap: 30px;
  align-items: center;
  width: 100%;
`;

const TechStackContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 90px 0;
  justify-content: center;
  max-width: 1120px;
`;

const OurPortfolioTemplate = ({
  data: {
    site: {
      siteMetadata: { siteUrl },
    },
    project: {
      frontmatter: {
        title,
        challenge,
        techStack,
        solution,
        results,
        metaDescription,
        keywords,
        color,
        colorFont,
        sliderImages,
      },
    },
  },
}) => {
  return (
    <PageWrapper>
      <SEO
        title={title}
        keywords={keywords}
        url={`${siteUrl}/our-work/${slugify(title)}`}
        description={metaDescription}
      />
      <Header />

      <OurPortfolioPageContent>
        <TitleBlock>
          <H2Text>{title}</H2Text>
          <P3Text color={colors2.lightText}>{metaDescription}</P3Text>
        </TitleBlock>

        {sliderImages && (
          <Slider
            paging
            autoplay
            slidesCount={sliderImages && sliderImages.length}
            slidesToShowDesktop={1}
            slidesToScrollDesktop={1}
            slidesToShowTablet={1}
            slidesToScrollTablet={1}
            slidesToShowMobile={1}
            slidesToScrollMobile={1}
            pagingAnimatedLineColor={colors2.black}
            prevArrowStyles="
                        top: 50%!important;
                        left: -55px!important;
                        fill: #1E242F;
                      "
            nextArrowStyles="
                        top: 50%!important;
                        right: -55px!important;
                        fill: #1E242F;
                      "
          >
            {sliderImages.map((image, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Image key={index} image={image} alt="image" />
            ))}
          </Slider>
        )}

        <PortfolioItem>
          {results && <PortfolioItemBlockWithMarkdown title="Business Result" content={results} />}
          {techStack && (
            <PortfolioItemBlock>
              <PortfolioItemBlockTitle text="Tech Stack" />
              <TechStackContainer>
                <Technologies techs={getTechnologiesByTechStack(techStack)} />
              </TechStackContainer>
            </PortfolioItemBlock>
          )}
          {challenge && <PortfolioItemBlockWithMarkdown title="Challenge" content={challenge} />}
          {solution && (
            <PortfolioItemBlockWithMarkdown
              title="Solution"
              content={solution}
              className="solution-block"
              background={color}
              color={colorFont}
            />
          )}
        </PortfolioItem>
      </OurPortfolioPageContent>

      <Footer />
    </PageWrapper>
  );
};

OurPortfolioTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
};

export const query = graphql`
  query project($id: String) {
    project: markdownRemark(id: { eq: $id }) {
      id
      fileAbsolutePath
      frontmatter {
        title
        subtitle
        metaDescription
        keywords
        challenge
        techStack
        solution
        results
        client
        teamComposition
        duration
        keyFeatures
        outcome
        clientAboutUs
        color
        colorFont
        sliderImages {
          id
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
    partnersBgImage: file(relativePath: { eq: "images/partnersBgImage.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default OurPortfolioTemplate;
