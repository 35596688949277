import React from 'react';

import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import ServiceListBlock from '../ServiceList';

const challenges = [
  {
    icon: <LogoIcon />,
    title: 'Time-consuming processes',
    text: 'Automate every HRM activity, from vacancy posting and candidate scoring and to job satisfaction survey. Boost the productivity of human resource managers and cut down operating expenses.',
  },
  {
    icon: <LogoIcon />,
    title: 'Disconnected systems',
    text: 'We’ll integrate a custom HRM system with your existing software, such as a learning management platform and CRM application. As a result, you will create a seamless workflow and increase company efficiency.',
  },
  {
    icon: <LogoIcon />,
    title: 'Inefficient recruiting and onboarding',
    text: 'Score and segment candidates efficiently, manage numerous resumes with ease, onboard new employees promptly. Set up automated notifications on interviews, meetings, and employee birthdays.',
  },
  {
    icon: <LogoIcon />,
    title: 'Paper-based workflows',
    text: 'Minimize paperwork and increase productivity with features like e-signatures, online interview scheduling,  video/audio conferencing, digital reporting,  and online polls. Visualize candidate and employee data on interactive dashboards. ',
  },
  {
    icon: <LogoIcon />,
    title: 'Lack of HR data visibility',
    text: 'Centralize data like candidate/staff information and surveys in one place. Get a unified view of HRM data and make faster decisions. We’ll enforce security with two-factor authentication and role-based access control.',
  },
  {
    icon: <LogoIcon />,
    title: 'Regulatory compliance',
    text: 'Achieve compliance with the necessary standards and regulations such as GDPR. Our HR software development team will build the system storing documents according to federal/state labor law guidelines. ',
  },
];

const HrSoftwareChallenges = () => {
  return (
    <ServiceListBlock
      title="Solve your challenges with HR software"
      iconColor={colors2.blue}
      list={challenges}
      bgColor={colors2.lightBlue}
    />
  );
};

export default HrSoftwareChallenges;
