import React from 'react';

import styled from 'styled-components';

import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import ProcessComponent from '../ProcessComponent';

import IntegrationIcon from '../../assets/icons/updateDesign/salesforce/integration.svg';
import IntegrationScrollIcon from '../../assets/icons/updateDesign/salesforce/integration-hover.svg';
import UxScrollIcon from '../../assets/icons/updateDesign/development/ux-hover.svg';
import UxIcon from '../../assets/icons/updateDesign/development/ux.svg';
import DevelopmentIcon from '../../assets/icons/updateDesign/development/development.svg';
import DevelopmentScrollIcon from '../../assets/icons/updateDesign/development/development-hover.svg';
import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import LaunchIcon from '../../assets/icons/updateDesign/salesforce/launch.svg';
import LaunchScrollIcon from '../../assets/icons/updateDesign/salesforce/launch-hover.svg';

export const Wrapper = styled.div`
  @media ${breakpoints.sm} {
    padding: 0 30px;
    .list-with-big-icons_wrapper {
      gap: 30px;
      .step {
        flex-direction: column;
        margin-bottom: 0px;
        gap: 30px;
      }
      .number {
        margin-top: 0px;
        margin-right: 0px;
      }
    }
  }
`;
const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'MVP roadmap ',
    text: 'We prepare an MVP development roadmap that includes a list of project stages, tasks, priorities, and due dates. As a deliverable, you receive the documented feature scope, buyer personas, and cost estimate.',
  },
  {
    number: 'step 2',
    icon: <DevelopmentIcon />,
    onScrollIcon: <DevelopmentScrollIcon />,
    title: 'Proof of concept',
    text: 'Our team builds a PoC to validate the product idea prior to development. We’ll also help you evaluate different architectural styles and technologies to find out how to deliver the best outcome possible.',
  },
  {
    number: 'step 3',
    icon: <UxIcon />,
    onScrollIcon: <UxScrollIcon />,
    title: 'Prototype',
    text: 'We create a dynamic prototype in 2–3 weeks and conduct user testing to collect the audience’s feedback. Then, we introduce the required changes, providing the result that aligns with customer preferences.',
  },
  {
    number: 'step 4',
    icon: <IntegrationIcon />,
    onScrollIcon: <IntegrationScrollIcon />,
    title: 'MVP',
    text: 'By following Agile best practices, our software engineers implement new functionality every 1–3 weeks. Demonstrating intermediate results to you, we make the necessary adjustments on the go. ',
  },
  {
    number: 'step 5',
    icon: <LaunchIcon />,
    onScrollIcon: <LaunchScrollIcon />,
    title: 'Launch',
    text: 'We release the MVP, measure user reaction, and learn what to improve. Delivering new features and updates according to customer feedback, we help you fill a market niche and generate revenue.',
  },
];

const ProcessSection = () => {
  return (
    <Wrapper>
      <ProcessComponent title="Our MVP development process" data={work} />
    </Wrapper>
  );
};

export default ProcessSection;
