/**
 * Returns cost of platform step
 *
 * @param {string[]} choosedPlatforms
 * @param {object[]} platformItems
 * @returns {number}
 */

export function getPlatformTimeEstimation(choosedPlatforms, platformItems) {
  return choosedPlatforms.reduce((result, platform) => {
    const { estimation } = platformItems.find(({ value }) => value === platform);
    return result + estimation;
  }, 0);
}
