import styled from 'styled-components';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import colors2 from '../../utils/theme/theme2/colors2';
import { P2Text } from '../Typography/styled';

export const MVPCalculatorMainContainer = styled.div`
  display: flex;
  gap: 100px;

  padding: 0 80px;
  max-width: 1280px;
  margin-top: 210px;
  width: 100%;
  @media ${breakpoints.mds} {
    padding: 0 40px;
    margin-top: 180px;
    flex-direction: column;
    gap: 40px;

    button {
      display: none;
    }
  }

  @media ${breakpoints.sm} {
    padding: 0 16px;
    margin-top: 125px;
    gap: 30px;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  & button {
    margin-top: 10px;
  }

  @media ${breakpoints.mds} {
    width: 100%;
  }
`;

export const ImageBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  background-color: ${colors2.lightViolet};
  padding: 50px 50px 0 50px;
  gap: 20px;
  & ul {
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  & li {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: auto;
    }
  }
  ${P2Text} {
    color: ${colors2.lightText};
  }
  & .mvp-calculator_image {
    right: -50px;
  }

  svg {
    min-width: 15px;
  }

  button {
    display: none;
    @media ${breakpoints.mds} {
      display: block;
      max-width: 217px;
    }
    @media ${breakpoints.sm} {
      max-width: none;
    }
  }

  @media ${breakpoints.mds} {
    padding: 40px 40px 0 40px;
    width: 100%;
    & .mvp-calculator_image {
      width: 290px !important;
      height: 150px !important;
      right: -20%;
    }
  }
  @media ${breakpoints.sm} {
    padding: 30px 30px 0 30px;
    & .mvp-calculator_image {
      width: 200px !important;
      height: 100px !important;
    }
  }
`;
