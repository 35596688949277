import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';

import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import MessageCard from '../Card/MessageCard';

import colors2 from '../../utils/theme/theme2/colors2';

import AssembleIcon from '../../assets/icons/updateDesign/dedicated/assemble.svg';
import AssembleScrollIcon from '../../assets/icons/updateDesign/dedicated/assemble-hover.svg';
import TestingIcon from '../../assets/icons/updateDesign/dedicated/testing.svg';
import TestingScrollIcon from '../../assets/icons/updateDesign/dedicated/testing-hover.svg';
import UxScrollIcon from '../../assets/icons/updateDesign/development/ux-hover.svg';
import UxIcon from '../../assets/icons/updateDesign/development/ux.svg';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import SupportIcon from '../../assets/icons/updateDesign/dedicated/support.svg';
import SupportScrollIcon from '../../assets/icons/updateDesign/dedicated/support-hover.svg';
import ProcessComponent from '../ProcessComponent';

import HealthcareIcon from '../../assets/icons/updateDesign/development/healthcare.svg';
import EcommerceIcon from '../../assets/icons/updateDesign/development/ecommerce.svg';
import HRIcon from '../../assets/icons/updateDesign/development/hr.svg';
import FintechIcon from '../../assets/icons/updateDesign/development/fintech.svg';

import ArrowUpIcon from '../../assets/icons/updateDesign/ecommerceMobileDev/arrowUp.svg';
import ChooseTechnologies from '../ChooseTechnologies';
import ServiceListBlock from '../ServiceList';
import CoreExpertise from '../Expertise/CoreExpertise';
import { H4Text } from '../Typography/styled';
import { ExpertiseDescriptionBlock } from './styled';
import ContactUsForm from '../ContactUsForm';

const list = [
  {
    icon: <LogoIcon />,
    title: 'Chatbots and Virtual Assistants',
    text: 'We enable businesses to effortlessly integrate virtual assistants into their websites and mobile apps providing AI chatbot development services. Customers won’t only benefit from a customer service boost but also from 24/7 support, which will surely improve their experience altogether.',
  },
  {
    icon: <LogoIcon />,
    title: 'Image and Video Recognition',
    text: 'We focus our efforts on helping businesses adopt video and image recognition technology to make their products better. This includes features like object detection and tracking, facial recognition, and so on.',
  },
  {
    icon: <LogoIcon />,
    title: 'Voice Recognition',
    text: 'We help businesses deploy voice recognition technology. Whether it`s integrating voice control into smart home gadgets or developing virtual assistants with voice-enabled features, we can assist you in harnessing the power of voice recognition technology.',
  },
  {
    icon: <LogoIcon />,
    title: 'Natural Language Processing (NLP)',
    text: 'We’ll make it easy for businesses to use NLP technology for language translation, text generation, sentiment analysis, and more.',
  },
  {
    icon: <LogoIcon />,
    title: 'Predictive analytics',
    text: 'We are willing to give businesses assistance in implementing predictive analytics to enhance their decision-making, such as forecasting sales, identifying trends, etc.',
  },
  {
    icon: <LogoIcon />,
    title: 'Recommendation systems',
    text: 'Businesses can turn to us to implement recommendation systems for delivering personalized recommendations to customers, including product recommendations, content recommendations, and more.',
  },
  {
    icon: <LogoIcon />,
    title: 'Fraud detection',
    text: 'To prevent financial losses and protect customer data, we can help businesses implement AI-based fraud detection systems.',
  },
  {
    icon: <LogoIcon />,
    title: 'Predictive maintenance',
    text: 'Using AI-based predictive maintenance systems, we can help businesses improve efficiency and reduce equipment downtime.',
  },
];

const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'Discovery',
    text: 'We help you to outline and fine-tune your idea, and provide guidance on the best ways to use the power of AI technology to achieve your goals.',
  },
  {
    number: 'step 2',
    icon: <UxIcon />,
    onScrollIcon: <UxScrollIcon />,
    title: 'UI/UX design',
    text: 'In 2 weeks, our team develops a dynamic prototype to visualize the product interface. After discussing the prototype with you and introducing the necessary changes, we create a user-friendly design enjoyed by the audience.',
  },
  {
    number: 'step 3',
    icon: <AssembleIcon />,
    onScrollIcon: <AssembleScrollIcon />,
    title: 'Solution development',
    text: 'Following Agile best practices, we deliver new functionality every 1–3 weeks. By enabling a CI/CD pipeline, we automate the testing, build, and deployment processes, reducing the feature release cycle by 3x–4x.',
  },
  {
    number: 'step 4',
    icon: <TestingIcon />,
    onScrollIcon: <TestingScrollIcon />,
    title: 'Quality assurance',
    text: 'Our QA experts test the product to make sure it is error-free, performs as intended, and meets all the requirements. We also prevent potential bottlenecks such as security and scalability issues.',
  },
  {
    number: 'step 5',
    icon: <SupportIcon />,
    onScrollIcon: <SupportScrollIcon />,
    title: 'Support & Maintenance',
    text: 'Finally, we launch your product on the market. On top of that, we offer post-release support and maintenance to ensure smooth system operation. Our engineers implement new features and fix arising issues on the fly.',
  },
];
const expertise = [
  {
    icon: <FintechIcon />,
    title: 'Finance & Banking',
    desc: (
      <ExpertiseDescriptionBlock>
        <li>
          <H4Text>1. Fraud Detection:</H4Text> AI algorithms can analyze large amounts of
          transaction data and detect fraudulent behavior in real-time.
        </li>
        <li>
          <H4Text> 2. Credit Scoring:</H4Text> AI models can predict creditworthiness by analyzing
          customer data, transaction history, and other factors.
        </li>
        <li>
          <H4Text>3. Chatbots:</H4Text> AI-powered chatbots can provide personalized customer
          service, answer queries, and provide financial advice.
        </li>
      </ExpertiseDescriptionBlock>
    ),
    link: '/solutions/fintech-software-development-services',
  },
  {
    icon: <EcommerceIcon />,
    title: 'E-commerce',
    desc: (
      <ExpertiseDescriptionBlock>
        <li>
          <H4Text>1. Personalization:</H4Text> AI algorithms can analyze customer behavior and
          provide personalized product recommendations and marketing offers.
        </li>
        <li>
          <H4Text>2. Inventory Management:</H4Text> AI models can predict demand, optimize inventory
          levels, and reduce stock-outs.
        </li>
        <li>
          <H4Text>3. Visual Search:</H4Text> AI-powered visual search engines can alleviate product
          searches for customers by analyzing images and bringing up similar items.
        </li>
      </ExpertiseDescriptionBlock>
    ),
    link: '/solutions/ecommerce-development',
  },
  {
    icon: <HRIcon />,
    title: 'E-learning',
    desc: (
      <ExpertiseDescriptionBlock>
        <li>
          <H4Text>1. Adaptive Learning:</H4Text> AI algorithms track student behavior, pinpoint
          knowledge gaps, and make learning more personalized.
        </li>
        <li>
          <H4Text>2. Automated Grading:</H4Text> AI models can grade assignments, essays, and exams,
          easing the burden of instructors and giving students immediate feedback.
        </li>
        <li>
          <H4Text>3. Natural Language Processing:</H4Text> AI-powered language models can help
          students master the language by analyzing their writing and providing feedback to them.
        </li>
      </ExpertiseDescriptionBlock>
    ),
  },
  {
    icon: <HealthcareIcon />,
    title: 'Healthcare',
    desc: (
      <ExpertiseDescriptionBlock>
        <li>
          <H4Text>1. Medical Imaging Analysis:</H4Text> AI algorithms can analyze medical images,
          detect abnormalities, and aid radiologists in diagnosing patients.
        </li>
        <li>
          <H4Text>2. Personalized Treatment:</H4Text> AI models evaluate patient information and
          medical history to provide personalized treatment plans and treatment recommendations.
        </li>
        <li>
          <H4Text>3. Chatbots:</H4Text> AI-driven chatbots can offer medical advice, schedule
          appointments, and handle patient triage.
        </li>
        <span>
          <H4Text>Personalized marketing</H4Text>
          <br />
          We can help businesses to implement AI-based personalized marketing systems, to deliver
          highly-targeted and personalized marketing messages to their customers.
        </span>
      </ExpertiseDescriptionBlock>
    ),
    link: '/solutions/healthcare',
  },
];

const techData = [
  ['OpenAI', <ArrowUpIcon />, 'TensorFlow', 'Keras'],
  ['PyTorch', 'Scikit-learn', 'BCaffe'],
  ['Microsoft Cognitive Toolkit (CNTK)', <ArrowUpIcon />],
  ['Apache Mahout', 'Theano', 'Deeplearning4j'],
];

const AiSoftwareDevelopmentPage = () => {
  const { message } = useStaticQuery(query);

  return (
    <PageContainer>
      <PageContent>
        <TopPageSectionWrapper
          title="AI software development services"
          description="We are pros in using OpenAI and other similar companies’ APIs, offering AI development services to help bring your startup ideas to life using the latest AI technology. Thanks to our skilled team and expertise, we can enhance the products you already have with new features or get customized solutions off the ground from scratch."
        />
        <ServiceListBlock
          title="AI software development services"
          iconColor={colors2.blue}
          list={list}
          bgColor={colors2.lightBlue}
        />

        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <ProcessComponent title="How we work" data={work} />
        <MessageCard
          image={message}
          title="Want to create a top-notch AI app?"
          subtitile="Contact us to schedule a free project consultation."
          button="Contact us"
        />

        <CoreExpertise title="Our expertise in ai/ml development services" data={expertise} />
        <ChooseTechnologies techData={techData} title="Platforms we work with" />
        <Technologies />

        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default AiSoftwareDevelopmentPage;
