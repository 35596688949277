import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ScheduleText, StyledScheduleCard } from './styled';
import { H2Text, P2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { SubmitBtn } from '../ContactUsPage/styled';

const ScheduleCard = ({ image, color }) => {
  return (
    <StyledScheduleCard color={color} className="schedule_card">
      <GatsbyImage
        className="schedule_card_image"
        image={image.childImageSharp.gatsbyImageData}
        alt=""
      />
      <ScheduleText className="schedule_card_text">
        <H2Text>Want to schedule a call?</H2Text>
        <P2Text color={colors2.lightText}>
          Tell about your business challenge, needs, or new product ideas.
        </P2Text>
        <a
          target="_blank"
          href="https://calendly.com/kobiashov"
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          <SubmitBtn id="call-schedule-btn">Schedule a call</SubmitBtn>
        </a>
      </ScheduleText>
    </StyledScheduleCard>
  );
};

export default ScheduleCard;
