import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';
import FrontendSolutions from './FrontendSolutions';
import ProcessSection from './ProcessSection';
import MessageCard from '../Card/MessageCard';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ContactUsForm from '../ContactUsForm';

const FrontendDevelopmentPage = () => {
  const { message } = useStaticQuery(query);
  return (
    <PageContainer>
      <PageContent>
        <TopPageSectionWrapper
          title="Frontend development company you can rely on"
          description="Our team will provide smooth navigation and a seamless user experience. Assemble a
          frontend development team in less than a week."
        />
        <FrontendSolutions />
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <MessageCard
          image={message}
          title="Need to build an appealing UI?"
          subtitile="Let’s discuss your project."
          button="Contact us"
          style={{ margin: '0' }}
        />
        <ProcessSection />

        <Technologies />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default FrontendDevelopmentPage;
