import React from 'react';
import styled from 'styled-components';
import fonts from '../../../../../utils/theme/fonts';
import { AvailableBlock, CategoryBlock, CrossedPriceBlock } from './components';
import { ROLES } from '../../constants';

const THead = styled.thead`
  width: 100%;
  font-family: ${fonts.euclidCircularA};
  font-weight: 500;
  line-height: 140%;
  font-size: 14px;
  background: #120f21;
  & th {
    padding: 15px;
    text-align: left;
  }
  & th:first-child {
    border-radius: 12px 0 0 12px;
  }
  & th:last-child {
    border-radius: 0 12px 12px 0;
  }
`;

const TBody = styled.tbody`
  & tr {
    border-bottom: 1px solid #1b172d;
    &:last-child {
      border-bottom: none;
    }
  }
  & span {
    font-family: ${fonts.poppins};
    font-weight: 500;
    line-height: 160%;
    font-size: 17px;
  }
  & td {
    padding: 20px 15px;
    vertical-align: middle;
  }
  & input {
    width: 15px;
    height: 15px;
  }
`;

const DesktopTable = ({ selected, onCounterChange, eurCurrency }) => {
  return (
    <table
      border="0"
      cellSpacing="0"
      cellPadding="0"
      style={{ width: '100%', borderCollapse: 'collapse' }}
    >
      <THead>
        <tr>
          <th>Category</th>
          <th>Available</th>
          <th>Price</th>
        </tr>
      </THead>
      <TBody>
        {selected.map((item) => {
          const role = ROLES.find((r) => r.name === item.name);
          return (
            <tr key={item.name}>
              <td>
                <CategoryBlock item={item} onCounterChange={onCounterChange(item.name)} />
              </td>
              <td>
                <AvailableBlock roleName={item.name} />
              </td>
              <td>
                <CrossedPriceBlock
                  crossedPrice={
                    eurCurrency
                      ? role.crossedPrice * item.count * 0.9
                      : role.crossedPrice * item.count
                  }
                  actualPrice={
                    eurCurrency
                      ? role.actualPrice * item.count * 0.9
                      : role.actualPrice * item.count
                  }
                  eurCurrency={eurCurrency}
                />
              </td>
            </tr>
          );
        })}
      </TBody>
    </table>
  );
};

export default DesktopTable;
