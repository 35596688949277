import React from 'react';
import { Tag, Wrapper } from './styled';

import { H2Text, H3Text, P2Text } from '../Typography/styled';

const ChooseTechnologies = ({ techData, title, description }) => {
  return (
    <Wrapper>
      <H2Text>{title}</H2Text>
      {description && <P2Text>{description}</P2Text>}
      <div>
        {techData.map((el, i) => {
          return (
            <Tag key={i}>
              {el.map((item, idx) =>
                typeof item === 'string' ? <H3Text key={idx + item}>{item}</H3Text> : item
              )}
            </Tag>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default ChooseTechnologies;
