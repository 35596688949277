import ARIcon from './AR.svg';
import DataProcessingIcon from './dataProcessing.svg';
import FacialRecognitionIcon from './facialRecognition.svg';
import ImageClassificationIcon from './imageClassification.svg';
import ImageGenerationIcon from './imageGeneration.svg';
import ImageRecognitionIcon from './imageRecognition.svg';
import MultipleLanguagesSupportIcon from './multipleLanguagesSupport.svg';
import NaturalLanguageQueryIcon from './naturalLanguageQuery.svg';
import OCRIcon from './OCR.svg';
import PredictiveAnalyticsIcon from './predictiveAnalytics.svg';
import TextClassificationIcon from './textClassification.svg';
import TextGenerationIcon from './textGeneration.svg';

export {
  ARIcon,
  DataProcessingIcon,
  FacialRecognitionIcon,
  ImageClassificationIcon,
  ImageGenerationIcon,
  ImageRecognitionIcon,
  MultipleLanguagesSupportIcon,
  NaturalLanguageQueryIcon,
  OCRIcon,
  PredictiveAnalyticsIcon,
  TextClassificationIcon,
  TextGenerationIcon,
};
