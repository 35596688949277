import React from 'react';

import CoreExpertise from '../Expertise/CoreExpertise';
import HealthcareIcon from '../../assets/icons/updateDesign/development/healthcare.svg';
import EcommerceIcon from '../../assets/icons/updateDesign/development/ecommerce.svg';
import HRIcon from '../../assets/icons/updateDesign/development/hr.svg';
import FintechIcon from '../../assets/icons/updateDesign/development/fintech.svg';

const services = [
  {
    icon: <HealthcareIcon />,
    title: 'Healthcare',
    desc: 'Build a web system to enhance patient treatment, digitize paper-based workflows, and improve staff productivity. We can employ IoT technology to enable remote patient health management and physical activity tracking.',
    link: '/solutions/healthcare',
  },
  {
    icon: <EcommerceIcon />,
    title: 'E-commerce',
    desc: 'Open a new sales channel, engage consumers, and increase revenue with a scalable e-commerce platform. We’ll create a beautiful user-friendly design, ensure performance, and make your product available even under heavy loads.',
    link: '/solutions/ecommerce-development',
  },
  {
    icon: <HRIcon />,
    title: 'HR',
    desc: 'Develop HRM software to automate candidate search, recruitment, and onboarding. Monitor job satisfaction and retain employees with ease. Our team will build a scalable solution and help integrate it with your existing systems.',
    link: '/solutions/hr-software-development',
  },
  {
    icon: <FintechIcon />,
    title: 'Fintech',
    desc: 'We have solid experience in making trading platforms, crypto wallets, expense tracking software, investment apps, and robo advisors. Our engineers ensure compliance with industry standards like PSD2, PCI DSS, and GDPR.',
    link: '/solutions/finance-software-development',
  },
];

const WebDevelopmentExpertise = () => {
  return <CoreExpertise data={services} title="We are ready to build your project" />;
};

export default WebDevelopmentExpertise;
