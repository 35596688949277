import styled from 'styled-components';
import { Flex } from 'rebass';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H3Text, P3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { web3Colors } from '../Web3Page/styled';

export const FooterWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 80px;
  gap: 80px;
  align-items: center;
  background: ${(props) =>
    props.web3ModeAvailible
      ? `radial-gradient(at bottom, ${web3Colors.darkPurple}, 10%, ${web3Colors.background})`
      : `${colors2.white}`};
  border-top: ${(props) => (props.web3ModeAvailible ? 'none' : '1px solid rgba(38, 38, 38, 0.12)')};
  a {
    text-decoration: none;
  }
  .footer_block__text {
    gap: 60px;
  }
  @media ${breakpoints.md} {
    ${H3Text} {
      font-weight: 500;
      font-size: 22px;
      line-height: 130%;
    }
    ${P3Text} {
      font-weight: 400;
      font-size: 14px;
      line-height: 165%;
    }
  }
  @media ${breakpoints.mds} {
    align-items: start;
    gap: 60px;
    padding: 40px;
    ${H3Text} {
      font-size: 20px;
      line-height: 130%;
    }
  }
  @media ${breakpoints.sm} {
    gap: 40px;
    .footer_block__text {
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export const FooterContainer = styled(Flex)`
  align-items: end;
  &.mobile--dispalyed {
    display: none;
  }
  .social {
    &__icon {
      fill-opacity: 0.7;
    }
  }

  @media ${breakpoints.mds} {
    &.desktop--dispalyed {
      display: none;
    }
    &.mobile--dispalyed {
      display: flex;
    }
  }
`;
