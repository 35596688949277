import React from 'react';

import styled from 'styled-components';

import TestingIcon from '../../assets/icons/updateDesign/dedicated/testing.svg';
import TestingScrollIcon from '../../assets/icons/updateDesign/dedicated/testing-hover.svg';
import UxScrollIcon from '../../assets/icons/updateDesign/development/ux-hover.svg';
import UxIcon from '../../assets/icons/updateDesign/development/ux.svg';
import DevelopmentIcon from '../../assets/icons/updateDesign/development/development.svg';
import DevelopmentScrollIcon from '../../assets/icons/updateDesign/development/development-hover.svg';
import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import SupportIcon from '../../assets/icons/updateDesign/dedicated/support.svg';
import SupportScrollIcon from '../../assets/icons/updateDesign/dedicated/support-hover.svg';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

import ProcessComponent from '../ProcessComponent';

export const Wrapper = styled.div`
  @media ${breakpoints.sm} {
    padding: 0 30px;
    .list-with-big-icons_wrapper {
      gap: 30px;
      .step {
        flex-direction: column;
        margin-bottom: 0px;
        gap: 30px;
      }
      .number {
        margin-top: 0px;
        margin-right: 0px;
      }
    }
  }
`;
const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'Discovery',
    text: 'We discuss the project idea, goals, and requirements with our client. Then, our software experts determine the work scope, prioritize features, and make time/cost estimates.',
  },
  {
    number: 'step 2',
    icon: <UxIcon />,
    onScrollIcon: <UxScrollIcon />,
    title: 'Planning',
    text: 'We choose a technology stack and software architecture that perfectly fit your backend project needs. Our engineers will also provide a list of recommendations if necessary.',
  },
  {
    number: 'step 3',
    icon: <DevelopmentIcon />,
    onScrollIcon: <DevelopmentScrollIcon />,
    title: 'Development',
    text: 'Cooperating with a frontend team, we build fast, scalable, and responsive apps. By following Agile best practices, our backend developers implement new functionality every 1–3 weeks.',
  },
  {
    number: 'step 4',
    icon: <TestingIcon />,
    onScrollIcon: <TestingScrollIcon />,
    title: 'Testing',
    text: 'We use various types of testing to achieve the smooth, error-free product operation. Our QA team will prevent potential bottlenecks like performance problems and security breaches.',
  },
  {
    number: 'step 5',
    icon: <SupportIcon />,
    onScrollIcon: <SupportScrollIcon />,
    title: 'Support & Maintenance',
    text: 'Our backend development company offers post-launch support and maintenance to introduce new features and fix arising issues on demand.',
  },
];

const ProcessSection = () => {
  return (
    <Wrapper>
      <ProcessComponent title="Backend development process" data={work} />
    </Wrapper>
  );
};

export default ProcessSection;
