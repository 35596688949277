import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import { H2Text, P2Text } from '../Typography/styled';

export const BlueSection = styled.div`
  width: 100%;
  padding: 80px;
  background-color: ${colors2.lightBlue};
  border-radius: 40px;
  position: relative;

  .info {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 80px 0;

  border: 1px dashed ${colors2.blue};
`;

export const Services = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  .wrapper {
    display: grid;
    grid-template-columns: 66px 1fr;
    gap: 30px;
    align-items: center;
  }

  .full-row {
    grid-column: 1 / span 2;
  }
`;

export const IconWrapper = styled.div`
  width: 66px;
  height: 66px;
  padding: 20px;
  background-color: ${colors2.lightGray};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    fill: ${colors2.black};
  }
`;

export const SolutionsSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 80px;

  @media ${breakpoints.sm} {
    grid-template-columns: 1fr;
    gap: 60px;
  }

  & > .info {
    ${H2Text} {
      display: block;
      margin-bottom: 30px;

      @media ${breakpoints.sm} {
        margin-bottom: 20px;
      }
    }

    ${P2Text} {
      margin-bottom: 30px;
    }
  }
`;

export const SolutionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 66px 1fr;
    gap: 30px;
    margin-bottom: 80px;
  }

  .info {
    padding-top: 13px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;
