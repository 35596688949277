import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO';
import NotFoundIcon from '../assets/icons/notfound.svg';
import { breakpoints } from '../utils/theme/theme2/breakpoints';
import PortfolioLayout from '../components/PortfolioLayout';
import PortfolioCard from '../components/PortfolioCard';
import { H4Text } from '../components/Typography/styled';
import colors2 from '../utils/theme/theme2/colors2';

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  .box:nth-child(3n + 1) {
    grid-column: auto / span 2;
    grid-template-columns: 1fr 1fr;

    @media ${breakpoints.md} {
      grid-column: auto;
      grid-template-columns: 1fr 1fr;
    }

    @media ${breakpoints.sm} {
      grid-template-rows: auto auto;
      grid-template-columns: 1fr;
      > div {
        grid-template-columns: 1fr;
      }
    }
  }
  .box:nth-child(3n + 2),
  .box:nth-child(3n + 3) {
    grid-column: auto / span 1;
    grid-template-rows: auto 1fr;
    > div {
      grid-template-columns: 1fr;
    }

    .gatsby-image-wrapper {
      grid-row: 1;
    }

    @media ${breakpoints.md} {
      grid-column: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;

      > div {
        grid-template-columns: 1fr 1fr;
      }

      .gatsby-image-wrapper {
        grid-row: auto;
      }
    }

    @media ${breakpoints.sm} {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;

      > div {
        grid-template-columns: 1fr;
      }

      .gatsby-image-wrapper {
        grid-row: 1;
      }
    }
  }

  @media ${breakpoints.md} {
    grid-template-columns: 1fr;
  }

  @media ${breakpoints.sm} {
    grid-column: auto / span 1;
    grid-template-rows: auto 1fr;
    > div {
      grid-template-columns: 1fr;
    }

    .gatsby-image-wrapper {
      grid-row: 1;
    }
  }
`;

const OurWorkPage = ({
  location,
  data: {
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
    ourWorksPageInfo: {
      frontmatter: { longTitle, seoTitle, description, keywords },
    },
    portfolio: { edges: portfolio },
    platforms: { edges: platforms },
    industries: { edges: industries },
  },
}) => {
  const [platforma, setPlatforma] = useState('all');
  const [industry, setIndustry] = useState('All industries');

  const portfolioData = useMemo(
    () =>
      portfolio.filter((data) =>
        platforma !== 'all' && industry !== 'All industries'
          ? data.node.frontmatter.platform === platforma &&
            data.node.frontmatter.industry === industry
          : platforma !== 'all'
          ? data.node.frontmatter.platform === platforma
          : industry !== 'All industries'
          ? data.node.frontmatter.industry === industry
          : data
      ),
    [industry, platforma, portfolio]
  );

  return (
    <>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={`${siteUrl}/our-work`}
        description={description}
      />
      <PortfolioLayout
        platforma={platforma}
        industry={industry}
        setPlatforma={setPlatforma}
        setIndustry={setIndustry}
        platforms={platforms}
        industries={industries}
        title={seoTitle}
        location={location}
        siteUrl={siteUrl}
        longTitle={longTitle}
      >
        {portfolioData.length ? (
          <Main>
            {portfolioData.map((data) => (
              <PortfolioCard className="box" key={data.node.id} data={data} />
            ))}
          </Main>
        ) : (
          <Flex pt={3} flexDirection="column" alignItems="center">
            <Box mb={3}>
              <H4Text color={colors2.lightText}>We Couldn&rsquo;t Find Anything</H4Text>
            </Box>
            <NotFoundIcon />
          </Flex>
        )}
      </PortfolioLayout>
    </>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    ourWorksPageInfo: markdownRemark(frontmatter: { name: { eq: "our works" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }

    platforms: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "portfolio-platform" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
        }
      }
    }

    industries: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "portfolio-industry" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
        }
      }
    }

    portfolio: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "portfolio" }, color: { ne: null } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            industry
            platform
            cardBackgroundImage {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            color
            colorFont
          }
        }
      }
    }
  }
`;

OurWorkPage.propTypes = {
  data: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default OurWorkPage;
