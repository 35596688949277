const colors2 = {
  white: '#FFFFFF',
  black: '#262626',
  blue: '#2665F6',
  green: '#C6F95A',
  violet: '#8139F5',
  lightBlue: '#EDF8FF',
  lightGray: '#F7F7F7',
  lightGreen: '#EFFBD8',
  lightViolet: '#F6F2FD',
  lightYellow: '#FDF7E1',
  lightText: 'rgba(38, 38, 38, 0.7)',
  yellow: '#FFD12F',
  red: '#EF4335',
  orange: '#FFD43E',
  gray: '#f6f6f6',
};

export default colors2;
