import React, { useMemo } from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArrowIcon from '../../assets/icons/updateDesign/next.svg';
import { P3Text } from '../Typography/styled';
import { ArrowBtn } from './styled';
import colors2 from '../../utils/theme/theme2/colors2';

export const Button = styled.button``;

export const Pages = styled.div`
  display: flex;
  gap: 10px;
  color: #262626;
`;

const range = (start, end) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

const Pagination = ({
  page,
  countPage,
  nextButtonClick,
  previousButtonClick,
  onPageChange,
  siblingCount = 1,
}) => {
  const DOTS = 'dots';

  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..countPage]
    */
    if (totalPageNumbers >= countPage) {
      return range(1, countPage);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and countPage
    */
    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, countPage);

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and countPage. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < countPage - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < countPage - 2;

    const firstPageIndex = 1;
    const lastPageIndex = countPage;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, countPage];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(countPage - rightItemCount + 1, countPage);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [];
  }, [page, countPage, siblingCount]);

  if (page === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" mt={5}>
      <ArrowBtn
        onClick={nextButtonClick}
        type="submit"
        name="submit subscribe form"
        variant="primary2"
        css={page === 1 && { visibility: 'hidden' }}
      >
        <ArrowIcon />
        <P3Text color={colors2.lightText}>Previous</P3Text>
      </ArrowBtn>
      <Pages>
        {paginationRange.map((pageNumber) => {
          return pageNumber === DOTS ? (
            <P3Text color={colors2.lightText}>&#8230;</P3Text>
          ) : (
            <P3Text
              color={page === pageNumber ? colors2.black : colors2.lightText}
              style={{ cursor: 'pointer' }}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </P3Text>
          );
        })}
      </Pages>
      <ArrowBtn
        onClick={previousButtonClick}
        type="submit"
        name="submit subscribe form"
        css={page === countPage && { visibility: 'hidden' }}
      >
        <P3Text className="btn" color={colors2.lightText}>
          Next articles
        </P3Text>
        <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
      </ArrowBtn>
    </Flex>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  countPage: PropTypes.number.isRequired,
  nextButtonClick: PropTypes.func.isRequired,
  previousButtonClick: PropTypes.func.isRequired,
};

export default Pagination;
