import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import CloudImg from '../../assets/icons/updateDesign/homePage/cloudTablet.svg';
import { OffersContent } from './styled';
import Link from '../Link';
import { P2Text, H2Text } from '../Typography/styled';

import ArrowBtn from '../Buttons/ArrowBtn';

export const offersQuery = graphql`
  {
    site {
      siteMetadata {
        menuLinks {
          name
          link
          event
          subLinks {
            name
            link
            event
          }
        }
      }
    }
    cloud: file(relativePath: { eq: "images/updateDesign/offers-cloud.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    web: file(relativePath: { eq: "images/updateDesign/offers-web.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobile: file(relativePath: { eq: "images/updateDesign/offers-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const Offers = () => {
  const { web, mobile, cloud } = useStaticQuery(offersQuery);
  return (
    <OffersContent>
      <div className="offerContents-cards">
        <div className="offerContents-card">
          <div className="offerContents-card__web">
            <div className="text_container">
              <H2Text className="offerContents-card__title">Web development</H2Text>
              <P2Text className="offerContents-card__desc">
                Through custom web development services, we help our clients attract users, boost
                conversions, and increase revenues. Hire our team to create a SEO-friendly,
                performant, and scalable platform. We’ll also protect against hacker/DDoS attacks,
                malware, and other cybersecurity threats.
              </P2Text>
              <div style={{ marginTop: '30px', marginBottom: '10px' }}>
                <Link to="/services/web-development">
                  <ArrowBtn />
                </Link>
              </div>
            </div>
            <div className="offerContents-card__img-wrapper">
              <GatsbyImage
                image={web.childImageSharp.gatsbyImageData}
                alt="offers-web"
                className="offerContents-card__img"
              />
            </div>
          </div>
          <div className="offerContents-card__mobile">
            <div className="text_container">
              <H2Text className="offerContents-card__title">Mobile development</H2Text>
              <P2Text className="offerContents-card__desc">
                Engage your customers, address pain points, and generate profit with our custom
                mobile app development services. We have vast experience in building native (iOS,
                Android) and cross-platform solutions. Following best software engineering
                practices, we ensure high availability, performance, and security.
              </P2Text>
              <div style={{ marginTop: '30px', marginBottom: '10px' }}>
                <Link to="/services/mobile-app-development">
                  <ArrowBtn />
                </Link>
              </div>
            </div>
            <div className="offerContents-card__img-wrapper">
              <GatsbyImage
                image={mobile.childImageSharp.gatsbyImageData}
                alt="offers-mobile"
                className="offerContents-card__img"
              />
            </div>
          </div>
        </div>
        <div className="offerContents-card">
          <div className="offerContents-card__mvp">
            <div className="offerContents-card__text">
              <H2Text>MVP development</H2Text>
              <P2Text className="offerContents-card__desc">
                Partner with our MVP software development company to launch a digital solution in 3
                months or less. Test functionality every 1–3 weeks, validate hypotheses promptly,
                find a product market fit, and raise funds.
              </P2Text>
              <Link to="/mvp-calculator">
                <ArrowBtn />
              </Link>
            </div>
            <div className="img_container">
              <GatsbyImage
                image={cloud.childImageSharp.gatsbyImageData}
                alt="offers-web"
                className="offerContents-card__img cloud_img"
              />
              <CloudImg className="cloud_img__tablet" />
            </div>
          </div>
        </div>
      </div>
    </OffersContent>
  );
};

export default Offers;
