import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import DropUsSection from './DropUsSection';
import ScheduleCard from '../Card/ScheduleCard';
import ContactUsForm from '../ContactUsForm';
import { PageContent, PageContainer } from '../homePage/styled';

const ContactUsPage = () => {
  const { schedule } = useStaticQuery(headerQuery);

  return (
    <PageContainer>
      <PageContent>
        <ContactUsForm />
        <ScheduleCard image={schedule} />
        <DropUsSection />
      </PageContent>
    </PageContainer>
  );
};

export const headerQuery = graphql`
  {
    schedule: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default ContactUsPage;
