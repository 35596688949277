import React from 'react';
import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Tooltip from 'react-simple-tooltip';
import CheckIcon from '../../assets/icons/MVPCalculator/check.svg';
import InfoIcon from '../../assets/icons/MVPCalculator/info.svg';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import colors2 from '../../utils/theme/theme2/colors2';

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  min-height: 190px;
  padding: ${(props) => (props.isSelected ? '34.5px 13.5px 18.5px' : '36px 15px 20px')};
  position: relative;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#F6F2FD' : '#ffffff')};
  color: ${(props) => (props.isSelected ? '#8139F5' : '#262626')};
  opacity: ${(props) => (props.isSelected ? '1' : '1')};
  border: ${(props) =>
    props.isSelected ? '3px solid #8139F5' : '1.5px solid rgba(38, 38, 38, 0.2)'};
  border-radius: 30px;

  @media ${breakpoints.sm} {
    padding: 20px 16px 16px 16px;
  }

  transition: none;

  &:hover {
    opacity: 1;

    border: ${(props) => (props.isSelected ? '3px solid #8139F5' : '1.5px solid transparent')};
    background-color: #f6f2fd;

    .icon {
      background-color: ${colors2.white};
    }
  }

  [data-icon='info'],
  [data-icon='check'] {
    transition: none;
  }

  .controls {
    position: absolute;
    top: ${(props) => (props.isSelected ? '18.5px;' : '20px;')};
    right: ${(props) => (props.isSelected ? '18.5px;' : '20px;')};
  }

  [data-icon='check'] {
    margin-left: 10px;
    opacity: ${(props) => (props.isSelected ? '1' : '0.2')};
  }

  [data-icon='info'] {
    color: #323347;
    opacity: 0.2;

    &:hover {
      opacity: 1;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    width: 80px;
    height: 80px;
    background-color: ${(props) => (props.isSelected ? colors2.white : colors2.gray)};
    border-radius: 50%;

    svg {
      max-width: 40px;
    }
  }

  @media (max-width: 442px) {
    min-height: 140px;
  }
`;

const Title = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  text-align: center;

  &:not(:only-child):last-child {
    font-weight: normal;
    font-size: 16px;
  }

  &:not(:only-child):not(:last-child) {
    margin-bottom: 10px;
  }
`;

const infoTooltipStyles = css`
  @media (max-width: 768px) {
    display: none;
  }

  & > div {
    width: 210px;
    font-size: 14px;
    line-height: 1.2em;
    box-shadow: 0px 4px 20px rgba(32, 64, 81, 0.08);
  }
`;

const styles = {
  content: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    flexGrow: 1,
    fontSize: 16,
    lineHeight: 1.5,
    textAlign: 'center',
  },
};

const MVPCalculatorStepItem = ({
  icon: Icon,
  value,
  additionalInfo,
  title,
  description,
  onClick,
  isSelected,
}) => (
  <Container isSelected={isSelected} onClick={() => onClick(value)}>
    <Box className="controls">
      {additionalInfo && (
        <Tooltip
          padding={10}
          background="#fff"
          border="#fff"
          color="rgba(50, 51, 71, 0.7)"
          customCss={infoTooltipStyles}
          content={additionalInfo}
        >
          <InfoIcon data-icon="info" />
        </Tooltip>
      )}
      <CheckIcon data-icon="check" />
    </Box>
    <Flex style={styles.content}>
      {Icon && (
        <div className="icon">
          <Icon />
        </div>
      )}
      {title && <Title>{title}</Title>}
      {description && <Box style={styles.description}>{description}</Box>}
    </Flex>
  </Container>
);

MVPCalculatorStepItem.propTypes = {
  value: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  additionalInfo: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

MVPCalculatorStepItem.defaultProps = {
  icon: null,
  additionalInfo: '',
  title: '',
  description: '',
  onClick: () => {},
  isSelected: false,
};

export default MVPCalculatorStepItem;
