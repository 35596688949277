import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Wrapper } from './styled';
import { H2Text, H3Text, P2Text } from '../Typography/styled';

import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: start;
  align-self: start;
  max-width: 670px;
  width: 100%;
`;
const CardsContainer = styled.div`
  display: flex;
  gap: 40px;
  @media ${breakpoints.mds} {
    flex-direction: column;
  }
`;
const CardWrapper = styled.div`
  width: calc((100%-40px) / 2);
  display: flex;
  flex-direction: column;
  padding: 60px;
  background-color: ${(props) => (props.bg ? props.bg : 'initial')};
  border-radius: 40px;
  gap: 15px;
  ${H3Text} {
    white-space: nowrap;
  }
  & img {
    object-fit: contain;
  }
  @media ${breakpoints.mds} {
    padding: 30px;
    & img {
      min-width: 125px;
    }
  }
  @media ${breakpoints.sm} {
    width: 100%;
  }
`;
const CardTopBlock = styled.div`
  display: flex;
  align-items: end;
`;

const OurTeamVision = ({ images }) => {
  return (
    <Wrapper>
      <TextContainer>
        <H2Text>Our vision & mission</H2Text>
        <P2Text color={colors2.lightText}>
          We take an individual approach to each customer and each project. We select the
          methodology on the basis of project requirements and specifications.
        </P2Text>
      </TextContainer>
      <CardsContainer>
        <CardWrapper bg={colors2.yellow}>
          <CardTopBlock>
            <H3Text>Our vision</H3Text>
            <GatsbyImage image={images.ourVisionImg.childImageSharp.gatsbyImageData} alt="" />
          </CardTopBlock>

          <P2Text>
            We use social and software technologies to help our customers and employees grow big
          </P2Text>
        </CardWrapper>
        <CardWrapper bg={colors2.green}>
          <CardTopBlock>
            <H3Text>Our mission</H3Text>
            <GatsbyImage image={images.ourMissionImg.childImageSharp.gatsbyImageData} alt="" />
          </CardTopBlock>

          <P2Text>To deliver the best software to our clients fast</P2Text>
        </CardWrapper>
      </CardsContainer>
    </Wrapper>
  );
};

export default OurTeamVision;
