import React from 'react';
import { IntegrationsCard, IntegrationsCardItems } from './styled';
import { H2Text, H3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';

import SlackIcon from '../../assets/icons/updateDesign/salesforce/slack.svg';
import TableauIcon from '../../assets/icons/updateDesign/salesforce/tableau.svg';
import PardotIcon from '../../assets/icons/updateDesign/salesforce/pardot.svg';
import MulesoftIcon from '../../assets/icons/updateDesign/salesforce/mulesoft.svg';

const integrations = [
  { icon: <SlackIcon />, label: 'Slack' },
  { icon: <TableauIcon />, label: 'Tableau' },
  { icon: <PardotIcon />, label: 'Pardot' },
  { icon: <MulesoftIcon />, label: 'MuleSoft' },
];

const Integrations = ({ image, title, subtitile, button, link, ...props }) => {
  return (
    <IntegrationsCard color={colors2.lightGreen} {...props}>
      <H2Text>Other integrations that we offer</H2Text>
      <IntegrationsCardItems>
        {integrations.map(({ icon, label }) => (
          <div key={label}>
            {icon}
            <H3Text>{label}</H3Text>
          </div>
        ))}
      </IntegrationsCardItems>
    </IntegrationsCard>
  );
};

export default Integrations;
