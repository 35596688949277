import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, Flex } from 'rebass';
import { FaqContentWrapper } from './styled';
import { PageContainer, PageContent } from '../homePage/styled';
import groupDataBy from '../../utils/groupDataBy';
import FaqBox from './FaqBox';
import { H2Text } from '../Typography/styled';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import colors2 from '../../utils/theme/theme2/colors2';

const FAQPage = () => {
  const { faq } = useStaticQuery(query);
  const questions = faq.edges;

  return (
    <PageContainer>
      <PageContent>
        <TopPageSectionWrapper
          title="FAQ"
          description="The basic principle – zero tolerance for any kind of deception."
        />

        <FaqContentWrapper>
          {Object.entries(groupDataBy('type')(questions)).map(([key, value]) => (
            <Flex
              flexDirection={['column', 'row']}
              key={key}
              color={colors2.lightText}
              style={{ gap: '50px' }}
            >
              <Box width={[1, 4 / 12]} pt={[35]} mb={[3, 0]}>
                <H2Text>{key}</H2Text>
              </Box>
              <Flex flexDirection="column" width={[1, 8 / 12]} style={{ gap: '20px' }}>
                {value.map(({ node }) => (
                  <FaqBox key={node.id} data={node} />
                ))}
              </Flex>
            </Flex>
          ))}
        </FaqContentWrapper>
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    faq: allMarkdownRemark(
      sort: { fields: frontmatter___type, order: ASC }
      filter: { frontmatter: { collection_name: { eq: "faq" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            type
            title
          }
          html
        }
      }
    }
  }
`;

export default FAQPage;
