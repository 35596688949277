import React from 'react';
import { CheckboxContainer, Icon, HiddenCheckbox, StyledCheckbox } from './styled';

const Web3Checkbox = ({ id, checked, onChange }) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox id={id} checked={checked} onChange={onChange} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Web3Checkbox;
