import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ cols }) => `repeat(${cols}, 1fr)`};
  gap: 80px;

  @media ${breakpoints.mds} {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  & > :first-child {
    grid-column: ${({ cols }) => (cols || cols !== 1) && `1 / ${cols + 1}`};
  }

  .step {
    display: grid;
    grid-template-columns: 66px 1fr;
    gap: 30px;

    @media ${breakpoints.sm} {
      margin-top: 60px;
      grid-template-columns: 1fr;
      gap: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      padding-top: 13px;

      @media ${breakpoints.sm} {
        padding-top: 0;
      }
    }

    .title {
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: ${colors2.black};
      margin-bottom: 20px;

      @media ${breakpoints.mds} {
        font-size: 24px;
        line-height: 36px;
      }

      @media ${breakpoints.sm} {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 10px;
      }
    }

    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 165%;
      color: ${colors2.lightText};
      margin-bottom: 40px;

      @media ${breakpoints.mds} {
        font-size: 16px;
        line-height: 26px;
      }

      @media ${breakpoints.sm} {
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 0;
      }
    }
  }
`;

export const IconWrapper = styled.div`
  width: 66px;
  height: 66px;
  padding: 20px;
  background-color: ${({ bg }) => bg ?? colors2.lightGray};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    ${({ color }) => color && `fill: ${color}`};
  }
`;
