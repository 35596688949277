import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ScheduleText, StyledScheduleCard } from './styled';
import { H2Text, P2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { DefaultButton } from '../Buttons/styled';

const MessageCard = ({ image, title, subtitile, button, link, ...props }) => {
  return (
    <StyledScheduleCard color={colors2.orange} {...props}>
      <GatsbyImage
        className="message-card-img"
        image={image.childImageSharp.gatsbyImageData}
        alt="message"
      />
      <ScheduleText>
        <H2Text>{title}</H2Text>
        <P2Text color={colors2.lightText}>{subtitile}</P2Text>
        <Link to="/contact-us" style={{ textDecoration: 'none' }}>
          <DefaultButton> {button}</DefaultButton>
        </Link>
      </ScheduleText>
    </StyledScheduleCard>
  );
};

export default MessageCard;
