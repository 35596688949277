import styled from 'styled-components';
import { web3Colors } from '../styled';
import fonts from '../../../utils/theme/fonts';
import { MOBILE_VIEW_BREAKPOINT } from '../constants';

export const Input = styled.input`
  width: 100%;
  background: #100e1e;
  color: ${web3Colors.grey};
  padding: 16px 20px;
  border: 1px solid #242e4b;
  border-radius: 12px;
  font-family: ${fonts.poppins};
  font-size: 16px;
  line-height: 160%;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${web3Colors.grey};
  }

  @media (max-width: ${MOBILE_VIEW_BREAKPOINT}) {
    font-size: 12px;
    padding: 10px 16px;
  }
`;
