import { OTHER_ROLE_NAME, ROLES } from './constants';

export const calculateTotal = (selected, eurCurrency) => {
  if (selected.length === 0) {
    return { totalCrossedPrice: NaN, totalActualPrice: 0 };
  }

  if (selected.length === 1 && selected[0].name === OTHER_ROLE_NAME) {
    return { totalCrossedPrice: NaN, totalActualPrice: NaN };
  }

  return selected
    .filter((s) => s.name !== OTHER_ROLE_NAME)
    .reduce(
      (acc, s) => {
        const role = ROLES.find((r) => r.name === s.name);
        if (eurCurrency) {
          acc.totalCrossedPrice += role.crossedPrice * s.count * 0.9;
          acc.totalActualPrice += role.actualPrice * s.count * 0.9;
        } else {
          acc.totalCrossedPrice += role.crossedPrice * s.count;
          acc.totalActualPrice += role.actualPrice * s.count;
        }
        return acc;
      },
      { totalCrossedPrice: 0, totalActualPrice: 0 }
    );
};
