import React from 'react';
import { Flex } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Slider from '../Slider';
import { BadgesContent } from '../homePage/styled';

const SliderStyled = styled(Slider)`
  & .slick-track {
    display: flex;
    align-items: center;
  }

  & .slick-list {
    width: 100% !important;
    margin: 0 !important;
  }
`;

const Container = styled(BadgesContent)`
  position: relative;
  z-index: 20;
  padding: 50px 0;
  & > .badges_container {
    padding-bottom: 0;
  }
`;

const ClientsSlider = () => {
  const { allFile } = useStaticQuery(awardsQuery);

  const clients = allFile.edges.map((item) => {
    return {
      image: item.node.childImageSharp.gatsbyImageData,
    };
  });

  return (
    <Container>
      <Flex className="badges_container" flexDirection="column" alignItems="center">
        <SliderStyled
          slidesCount={clients.length}
          slidesToShowDesktop={5}
          slidesToShowTablet={5}
          slidesToShowMobile={2}
          slidesToScrollDesktop={1}
          prevArrowStyles="display: none !important;"
          nextArrowStyles="display: none !important;"
          centerPadding="25px"
          centerMode
          autoplay
        >
          {clients.map((client) => (
            <Flex className="card" key={`badge_${client}`}>
              <Flex className="card__img">
                <GatsbyImage image={client.image} />
              </Flex>
            </Flex>
          ))}
        </SliderStyled>
      </Flex>
    </Container>
  );
};

export const awardsQuery = graphql`
  {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "icons/web3/clients" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

export default ClientsSlider;
