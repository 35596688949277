import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import StatisticsBanner from '../homePage/StatisticsBanner';
import { PageContent, PageContainer } from '../homePage/styled';
import MessageCard from '../Card/MessageCard';
import ListWithSmallIcons from '../ListWithSmallIcons';

import colors2 from '../../utils/theme/theme2/colors2';

import AssembleIcon from '../../assets/icons/updateDesign/dedicated/assemble.svg';
import AssembleScrollIcon from '../../assets/icons/updateDesign/dedicated/assemble-hover.svg';
import TestingIcon from '../../assets/icons/updateDesign/dedicated/testing.svg';
import TestingScrollIcon from '../../assets/icons/updateDesign/dedicated/testing-hover.svg';

import DiscoveryIcon from '../../assets/icons/updateDesign/dedicated/discovery.svg';
import DiscoveryScrollIcon from '../../assets/icons/updateDesign/dedicated/discovery-hover.svg';
import SupportIcon from '../../assets/icons/updateDesign/dedicated/support.svg';
import SupportScrollIcon from '../../assets/icons/updateDesign/dedicated/support-hover.svg';

import FlexibilityIcon from '../../assets/icons/updateDesign/dedicated/flexibility.svg';
import CostEffectivenessIcon from '../../assets/icons/updateDesign/dedicated/cost-effectiveness.svg';
import TeamCohesionIcon from '../../assets/icons/updateDesign/dedicated/team-cohesion.svg';
import ScalabilityIcon from '../../assets/icons/updateDesign/dedicated/scalability.svg';
import KnowledgeIcon from '../../assets/icons/updateDesign/dedicated/knowledge.svg';
import OwnershipIcon from '../../assets/icons/updateDesign/dedicated/ownership.svg';

import TeamIcon from '../../assets/icons/updateDesign/team.svg';
import StarIcon from '../../assets/icons/updateDesign/star.svg';
import ChooseTechnologies from '../ChooseTechnologies';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ProcessComponent from '../ProcessComponent';

import BlueArrowIcon from '../../assets/icons/updateDesign/blueArrow.svg';
import SlashIcon from '../../assets/icons/updateDesign/slash.svg';
import ServiceListBlock from '../ServiceList';
import ContactUsForm from '../ContactUsForm';

const list = [
  {
    icon: <StarIcon />,
    title: 'Dedicated development center',
    text: 'With our dedicated development center, we provide you an experienced and dedicated team that works exclusively on your project. \n\nYou can save time and money on recruitment, training, and infrastructure costs, as we provide everything you need to get started right away. \n\nOur team members are experts in their respective fields and have extensive experience in delivering high-quality projects on time and within budget.',
  },
  {
    icon: <TeamIcon />,
    title: 'Team extension',
    text: 'Our team extension services allow you to scale up your development team quickly and easily, without the need for a long and costly hiring process. \n\nWe can provide you with talented developers, designers, and project managers who will seamlessly integrate with your existing team and work towards your goals. You have full control over the team, with the ability to manage them directly and assign tasks as needed, while we take care of all the administrative tasks.',
  },
];

const expertise = [
  {
    icon: <FlexibilityIcon />,
    title: 'Flexibility',
    text: 'The dedicated team model offers a high degree of flexibility in terms of team size, skillset, and project scope.',
  },
  {
    icon: <CostEffectivenessIcon />,
    title: 'Cost-effectiveness',
    text: 'Hiring a dedicated team can be more cost-effective than building an in-house team or outsourcing on a project-by-project basis.',
  },
  {
    icon: <TeamCohesionIcon />,
    title: 'Team cohesion',
    text: 'A dedicated team model ensures a cohesive team that works together on the same project for an extended period, leading to better communication, collaboration, and productivity.',
  },
  {
    icon: <ScalabilityIcon />,
    title: 'Scalability',
    text: 'The dedicated team model is highly scalable, allowing businesses to easily adjust team size based on their changing project needs.',
  },
  {
    icon: <KnowledgeIcon />,
    title: 'Knowledge retention',
    text: "With a dedicated team, knowledge retention is higher since team members are committed to a project for a longer period and gain a deeper understanding of the client's business and industry.",
  },
  {
    icon: <OwnershipIcon />,
    title: 'Ownership',
    text: 'The dedicated team model ensures team members have a greater sense of ownership and commitment to the project, resulting in higher quality work and better outcomes.',
  },
];

const work = [
  {
    number: 'step 1',
    icon: <DiscoveryIcon />,
    onScrollIcon: <DiscoveryScrollIcon />,
    title: 'Gather requirements',
    text: 'We start by discussing your project requirements, goals, and timeline to determine what kind of team you need.',
  },
  {
    number: 'step 2',
    icon: <AssembleIcon />,
    onScrollIcon: <AssembleScrollIcon />,
    title: 'Assemble a team',
    text: 'Our team works closely with you to ensure a seamless onboarding process and clear communication throughout the development process. We follow agile methodologies to ensure that development is iterative and adapts to your needs as they evolve.',
  },
  {
    number: 'step 3',
    icon: <TestingIcon />,
    onScrollIcon: <TestingScrollIcon />,
    title: 'Quality assurance and testing',
    text: 'Our QA team conducts rigorous testing to ensure that your product is of the highest quality and meets your specifications.',
  },
  {
    number: 'step 4',
    icon: <SupportIcon />,
    onScrollIcon: <SupportScrollIcon />,
    title: 'Ongoing support and maintenance',
    text: "We provide ongoing support and maintenance to ensure that your product stays up to date and continues to meet your business needs. \n\nWith our dedicated team model, you get the benefits of an experienced and dedicated team of professionals who are fully committed to your project's success.",
  },
];

const techData = [
  ['Python', 'iOS', 'Android', '.NET'],
  ['React Native', <SlashIcon />, 'Node.js', 'Angular', 'Go'],
  ['QA', 'Vue.js', 'DevOps', 'PHP', <BlueArrowIcon />],
];

const DedicatedTeamPage = () => {
  const { puzzle, message } = useStaticQuery(query);

  return (
    <PageContainer>
      <PageContent>
        <div className="service-wrapper">
          <TopPageSectionWrapper
            title="Dedicated team"
            description="Looking for a reliable partner to build a dedicated software development team for your web
        and mobile app projects? Dashbouquet Development provides top-notch IT outsourcing services
        and custom web and mobile development services for small businesses with transparent pricing
        and flexible engagement models."
          />
          <ServiceListBlock
            title="Our team can help you with"
            image={puzzle}
            list={list}
            bgColor={colors2.lightGreen}
            iconColor={colors2.black}
          />
        </div>
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
      </PageContent>
      <Works />
      <PageContent>
        <ProcessComponent
          title="How it works"
          subtitle="At Dashbouquet, we've refined our dedicated team model to ensure a smooth and efficient process for our clients. Here's how it works:"
          data={work}
        />
        <MessageCard
          image={message}
          title="Ready to start your custom app development?"
          subtitile="Contact us today to schedule a consultation and get a project estimate."
          button="Contact us"
        />
        <ListWithSmallIcons title="Pros of a dedicated team model" data={expertise} />
        <ChooseTechnologies
          techData={techData}
          title="Choose your expert"
          description="Here's a list of available tech specialists in our company:"
        />
        <StatisticsBanner />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    puzzle: file(relativePath: { eq: "images/updateDesign/puzzle.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default DedicatedTeamPage;
