import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import { PageContent, PageContainer } from '../homePage/styled';
import MessageCard from '../Card/MessageCard';
import ListWithSmallIcons from '../ListWithSmallIcons';
import OurExperts from '../AboutUsBlock/OurExperts';
import EndlessPossibilities from './EndlessPossibilities';
import colors2 from '../../utils/theme/theme2/colors2';
import DollarIcon from '../../assets/icons/updateDesign/ecommerceMobileDev/dollar.svg';
import MobileAppsIcon from '../../assets/icons/updateDesign/ecommerceMobileDev/mobileApps.svg';
import CartIcon from '../../assets/icons/updateDesign/ecommerceMobileDev/cart.svg';
import GraphIcon from '../../assets/icons/updateDesign/ecommerceMobileDev/graph.svg';
import StarIcon from '../../assets/icons/updateDesign/ecommerceMobileDev/experience.svg';

import {
  AnalyticsIcon,
  BrandRecognitionIcon,
  FeedbackIcon,
  IncreaseIcon,
  NotificationIcon,
} from '../../assets/icons/updateDesign/ecommerceMobileDev';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ChooseTechnologies from '../ChooseTechnologies';
import BagIcon from '../../assets/icons/updateDesign/ecommerceMobileDev/bag.svg';
import ArrowUpIcon from '../../assets/icons/updateDesign/ecommerceMobileDev/arrowUp.svg';
import ServiceListBlock from '../ServiceList';
import ContactUsForm from '../ContactUsForm';

const buisnessNeedsList = [
  {
    icon: <DollarIcon />,
    title: 'Mobile e-commerce apps can generate 55% more revenue per user.',
    text: 'Mobile shoppers spend twice as much money as other customers.',
  },
  {
    icon: <GraphIcon />,
    title: 'Mobile apps convert 3 times better than websites.',
    text: "Don't miss out on the opportunity to maximize your revenue.",
  },
  {
    icon: <MobileAppsIcon />,
    title: '35% of users only use their phone to buy online.',
    text: 'According to Forbes, 85% of online shoppers prefer eCommerce mobile apps over eCommerce websites.',
  },
  {
    icon: <CartIcon />,
    title: 'The average cart abandonment rate is 69.99%.',
    text: 'E-commerce mobile apps reduce friction in the checkout process, keeping the drop rate low.',
  },
  {
    icon: <StarIcon />,
    title: 'Conversions can grow 7-12% when users have a personalized experience.',
    text: "With an app, you can track users' browsing and purchase history to offer personalized recommendations.",
  },
];

const expertise = [
  {
    icon: <BrandRecognitionIcon />,
    title: 'Brand recognition',
    text: 'Mobile applications support the deep connection between brands and customers.',
  },
  {
    icon: <NotificationIcon />,
    title: 'Engage your customers',
    text: 'Send push notifications, show personalized offers, and communicate with your customers directly through the app.',
  },
  {
    icon: <FeedbackIcon />,
    title: 'Increase customer loyalty',
    text: 'Collect customer feedback, reviews, and use data to improve your inventory and service quality.',
  },
  {
    icon: <IncreaseIcon />,
    title: 'Increase your revenue',
    text: 'A well-designed app with the proper functionality and concept will attract more clients and result in more orders.',
  },
  {
    icon: <AnalyticsIcon />,
    title: 'Detailed analytics',
    text: 'Our e-commerce tracking app helps gather data such as customer sessions, responsiveness to particular features and content, audience composition, and most-liked products. This data helps create personalized content, run efficient promotional campaigns, and develop an advanced marketing strategy.',
  },
];
const techData = [
  [<ArrowUpIcon />, 'Shopify', 'WooCommerce'],
  ['Oracle CX Commerce', <BagIcon />, 'BigCommerce'],
  ['Magento', 'Custom systems'],
];

const ECommerceDevelopmentPage = () => {
  const { foodTrolley, message } = useStaticQuery(query);

  return (
    <PageContainer>
      <PageContent>
        <div className="service-wrapper">
          <TopPageSectionWrapper
            title="E-commerce software development"
            description="We develop custom e-commerce applications that work seamlessly across all platforms,
        allowing for continuous updates and new features. We also integrate with your existing CRM,
        inventory management system and business intelligence tools to help you sell even more to
        your customers."
          />
          <ServiceListBlock
            title="Our offer"
            description="We offer a comprehensive service to design, build, and publish fully custom mobile apps for your e-commerce business or marketplace. Our pricing starts at $9999 and includes connecting your app to your existing backend systems."
            image={foodTrolley}
            bgColor={colors2.lightGreen}
          />
        </div>
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
        <ServiceListBlock
          title="Why your e-commerce business needs an app"
          iconColor={colors2.blue}
          list={buisnessNeedsList}
          bgColor={colors2.lightBlue}
        />
      </PageContent>
      <Works />
      <PageContent>
        <ChooseTechnologies title="Platforms we work with" techData={techData} />
        <MessageCard
          image={message}
          title="Ready to start your custom app development?"
          subtitile="Contact us today to schedule a consultation and get a project estimate."
          button="Contact us"
        />
        <ListWithSmallIcons
          className="main-benefits_block"
          title="Main benefits of building an e-commerce app with us"
          data={expertise}
        />
        <OurExperts />
        <EndlessPossibilities />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    foodTrolley: file(relativePath: { eq: "images/updateDesign/foodTrolley.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default ECommerceDevelopmentPage;
