import React, { useEffect, useState } from 'react';
import { Titles, Wrapper } from './styled';
import { H2Text, H3Text, H4Text, P2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';

const ProcessComponent = ({ data, title, subtitle }) => {
  const [offset, setOffset] = useState(0);
  const [isDocument, setIsDocument] = useState();

  useEffect(() => {
    setIsDocument(typeof document !== 'undefined');
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return (
    <Wrapper withoutIcons className="list-with-big-icons_wrapper">
      <Titles className="list-with-big-icons_titles">
        {title && <H2Text style={{ display: 'block' }}>{title}</H2Text>}
        {subtitle && (
          <P2Text color={colors2.lightText} style={{ display: 'block' }}>
            {subtitle}
          </P2Text>
        )}
      </Titles>
      {data?.map((item) => {
        const isFocused =
          isDocument &&
          document.getElementById(item.title)?.offsetTop < offset + 300 &&
          document.getElementById(item.title)?.offsetTop > offset - 300;
        return (
          <div className="step" key={item.title} id={item.title}>
            {isFocused ? (
              <div className="number">{item.onScrollIcon}</div>
            ) : (
              <div className="number">{item.icon}</div>
            )}

            <div className="info">
              <H4Text className="numberOfStep" style={{ color: isFocused ? colors2.blue : '' }}>
                {item.number}
              </H4Text>
              <H3Text color={colors2.black} className="title">
                {item.title}
              </H3Text>
              <P2Text color={colors2.lightText} className="text">
                {item.text}
              </P2Text>
            </div>
          </div>
        );
      })}
    </Wrapper>
  );
};

export default ProcessComponent;
