import React from 'react';
import { Link } from 'gatsby';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { NewsContent } from './styled';
import { H2Text } from '../Typography/styled';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import ArrowBtn from '../Buttons/ArrowBtn';
import BlogCard from '../BlogCard';
import colors2 from '../../utils/theme/theme2/colors2';

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  .box:nth-child(3n + 3) {
    grid-column: auto / span 2;
    grid-template-columns: 1fr 1fr;

    @media ${breakpoints.md} {
      grid-column: auto;
      grid-template-columns: 1fr 1fr;
    }

    @media ${breakpoints.sm} {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
      > div {
        grid-template-columns: 1fr;
      }
    }
  }

  .box:nth-child(3n + 1),
  .box:nth-child(3n + 2) {
    grid-column: auto / span 1;
    grid-template-rows: auto 1fr;
    > div {
      grid-template-columns: 1fr;
    }

    .gatsby-image-wrapper {
      grid-row: 1;
    }

    @media ${breakpoints.md} {
      grid-column: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;

      > div {
        grid-template-columns: 1fr 1fr;
      }

      .gatsby-image-wrapper {
        grid-row: auto;
      }
    }

    @media ${breakpoints.sm} {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;

      > div {
        grid-template-columns: 1fr;
      }

      .gatsby-image-wrapper {
        grid-row: 1;
      }
    }
  }

  @media ${breakpoints.md} {
    grid-template-columns: 1fr;
  }

  @media ${breakpoints.sm} {
    grid-column: auto / span 1;
    grid-template-rows: auto 1fr;
    > div {
      grid-template-columns: 1fr;
    }

    .gatsby-image-wrapper {
      grid-row: 1;
    }
  }
`;

const News = ({ news }) => {
  return (
    <NewsContent>
      <H2Text>Latest news</H2Text>
      <Flex className="news_container" flexDirection="column">
        <Main>
          {news.map((data) => (
            <BlogCard className="box" key={data.node.id} data={data} />
          ))}
        </Main>
      </Flex>
      <Link to="/blog" style={{ marginTop: '30px', alignSelf: 'end' }}>
        <ArrowBtn color={colors2.black} text="Read all articles" />
      </Link>
    </NewsContent>
  );
};

export default News;
