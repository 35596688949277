import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ReliableTeamContent, ButtonsContainer } from './styled';
import { H1Text, P1Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { DefaultButton } from '../Buttons/styled';

import Link from '../Link';

export const reliableTeamQuery = graphql`
  {
    team: file(relativePath: { eq: "images/updateDesign/team-header.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const ReliableTeam = () => {
  const { team } = useStaticQuery(reliableTeamQuery);

  return (
    <ReliableTeamContent>
      <H1Text style={{ textAlign: 'center' }}>
        Reliable team
        <GatsbyImage image={team.childImageSharp.gatsbyImageData} alt="header-team" />
        aligned to deliver solutions
      </H1Text>
      <P1Text
        color={colors2.lightText}
        className="content_description"
        style={{ textAlign: 'center' }}
      >
        Empower your business with a custom software solution. Start a project in 10 days, review
        new features every 1–3 weeks, and get a quality product within a budget.
      </P1Text>
      <ButtonsContainer>
        <Link to="/contact-us">
          <DefaultButton>Contact us</DefaultButton>
        </Link>
        <Link to="/mvp-calculator">
          <DefaultButton className="light" bgColor={colors2.lightGray} color={colors2.black}>
            MVP-Calculator
          </DefaultButton>
        </Link>
      </ButtonsContainer>
    </ReliableTeamContent>
  );
};

export default ReliableTeam;
