import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import PlusIcon from '../../assets/icons/updateDesign/down.svg';
import { H4Text } from '../Typography/styled';
import { QuestionContainer, AnswerWrapper, HtmlWrapper } from './styled';

const FaqBox = ({
  data: {
    html,
    frontmatter: { title },
  },
}) => {
  const [open, setOpen] = useState(false);
  const [answerRef, setAnswerRef] = useState(0);
  const boxRef = useRef(null);
  useEffect(() => {
    setAnswerRef(boxRef.current.scrollHeight);
  }, [boxRef]);
  return (
    <QuestionContainer onClick={() => setOpen(!open)}>
      <Flex justifyContent="space-between">
        <H4Text width="calc(100% - 50px)">{title}</H4Text>
        <Flex alignItems="center">
          <PlusIcon
            id="icon"
            width="20px"
            height="20px"
            style={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              transitionDuration: '0.3s',
              transitionProperty: 'transform',

              background: 'transparent',
            }}
          />
        </Flex>
      </Flex>
      <AnswerWrapper id="answer" ref={boxRef} open={open} answerRef={answerRef}>
        <HtmlWrapper dangerouslySetInnerHTML={{ __html: html }} />
      </AnswerWrapper>
    </QuestionContainer>
  );
};

FaqBox.propTypes = {
  data: PropTypes.shape({
    html: PropTypes.string,
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default FaqBox;
