import React from 'react';

export default () => (
  <form name="DashbouquetForm" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
    <input type="text" name="text" />
    <input type="text" name="name" />
    <textarea name="footer-message" />
    <input type="email" name="email-text" />
    <input name="bot-field" />
    <input type="hidden" name="form-name" value="DashbouquetForm" />
    <input type="hidden" name="user-came-from" />
    <input type="hidden" name="form-type" />
    <input type="hidden" name="subject" value="Dashbouquet form submission" />
    <button type="submit">Send</button>
  </form>
);

// You must add js file with form in pages folder if you want register it on Netlify. It can be deleted.
