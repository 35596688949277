import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';

export const CategoryWrapper = styled(Link)`
  border: solid 1px rgba(38, 38, 38, 0.12);
  border-radius: 60px;
  padding: 15px 30px;
  text-decoration: none;
`;

export const SearchInput = styled.input`
  border: solid 1px rgba(38, 38, 38, 0.12);
  border-radius: 60px;
  padding: 15px 30px 15px 60px;
  text-decoration: none;
  outline: none;

  @media ${breakpoints.sm} {
    width: 100%;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  margin-bottom: 50px;
`;
