import React from 'react';
import styled from 'styled-components';
import { marked } from 'marked';
import colors2 from '../utils/theme/theme2/colors2';
import { breakpoints } from '../utils/theme/theme2/breakpoints';

export const MarkdownBlock = styled(({ html, content, className }) => (
  <div
    className={`post-body ${className || ''}`}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: html || marked.parse(content) }}
  />
))`
  h3,
  h2,
  colorwrapper {
    color: ${colors2.black};
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    margin-top: 80px;
    margin-bottom: 40px;
  }
  colorwrapper {
    span:first-child {
      color: ${(props) => (props.colorTheme && props.colorTheme.textColor) || 'unset'};
    }
  }
  h4 {
    color: ${colors2.black};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 165%;
  }
  a {
    color: ${colors2.blue};
    text-decoration: none;
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 165%;
    color: ${colors2.lightText};
  }
  ol,
  ul {
    color: ${colors2.lightText};
    list-style-position: inside;
  }
  strong {
    font-weight: 500;
    color: ${colors2.black};
  }
  .gatsby-resp-image-wrapper {
    width: 100%;
    max-width: unset !important;
    border-radius: 40px;
    display: flex !important;
  }
  .gatsby-resp-image-image {
    height: unset !important;
    border-radius: 40px;
    object-fit: contain;
    object-position: center;
  }
  iconwrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    align-items: flex-start;
    .img-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      max-width: 38px;
      min-width: 38px;
      height: 38px;
      background: ${(props) => (props.colorTheme && props.colorTheme.bgColor) || ''};
    }
    img {
      ${(props) => (props.colorTheme && props.colorTheme.filter) || ''}
      margin: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: transparent;
    }
  }

  blockquotewrapper {
    background: ${colors2.lightGray};
    padding: 60px 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 40px;

    & span {
      color: ${colors2.black};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 165% !important;
    }
    .blockquote-author {
      display: flex;
      gap: 15px;
      & span:first-child {
        white-space: nowrap;
      }
    }
  }
  @media ${breakpoints.mds} {
    h2,
    h3,
    colorwrapper {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
    }
    blockquotewrapper {
      padding: 40px;
      & span {
        font-size: 16px;
      }
    }
  }
  @media ${breakpoints.sm} {
    h2,
    h3,
    colorwrapper {
      font-size: 18px;
      line-height: 30px;
      margin-top: 60px;
      margin-bottom: 30px;
    }
    p,
    ul {
      font-size: 14px;
    }
    blockquotewrapper {
      padding: 30px;
      & span {
        font-size: 14px;
      }
    }
  }
`;
