import React from 'react';
import { Flex, Box } from 'rebass';
import { graphql, Link, useStaticQuery } from 'gatsby';
import WorksSliderCard from '../WorksSliderCard';
import { WorksContent, WorksLink } from './styled';
import colors2 from '../../utils/theme/theme2/colors2';
import { H2Text } from '../Typography/styled';
import { breakpoints } from '../../utils/theme/theme2/breakpoints';
import Slider from '../Slider';
import ArrowBtn from '../Buttons/ArrowBtn';

const Works = () => {
  const { pyroscope, airr, hvmn } = useStaticQuery(worksQuery);

  const worksList = [
    {
      title: 'Pyroscope',
      desc: `Partnering with Dashbouquet, Pyroscope increased revenue by 2x and the number of DAU by — 30%.   The company raised a seed round in 2021 and was acquired by Grafana Labs.`,
      image: pyroscope,
      tags: ['Web'],
      class: 'green',
      bgColor: colors2.lightGreen,
      link: '/our-work/pyroscope',
    },
    {
      title: 'Airr',
      desc: `AIRR is one SaaS solution for all investment management needs that consolidates data from any front or back-office systems you use on a daily basis and seamlessly fit into your investment decision process.`,
      image: airr,
      tags: ['Mobile', 'Finance', 'SaaS'],
      bgColor: colors2.blue,
      link: '/our-work/airr',
    },
    {
      title: 'HVMN',
      desc: `Our client is a US-based company that manufactures and sells products for the ketogenic diet. The client needed a robust development team to work on the e-commerce store and requested DashBouquet to provide the needed specialists for them.`,
      image: hvmn,
      tags: ['Web', 'E-commerce'],
      class: 'purple',
      bgColor: colors2.lightViolet,
      link: '/our-work/hvmn',
    },
  ];
  return (
    <WorksContent className="works-content">
      <Box as="section" id="works-slider" bg="white">
        <Flex flexDirection="column" alignItems="center">
          <Box
            css={{
              marginBottom: '80px',
              '@media screen and (max-width: 520px)': {
                marginBottom: '60px',
              },
            }}
          >
            <H2Text className="title">Our work</H2Text>
          </Box>

          <Box className="works_slider">
            <Slider
              slidesCount={worksList.length}
              slidesToShowDesktop={1}
              slidesToShowTablet={1}
              slidesToScrollDesktop={1}
              centerMode={true}
              variableWidth={true}
              prevArrowStyles={`
              top: unset !important;
              bottom: 0px !important;
              left: 46% !important;
              :hover {
                opacity: 0.7;x
              }
              @media ${breakpoints.md} {
                left: 45% !important;
              }
            `}
              nextArrowStyles={`
              top: unset !important;
              bottom: 0px;
              right: 46% !important;
              :hover {
                opacity: 0.7;
              }
              @media ${breakpoints.md} {
                right: 45% !important;
              }
            `}
            >
              {worksList.map((el, indx) => {
                return <WorksSliderCard el={el} key={`works-item_${indx}`} />;
              })}
            </Slider>
          </Box>

          <WorksLink to="/our-work">
            <ArrowBtn color={colors2.black} text="View all Portfolio" />
          </WorksLink>
        </Flex>
      </Box>
    </WorksContent>
  );
};

export const worksQuery = graphql`
  {
    pyroscope: file(relativePath: { eq: "images/ourWorks/pyroscope_u.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    airr: file(relativePath: { eq: "images/ourWorks/airr_u.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    hvmn: file(relativePath: { eq: "images/ourWorks/hvmn_u.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default Works;
