import React from 'react';
import { Flex } from 'rebass';
import {
  CoreExpertiseWrapper,
  CoreExpertiseCards,
  TextBox,
  IconBox,
  CoreExpertiseCard,
} from './styled';
import { H3Text, H2Text, P2Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import ArrowBtn from '../Buttons/ArrowBtn';
import Link from '../Link';

const CoreExpertise = ({ data, title, description }) => {
  return (
    <CoreExpertiseWrapper>
      <Flex>
        <H2Text>{title}</H2Text>
        <P2Text>{description}</P2Text>
      </Flex>

      <CoreExpertiseCards>
        {data.map((el, indx) => {
          return (
            <CoreExpertiseCard key={`core-expertise-card${indx}`}>
              <IconBox>{el.icon}</IconBox>
              <TextBox>
                <H3Text className="content__title">{el.title}</H3Text>
                <P2Text color={colors2.lightText} className="content__desc">
                  {el.desc}
                </P2Text>
                {el.link && (
                  <Link to={el.link}>
                    <div className="content__btn">
                      <ArrowBtn />
                    </div>
                  </Link>
                )}
              </TextBox>
            </CoreExpertiseCard>
          );
        })}
      </CoreExpertiseCards>
    </CoreExpertiseWrapper>
  );
};

export default CoreExpertise;
