import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Badges from '../homePage/Badges';
import Reviews from '../homePage/Reviews';
import Works from '../homePage/Works';
import Technologies from '../homePage/Technologies';
import { PageContent, PageContainer } from '../homePage/styled';
import WeAreReadyToBuild from './WeAreReadyToBuild';
import ProcessSection from './ProcessSection';
import MessageCard from '../Card/MessageCard';
import WebDevelopmentExpertise from './WebDevelopmentExpertise';
import colors2 from '../../utils/theme/theme2/colors2';
import LogoIcon from '../../assets/icons/updateDesign/logo.svg';
import TopPageSectionWrapper from '../TopPageSectionWrapper';
import ServiceListBlock from '../ServiceList';
import ContactUsForm from '../ContactUsForm';

const list = [
  {
    icon: <LogoIcon />,
    title: 'Frontend development services',
    text: 'Our web developers create beautiful user interfaces, ensuring smooth navigation and interactiveness. We make apps and websites that look and behave as intended across all screens and devices.',
    link: '/services/frontend-development',
  },
  {
    icon: <LogoIcon />,
    title: 'Backend development services',
    text: 'Our team will choose the best software architecture, build a complex business logic, and integrate various APIs. Using advanced technologies and best practices, we’ll enable high availability, scalability, and performance.',
    link: '/services/backend-development',
  },
];

const WebDevelopmentPage = () => {
  const { message } = useStaticQuery(query);
  return (
    <PageContainer>
      <PageContent>
        <TopPageSectionWrapper
          title="Web development services for small and medium-sized business"
          description="Build a web solution that meets end-user needs. Start your project in 10 days, test new
          features in action every 2-3 weeks, get a web product within a budget."
        />

        <ServiceListBlock
          title="Web development services we deliver"
          iconColor={colors2.blue}
          list={list}
          bgColor={colors2.lightBlue}
        />
        <div className="badges-wrapper">
          <Badges />
          <Reviews />
        </div>
        <WeAreReadyToBuild />
      </PageContent>
      <Works />
      <PageContent>
        <MessageCard
          image={message}
          title="Aim to build a web product?"
          subtitile="Let’s put your idea into life."
          button="Contact us"
          style={{ margin: '0' }}
        />
        <ProcessSection />
        <WebDevelopmentExpertise />
        <Technologies />
        <ContactUsForm />
      </PageContent>
    </PageContainer>
  );
};

export const query = graphql`
  {
    message: file(relativePath: { eq: "images/updateDesign/message.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default WebDevelopmentPage;
