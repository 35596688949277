import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors2 from '../../utils/theme/theme2/colors2';
import { P1Text } from '../Typography/styled';

const PagingLine = styled(P1Text)`
  display: block;
  position: relative;
  border-top: 4px solid ${colors2.lightViolet};
  width: ${(props) => props.pagingLineWidth}px;
  height: 0;
  margin-left: 10px;
`;

const AnimatedPagingLine = styled(P1Text)`
  position: absolute;
  top: -3.5px;
  border-top: 4px solid ${colors2.violet};
  width: ${(props) => props.pagingAnimatedLineWidth}px;
  transition: width 1s;
`;

const SliderPagingLine = ({ pagingLineWidth, pagingAnimatedLineWidth, pagingLineColor }) => (
  <PagingLine pagingLineWidth={pagingLineWidth} pagingLineColor={pagingLineColor}>
    <AnimatedPagingLine as="span" pagingAnimatedLineWidth={pagingAnimatedLineWidth} />
  </PagingLine>
);

SliderPagingLine.propTypes = {
  pagingLineWidth: PropTypes.number,
  pagingAnimatedLineWidth: PropTypes.number,
  pagingLineColor: PropTypes.string,
};

SliderPagingLine.defaultProps = {
  pagingLineWidth: 130,
  pagingAnimatedLineWidth: 0,
  pagingLineColor: colors2.lightText,
};

export default SliderPagingLine;
