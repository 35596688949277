export const CONTACT_EMAIL = 'contact@dashbouquet.com';
export const INFO_EMAIL = 'info@dashbouquet.com';
export const OUTREACH_EMAIL = 'outreach@dashbouquet.com';
export const CONTACT_FORM_NAME = 'DashbouquetForm';
export const CONTACT_FORM_FIELD_NAMES = {
  NAME: 'name',
  EMAIL: 'email-text',
  MESSAGE: 'footer-message',
};
export const CONTACT_PAGE_FORM_FIELD_NAMES = {
  NAME: 'contact-name',
  EMAIL: 'contact-email',
  MESSAGE: 'contact-message',
  BUDGET: 'contact-budget',
  PHONE: 'contact-phone',
  FILE: 'contact-file',
};

export const SHEETBEST_CONNECTION_URL =
  'https://sheet.best/api/sheets/c1e3d8a2-9193-4a17-ad51-be3bb97f49b2';

export const SMTP_SECURE_TOKEN = '706ad131-6e61-4cd8-84c1-c9e2908f5057';
export const DASHAPPS_CONNECTION_URL = 'https://apps.o.dashapps.io/events/mvp/submit';
export const DASHAPPS_CONNECTION_URL_FOR_FORMS =
  'https://apps.o.dashapps.io/events/leadform/submit';
