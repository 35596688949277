import React, { useEffect, useState } from 'react';
import MVPCalculatorStepsData from '../../configs/mvp-calculator/MVPCalculatorStepsData';

const getMVPCalculatorStepDataByValue = (value) =>
  MVPCalculatorStepsData.find((step) => step.value === value);

const withCurrentStepData =
  (Wrapper) =>
  ({ currentStep, ...props }) => {
    const [currentStepData, setCurrentStepData] = useState(
      getMVPCalculatorStepDataByValue(currentStep)
    );

    useEffect(() => {
      setCurrentStepData(getMVPCalculatorStepDataByValue(currentStep));
    }, [currentStep]);

    return <Wrapper currentStepData={currentStepData} {...props} />;
  };

export default withCurrentStepData;
